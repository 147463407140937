import React from "react";
import {
    Grid,
    Paper,
    Typography,
    Backdrop,
    CircularProgress,
    Dialog,
    IconButton,
    Toolbar,
    AppBar,
    Slide
} from '@material-ui/core/';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Close } from '@material-ui/icons/';
import MaterialTable from "material-table";

import { deleteAd } from '../../../actions/80/MarketingAdApis';
import CreateForm from '../CreateForm';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        createFormContainer: {
            //padding: '100px'
        }
    })
);

const AdsView = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dataWantToEdit, setDataWantToEdit] = React.useState({});
    const [dilaogType, setDilaogType] = React.useState("create");
    const [columns, setColumns] = React.useState([
        {
            title: 'Location Type',
            field: 'LocationType',
            render: rowData => rowData.LocationType.Name,
            lookup: Object.assign({}, ...props.locationsTypes.map(object => ({ [object.Name]: object.Name }))),
        },
        {
            title: 'Location',
            field: 'Location',
            initialEditValue: 'initial edit value',
            render: rowData => rowData.Location.Name,
            lookup: {
                1: 'Shrak',
                2: 'Soliter'
            },
        },
        {
            title: 'Location Image',
            field: 'Location',
            render: rowData => <img
                src={`${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
                    `/${rowData.Location.ImageUrl}`
                )}
                style={{ width: 100 }}
            />,
            editable: 'never',
        },
        {
            title: 'Title',
            field: 'Title',
        },
        {
            title: 'Status',
            field: 'Status',
            lookup: { true: 'Valid', false: 'Invalid' },
        },
    ]);

    const [data, setData] = React.useState([]);

    const handleDeleteAd = async (oldData) => {
        let response = await deleteAd(oldData.Id);
        if (response) {
            setData((prevState) => {
                const data = prevState;
                const elementId = (element) => element.Id === oldData.Id;
                data.splice(data.findIndex(elementId), 1);
                return { data };
            });
            props.refreshTable && props.refreshTable();
            props.onShowMsg && props.onShowMsg("DeleteAdSuccess");
        } else {
            props.onShowMsg && props.onShowMsg("DeleteAdErr");
            setLoading(false);
        }
    }

    const prepareTableData = () => {
        setData(props.adsData);
        setLoading(false);
    }

    React.useEffect(() => {
        prepareTableData();
    }, [props.adsData]);

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress color="inherit" />
            </Backdrop >
            {
                (!loading) && (
                    <Paper>
                        <Grid container justify="center">
                            <Grid item xs={12} style={{ padding: '20px' }}>
                                <MaterialTable
                                    title="Editable Preview"
                                    columns={columns}
                                    data={data}
                                    options={{
                                        //search: false,
                                        showTitle: false,
                                        actionsColumnIndex: -1,
                                        headerStyle: {
                                            backgroundColor: '#5A6B75',
                                            color: '#FFF'
                                        },
                                        pageSize: 10,
                                        pageSizeOptions: [10, 30, 50, { value: data.length, label: 'All' }],
                                    }}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Edit Ad',
                                            onClick: (event, rowData) => {
                                                setDilaogType("edit");
                                                setDataWantToEdit(rowData);
                                                setOpenDialog(true);
                                            }
                                        },
                                        {
                                            icon: 'add',
                                            tooltip: 'Add Ad',
                                            isFreeAction: true,
                                            onClick: (event, rowData) => {
                                                setDilaogType("create");
                                                setOpenDialog(true);
                                            }
                                        }
                                    ]}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    resolve()
                                                    handleDeleteAd(oldData)
                                                }, 600)
                                            }),
                                    }}
                                    detailPanel={
                                        [
                                            {
                                                tooltip: 'Show Advertisement Image',
                                                render: rowData => {
                                                    return (
                                                        <div
                                                            style={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {
                                                                (rowData.AdvertisementImages[0]) ? (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
                                                                            `/${rowData.AdvertisementImages[0].ImageUrl}`
                                                                        )}
                                                                    />
                                                                ) : (
                                                                        <Typography>No Image</Typography>
                                                                    )
                                                            }
                                                        </div>
                                                    )
                                                },
                                            },
                                        ]}
                                />
                            </Grid>
                        </Grid>
                        {/* <EditDialog
                            onOpen={openDialog}
                            onClose={() => {
                                setOpenDialog(false)
                                props.refreshTable && props.refreshTable()
                            }}
                            data={dataWantToEdit}
                            locationsTypes={props.locationsTypes}
                            onShowMsg={props.setNotificationMsg}
                            type={dilaogType}
                        /> */}
                        <Dialog
                            fullScreen
                            open={openDialog}
                            onClose={() => {
                                setOpenDialog(false)
                                props.refreshTable && props.refreshTable()
                            }}
                            TransitionComponent={Transition}
                        >
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            setOpenDialog(false)
                                            props.refreshTable && props.refreshTable()
                                        }}
                                        aria-label="close"
                                    >
                                        <Close />
                                    </IconButton>
                                    {/* <Typography
                                        variant="h6"
                                        className={classes.title}
                                    >
                                        Edit 
                                    </Typography> */}
                                </Toolbar>
                            </AppBar>
                            <div className={classes.createFormContainer}>
                                <CreateForm
                                    locationsTypes={props.locationsTypes}
                                    onShowMsg={props.onShowMsg}
                                    type={dilaogType}
                                    adData={dataWantToEdit}
                                />
                            </div>
                        </Dialog>
                    </Paper>
                )
            }
        </React.Fragment >
    );
}

export default AdsView;
