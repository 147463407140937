import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const InventoryTableToolbar = () => (
  <Toolbar className='table-toolbar'>
    <Typography variant='h6'>
      Products
    </Typography>
  </Toolbar>
);

export default InventoryTableToolbar;
