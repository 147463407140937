import React from 'react';
import { Grid } from '@material-ui/core';
import greenLoader from '../../assests/images/GreenLoader.gif';
import './GreenLoader.scss';

export default () => (
  <Grid container justify='center'>
    <Grid item>
      <img src={greenLoader} alt='Loading' className='green-loader' />
    </Grid>
  </Grid>
);
