import React, { Component } from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import {
  Help, Done, Edit, Close,
} from '@material-ui/icons';

import './QuestionCard.scss';

class QuestionCard extends Component {
  constructor() {
    super();
    this.state = {
      showEdit: false,
    };
  }

  handleShowEdit = (questionContent) => {
    this.setState(prevState => ({
      showEdit: !prevState.showEdit,
      questionContent,
    }));
  }

  handleChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  }

  handleUpdate = (id) => {
    const { questionContent } = this.state;
    this.setState({
      showEdit: false,
    });
  }

  render() {
    const { showEdit, questionContent } = this.state;
    const { question } = this.props;
    return (
      <Grid item xs={12} sm={6} className='question-container'>
        <Paper className='card'>
          <Grid container >
            <Grid item xs={2}>
              <Help fontSize='large' />
            </Grid>
            <Grid item xs>
              {!showEdit && <p>{question.QuestionContent}</p>}
              {showEdit && (
              <textarea
                className='rawabi-textarea'
                value={questionContent}
                onChange={e => this.handleChange('questionContent', e.target.value)}
              />
              )}
            </Grid>
            {!showEdit
            && (
            <Grid item xs={2}>
              <Button
                onClick={() => this.handleShowEdit(question.QuestionContent)}
                fullWidth
              >
                <span className='button-text'><Edit /></span>
              </Button>
            </Grid>
            )}
            {showEdit
            && (
            <Grid item xs={2}>
              <Button
                onClick={() => this.handleUpdate(question.Id)}
                fullWidth
              >
                <span className='button-text'><Done /></span>
              </Button>
              <Button
                onClick={() => this.handleShowEdit(question.QuestionContent)}
                fullWidth
              >
                <span className='button-text'><Close /></span>
              </Button>
            </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default QuestionCard;
