import React, { Component } from 'react';
import { TextField } from '@material-ui/core';

class PricesCell extends Component {
  constructor() {
    super();
    this.state = {
      editPrice: '',
    };
  }

  componentDidMount() {
    const { price } = this.props;
    this.setState({
      editPrice: price,
    });
  }

  handleChangePrice = (value) => {
    const { productRefNo, onChangePrice } = this.props;
    const newPrice = value.replace(',', '.')
      .replace(/[^\d.]/g, '')
      .replace(/\./, 'x')
      .replace(/\./g, '')
      .replace(/x/, '.');
    this.setState({
      editPrice: newPrice,
    }, () => {
      onChangePrice(productRefNo, newPrice);
    });
  }

  render() {
    const { editPrice } = this.state;
    const height = 3;
    return (
      <div style={{textAlign:'center'}}>
        <div className='prices-cell'>
        </div>
        <div className='prices-cell'>
    
           <TextField
              id='outlined-name'
              label='Tag Price'
              margin='normal'
              variant='outlined'
              className='price-input'
              onChange={e => this.handleChangePrice(e.target.value)}
              value={editPrice}
              inputProps={{
                style: {
                  height
                },
              }}
              />

                          
        </div>
      </div>
    );
  }
}
export default PricesCell;
