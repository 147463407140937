import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Login from './pages/Login/Login';
import NavBar from './components/NavBar/NavBar';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { logout } from './services/auth';
import { isAuth, checkUser } from './services/share/DataService';

class App extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    checkUser();
    this.setState({
      isAuthenticated: isAuth(),
    });
  }

  // when the user press the login button.
  setUser = () => {
    this.setState({
      isAuthenticated: true,
    });
  };

  // when the user press the logout button.
  handleLogout = () => {
    logout();
    this.setState({
      isAuthenticated: false,
    });
  };

  render() {
    const { isAuthenticated } = this.state;
    return (
      <BrowserRouter>
        <div className='App'>
          <NavBar isAuthenticated={isAuthenticated} onLogout={this.handleLogout} />
          {/* { checkUser() } */}
          {!isAuthenticated
            && (
              <Switch>
                <Route
                  path='/'
                  component={() => <Login setUser={this.setUser} />}
                />
              </Switch>
            )}
          {isAuthenticated && <PrivateRoute onLogout={this.handleLogout} />}
        </div>
      </BrowserRouter>

    );
  }
}

export default App;
