import React from "react";
import { Grid } from "@material-ui/core";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import {
  SendNewRunnerRunnerStatus,
  getImage,
  UploadReceiptImg
} from "../../../actions/OrderAPI";
import "./ViewOrder.scss";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import TableHead from "@material-ui/core/TableHead";
import FilterSelect from "../../FilterSelect/FilterSelect";
import EditIcon from "@material-ui/icons/Edit";
import Done from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import receiptImg from "../../../assests/images/icons8-receipt-24.png";

const OrderItemStatus = [{ label: "Packing Area", value: 0 }];

class ViewOrders extends React.Component {
  constructor() {
    super();
    this.state = {
      notificationMsg: "",
      ShowDropDownStatus: false,
      ItemID: null,
      selectedOrderItemStatus: { label: "Select", value: null },
      open: false,
      showImg: false,
      Barcode: "",
      Showerror: false,
      receiptImgView: ""
    };
  }

  componentDidMount() {}

  handleClose = () => {
    this.setState({ showImg: false });
  };

  handleCloseUploadImages = () => {
    this.setState({ open: false });
  };

  handleDateFormat = date => {
    const Newdate = new Date(Date.parse(date));
    return (
      Newdate.getFullYear() +
      "/" +
      (Newdate.getMonth() + 1) +
      "/" +
      Newdate.getUTCDate()
    );
  };

  handleCloseExpandClick = index => {
    this.setState({
      ItemID: null,
      ShowDropDownOrderStatus: false
    });
  };

  handleChange = (id, index) => selectedValue => {
    if (id === "selectedOrderStatus") {
      this.setState({
        [id]: selectedValue.label,
        enableUpdateOrderStatus: true,
        ItemID: index
      });
    } else {
      this.setState({
        [id]: selectedValue.label,
        enableUpdate: true
      });
    }
  };

  EditItemStatus = (orderid, index) => {
    this.setState({
      ItemID: index,
      ShowDropDownStatus: true,
      notificationMsg: ""
    });
  };

  handleSaveTrackingStatus = (Barcode, index) => {
    const { selectedOrderItemStatus } = this.state;
    const { OrderNo, data } = this.props;
    this.setState({
      notificationMsg: ""
    });

    SendNewRunnerRunnerStatus(
      "#" + OrderNo,
      Barcode,
      selectedOrderItemStatus
    ).then(res => {
      if (res === null) {
        this.setState({
          notificationMsg: "OrderItemStatusNotUpdated"
        });
      } else {
        data[index].TrackingStatus = selectedOrderItemStatus;
        this.setState({
          notificationMsg: "ItemUpdated",
          ShowDropDownStatus: false,
          ItemID: null,
          enableUpdate: false
        });
      }
    });
  };

  handleViewReceiptImg = Barcode => {
    this.setState({
      receiptImgView: ""
    });
    const { OrderNo } = this.props;
    getImage("#" + OrderNo, Barcode)
      .then(res => {
        this.setState({
          showImg: true,
          receiptImgView: `${process.env.REACT_APP_API_ENDPOINT}`
            .concat("/api/image/order/item?orderNo=")
            .concat(OrderNo + "&orderItemBarcode=" + Barcode),
          Barcode: Barcode
        });
      })
      .catch(() => {
        this.setState({
          showImg: true,
          Showerror: true
        });
      });
  };

  handleImageChange = (event, barcode) => {
    const { id } = event.target;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        this.setState({
          [id]: reader.result,
          open: true,
          OrderItemBarcode: barcode
        });
      };
      image.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleUploadReciept = () => {
    const { recepietImg, OrderItemBarcode } = this.state;
    const { OrderNo } = this.props;
    var base64result = recepietImg.substr(recepietImg.indexOf(",") + 1);
    const toUploadImage = {
      OrderNo: OrderNo,
      OrderItemBarcode: OrderItemBarcode,
      ImageCode: base64result
    };
    UploadReceiptImg(toUploadImage).then(() =>
      this.setState({
        open: false,
        notificationMsg: "ImageUploaded"
      })
    );
  };

  render() {
    const {
      notificationMsg,
      ShowDropDownStatus,
      selectedOrderItemStatus,
      ItemID,
      showImg,
      open,
      receiptImgView
    } = this.state;

    const { data, OrderNo } = this.props;

    return (
      <div className="container-root">
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Grid container>
          <Paper className="table-root">
            <div className="table-wrapper">
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "223px" }}>Actions</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell style={{ minWidth: "217px" }}>
                      Tracking Status
                    </TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Barcode</TableCell>
                    <TableCell>VariantTitle</TableCell>
                    <TableCell>Vender</TableCell>
                    <TableCell>Locations</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {ItemID !== index && (
                            <Grid item container>
                              <Button
                                onClick={() =>
                                  this.EditItemStatus(item.Id, index)
                                }
                              >
                                <EditIcon />
                              </Button>
                              <Grid
                                item
                                className="upload-button-wrapper"
                                xs={2}
                              >
                                <span className="label">
                                  <AddPhotoAlternate />
                                </span>
                                <input
                                  type="file"
                                  id="recepietImg"
                                  accept="image/*"
                                  className="upload-box"
                                  onChange={e =>
                                    this.handleImageChange(e, item.Barcode)
                                  }
                                />
                              </Grid>
                              <Button>
                                <img
                                  src={receiptImg}
                                  onClick={() =>
                                    this.handleViewReceiptImg(item.Barcode)
                                  }
                                />
                              </Button>
                            </Grid>
                          )}

                          {ItemID === index && (
                            <Grid container>
                              <Button
                                color="primary"
                                onClick={() =>
                                  this.handleSaveTrackingStatus(
                                    item.Barcode,
                                    index
                                  )
                                }
                              >
                                <Done />
                              </Button>

                              <Button
                                onClick={() =>
                                  this.handleCloseExpandClick(item.Id, index)
                                }
                                cursor="pointer"
                              >
                                <Close />
                              </Button>
                            </Grid>
                          )}
                        </TableCell>
                        <TableCell>
                          <Grid col={2}>{item.Title}</Grid>
                          {item.ImageName !== null && (
                            <Grid col={2}>
                              <img
                                height="94px"
                                style={{
                                  marginLeft: "1px"
                                }}
                                width="121px"
                                src={`${process.env.REACT_APP_API_ENDPOINT}`
                                  .concat("/api/images/")
                                  .concat(item.ImageName)}
                              />
                            </Grid>
                          )}
                        </TableCell>
                        <TableCell>
                          {ItemID !== index && (
                            <TextField
                              id="outlined-name"
                              margin="normal"
                              value={item.TrackingStatus || ""}
                              inputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          )}
                          {ShowDropDownStatus && ItemID === index && (
                            <FilterSelect
                              options={OrderItemStatus}
                              selected={selectedOrderItemStatus.label}
                              onChange={this.handleChange(
                                "selectedOrderItemStatus",
                                index
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell>{item.Quantity}</TableCell>
                        <TableCell>{item.Barcode}</TableCell>
                        <TableCell>{item.VariantTitle}</TableCell>
                        <TableCell>{item.Vender}</TableCell>
                        {item.Locations.length > 0 &&
                          item.Locations.map((itemlocations, index) => (
                            <TableCell key={index}>
                              {itemlocations.Name},
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </Grid>

        <Dialog open={showImg} onClose={this.handleClose} fullWidth>
          {receiptImgView && (
            <div>
              <img
                style={{ width: "100%" }}
                src={receiptImgView}
                className="image"
              />
            </div>
          )}
          {!receiptImgView && (
            <div>
              <Grid container justify="center">
                <h5>No Image in This Order</h5>
              </Grid>
            </div>
          )}

          <Grid container justify="center">
            <DialogActions>
              <Button
                onClick={this.handleClose}
                color="default"
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        <Dialog open={open} onClose={this.handleCloseUploadImages}>
          <div>
            <img src={this.state.recepietImg} style={{ width: "100%" }} />
          </div>

          <DialogActions style={{ flexFlow: "column" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleUploadReciept}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ViewOrders;
