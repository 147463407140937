import {
  GET,
  PUT,
  POST,
  DELETE,
  getBase64,
  reform,
} from './Methods';

export const getEntertainments = () => GET('api/funfactory/entertainments')
  .then(res => reform(res, 'EntertainmentId', 'value'))
  .then(res => reform(res, 'EntertainmentName', 'label'));

export const getEntertainment = entertainmentId => GET(`api/funfactory/entertainment/${entertainmentId}`);

export const createEntertainment = (EntertainmentData) => {
  const query = {
    EntertainmentName: EntertainmentData.Name,
    EntertainmentDescription: EntertainmentData.Description,
  };
  return POST('/api/funfactory/entertainment', query);
};

export const updateEntertainment = (EntertainmentData) => {
  const query = {
    EntertainmentId: EntertainmentData.Id,
    EntertainmentName: EntertainmentData.Name,
    EntertainmentDescription: EntertainmentData.Description,
    PhoneNumber: EntertainmentData.phoneNumber,
    Email: EntertainmentData.email,
    ExtNumber: EntertainmentData.ExtNumber,
    EntertainmentArabicName: EntertainmentData.ArabicName,
    EntertainmentArabicDescription: EntertainmentData.ArabicDescription,

  };
  return PUT(`api/funfactory/entertainment/`, query);
};

export const DeleteEntertainment = id => DELETE(`/api/funfactory/entertainment/${id}`);

export const DeleteGame = id => DELETE(`/api/funfactory/game/${id}`);

export const AddNewGame = (GameData) => {
  return POST('/api/funfactory/game', GameData);
};

export const EditGame = (GameData) => {
  return PUT('/api/funfactory/game', GameData);
};

export const updateEntertainmentCover = (ImageData) => {
  return POST('/api/funfactory/entertainmentcoverimage', ImageData);
};

export const updateGameCoverImage = (GameImageData) => {
  return POST('/api/funfactory/entertainmentgameimage/', GameImageData);
};
