import React from 'react';
import Button from '@material-ui/core/Button';

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notAcceptedFileType: [],
    };
    this.inputElement = '';
  }

  onUploadClick = (e) => {
    e.target.value = null;
  }

  triggerFileUpload = () => {
    this.inputElement.click();
  }

  onDropFile = (file) => {
    const { notAcceptedFileType } = this.state;
    const { setFileData, importData } = this.props;

    if (!this.hasExtension(file[0].name)) {
      const newArray = notAcceptedFileType.slice();
      newArray.push(file[0].name);
      this.setState({ notAcceptedFileType: newArray });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        setFileData(file[0]);
        importData();
      };
    }
  };

  /* Check file extension (onDropFile) */
  hasExtension = (fileName) => {
    const { fileExtension } = this.props;
    const pattern = `(${fileExtension.join('|').replace(/\./g, '\\.')})$`;
    return new RegExp(pattern, 'i').test(fileName);
  }

  renderErrors() {
    const { notAcceptedFileType } = this.state;
    let notAccepted = '';
    if (notAcceptedFileType.length > 0) {
      notAccepted = notAcceptedFileType.map((error, index) => (
        <div className='error-message' key={index}>
            Is not a supported file extension
        </div>
      ));
    }
    return notAccepted;
  }

  render() {
    const { isImporting } = this.props;
    return (
      <div className='file-uploader'>
        <div className='file-container'>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={this.triggerFileUpload}
            disabled={isImporting}
          >
            <span className='button-text'>Import</span>
          </Button>
          <input
            type='file'
            ref={(input) => { this.inputElement = input; }}
            onChange={e => this.onDropFile(e.target.files)}
            onClick={this.onUploadClick}
            accept='xlsx/*'
          />
          <div className='errors-container'>
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }
}

export default FileUploader;
