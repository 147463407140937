import { loginByRefreshToken } from '../auth';
export const getJWT = () => localStorage.getItem('access_token');

export const getTokenExpires = () => localStorage.getItem('token_expires');

export const getRole = () => localStorage.getItem('role');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const isAuth = () => (!!localStorage.getItem('access_token'));

export const setUser = (user) => {
  localStorage.setItem('access_token', user.access_token);
  localStorage.setItem('refresh_token', user.refresh_token);
  localStorage.setItem('token_issued', user['.issued']);
  localStorage.setItem('token_expires', user['.expires']);
  localStorage.setItem('role', user.roles);
};

export const removeUser = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token_issued');
  localStorage.removeItem('token_expires');
  localStorage.removeItem('role');
};

export const checkUser = () => {
  const tokenExpires = new Date(getTokenExpires());
  const currentTime = new Date();
  // refresh the token before 80 seconds from the expiration time.
  if ((Date.now() > (tokenExpires.getTime()))) {
    loginByRefreshToken(getRefreshToken());
  }
};
