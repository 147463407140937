import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import BarcodeReader from 'react-barcode-reader';
import barcodeIcon from '../../../assests/images/Barcode.png';
import Pass from '../../../assests/sounds/Pass.mp3';
import Fail from '../../../assests/sounds/Fail.mp3';
import { checkItem, getLog, getInventoryCount } from '../../../actions/InventoryControlAPIs';
import FilterSelect from '../../FilterSelect/FilterSelect';
import BlackLoader from '../../BlackLoader/BlackLoader';
import LogList from '../LogList/LogList';
import InfoBox from '../../InfoBox/InfoBox';
import MessagesNotifications from '../../MessagesNotifications/MessagesNotifications';
import './Barcode.scss';


class Stores extends Component {
  constructor() {
    super();
    this.state = {
      barcode: '',
      quantity: '',
      status: '',
      statusMessage: '',
      selectedStore: '',
      notificationMsg: '',
      logs: [],
      inventoryCount: 0,
      inMinusInventory: false,
      manualEnable: false,
      barcodeDisabled: true,
      isLoading: false,
      logsLoading: true,
    };
    this.passAudio = new Audio(Pass);
    this.failAudio = new Audio(Fail);
  }

  componentDidMount() {
    getLog().then(res => this.setState({
      logs: res,
      logsLoading: false,
    }))
      .catch(() => {
        this.setState({
          notificationMsg: 'getLog',
        });
      });

    getInventoryCount().then(res => this.setState({
      inventoryCount: res,
    }))
      .catch(() => {
        this.setState({
          notificationMsg: 'getInventoryCount',
        });
      });
  }

  handleScan = (barcode) => {
    const { statusOptions } = this.props;
    const { selectedStore, inMinusInventory, quantity } = this.state;

    this.setState({
      barcode,
      isLoading: barcode.length > 11,
    });

    if (barcode.length > 11) {
      checkItem(selectedStore.value, quantity, barcode, inMinusInventory)
        .then((res) => {
          const newStatus = statusOptions.find(status => res.Status === status.value);
          this.setState({
            status: res.Status,
            statusMessage: newStatus.label,
            inventoryCount: res.InventoryCount,
            isLoading: false,
          });
          this.handleSound(res.Status);
          getLog().then(res => this.setState({
            logs: res,
          }))
            .catch(() => {
              this.setState({
                notificationMsg: 'getLog',
              });
            });
        })
        .catch(() => {
          this.setState({
            notificationMsg: 'checkItem',
          });
        });
    }
  }

  handleError = (err) => {
  }

  handleCheck = () => {
    const { statusOptions } = this.props;
    const {
      selectedStore, inMinusInventory, barcode, quantity,
    } = this.state;

    this.setState({
      isLoading: true,
    });
    checkItem(selectedStore.value, quantity, barcode, inMinusInventory)
      .then((res) => {
        const newStatus = statusOptions.find(status => res.Status === status.value);
        this.setState({
          barcode: '',
          quantity: '',
          status: res.Status,
          statusMessage: newStatus.label,
          inventoryCount: res.InventoryCount,
          isLoading: false,
        });
        this.handleSound(res.Status);
        getLog().then(res => this.setState({
          logs: res,
        }))
          .catch(() => {
            this.setState({
              notificationMsg: 'getLog',
            });
          });
      })
      .catch(() => {
        this.setState({
          notificationMsg: 'checkItem',
        });
      });
  }

  handleChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  }

  handleSound = (status) => {
    switch (status) {
      case '1':
        this.failAudio.play();
        break;
      case '2':
        this.passAudio.play();
        break;
      default:
        break;
    }
  }

  render() {
    const {
      barcode,
      quantity,
      status,
      statusMessage,
      inventoryCount,
      barcodeDisabled,
      isLoading,
      logs,
      logsLoading,
      manualEnable,
      notificationMsg,
    } = this.state;
    const { storeOptions } = this.props;
    return (
      <Paper className='barcode-paper' elevation={1}>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid container>
          <p className='title'>INVENTORY</p>
        </Grid>
        <div>
          <Switch
            color='primary'
            onChange={(e) => {
              this.setState({ inMinusInventory: e.target.checked });
              e.target.blur();
            }}
          /> IN MINUS
        </div>
        <Grid container justify='center'>
          <Grid item xs={12} sm={7}>
            <Grid container justify='center'>
              <Grid item xs={12}>
                <img src={barcodeIcon} alt='barcode' className='barcode-icon' />
                {<p className='count'>Count: {inventoryCount}</p>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span>Store</span>
                <FilterSelect
                  options={storeOptions}
                  onChange={selectedValue => this.setState({
                    selectedStore: selectedValue,
                    barcode: '',
                    quantity: '',
                    status: '',
                    statusMessage: '',
                    barcodeDisabled: false,
                  })}
                />
                <br />
              </Grid>
              <Grid container justify='center'>
                <Grid item xs={12} sm={6}>
                  {!barcodeDisabled
                    && (
                      <BarcodeReader
                        onError={this.handleError}
                        onScan={this.handleScan}
                        minLength={12}
                      />
                    )}
                  <Switch
                    color='primary'
                    onChange={(e) => {
                      this.setState({ manualEnable: e.target.checked });
                      e.target.blur();
                    }}
                  /> Manual
                  <input
                    type='text'
                    placeholder='Barcode'
                    className='barcode-input'
                    onChange={e => this.handleChange('barcode', e.target.value)}
                    value={barcode}
                    disabled={barcodeDisabled || !manualEnable}
                  />
                  <input
                    type='text'
                    placeholder='Quantity'
                    className='barcode-input'
                    onChange={e => this.handleChange('quantity', e.target.value)}
                    value={quantity}
                    disabled={barcodeDisabled || !manualEnable}
                  />
                  <br />
                  <br />
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={this.handleCheck}
                    disabled={barcode.length < 11}
                  >
                    <span className='button-text'>CHECK</span>
                  </Button>
                </Grid>
              </Grid>
              {isLoading
                && (
                  <Grid container justify='center'>
                    <BlackLoader />
                  </Grid>
                )}
              {status && !isLoading
                && (
                  <Grid container className='item-status' justify='center'>
                    <div className={status === '2' ? 'passed' : 'failed'}>
                      <p>{statusMessage}</p>
                      {status === '2'
                        ? <Done className='icon' />
                        : <Close className='icon' />}
                    </div>
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            {logs.length > 0 && !logsLoading && <LogList logs={logs} />}
            {logs.length === 0 && !logsLoading && <InfoBox message='Log is empty!' />}
            {logsLoading
              && (
                <Grid container justify='center'>
                  <BlackLoader />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default Stores;
