import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { NavLink } from "react-router-dom";
import driverIcon from "../../assests/images/driver.png";
import Avatar from "@material-ui/core/Avatar";
import { withRouter } from "react-router-dom";
import Drivers from "../Transport/Drivers/Driver.component";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import PlusIcon from "../../assests/images/pluss.png";
import FilterSelect from "../FilterSelect/FilterSelect";
import MessagesNotifications from "../MessagesNotifications/MessagesNotifications";
import {
  getTransportationTrips,
  getTripsByDay,
  getTripsByRoute,
  getTripByDayAndRoute,
  deleteTrip,
  editTrip,
  addTrip
} from "../../actions/80/TransportationAPIs";
import "./transport.styles.scss";
const styles = theme => ({
  filter: {
    minWidth: "100px",
  },
  root: {
    margin: "0 auto",
    width: "90%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  plusIcon: {
    position: "absolute",
    right: "40px",
  },
  dialog: {
    minHeight: "550px",
    maxHeight: "550px",
  },
  dialogStyle: {
    backgroundColor: "#313639",
    color: "white",
    textAlign: "center",
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  tab: {
    flexGrow: 1,
    backgroundColor: "#F8FBF6",
    width: "60%",
  }
});

class Transport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      day: "",
      trip: "",
      filterdArray: [],
      selectedDay: null,
      selectedDest: null,
      selecteddialogDay: null,
      selectdDialogDes: null,
      selectedDate: new Date("2014-08-18T22:08:54"),
      selectedEndDate: new Date("2014-08-18T22:08:54"),
      selectedDialogStartTime: new Date("2014-08-18T22:08:54"),
      selectedDialogEndTime: new Date("2014-08-18T22:08:54"),
      isEditing: false,
      editRow: null,
      editedTime: "",
      editedTimeEnd: "",
      dialogStartTime: "",
      dialogEndTime: "",
      notificationMsg: "",
      page: 0,
      rowsPerPage: 5,
      tabValue: 1
    };
  }

  componentDidMount() {
    getTransportationTrips().then(res => {
      this.setState({
        filterdArray: res,
      });
    });
  }
  handleChange = type => event => {
    switch (type) {
      case "selectedDay":
        if (this.state.selectedDest) {
          const day = event.label;
          getTripByDayAndRoute(day, this.state.selectedDest.label).then(res => {
            this.setState({
              filterdArray: res,
              selectedDay: event,
            });
          });
        } else {
          getTripsByDay(event.label).then(res => {
            this.setState({
              filterdArray: res,
              selectedDay: event,
            });
          });
        }
        break;
      case "selectedDest":
        if (this.state.selectedDay) {
          getTripByDayAndRoute(this.state.selectedDay.label, event.label).then(
            res => {
              this.setState({
                filterdArray: res,
                selectedDest: event,
              });
            }
          );
        } else {
          getTripsByRoute(event.label).then(res => {
            this.setState({
              filterdArray: res,
              selectedDest: event,
            });
          });
        }
        break;
      case "selecteddialogDay":
        this.setState({
          selecteddialogDay: event,
        });
        break;
      case "selectdDialogDes":
        this.setState({
          selectdDialogDes: event,
        });
        break;
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleEdit = index => {
    this.setState({
      isEditing: true,
      editRow: index,
      editedTime: this.state.filterdArray.find(item => item.Id === index)
        .StartTime,
      editedTimeEnd: this.state.filterdArray.find(item => item.Id === index)
        .EndTime,
      notificationMsg: ""
    });
  };

  AddRow = () => {
    this.setState({
      open: true,
    });
  };

  handleDelete = id => {
    const editedArray = this.state.filterdArray.filter(item => item.Id !== id);
    deleteTrip(id)
      .then(res => {
        this.setState({
          filterdArray: editedArray,
          notificationMsg: "Remove Trip Successfully",
        });
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error while removing trip",
        });
      });
    this.setState({
      notificationMsg: "",
    });
  };

  handleDateChange = (event, type) => {
    const hours =
      event.getHours() > 12 ? event.getHours() - 12 : event.getHours();
    const minute =
      event.getMinutes() < 10 ? "0" + event.getMinutes() : event.getMinutes();
    const _time = event.getHours() >= 12 ? " PM" : " AM";
    const time = `${hours}:${minute}${_time}`;
    switch (type) {
      case "editstart":
        this.setState({
          selectedDate: event,
          editedTime: time,
        });
        break;
      case "editend":
        this.setState({
          selectedEndDate: event,
          editedTimeEnd: time,
        });
        break;
      case "DialogStartTime":
        this.setState({
          selectedDialogStartTime: event,
          dialogStartTime: time,
        });
        break;
      case "DialogEndTime":
        this.setState({
          selectedDialogEndTime: event,
          dialogEndTime: time,
        });
    }
  };

  closeEditing = (id, row) => {
    const EditedArray = this.state.filterdArray.map(item =>
      item.Id === id
        ? {
            ...item,
            StartTime: this.state.editedTime,
            EndTime: this.state.editedTimeEnd,
          }
        : item
    );
    const editedData = {
      Id: id,
      From: row.From,
      To: row.To,
      StartTime: this.state.editedTime,
      EndTime: this.state.editedTimeEnd,
      Day: row.Day,
      TripRoute: row.TripRoute,
    };

    editTrip(editedData)
      .then(res => {
        this.setState({
          isEditing: false,
          filterdArray: EditedArray,
          notificationMsg: "Edit trip successfully",
        });
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error while editing trip",
        });
      });
  };

  AddTrip = () => {
    this.setState({
      notificationMsg: "",
    });
    const newTrip = {
      Id: 0,
      From: this.state.selectdDialogDes.label.split("-")[0],
      To: this.state.selectdDialogDes.label.split("-")[1],
      Day: this.state.selecteddialogDay.label,
      StartTime: this.state.dialogStartTime,
      EndTime: this.state.dialogEndTime,
      TripRoute:
        this.state.selectdDialogDes.label.split("-")[0] === "Rawabi"
          ? "Rawabi-Ramallah"
          : "Ramallah - Rawabi"
    };
    const modifiedTrips = [...this.state.filterdArray, newTrip];

    addTrip(newTrip)
      .then(res => {
        this.setState({
          filterdArray: modifiedTrips,
          notificationMsg: "Add Trip Successfully",
          open: false,
        });
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error while adding Trip",
        });
      });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleTabChange = (event, newvalue) => {
    if (!newvalue) {
      this.props.history.push("/transportation/Drivers");
    }
    this.setState({
      tabValue: newvalue,
    });
  };

  render() {
    const { classes } = this.props;
    const { filterdArray, selectedDay, selectedDest } = this.state;
    const days = [
      { value: "Saturday", label: "Saturday" },
      { value: "Sunday", label: "Sunday" },
      { value: "Monday", label: "Monday" },
      { value: "Tuesday", label: "Tuesday" },
      { value: "Wednesday", label: "Wednesday" },
      { value: "Thursday", label: "Thursday" },
      { value: "Friday", label: "Friday" }
    ];
    const trip = [
      { value: 1, label: "Rawabi-Ramallah" },
      { value: 2, label: "Ramallah - Rawabi" }
    ];

    return (
      <div>
        <Grid container xs={7} justify="center">
          <Grid className={classes.tab}>
            <Tabs
              indicatorColor="primary"
              value={this.state.tabValue}
              onChange={this.handleTabChange}
            >
              <Tab label="Drivers" style={{ width: 40,marginLeft:10 }} />
              <Tab label="Rawabi Transportation" style={{ width: 70 }} />
            </Tabs>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        {this.state.tabValue ? (
          <div className="container-div">
            {this.state.notificationMsg && (
              <MessagesNotifications message={this.state.notificationMsg} />
            )}
            <Grid container justify="center" spacing={9}>
              <Grid item xs={5} sm={3}>
                Days Filter
                <FilterSelect
                  placeholder="Day"
                  className={classes.filter}
                  options={days}
                  selected={selectedDay}
                  onChange={this.handleChange("selectedDay")}
                />
              </Grid>
              <Grid item xs={5} sm={3}>
                Direction Filter
                <FilterSelect
                  placeholder="TripRoute"
                  options={trip}
                  selected={selectedDest}
                  onChange={this.handleChange("selectedDest")}
                />
              </Grid>
            </Grid>

            <br />
            <Grid container>
              <Paper className={classes.root}>
                <div className="table-wrapper">
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell align="right"> From</TableCell>
                        <TableCell align="right">To</TableCell>
                        <TableCell align="right">Start Time</TableCell>
                        <TableCell align="right">End Time</TableCell>
                        <TableCell align="right">Day</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterdArray
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow key={row.Id}>
                            <TableCell component="th" scope="row">
                              {this.state.isEditing &&
                              this.state.editRow === row.Id ? (
                                <Button
                                  onClick={() => this.closeEditing(row.Id, row)}
                                >
                                  {" "}
                                  save{" "}
                                </Button>
                              ) : (
                                <Button onClick={() => this.handleEdit(row.Id)}>
                                  {" "}
                                  <EditIcon />{" "}
                                </Button>
                              )}
                              <Button onClick={() => this.handleDelete(row.Id)}>
                                {" "}
                                <DeleteIcon />{" "}
                              </Button>
                            </TableCell>
                            <TableCell align="right"> {row.From} </TableCell>
                            <TableCell align="right">{row.To}</TableCell>
                            <TableCell align="right">
                              {" "}
                              {this.state.isEditing &&
                              this.state.editRow === row.Id ? (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    spacing={5}
                                    margin="normal"
                                    id="time-picker1"
                                    label="Time"
                                    value={this.state.selectedDate}
                                    onChange={event =>
                                      this.handleDateChange(event, "editstart")
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "change time"
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              ) : (
                                <p>{row.StartTime}</p>
                              )}
                            </TableCell>

                            <TableCell align="right">
                              {" "}
                              {this.state.isEditing &&
                              this.state.editRow === row.Id ? (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker2"
                                    label="Time"
                                    value={this.state.selectedEndDate}
                                    onChange={event =>
                                      this.handleDateChange(event, "editend")
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "change time"
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              ) : (
                                <p>{row.EndTime}</p>
                              )}
                            </TableCell>
                            <TableCell align="right">{row.Day}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[6]}
                  component="div"
                  count={this.state.filterdArray.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                />
              </Paper>
            </Grid>
            <br />
            <img
              src={PlusIcon}
              alt=""
              srcSet=""
              className={classes.plusIcon}
              onClick={this.AddRow}
            />

            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
              classes={{ paper: classes.dialog }}
              fullWidth
            >
              <DialogTitle
                id="form-dialog-title"
                className={classes.dialogStyle}
              >
                Add Trip
              </DialogTitle>
              <br />
              <br />
              <DialogContent>
                <Grid container justify="center" spacing={9}>
                  <Grid item xs={4}>
                    Days
                    <FilterSelect
                      placeholder="Select the day"
                      className={classes.filter}
                      options={days}
                      selected={this.state.selecteddialogDay}
                      onChange={this.handleChange("selecteddialogDay")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    Destination
                    <FilterSelect
                      placeholder="Select trip route"
                      className="filter"
                      options={trip}
                      selected={this.state.selectdDialogDes}
                      onChange={this.handleChange("selectdDialogDes")}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="center" spacing={9}>
                    <Grid xs={4} spacing={8}>
                      <KeyboardTimePicker
                        spacing={5}
                        margin="normal"
                        id="time-picker"
                        label="Time"
                        value={this.state.selectedDialogStartTime}
                        onChange={event =>
                          this.handleDateChange(event, "DialogStartTime")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change time"
                        }}
                      />
                    </Grid>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Time"
                      value={this.state.selectedDialogEndTime}
                      onChange={event =>
                        this.handleDateChange(event, "DialogEndTime")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time"
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="secondary">
                  Cancel
                </Button>
                <Button onClick={this.AddTrip} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div>
            <Drivers />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Transport));
