import React from 'react';
import Select from 'react-select';
import './FilterSelect.scss';

const FilterSelect = (props) => {
  const {
    placeholder,
    isMulti,
    onChange,
    selected,
    options,
    disabled,
    defaultValue,
  } = props;
  return (
    <Select
    menuPortalTarget={props.fordialog && (document.body)}
      className='select'
      placeholder={placeholder}
      value={selected}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      isDisabled={disabled}
      defaultValue={defaultValue}
      blurInputOnSelect
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: '#bbda9b',
          primary25: '#bbda9b',
          primary: '#7cb342',
        },
      })}
    />
  );
};

export default FilterSelect;
