import React, { Component } from "react";
// import { Grid } from "@material-ui/core";
import FilterSelect from "../../FilterSelect/FilterSelect";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
import {
  getCategories,
  getSubCategories,
  getSubSubCategories,
  getSections
} from "../../../actions/PhotographerAPIs";
import { Grid } from "@material-ui/core";

class SectionsCell extends Component {
  constructor() {
    super();
    this.state = {
      sections: [],
      selectedSection: "",
      selectedCategory: "",
      selectedSubCategory: "",
      selectedMiniCategory: "",
      notificationMsg: ""
    };
  }

  componentDidMount() {
    const { sectionId, categoryId, subCategoryId, miniCategoryId } = this.props;
    getSections()
      .then(res => {
        let selectedCategory;
        let selectedSubCategory;
        let selectedMiniCategory;

        const selectedSection = res.find(
          section => section.value === sectionId
        );

        if (selectedSection) {
          const categories = getCategories(selectedSection);
          selectedCategory = categories.find(
            category => category.value === categoryId
          );
        }

        if (selectedCategory) {
          const subCategories = getSubCategories(selectedCategory);
          selectedSubCategory = subCategories.find(
            subCategory => subCategory.value === subCategoryId
          );
        }
        if (selectedSubCategory) {
          const subsubCategories = getSubSubCategories(selectedSubCategory);

          selectedMiniCategory = subsubCategories.find(
            subsubCategory => subsubCategory.value === miniCategoryId
          );
        }

        if (selectedSubCategory) {
          const miniCategories = getSubSubCategories(selectedSubCategory);
          selectedMiniCategory = miniCategories.find(
            miniCategory => miniCategory.value === miniCategoryId
          );
        }

        this.setState({
          sections: res,
          selectedSection,
          selectedCategory,
          selectedSubCategory,
          selectedMiniCategory
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "getSections"
        });
      });
  }

  handleChangeSection = type => selectedValue => {
    const { setNewSection } = this.props;
    switch (type) {
      case "SECTION":
        this.setState({
          selectedSection: selectedValue,
          selectedCategory: "",
          selectedSubCategory: "",
          selectedMiniCategory: ""
        });
        setNewSection("SECTION", selectedValue.value, selectedValue);
        break;
      case "CATEGORY":
        this.setState({
          selectedCategory: selectedValue,
          selectedSubCategory: "",
          selectedMiniCategory: ""
        });
        setNewSection("CATEGORY", selectedValue.value, selectedValue);
        break;
      case "SUBCATEGORY":
        this.setState({
          selectedSubCategory: selectedValue,
          selectedMiniCategory: ""
        });
        setNewSection("SUBCATEGORY", selectedValue.value, selectedValue);
        break;
      case "MINICATEGORY":
        this.setState({
          selectedMiniCategory: selectedValue
        });
        setNewSection("MINICATEGORY", selectedValue.value, selectedValue);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      sections,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedMiniCategory,
      notificationMsg
    } = this.state;
    const { isDisabled } = this.props;
    return (
      <div>
        <Grid container spacing={4}>
          {notificationMsg !== "" && (
            <MessagesNotifications message={notificationMsg} />
          )}

          <Grid item xs={6}>
            Section
            <FilterSelect
              options={sections}
              onChange={this.handleChangeSection("SECTION")}
              selected={selectedSection}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={6}>
            Category
            <FilterSelect
              options={selectedSection ? getCategories(selectedSection) : []}
              onChange={this.handleChangeSection("CATEGORY")}
              selected={selectedCategory}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            Sub Category
            <FilterSelect
              options={
                selectedCategory ? getSubCategories(selectedCategory) : []
              }
              onChange={this.handleChangeSection("SUBCATEGORY")}
              selected={selectedSubCategory}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            Mini Category
            <FilterSelect
              options={
                selectedSubCategory
                  ? getSubSubCategories(selectedSubCategory)
                  : []
              }
              onChange={this.handleChangeSection("MINICATEGORY")}
              selected={selectedMiniCategory}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SectionsCell;
