import { setUser, removeUser } from "./share/DataService";
import getToken from "../actions/AuthAPI";
import { setToken, POST } from "../actions/actions";

export const login = (UserName, Password) => {
  const userData = {
    UserName,
    Password,
    GrantType: "password",
    ClientId: "ecomerce"
  };
  return getToken(userData).then(res => {
    setUser(res);
    setToken();
    return res;
  });
};

// when the token expires.
export const loginByRefreshToken = RefreshToken => {
  const userData = {
    RefreshToken,
    GrantType: "refresh_token",
    ClientId: "ecomerce"
  };
// localStorage.clear();
  return getToken(userData)
    .then(res => {
      setUser(res);
      setToken();
      return res;
    })
  .catch((err) => removeUser());

    // .catch(error =>
    //   console.log("error in getting refreshtoken from server ", error)
    // );
};

export const logout = () => {
  removeUser();
};

export const SetNewPass = UserName => {
  return POST(`api/usermanagement/forgetPassword?UserName=${UserName}`);
};
