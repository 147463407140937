import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import OrdersShow from "../../components/Orders/OrderShow/ViewOrder";
import OrderSearchForm from "../../components/Orders/OrderForm/OrderSearchForm";
import WarningIcon from '@material-ui/icons/Warning';
import axios from 'axios';
import {
  getOrders,
  downloadZipfile,
  printTable,
  downloadTranPdf,
  getOrdersById,
  downloadZipfileWasel,
  downloadInvoice,
  DispatchOrder,
  setShopifyTrackingNumber,
  inovieDownloadTest,
  getItemStatus,
} from "../../actions/OrderAPI";
import InfoBox from "../../components/InfoBox/InfoBox";
import GreenLoader from "../../components/GreenLoader/GreenLoader";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import "./Orders.scss";
import FilterSelect from "../../components/FilterSelect/FilterSelect";
import BlackLoader from "../../components/BlackLoader/BlackLoader";
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";
import { getRole } from "../../services/share/DataService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContentText } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { tr } from "date-fns/locale";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

let filterData;
const token = localStorage.getItem('access_token');
const DropDownForDownloadTras = [
  { label: "Qhub", value: 0 },
  { label: "Transit", value: 1 },
  { label: "Packing Area", value: 2 }
];

const OrderStatusForOrderSearch = [
  { label: "ALL", value: null },
  { label: "Dispatched", value: 1 },
  { label: "Dispatch Area", value: 2 }
];
const styles = theme => ({
  dialogStyle: {
    backgroundColor: "#313639",
    color: "white",
    textAlign: "center"
  },
  dialogText: {
    color: "black",
    fontSize: "20px",
    textAlign: "center"
  }
});

class QhubRunnerOrders extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      data: [],
      isExporting: false,
      ShowFull: false,
      page: 0,
      count: 0,
      rowsPerPage: 20,
      expanded: "",
      selected: [],
      selectedData: [],
      selectedCheckboxes: {},
      selectedStatusForTrans: { label: "Select", value: null },
      dispalyDownloadButton: false,
      ErrorMessage: false,
      searchOrderValue: "",
      BlackLoaderZip: false,
      OrderNumber: "",
      selectedOrderStatus: { label: "ALL", value: null },
      disabledbutton: false,
      resetcheckbox: {},
      tableLoading: false,
      notificationMsg: "",
      open: false,
      openTrackingNumberDialog: false,
      relatedBarcodeStatus: [],
      selectedRelatedBarcodeStatus: { label: "ALL", value: null },
      openErrorDialog: false,
      isSettingTrackingNumber: false,
    };
  }

   InvoiceDownload = (fileUrl, filter) => {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    return axios.post(fileUrl, filter,{Authorization: `bearer ${token}`, responseType: 'blob' })
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby.data);
        anchor.href = objectUrl;
        anchor.download = `Invoice.pdf`;
        anchor.click();
      });
  
  };
  componentDidMount() {
    // toast.error('fffffffff')
    // getItemStatus().then(res => {
    //   console.log(res)
    //   const temp = res;
    //   res.push({ label:'Unknown',value: 'Unknown'})
    //   this.setState({
    //     relatedBarcodeStatus: temp
    //   });
    // });
    const {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      OrderNumber,
      selectedOrderStatus
    } = this.state;
    this.setState({
      isLoading: true,
      page: 0,
      ShowFull: false
    });

    filterData = {
      from: selectedFromDate,
      to: selectedToDate,
      status: selectedOrderStatus
    };
    getOrders(filterData, OrderNumber, 0, rowsPerPage)
      .then(res => {
        if (res.Results.length > 0) {
          this.setState({
            count: res.Count,
            data: res.Results,
            isLoading: false,
            disabledbutton: true
          });
        }
        if (res.Results.length === 0) {
          this.setState({
            ShowFull: true,
            isLoading: false,
            disabledbutton: false
          });
        }
      })
      .catch(() => {
        this.setState({
          notificationMsg: "search"
        });
      });
  }

  handleDispatchOrder = () => {
    this.setState({
      notificationMsg: ""
    });
    const params = {
      pageNo: 0,
      pageSize: 0,
      from: "",
      to: "",
      ordersId: this.state.selected,
      orderStatus: "",
      orderNo: 0
    };

    DispatchOrder(params)
      .then(res => {
        this.setState({
          notificationMsg: "Dispatch order Successfully",
          open: false
        });
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error while dispatch orders",
          open: false
        });
      });
  };

  handleSearch = () => {
    const {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      OrderNumber,
      selectedOrderStatus,
      selectedRelatedBarcodeStatus,
    } = this.state;
    this.setState({
      isLoading: true,
      page: 0,
      ShowFull: false,
      selected: [],
      data: []
    });

    filterData = {
      from: selectedFromDate,
      to: selectedToDate,
      status: selectedOrderStatus.label,
      // itemStatus: selectedRelatedBarcodeStatus.label
    };

    getOrders(filterData, OrderNumber, 0, rowsPerPage)
      .then(res => {
        if (res.Results.length > 0) {
          const newObject = {};
          for (let order of res.Results) {
            newObject[order.Id] = false;
          }
          this.setState({
            selectedCheckboxes: newObject
          });
          if (OrderNumber !== "") {
            this.setState({
              disabledbutton: false,
              count: res.Count,
              data: res.Results,
              isLoading: false
            });
          } else {
            this.setState({
              disabledbutton: true,
              count: res.Count,
              data: res.Results,
              isLoading: false
            });
          }
        } else {
          this.setState({
            ShowFull: true,
            isLoading: false,
            disabledbutton: false
          });
        }
      })
      .catch(() => {
        this.setState({
          ShowFull: true,
          isLoading: false,
          notificationMsg: "search"
        });
      });
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage, page, data, OrderNumber } = this.state;
    this.setState({
      page: newPage,
      isLoading: newPage * rowsPerPage >= data.length
    });
    if (newPage > page && newPage * rowsPerPage >= data.length) {
      getOrders(filterData, OrderNumber, newPage, rowsPerPage).then(res => {
        res.Results.map(order => {
          this.setState(
            prevState => ({
              data: [...prevState.data, order]
            }),
            () => {
              if (this.state.selectedCheckboxes.hasOwnProperty(`${order.Id}`)) {
              } else {
                this.setState({
                  selectedCheckboxes: {
                    ...this.state.selectedCheckboxes,
                    [order.Id]: false
                  }
                });
              }
            }
          );
        });

        this.setState({
          isLoading: false
        });
      });
    }
  };

  handleSetTrackingNumber = () => {
   
    this.setState({
      isSettingTrackingNumber: true,
      notificationMsg: ""
    });
      const params = {
        pageNo: 0,
        pageSize: 0,
        from: "",
        to: "",
        ordersId: this.state.selected,
        orderStatus: "",
        orderNo: 0
      };
  
      setShopifyTrackingNumber(params)
        .then(res => {
          if(res.Success){
            toast.success(res.Message)
            this.setState({
              // notificationMsg: "Set Successfully",
              openTrackingNumberDialog: false,
              isSettingTrackingNumber: false,
            })
            // this.setTrckingNumber('444444')
          }
          else{
            toast.error(res.Message)
            this.setState({
              // notificationMsg: "Error while set tracking number",
              openTrackingNumberDialog: false,
              isSettingTrackingNumber: false,
              // openErrorDialog: true,
            });
          }
         
          
        })
        .catch(error => {
          // console.log(error)
          // toast.error(error.Message)
          this.setState({
            notificationMsg: "Error while set tracking number",
            openTrackingNumberDialog: false,
            isSettingTrackingNumber: false,
            // openErrorDialog: true,
          });
        });


   
    // const params = {
    //   pageNo: 0,
    //   pageSize: 0,
    //   from: "",
    //   to: "",
    //   ordersId: this.state.selected,
    //   orderStatus: "",
    //   orderNo: 0
    // };

    // setShopifyTrackingNumber(params)
    //   .then(res => {
    //     this.setState({
    //       notificationMsg: "Set Successfully",
    //       openTrackingNumberDialog: false
    //     });
    //   })
    //   .catch(error => {
    //     this.setState({
    //       notificationMsg: "Error while set tracking number",
    //       openTrackingNumberDialog: false
    //     });
    //   });
  };
  handleChangeRowsPerPage = event => {
    this.setState({
      tableLoading: true,
      selectedCheckboxes: this.state.selectedCheckboxes
    });
    getOrders(
      filterData,
      this.state.OrderNumber,
      0,
      parseInt(event.target.value, 10)
    ).then(res => {
      this.setState({
        data: res.Results,
        rowsPerPage: parseInt(event.target.value, 10),
        tableLoading: false,
        page: 0
      });
    });
  };

  handleChange = id => selectedValue => {
    // console.log(id,selectedValue)
    if (id === "selectedOrderStatus") {
      this.setState({
        disabledbutton: false,
        [id]: selectedValue.label,
        data: []
      });
    }
    if (id === "selectedRelatedBarcodeStatus") {
      this.setState({
        disabledbutton: false,
        [id]: selectedValue.label,
        data: []
      });
    }
    
    if (id === "OrderNumber") {
      this.setState({
        disabledbutton: false,
        [id]: selectedValue.target.value,
        data: []
      });
    } else {
      this.setState({
        disabledbutton: false,
        [id]: selectedValue,
        data: []
      });
    }
  };

  handleChangeInputSearch = (e, id) => {
    const {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      OrderNumber,
      page
    } = this.state;

    filterData = {
      from: selectedFromDate,
      to: selectedToDate
    };
    if (e.target.value === "") {
      getOrders(filterData, OrderNumber, page, rowsPerPage)
        .then(res => {
          if (res.Results !== null) {
            this.setState({
              count: res.Count,
              data: res.Results,
              isLoading: false,
              ErrorMessage: false
            });
          }
          if (res.Results.length === 0) {
            this.setState({
              ShowFull: true,
              isLoading: false,
              ErrorMessage: false
            });
          }
        })
        .catch(() => {
          this.setState({
            notificationMsg: "search"
          });
        });
    } else {
      this.setState({
        searchOrderValue: e.target.value
      });
    }
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleSelected = (newSelected, newSelectedData) => {
    this.setState({
      selected: newSelected,
      selectedData: newSelectedData
    });
  };

  handleChangeCheckbox = id => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected
    });
    this.setState(prevState => ({
      selectedCheckboxes: {
        ...prevState.selectedCheckboxes,
        [id]: !prevState.selectedCheckboxes[id]
      }
    }));
  };

  handledownloadZip = () => {
    const {
      selected,
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selectedOrderStatus
    } = this.state;

    const toDownloadPDFS = {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selected,
      status: selectedOrderStatus.label
    };
    this.setState({
      BlackLoaderZip: true
    });
    downloadZipfile(toDownloadPDFS).then(() => {
      this.setState({
        BlackLoaderZip: false
      });
    });
  };

  closeDialog = () => {
    this.setState({
      open: false,
      openTrackingNumberDialog: false,
      openErrorDialog: false,
    });
  };

  handledownloadZipWasel = () => {
    const {
      selected,
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selectedOrderStatus
    } = this.state;

    const toDownloadPDFS = {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selected,
      status: selectedOrderStatus.label
    };
    this.setState({
      BlackLoaderZip: true
    });
    downloadZipfileWasel(toDownloadPDFS).then(() => {
      this.setState({
        BlackLoaderZip: false
      });
    });
  };
  
  setTrckingNumber = trackNumb => { 
    const temp = this.state.data;
    const selectedId = this.state.selected[0];
    const index = temp.findIndex(order => order.Id === selectedId);
    // console.log(index);
    temp[index].TrackingNumber = trackNumb;
    this.setState({
      data: temp,
    })
  } 

  handlePrintTable = () => {
    const {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selected,
      selectedOrderStatus
    } = this.state;
    const toPrint = {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      status: selectedOrderStatus.label
    };
    this.setState({
      BlackLoaderZip: true
    });
    printTable(toPrint, selected).then(() => {
      this.setState({
        BlackLoaderZip: false
      });
    });
  };

  hanldeSelectStatus = id => selectedValue => {
    this.setState({
      [id]: selectedValue.label,
      dispalyDownloadButton: true
    });
  };

  handledownloadInvoice = () => {
    const {
      selected,
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selectedOrderStatus
    } = this.state;

    const toDownloadPDFS = {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selected,
      status: selectedOrderStatus.label
    };
    this.setState({
      BlackLoaderZip: true
    });
    const params = {
      from: selectedFromDate,
      to: selectedToDate,
      pageNo: page,
      pageSize : rowsPerPage,
      ordersId: selected,
      orderStatus: selectedOrderStatus.label
    };
    inovieDownloadTest(params) .then(res=> {
      if(res.ErrorMessage){
        this.setState({
          BlackLoaderZip: false,
          notificationMsg: "Please, select at least one order has tracking number"
        })
      }
      else{
        this.InvoiceDownload('/api/Order/InvoicePDFs', params);
        this.setState({
          BlackLoaderZip: false,
        })
      }
    })

    //  downloadInvoice(toDownloadPDFS)
    //   .then( res => {
    //     this.setState({
    //       BlackLoaderZip: false
    //     });
    //     // to do  for handling
    //   })
    //   .catch(err => {
    //     this.setState({
    //       notificationMsg: "Error while downloading  Invoice",
    //       BlackLoaderZip: false
    //     });
    //   });
  };
  getlocalDate = () => {
    var now = new Date(),
    ampm = 'am',
    h = now.getHours(),
    m = now.getMinutes(),
    s = now.getSeconds();
  if (h >= 12) {
    if (h > 12) h -= 12;
    ampm = 'pm';
  }

  if (m < 10) m = '0' + m;
  if (s < 10) s = '0' + s;
  return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
  }
  handledownloadTransportionPdf = () => {
    const {
      selectedStatusForTrans,
      selected,
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selectedOrderStatus
    } = this.state;
    const toDownloadTrans = {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page,
      selected,
      status: selectedOrderStatus.label
    };
    this.setState({
      BlackLoaderZip: true
    });
    downloadTranPdf(toDownloadTrans, selectedStatusForTrans).then(() => {
      this.setState({
        BlackLoaderZip: false
      });
    });
  };

  handleSearchOrderNumber = () => {
    const {
      searchOrderValue,
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      page
    } = this.state;

    filterData = {
      from: selectedFromDate,
      to: selectedToDate
    };
    getOrdersById(searchOrderValue)
      .then(res => {
        if (res.Results.length > 0) {
          this.setState({
            data: [res],
            ErrorMessage: false
          });
        }
      })
      .catch(() => {
        getOrders(filterData, page, rowsPerPage)
          .then(res => {
            if (res.Results !== null) {
              this.setState({
                count: res.Count,
                data: res.Results,
                isLoading: false,
                ErrorMessage: false
              });
            }
            if (res.Results.length === 0) {
              this.setState({
                ShowFull: true,
                isLoading: false,
                ErrorMessage: false
              });
            }
          })
          .catch(() => {
            this.setState({
              notificationMsg: "search"
            });
          });
      });
  };

  handleShowOrderTrackingNumberIds = () => {
    let idsString = '';
    for( let orderId of this.state.selected){
        idsString += orderId + ','; 
    };
    const newString = idsString.slice(0,-1)
    // console.log(idsString)
    return newString;
  }

  showDispatchDialog = () => {
    this.setState({
      open: true
    });
  };
  showTrackingNumberDialog = () => {
    this.setState({
      openTrackingNumberDialog: true
    });
  };

  render() {
    // console.log(this.state.selected)
    const {
      selectedFromDate,
      selectedToDate,
      data,
      count,
      isLoading,
      ShowFull,
      rowsPerPage,
      page,
      expanded,
      selected,
      selectedData,
      selectedCheckboxes,
      dispalyDownloadButton,
      ErrorMessage,
      BlackLoaderZip,
      OrderNumber,
      selectedOrderStatus,
      disabledbutton
    } = this.state;
    const role = getRole();
    const { classes } = this.props;
    return (
      <div>
          <ToastContainer />
        {this.state.notificationMsg !== "" && (
          <MessagesNotifications message={this.state.notificationMsg} />
        )}
        <Grid container justify="center">
        <Dialog
            open={this.state.openErrorDialog}
            onClose={this.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.deletedialog}
            fullWidth={true}
            // maxWidth={"xs"}
          >
            {/* <DialogTitle
              id="alert-dialog-title"
              // className={classes.dialogStyle}
            >
             
            </DialogTitle> */}
            <DialogContent style={{backgroundColor:'#f3785e'}}>
            <Grid container justify='center'>
            <div>
  <div >
    {/* <h1 className='h1'>oh no...</h1> */}
    <h1 className='h3'>Something went wrong</h1>
    <h4 className='h4'> <WarningIcon fontSize='large'/> Error Code: 1231</h4>
    <h5 className='h5'> Orders Id: {this.handleShowOrderTrackingNumberIds()}</h5>
    <h6>Sorry...please try again later</h6>
    <p> Date: {this.getlocalDate()} </p>
  </div>
</div>
            </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeDialog}
                color="default"
                variant="outlined"
                autoFocus
              >
                Close
              </Button>
              {/* <Button
                onClick={this.handleDispatchOrder}
                color="secondary"
                variant="outlined"
              >
                Confirm
              </Button> */}
            </DialogActions>
          </Dialog>
          <Grid container justify="center">
            <Grid item xs={12} sm={11}>
              <ExpansionPanel
                expanded={expanded === "searchPanel"}
                onChange={this.handleExpand("searchPanel")}
                className="data-entry-expand"
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="data-entry-expand-title">Search</span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <OrderSearchForm
                    onSearch={this.handleSearch}
                    relatedBarcodeStatus={this.state.relatedBarcodeStatus}
                    selectedRelatedBarcodeStatus={this.state.selectedRelatedBarcodeStatus}
                    selectedFromDate={selectedFromDate}
                    selectedToDate={selectedToDate}
                    OrderNumber={OrderNumber}
                    OrderStatusForOrderSearch={OrderStatusForOrderSearch}
                    selectedOrderStatus={selectedOrderStatus}
                    onChange={this.handleChange}
                    onExport={this.handleExport}
                    handlePrintTable={this.handlePrintTable}
                    disabledbutton={disabledbutton}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={11}>
            <div className="container-root">
              <Grid container justify="flex-start" spacing={2}>
                {/* {data.length > 0 && (
                  <Grid item xs={6} sm={3}>
                    <FilterSelect
                      placeholder={"Select Status"}
                      options={DropDownForDownloadTras}
                      onChange={this.hanldeSelectStatus(
                        "selectedStatusForTrans"
                      )}
                    />
                  </Grid>
                )} */}

                {data.length > 0 && dispalyDownloadButton > 0 && (
                  <Grid item xs={6} sm={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handledownloadTransportionPdf}
                      fullWidth
                    >
                      Trasportation FORM
                    </Button>
                  </Grid>
                )}
                {selected.length > 0 && (
                  <Grid item xs={6} sm={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handledownloadZipWasel}
                      fullWidth
                    >
                      WASEL PACKED LIST
                    </Button>
                  </Grid>
                )}
                {selected.length > 0 && (
                  <Grid item xs={6} sm={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handledownloadZip}
                      fullWidth
                    >
                      PICK LIST
                    </Button>
                  </Grid>
                )}
                {selected.length > 0 && role === "Admin" && (
                  <Grid item xs={6} sm={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.showDispatchDialog}
                      fullWidth
                    >
                      Dispatch Order
                    </Button>
                  </Grid>
                )}
                {selected.length > 0 && role === "Admin" && (
                  <Grid item xs={6} sm={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.showTrackingNumberDialog}
                      fullWidth
                    >
                      Set Tracking Number
                    </Button>
                  </Grid>
                )}
                {selected.length > 0 && (
                  <Grid item xs={6} sm={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handledownloadInvoice}
                      fullWidth
                    >
                      Invoice
                    </Button>
                  </Grid>
                )}
                 <Grid item xs={6} sm={3}>
                  {
                    this.state.isSettingTrackingNumber &&(<BlackLoader/>)
                  } 
                  </Grid>
              </Grid>
            </div>
            <div className="container-root">
              <Grid container>
                <Grid item xs={6} sm={3}>
                  {BlackLoaderZip && (
                    <div className="uploading">
                      <BlackLoader />
                      <p className="uploading-text ">Downloading</p>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
            {data.length > 0 && !isLoading && (
              <OrdersShow
                setTrckingNumber={this.setTrckingNumber}
                data={data}
                page={page}
                count={count}
                rowsPerPage={rowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChange={this.handleChangeCheckbox}
                selected={selected}
                selectedData={selectedData}
                selectedCheckboxes={selectedCheckboxes}
                handleSearchOrderNumber={this.handleSearchOrderNumber}
                handleChangeInputSearch={this.handleChangeInputSearch}
                ErrorMessage={ErrorMessage}
                tableLoading={this.state.tableLoading}
                selectedFromDate={this.state.selectedFromDate}
                selectedToDate={this.state.selectedToDate}
                selectedOrderStatus={this.state.selectedOrderStatus}
              />
            )}
          </Grid>
          {isLoading && <GreenLoader />}

          {ShowFull && <InfoBox message="Data Not Available" />}
          <Dialog
            open={this.state.open}
            onClose={this.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.deletedialog}
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.dialogStyle}
            >
              {" Dispatch Order "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogText}
              >
                Are You Sure About That?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeDialog}
                color="default"
                variant="outlined"
                autoFocus
              >
                Close
              </Button>
              <Button
                onClick={this.handleDispatchOrder}
                color="secondary"
                variant="outlined"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openTrackingNumberDialog}
            onClose={this.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.deletedialog}
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.dialogStyle}
            >
              {" Set Tracking Number "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogText}
              >
                Are You Sure About That?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeDialog}
                color="default"
                variant="outlined"
                autoFocus
              >
                Close
              </Button>
              <Button
                onClick={this.handleSetTrackingNumber}
                color="secondary"
                variant="outlined"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(QhubRunnerOrders);
