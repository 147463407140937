import React, { Component } from "react";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import {
    Button,
    TextField,
    DialogContent,
    Dialog,
    Grid,
    Paper,
    Divider
} from "@material-ui/core";

import NoImage from "../../../assests/images/NoImage.png";
import "../../../components/Store/RestaurantData/RestaurantData.scss";
import FilterSelect from '../../../components/FilterSelect/FilterSelect';
import {
    createNewAd,
    getLocations,
    editAd,
    uploadAdImage
} from '../../../actions/80/MarketingAdApis';

class CreateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationsTypes: [],
            locations: [],
            coverImage: "",
            title: '',
            locationTypeSelected: {},
            locationSelected: {},
            statusSelected: { value: true, label: 'Valid' },
            titleErr: false,
            imgErr: false,
            locationErr: false,
            isProcess: false
        };
    }

    async componentDidMount() {
        this.setLocationstypes();
        (this.props.type === "edit") && this.setDataToEdit();
    }

    setDataToEdit = () => {
        this.handleChangelocationsTypes({ "value": this.props.adData.LocationType.Id, "label": this.props.adData.LocationType.Name });
        let image = (this.props.adData.AdvertisementImages[0]) ? `${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
            `/${this.props.adData.AdvertisementImages[0].ImageUrl}`) : "";
        this.setState({
            locationSelected: {
                "value": this.props.adData.Location.Id,
                "label": this.props.adData.Location.Name,
                "imageUrl": this.props.adData.Location.ImageUrl
            },
            title: this.props.adData.Title,
            statusSelected: { "value": this.props.adData.Status, "label": (this.props.adData.Status) ? "Valid" : "Invalid" },
            coverImage: image
        });
    }

    setLocationstypes = () => {
        let temp = [];
        this.props.locationsTypes.map((location) => {
            temp.push({ value: location.Id, label: location.Name });
        });
        this.setState({
            locationsTypes: temp,
            locationTypeSelected: temp[0]
        });
        temp[0] && (this.props.type !== "edit") && this.handleChangelocationsTypes(temp[0]);
    }


    handleCoverImageChange = (e) => {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            const image = new Image();
            image.onload = () => {
                this.setState({
                    coverImage: reader.result
                });
            };
            image.src = reader.result;
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    handleCreateAd = async () => {
        if (this.state.title === "") {
            this.setState({ titleErr: true });
        } else {
            this.setState({ titleErr: false });
        }
        if (this.state.coverImage === "") {
            this.setState({ imgErr: true });
        } else {
            this.setState({ imgErr: false })
        }
        if (this.state.title !== "" && this.state.coverImage !== "" && !this.state.locationErr) {
            this.setState({ isProcess: true })
            let img = this.state.coverImage.replace(/^data:image\/[a-z]+;base64,/, "");
            let createData = {
                "Title": this.state.title.trim(),
                "Status": this.state.statusSelected.value,
                "Location": {
                    "Id": this.state.locationSelected.value,
                    "Name": this.state.locationSelected.label,
                    "ImageUrl": this.state.locationSelected.imageUrl
                },
                "LocationType": {
                    "Id": this.state.locationTypeSelected.value,
                    "Name": this.state.locationTypeSelected.label
                },
                "AdvertisementImages": [
                    {
                        "AdvertisementId": 0,
                        "ImageUrl": "",
                        "ImageId": 0,
                        "ImageCode": img
                    }
                ]
            }
            let response = await createNewAd(createData);
            if (!response.errorMsg) {
                this.props.onShowMsg && this.props.onShowMsg("createNewAdSuccess");
            } else {
                if (response.errorMsg && response.errorMsg.includes("duplicated")) {
                    this.props.onShowMsg && this.props.onShowMsg("createNewAdDuplicatedErr");
                } else {
                    this.props.onShowMsg && this.props.onShowMsg("createNewAdErr");
                }
            }
            this.setState({ isProcess: false })
        }
    };

    handleEditAdInfo = async () => {
        if (this.state.title === "") {
            this.setState({ titleErr: true });
        }
        else {
            this.setState({ titleErr: false });
        }
        if (this.state.title !== "" && !this.state.locationErr) {
            this.setState({ isProcess: true })
            let editData = {
                "Id": this.props.adData.Id,
                "Title": this.state.title.trim(),
                "Status": this.state.statusSelected.value,
                "Location": {
                    "Id": this.state.locationSelected.value,
                    "Name": this.state.locationSelected.label,
                    "ImageUrl": this.state.locationSelected.imageUrl
                },
                "LocationType": {
                    "Id": this.state.locationTypeSelected.value,
                    "Name": this.state.locationTypeSelected.label
                },
                //"AdvertisementImages": this.props.adData.AdvertisementImages
            }
            let response = await editAd(editData);
            if (!response.errorMsg) {
                this.props.onShowMsg && this.props.onShowMsg("EditAdInfoSuccess")
            } else {
                if (response.errorMsg && response.errorMsg.includes("duplicated")) {
                    this.props.onShowMsg && this.props.onShowMsg("createNewAdDuplicatedErr");
                } else {
                    this.props.onShowMsg && this.props.onShowMsg("EditAdInfoErr");
                }
            }
            this.setState({ isProcess: false })
        }
    };

    handleEditAdImg = async () => {
        this.setState({ isProcess: true })
        let img = this.state.coverImage.replace(/^data:image\/[a-z]+;base64,/, "");
        let imgData = {
            "AdvertisementId": this.props.adData.Id,
            "ImageCode": img,
            "ImageUrl": "",
            "ImageId": this.props.adData.AdvertisementImages[0].ImageId,
        }
        let response = await uploadAdImage(imgData);
        if (response) {
            this.props.onShowMsg && this.props.onShowMsg("EditAdImgSuccess")
        } else {
            this.props.onShowMsg && this.props.onShowMsg("EditAdImgErr")
        }
        this.setState({ isProcess: false })
    }


    handleChangelocationsTypes = async (value) => {
        this.setState({
            locationTypeSelected: value,
            locations: [],
        });
        let response = await getLocations(value.label);
        if (response) {
            let temp = [];
            response.map((location) => {
                temp.push(
                    {
                        value: location.Id,
                        label: location.Name,
                        imageUrl: location.ImageUrl
                    }
                );
            });
            this.setState({
                locations: temp,
            });

            (this.props.type !== "edit") && temp[0] && this.setState({
                locationSelected: temp[0]
            });

            if (temp.some(data => data.label === (this.state.locationSelected.label))) {
                this.setState({ locationErr: false })
            } else {
                this.setState({ locationErr: true })
            }

        } else {
            this.setNotificationMsg("getLocations");
        }
    }

    render() {
        return (
            <Paper>
                <Grid container justify="center">
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <div className="cover-container">
                            <img
                                className="cover-img"
                                src={this.state.coverImage || NoImage}
                                alt="cover"
                                style={{ width: 'auto' }}
                            />
                            <Grid item className="cover-button-wrapper" xs={1}>
                                <span className="label">
                                    <AddPhotoAlternate />
                                </span>
                                <input
                                    type="file"
                                    id="upload"
                                    accept="image/*"
                                    className="upload-box"
                                    onChange={e => this.handleCoverImageChange(e)}
                                />
                            </Grid>
                        </div>
                    </Grid>
                    {
                        this.state.imgErr && (
                            <Grid container justify="center">
                                <Grid item xs={12} sm={6}>
                                    <p
                                        style={{
                                            color: '#f44336',
                                            marginLeft: '14px',
                                            marginRight: '14px',
                                            fontSize: '0.75rem',
                                            marginTop: '3px',
                                            textAlign: 'left',
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                            fontWeight: 400,
                                            lineHeight: '1.66',
                                            letterSpacing: '0.03333em',
                                        }}
                                    >
                                        This field is required.
                                        </p>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        (this.props.type === "edit") && (
                            <React.Fragment>
                                <Grid container justify="space-evenly" className="margin-bottom">
                                    <Grid item xs={12} sm={3} className="margin-bottom">
                                        <Button
                                            onClick={this.handleEditAdImg}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={this.state.isProcess}
                                        >
                                            <span className="button-text">
                                                EDIT IMAGE
                                        </span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    <Grid container justify="center" style={{ marginBottom: '20px' }}>
                        <Grid item xs={12} sm={6}>
                            <span>Location Type</span>
                            <FilterSelect
                                className="rawabi-input"
                                options={this.state.locationsTypes}
                                onChange={this.handleChangelocationsTypes}
                                selected={this.state.locationTypeSelected}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ marginBottom: '20px' }}>
                        <Grid item xs={12} sm={6}>
                            <span>Location</span>
                            <FilterSelect
                                className="rawabi-input"
                                options={this.state.locations}
                                onChange={(value) => {
                                    this.setState({ locationSelected: value })
                                    this.setState({ locationErr: false })
                                }}
                                selected={this.state.locationSelected}
                            />
                        </Grid>
                    </Grid>
                    {
                        this.state.locationErr && (
                            <Grid container justify="center" >
                                <Grid item xs={12} sm={6}>
                                    <p
                                        style={{
                                            color: '#f44336',
                                            marginLeft: '14px',
                                            marginRight: '14px',
                                            fontSize: '0.75rem',
                                            marginTop: '3px',
                                            textAlign: 'left',
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                            fontWeight: 400,
                                            lineHeight: '1.66',
                                            letterSpacing: '0.03333em',
                                        }}
                                    >
                                        Please change the location Commensurate with the location type.
                                    </p>
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid container justify="center" style={{ marginBottom: '20px' }}>
                        <Grid item xs={12} sm={6}>
                            <span>Title</span>
                            <TextField
                                error={this.state.titleErr}
                                id="outlined-error-helper-text"
                                label=""
                                helperText={this.state.titleErr ? "This field is required." : ""}
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={this.state.title}
                                onChange={e => this.setState({ title: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ marginBottom: '20px' }}>
                        <Grid item xs={12} sm={6}>
                            <span>Status</span>
                            <FilterSelect
                                className="rawabi-input"
                                onChange={(value) => this.setState({ statusSelected: value })}
                                selected={this.state.statusSelected}
                                options={
                                    [
                                        { value: true, label: 'Valid' },
                                        { value: false, label: 'Invalid' },
                                    ]
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="space-evenly" className="margin-bottom">
                        <Grid item xs={12} sm={3} className="margin-bottom">
                            <Button
                                onClick={(this.props.type === "edit") ? this.handleEditAdInfo : this.handleCreateAd}
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={this.state.isProcess}
                            >
                                <span className="button-text">
                                    {(this.props.type === "edit") ? "EDIT INFO." : "CREATE"}
                                </span>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper >
        );
    }
}

export default CreateForm;
