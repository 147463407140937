import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  setQuantityForItem,
  addNewBarcodeToDatabase,
  generateBarcode,
} from "../../actions/DataEntryAPIs";
import { getRole } from "../../services/share/DataService";
import { getColors } from "../../actions/PhotographerAPIs";
import FilterSelect from "../FilterSelect/FilterSelect";
import DoneIcon from "@material-ui/icons/Done";
import CreateIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip';
import BlackLoader from '../BlackLoader/BlackLoader';
import "./DataEntryTableInfo.styles.scss";

export default ({
  productInfo,
  productName,
  handleUpdateDta,
  dataIndex,
  handleEditQuantity,
  showSuccessMessage,
  showErrorMessage,
  clearMessage,
  handleAddNewBarcode,
}) => {
  const [isEditing, SetIsEditing] = useState(false);
  const [showRequiredMessage, SetShowMessage] = useState(false);
  const [isEditingSize, SetIsEditingSize] = useState(false);
  const [isEditingColor, SetIsEditingColor] = useState(false);
  const [editIndex, SetIndex] = useState(null);
  const [modifiedSize, SetModifiedSize] = useState(null);
  const [modifedQuantity, SetQuantity] = useState(null);
  const [modifiedColor, setModifiedColor] = useState({
    label: "",
    value: null,
  });
  const [isGenerate, SetIsGenerate] = useState(false);
  const [colorOptions, SetcolorOptions] = useState([]);
  const [Barcode, setBarcode] = useState("");
  const [Barcode4, setBarcode4] = useState("");
  const [color, setColor] = useState({});
  const [size, setSize] = useState("");
  const [quantity, SetQTY] = useState('');
  const [isGenerateBarcode, SetIsGenerateBarcode] = useState(false);
  const [isGenerateBarcode4, SetIsGenerateBarcode4] = useState(false);
  // console.log(dataIndex)

  const setModifiedQuantity = (e) => {
    SetQuantity(e.target.value);
  };
  const handleAdd = () => {
    SetIsGenerate(true);
  };
  const handleAddNewRow = () => {
    if((Barcode || Barcode4 !=='')&& color!=={}&& size!==''){
      SetShowMessage(false);
    const modal = {
      Barcode: Barcode,
      Barcode4: Barcode4,
      Color: color.label,
      Size: size,
      QTY: parseInt(quantity),
    };
    addNewBarcodeToDatabase(modal, productInfo._id).then((res) => {
      handleAddNewBarcode(dataIndex, res);
    });
    // handleAddNewBarcode(dataIndex, Barcode, Barcode4, color.label, size, quantity );
    // SetIsGenerate(false)
    setSize("");
    SetQTY("");
    setBarcode("");
    setBarcode4("");
    setColor({});
  }
  else{
    SetShowMessage(true);
  }
  };
  const removeAdditionRow = () => {
    SetIsGenerate(false);
    SetShowMessage(false)
  };
 const  handleGenerateBarcode = inputType => {
     if(inputType === 'Barcode'){
       SetIsGenerateBarcode(true);
       setBarcode('');
       generateBarcode().then(res => {
        setBarcode(res.Barcode);
        SetIsGenerateBarcode(false);
       })

     }

     if(inputType === 'Barcode4'){
      SetIsGenerateBarcode4(true);
      setBarcode4('');
      generateBarcode().then(res => {
       setBarcode4(res.Barcode);
       SetIsGenerateBarcode4(false);
      })

    }
}

  const handleChange = (type, value) => {
    console.log(value);
    switch (type) {
      case "color":
        setColor({ label: value, value: value });
        break;
      case "Modifiedcolor":
        setModifiedColor({ label: value, value: value });
        break;
      case "barcode":
        setBarcode(value);
        break;
      case "barcode4":
        setBarcode4(value);
        break;
      case "size":
        setSize(value);
        break;
      case "quantity":
        SetQTY(value);
    }
  };
  const generateRow = () => {
    // console.log(color);
    // console.log(Barcode);
    // console.log(Barcode4);
    // console.log(size);
    // console.log(quantity);
    return (
      <React.Fragment>
      <TableRow>
        <TableCell>
        <Grid style={{position: 'relative'}}>
          <input
            value={Barcode}
            onChange={(event) => handleChange("barcode", event.target.value)}
            style={{ width: 200 ,borderRadius:'5px'}}
            autoFocus
          />
           <Tooltip title='Generate' >
            <Button onClick={()=> handleGenerateBarcode('Barcode')}  style={{position:'absolute',right:'-1px',top:'0px',width:'5px',height:'25px'}}>
              <CreateIcon fontSize='small' />
              </Button>
            </Tooltip>
            {
              isGenerateBarcode && (
                <div style={{position:'absolute',left:0,top:1}}>
                <BlackLoader />
                </div>
              )
            }
           
            </Grid>
        </TableCell>
        <TableCell>
        <Grid style={{position: 'relative'}}>
          <input
            value={Barcode4}
            onChange={(event) => handleChange("barcode4", event.target.value)}
            style={{ width: 200,borderRadius:'5px' }}
            autoFocus
          />
           <Tooltip title='Generate' >
            <Button onClick={()=> handleGenerateBarcode('Barcode4')}    style={{position:'absolute',right:'-1px',top:'0px',width:'5px',height:'25px'}}>
              <CreateIcon fontSize='small' />
              </Button>
            </Tooltip>
            {
              isGenerateBarcode4 && (
                <div style={{position:'absolute',left:0,top:1}}>
              <BlackLoader />
              </div>
              )
            }
            
            </Grid>
        </TableCell>
        <TableCell className='color-filter' align="right" >
          <Grid >
            <FilterSelect
              selected={color}
              options={colorOptions}
              onChange={(selected) => handleChange("color", selected.label)}
            />
          </Grid>
        </TableCell>
        <TableCell align="right">
          <input
            value={size}
            onChange={(event) => handleChange("size", event.target.value)}
            style={{ width: 60 ,borderRadius:'5px'}}
            autoFocus
          />
        </TableCell>
        <TableCell align="right">
          <input
            value={quantity}
            onChange={(event) => handleChange("quantity", event.target.value)}
            style={{ width: 60 ,borderRadius:'5px'}}
            autoFocus
          ></input>
        </TableCell>
        <TableCell align="right">
          <Button onClick={handleAddNewRow}>
            {" "}
            <AddCircleOutlineIcon />
          </Button>
        </TableCell>
        {/* {getRole() !== "QA" && <TableCell align="right">Actions</TableCell>} */}
        
      </TableRow>
       
      </React.Fragment>
    );
  };

  const handleUpdateColor = (res) => {
    const temp = res;
    temp.Color = modifiedColor.label;
    setQuantityForItem(temp, productInfo._id).then((res) => {
      SetIsEditingColor(false);
    });
  };
  const keypressed = (e, res, index, type) => {
    clearMessage();
    let temp = res;
    if (e.keyCode == 13) {
      if (type === "quantity") {
        temp.QTY = modifedQuantity;
      }
      if (type === "size") {
        temp.Size = modifiedSize;
      }
      setQuantityForItem(temp, productInfo._id).then((res) => {
        SetIsEditing(false);
        SetIsEditingSize(false);
      });
    }

    // if(e.keyCode == 13){
    //   setQuantityForItem({
    //     sizeId: id,
    //     quantity: modifedQuantity
    //   }).then(res => {
    //     handleEditQuantity(dataIndex,index,modifedQuantity);
    //     showSuccessMessage();
    //     SetIsEditing(false)
    //   }).catch( err => {
    //     showErrorMessage();
    //     SetIsEditing(false);
    //   })
    // setpricesForItem({
    //   itemid: id,
    //   sellingPriceOnline: this.state.modifiedPrice,
    // }).then(res => {
    // this.props.handleEditOnlinePrice(index,this.state.modifiedPrice);
    // this.setState({
    //   isEditingQunatity: false,
    // })
    // }).catch(err => {

    // })
    // put the login here
    //  }
  };

  useEffect(() => {
    getColors().then((res) => SetcolorOptions(res));
  }, []);
  return (
    <Grid item xs={6}>
        {!isGenerate ? (
          <Button onClick={handleAdd}>
            {" "}
            <AddCircleOutlineIcon />
          </Button>
        ) : (
          <Button
            color="primary"
            variant="outlined"
            onClick={removeAdditionRow}
          >
            Close
          </Button>
        )}

   {
     showRequiredMessage && (<Grid container justify='center'>
     <h6 style={{color:'red', margin:'0 auto !important'}}> All required field should be filled  </h6>
     </Grid>)
   }
        
        
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Barcode</TableCell>
              <TableCell align='center'>Barcode4</TableCell>
              <TableCell style={{paddingLeft: '37px'}}>Color</TableCell>
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">QTY</TableCell>
              {/* <TableCell align="right">Selling QTY</TableCell> */}

              {getRole() !== "QA" && (
                <TableCell align="right">Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isGenerate && generateRow()}
            {productInfo.BarcodesExtraData.map((res, index) => (
              <React.Fragment>
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {" "}
                    {res.Barcode ? res.Barcode : <h2>______</h2>}{" "}
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {res.Barcode4 ? res.Barcode4 : <h2>______</h2>}{" "}
                  </TableCell>
                  <TableCell style={{width:'300px'}}>
                    {" "}
                    {isEditingColor && editIndex === index ? (
                      <React.Fragment>
                        <FilterSelect
                        fordialog={true}
                          selected={modifiedColor}
                          options={colorOptions}
                          onChange={(selected) =>
                            handleChange("Modifiedcolor", selected.label)
                          }
                        />
                        {modifiedColor.value !== null && isEditingColor && (
                          <Button onClick={() => handleUpdateColor(res)}>
                            {" "}
                            <DoneIcon />{" "}
                          </Button>
                        )}
                      </React.Fragment>
                    ) : (
                      <span
                        onClick={() => {
                          SetIsEditingColor(true);
                          SetIndex(index);
                        }}
                        style={{
                          textAlign: "center",
                          color: "black",
                          cursor: "pointer",
                        }}
                        className="price editPrice"
                      >
                        {res.Color}
                      </span>
                    )}{" "}
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {isEditingSize && editIndex === index ? (
                      <input
                        style={{ width: "100px" }}
                        autoFocus
                        onBlur={() => SetIsEditingSize(false)}
                        onChange={(e) => SetModifiedSize(e.target.value)}
                        onKeyDown={(e) => keypressed(e, res, index, "size")}
                      />
                    ) : (
                      <span
                        onClick={() => {
                          SetIsEditingSize(true);
                          SetIndex(index);
                        }}
                        style={{
                          textAlign: "center",
                          color: "black",
                          cursor: "pointer",
                        }}
                        className="price editPrice"
                      >
                        {res.Size}
                      </span>
                    )}
                  </TableCell>
                  {/* <TableCell align="right">
                  {res.QTY ? res.QTY : <h2>____</h2>}{" "}
                  </TableCell> */}
                  <TableCell align="right">
                    {" "}
                    {isEditing && editIndex === index ? (
                      <input
                        autoFocus
                        type="number"
                        style={{ width: "100px" }}
                        onBlur={() => SetIsEditing(false)}
                        onChange={(e) => setModifiedQuantity(e)}
                        onKeyDown={(e) => keypressed(e, res, index, "quantity")}
                      />
                    ) : (
                      <span
                        onClick={() => {
                          SetIsEditing(true);
                          SetIndex(index);
                        }}
                        style={{
                          textAlign: "center",
                          color: "black",
                          cursor: "pointer",
                        }}
                        className="price"
                      >
                          {" "}
                    {res.QTY ? res.QTY : <h2>____</h2>}{" "}
                       
                      </span>
                    )}
                  </TableCell>
                  {getRole() !== "QA" && (
                    <TableCell align="right">
                      {" "}
                      <DeleteIcon
                        className="deleteButton"
                        onClick={() =>
                          handleUpdateDta(res.SizeId, productInfo._id)
                        }
                      />{" "}
                    </TableCell>
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
    </Grid>
  );
};
