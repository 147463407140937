import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {checkUser} from './services/share/DataService';
import './index.scss';


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: lightGreen,
    secondary: red,
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    {
      checkUser()
    }
    <App />
  </MuiThemeProvider>, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
