import React from "react";
import { Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import { withStyles } from "@material-ui/styles";
import GreenLoader from "../../GreenLoader/GreenLoader";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { getColors } from '../../../actions/PhotographerAPIs';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  downloadZipfile,
  getItemStatus,
  UpdateOrderItemStatusForRelatedBarcode
} from "../../../actions/OrderAPI";
import BlackLoader from "../../BlackLoader/BlackLoader";
import BarcodeReader from "react-barcode-reader";
import barcodeIcon from "../../../assests/images/Barcode.png";
import BarcodeReaderExtra from "../../barcodeReader/BarcodeReader";
import { debounce } from "lodash";

import {
  getLoations,
  exportFile,
  SendNewRunnerRunnerStatus,
  SendNewWarehouseManagerStatus,
  DownloadPdfFile,
  UploadReceiptImg,
  getImageNames
} from "../../../actions/OrderAPI";
import "./ViewOrder.scss";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  stableSort,
  getSorting
} from "../../DataEntry/DataEntryCard/utilitiez";
import TableHead from "@material-ui/core/TableHead";
import FilterSelect from "../../FilterSelect/FilterSelect";
import EditIcon from "@material-ui/icons/Edit";
import Done from "@material-ui/icons/Done";
import { getRole } from "../../../services/share/DataService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import receiptImg from "../../../assests/images/icons8-receipt-24.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
const styles = {
  dialogPaper: {
    minHeight: "40vh"
  }
};

const OrderItemStatus = [
  { label: "Missing", value: 0 },
  { label: "Available", value: 0 }
];

const OrderItemStatusForWarehouseManager = [
  { label: "Missing", value: 0 },
  { label: "Available", value: 0 }
];

const OrderStatusForWarehouseManager = [
  { label: "Dispatched", value: 0 },
  { label: "Dispatch Area", value: 0 }
];

const itemStatus = [
  { label: "Missing", value: 0 },
  { label: "Available", value: 0 }
];
const OrderItemStatusForAdmin = [
  { label: "Missing", value: 0 },
  { label: "Available", value: 0 }
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: "53px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      marginLeft: -110,
      width: 300
    }
  }
};

// var defaultLocation = ['Locations'];
class ViewOrders extends React.Component {
  constructor() {
    super();
    this.state = {
      imagesName: [],
      Orders: [],
      isExpanding: false,
      ExpandItemid: null,
      Items: [],
      notificationMsg: "",
      order: "asc",
      orderBy: "Description",
      open: false,
      selectedOrderItemStatus: { label: "Select", value: null },
      selectedOrderStatus: { label: "Select", value: null },
      selectedItemStatus: { label: "Select", value: null },
      selectedRelatedBarcodeStatus: { label: "Select", value: null },
      enableUpdate: false,
      OrderItemId: null,
      ShowDropDownStatus: false,
      ShowDropDownOrderStatus: false,
      OrderId: null,
      enableUpdateOrderStatus: false,
      openImg: false,
      showImg: false,
      Barcode: "",
      Showerror: false,
      OrderNo: "",
      recepietImg: "",
      rotation: 0,
      currentImageIndex: null,
      selectedIndex: null,
      locationList: [],
      defaultLocation: ["Locations"],
      downloadPdf: false,
      indexForClickedPdf: null,
      locations: "sdffffff",
      opendialog: false,
      openAlterDialog: false,
      tagsInputValue: "",
      actualBarcode: "",
      relatedBarcodeStatus: [],
      editIndex: null,
      existRelatedBarcode4: true,
      alterbarcode: "",
      opencolorDialog: false,
      colorOptions: [],
      COLOR: '',
      currentItem: {},
      openMissingDialig: false,
      commentForItemStatus: '',
      suggestionColors: [],
      tags: [],
    };
  }

  componentDidMount() {
    getItemStatus().then(res => {
      this.setState({
        relatedBarcodeStatus: res
      });
    });
    getColors()
    .then(res =>{
      let temp = [];
      this.setState({
        colorOptions: res
      })
      for(let color of this.state.colorOptions){
        temp.push(color.label)
      }
      this.setState({
        suggestionColors: temp
      })
    }
    )
    .catch(() => {
      this.setState({
        notificationMsg: "getColors"
      });
    });
  }
  handleCloseMissingDialog = () => {
    this.setState({
      openMissingDialig: false,
    })
  }
  handChangeAcutalBarCode = value => {
    this.setState({
      actualBarcode: value
    });
  };
  handlechangeAlterBarcode = debounce((value) => {
    // console.log(event.target.value)
    this.setState({
      alterbarcode: value
    });
  },1000) 

  handleChangeComments = debounce((value) => {
    // console.log(event.target.value)
    this.setState({
      commentForItemStatus: value
    });
  },1000) 
  
  
  handleCloseShowImg = () => {
    this.setState({
      showImg: false,
      open: false
    });
  };

  rotate = index => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      selectedIndex: index,
      rotation: newRotation
    });
  };

  handledownloadZip = (id, index) => {
    const toDownloadPDFS = {
      to: this.props.selectedFromDate,
      from: this.props.selectedToDate,
      pageSize: this.props.rowsPerPage,
      page: this.props.page,
      ordersId: id,
      orderStatus: this.props.selectedOrderStatus.label
    };
    this.setState({
      downloadPdf: true,
      indexForClickedPdf: index
    });
    downloadZipfile(toDownloadPDFS, true).then(() => {
      this.setState({
        downloadPdf: false
      });
    });
  };

  rotateleft = index => {
    let newRotation = this.state.rotation - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      selectedIndex: index,
      rotation: newRotation
    });
  };

  handleImageChange = (event, orderNumm) => {
    const { id } = event.target;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        this.setState({
          [id]: reader.result,
          open: true,
          OrderNo: orderNumm
        });
      };
      image.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleUploadReciept = () => {
    const { recepietImg, OrderItemBarcode } = this.state;
    const { OrderNo } = this.state;
    var base64result = recepietImg.substr(recepietImg.indexOf(",") + 1);
    const toUploadImage = {
      OrderNo: OrderNo,
      OrderItemBarcode: OrderItemBarcode,
      ImageCode: base64result
    };
    UploadReceiptImg(toUploadImage)
      .then(() =>
        this.setState({
          open: false,
          showImg: false,
          notificationMsg: "ImageUploaded"
        })
      )
      .catch(error => {});
  };

  isSelected = id => {
    const { selected } = this.props;
    return selected.indexOf(id) !== -1;
  };

  handleDateFormat = date => {
    const Newdate = new Date(Date.parse(date));
    return (
      Newdate.getFullYear() +
      "/" +
      (Newdate.getMonth() + 1) +
      "/" +
      Newdate.getUTCDate()
    );
  };

  handleCloseExpandClick = index => {
    this.setState({
      isExpanding: false,
      open: false,
      ExpandItemid: null,
      OrderId: null,
      ShowDropDownStatus: false,
      enableUpdate: false,
      enableUpdateOrderStatus: false,
      ShowDropDownOrderStatus: false
    });
  };

  handleExport = Id => {
    this.setState({
      notificationMsg: ""
    });

    exportFile(Id)
      .then(() => {
        this.setState({
          notificationMsg: "ProductExportedSuccessfully"
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "exportFile"
        });
      });
  };

  ExpandItem = (orderName, orderId, index) => {
    this.setState({
      item: [],
      enableUpdate: false,
      ShowDropDownStatus: false,
      ShowDropDownOrderStatus: false
    });
    getLoations(orderName)
      .then(res => {
        this.setState({
          ExpandItemid: index,
          Items: res["Items"],
          open: false,
          isExpanding: true
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "GetItemLocations"
        });
      });
  };

  handleChange = (id, index) => selectedValue => {
    // console.log(selectedValue);
    if (id === "selectedOrderStatus") {
      this.setState({
        [id]: selectedValue.label,
        enableUpdateOrderStatus: true,
        OrderId: index
      });
    } else if (id === "selectedRelatedBarcodeStatus") {
      this.setState({
        selectedRelatedBarcodeStatus: selectedValue.label,
        enableUpdateOrderStatus: true,
        OrderId: index
      });
      if(selectedValue.label === 'Available_With_Different_Barcode'){
        this.setState({
          openAlterDialog: true,
        })
      }
      if(selectedValue.label === 'Available_With_Different_Color'){
        this.setState({
          opencolorDialog: true,
        })
      }
      if(selectedValue.label === 'Missing_With_Different_Barcode'){
        this.setState({
          openMissingDialig: true,
        })
      }
    } else {
      this.setState({
        [id]: selectedValue.label,
        enableUpdate: true,
        OrderItemId: index
      });
    }
  };
  handleChangeColor = ( type, color) => {
    this.setState({
      COLOR: color
    })
  }
  handleChangeExpansion = index => {
    this.setState({
      enableUpdate: false,
      OrderItemId: null,
      ShowDropDownStatus: false,
      notificationMsg: ""
    });
  };
  handleCloseColorDialog = () => {
    this.setState({
      opencolorDialog: false,
    })
  }

  // to sort the table items by a spicific property either ascending or descending.
  handleRequestSort = (event, property) => {
    const currentOrderBy = property;
    let currentOrder = "desc";
    const { orderBy, order } = this.state;

    if (orderBy === property && order === "desc") {
      currentOrder = "asc";
    }
    this.setState({
      order: currentOrder,
      orderBy: currentOrderBy
    });
  };

  handleSaveTrackingStatus = (orderName, barcode, index) => {
    const { selectedOrderItemStatus, Items } = this.state;
    this.setState({
      notificationMsg: ""
    });
    SendNewRunnerRunnerStatus(orderName, barcode, selectedOrderItemStatus).then(
      res => {
        if (res === null) {
          this.setState({
            notificationMsg: "OrderItemStatusNotUpdated"
          });
        } else {
          Items[index].TrackingStatus = selectedOrderItemStatus;
          this.setState({
            notificationMsg: "ItemUpdated",
            ShowDropDownStatus: false,
            OrderItemId: null,
            enableUpdate: false
          });
        }
      }
    );
  };

  EditItemStatus = (orderid, index) => {
    this.setState({
      OrderItemId: index,
      ShowDropDownStatus: true,
      notificationMsg: ""
    });
  };
  handleCloseAlterDialog = () => {
    this.setState({
      openAlterDialog: false,
    })
  }

  EditOrderStatus = (orderid, index) => {
    this.setState({
      OrderId: index,
      ShowDropDownOrderStatus: true,
      enableUpdate: false,
      isExpanding: false,
      notificationMsg: ""
    });
  };
  handleSaveOrderTrackingStatus = (orderName, orderid, index) => {
    const { selectedOrderStatus } = this.state;
    this.setState({
      notificationMsg: ""
    });

    SendNewWarehouseManagerStatus(orderName, selectedOrderStatus).then(res => {
      if (res === null) {
        this.setState({
          notificationMsg: "OrderStatusNotUpdated"
        });
      } else {
        this.props.data[index].TrackingStatus = null;
        this.props.data[index].TrackingStatus = selectedOrderStatus;
        this.setState({
          notificationMsg: "OrderUpdated",
          ExpandItemid: null,
          OrderId: null,
          ShowDropDownOrderStatus: false
        });
      }
    });
  };

  DownloadPdfFile = (orderid, index) => {
    this.setState({
      notificationMsg: ""
    });
    DownloadPdfFile(orderid)
      .then(res => {
        this.setState({
          notificationMsg: "OrderExported"
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "exportFile"
        });
      });
  };

  handleViewReceiptImg = orderNumber => {
    getImageNames(orderNumber)
      .then(res => {
        this.setState({
          imagesName: res,
          showImg: true,
          OrderNo: orderNumber
        });
      })
      .catch(() => {
        this.setState({
          showImg: true,
          Showerror: true
        });
      });
  };
  onTagsChange = (event, values) => {
    this.setState({
      tags: values
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
      console.log(this.state.tags);
    });
  }
  handleUpdateRelatedBarcodeStatus = (
    orderNo,
    orderItemId,
    orderItemBarcode,
    trackingStatus,
  ) => {
    const params = {
      orderNo: orderNo,
      orderItemId: orderItemId,
      orderItemBarcode: orderItemBarcode,
      trackingStatus: trackingStatus,
      altBarcode: this.state.alterbarcode,
      color: this.state.COLOR,
      comments: this.state.commentForItemStatus,
    };
    const temp = this.state.Items;
    let modifyIndex;
    const data = temp[this.state.editIndex].relatedBarcode4;
    for( let i =0 ; i < data.length; i++){
        if(data[i].Barcode === this.state.actualBarcode.trim('')){
          modifyIndex = i;
        }
    }
   temp[this.state.editIndex].relatedBarcode4[modifyIndex].Status= this.state.selectedRelatedBarcodeStatus;
   if(typeof(this.state.selectedRelatedBarcodeStatus)=== 'string' && this.state.selectedRelatedBarcodeStatus === 'Available_With_Different_Barcode' || 'Missing_With_Different_Barcode'){
    temp[this.state.editIndex].relatedBarcode4[modifyIndex].AltBarcode = this.state.alterbarcode;
   }
   if(typeof(this.state.selectedRelatedBarcodeStatus)=== 'string' && this.state.selectedRelatedBarcodeStatus === 'Available_With_Different_Color'){
    temp[this.state.editIndex].relatedBarcode4[modifyIndex].AltColor = this.state.COLOR;
   }

   

    UpdateOrderItemStatusForRelatedBarcode(params).then(res => {
     
      this.setState({
        notificationMsg: "operation accomplished successfully",
        Items: temp,
        opendialog: false,
      });
    });
  };

  handleClose = () => {
    this.setState({
      showImg: false,
      Barcode: "",
      OrderNo: ""
    });
  };
  handleCloseDialog = () => {
    this.setState({
      opendialog: false
    });
  };
  // hadleAddActualBarCODE = () => {};
  setTags = tags => {
    let tmp = "";
    tmp = tags.join(",");
    this.setState({
      tagsInputValue: tmp
    });
  };
  openAddDialog = (index, item) => {
    this.setState({
      opendialog: true,
      editIndex: index,
      currentItem: item
    });

    if(item.relatedBarcode4.length == 1){
      this.setState({
        existRelatedBarcode4: false,
        actualBarcode : item.relatedBarcode4[0].Barcode
      })
    }
    // console.log(item)
  };

  handleScan = barcode => {
    this.setState({
      alterbarcode: barcode
    });
  };

  handleError = err => {};
  handleAddActualBarCode = barcode => {
    this.setState({
      opendialog: false
    });
  };

  render() {
    console.log(this.state.commentForItemStatus);
    console.log(this.state.colorOptions)
    console.log(this.state.suggestionColors)

    const {
      isExpanding,
      ExpandItemid,
      notificationMsg,
      Items,
      order,
      orderBy,
      selectedOrderItemStatus,
      enableUpdate,
      OrderItemId,
      ShowDropDownStatus,
      ShowDropDownOrderStatus,
      selectedOrderStatus,
      OrderId,
      enableUpdateOrderStatus,
      showImg,
      Barcode,
      Showerror,
      OrderNo,
      actualBarcode
    } = this.state;
    const { classes } = this.props;
    const {
      data,
      rowsPerPage,
      count,
      page,
      onChangePage,
      onChangeRowsPerPage,
      handleChange,
      selectedCheckboxes,
      handleSearchOrderNumber,
      ErrorMessage,
      handleChangeInputSearch,
      tableLoading
    } = this.props;

    const Role = getRole();
    const marginLeft = 3;
    return (
      <div>
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Grid container>
          <Paper className="table-root">
            <div className="table-wrapper">
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ verticalAlign: "top" }}>
                      Actions
                    </TableCell>
                    <TableCell style={{ maxWidth: "207px" }}>
                      <Grid container>Order NO.</Grid>
                      {/* <Divider></Divider> */}
                      {/* <Paper
                        style={{
                          display: "flex",
                          overflowX: "auto",
                          marginTop: "5px"
                        }}
                      >
                        <InputBase
                          placeholder="Search"
                          inputProps={{
                            style: {
                              marginLeft
                            }
                          }}
                          id="SearchOrder"
                          onChange={e =>
                            handleChangeInputSearch(e, "SearchOrder")
                          }
                        />
                        <IconButton
                          onClick={handleSearchOrderNumber}
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper> */}
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      Processed At
                    </TableCell>
                    {
                      Role ==='Admin' && (<TableCell style={{ verticalAlign: "top" }}>
                      Customer
                    </TableCell>)
                    }
                    <TableCell style={{ verticalAlign: "top" }}>
                      Tracking Status
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      Status
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      Tracking Number
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      Total Price
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {ErrorMessage && (
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: "center" }}>
                        <h5> No Data Avaliable</h5>
                      </TableCell>
                    </TableRow>
                  )}
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order, index) => {
                      return [
                        <TableRow key={index}>
                          <TableCell className="table-cell">
                            {(Role === "WarehouseManager" ||
                              Role === "Admin") && (
                              <Grid item container spacing={2}>
                                {ExpandItemid !== index && OrderId !== index && (
                                  <div>
                                    {this.state.downloadPdf &&
                                      this.state.indexForClickedPdf ===
                                        index && <BlackLoader />}
                                    <Checkbox
                                      onChange={() => handleChange(order.Id)}
                                      checked={selectedCheckboxes[order.Id]}
                                      color="primary"
                                    />

                                    {/* <Button
                                      onClick={() =>
                                        this.EditOrderStatus(order.Id, index)
                                      }
                                    >
                                      <EditIcon />
                                    </Button> */}
                                    <Button
                                      onClick={() =>
                                        this.handledownloadZip(
                                          [order.Id],
                                          index
                                        )
                                      }
                                    >
                                      <PictureAsPdfIcon />
                                    </Button>
                                    <Grid
                                      item
                                      className="upload-button-wrapper"
                                      xs={2}
                                    >
                                      <span className="label">
                                        <AddPhotoAlternate />
                                      </span>
                                      <input
                                        type="file"
                                        id="recepietImg"
                                        accept="image/*"
                                        className="upload-box"
                                        onChange={e =>
                                          this.handleImageChange(
                                            e,
                                            order.Number
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Dialog
                                      open={this.state.open}
                                      onClose={this.handleClose}
                                    >
                                      <div>
                                        <img
                                          src={this.state.recepietImg}
                                          style={{ width: "100%" }}
                                        />
                                      </div>

                                      <DialogActions
                                        style={{ flexFlow: "column" }}
                                      >
                                        <Button
                                          color="primary"
                                          variant="outlined"
                                          onClick={this.handleUploadReciept}
                                        >
                                          Upload
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    {/* For Show Img */}
                                    <Dialog
                                      open={showImg}
                                      onClose={this.handleCloseShowImg}
                                      fullWidth
                                      maxWidth="md"
                                    >
                                      {!Showerror && (
                                        <div className="view-image-container">
                                          {this.state.imagesName.map(
                                            (name, index) => (
                                              <div
                                                key={index}
                                                style={{ position: "relative" }}
                                              >
                                                {/* <RotateLeftIcon
                                                                  className='image-rotate'
                                                                 id={index}
                                                                 onClick={()=> this.rotateleft(index)}
                                                                 /> */}
                                                <img
                                                  className={
                                                    this.state.isRotate
                                                      ? "rotate"
                                                      : ""
                                                  }
                                                  style={{
                                                    width: "90%",
                                                    position: "relative",
                                                    transform: `rotate(${
                                                      this.state
                                                        .selectedIndex === index
                                                        ? this.state.rotation
                                                        : 0
                                                    }deg)`
                                                  }}
                                                  src={`${process.env.REACT_APP_API_ENDPOINT}/api/image/orders/receipt?imageName=${name.Size}`}
                                                />
                                                <RotateRightIcon
                                                  onClick={() =>
                                                    this.rotate(index)
                                                  }
                                                  className="image-rotate"
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {Showerror && (
                                        <DialogContent>
                                          <Grid container justify="center">
                                            <h6>No Image Avaliable</h6>
                                          </Grid>
                                        </DialogContent>
                                      )}
                                      <Grid container justify="center">
                                        <DialogActions>
                                          <Button
                                            onClick={this.handleCloseShowImg}
                                            color="default"
                                            variant="contained"
                                          >
                                            Close
                                          </Button>
                                        </DialogActions>
                                      </Grid>
                                    </Dialog>
                                    <Button
                                      onClick={() =>
                                        this.handleViewReceiptImg(order.Number)
                                      }
                                    >
                                      <img
                                        style={{
                                          height: "30px",
                                          marginBottom: "5px"
                                        }}
                                        src={receiptImg}
                                      />
                                    </Button>

                                    <Button
                                      onClick={() =>
                                        this.ExpandItem(
                                          order.Name,
                                          order.Id,
                                          index
                                        )
                                      }
                                    >
                                      <ExpandMoreIcon />
                                    </Button>
                                  </div>
                                )}

                                {(ExpandItemid === index ||
                                  OrderId === index) && (
                                  <Button
                                    onClick={() =>
                                      this.handleCloseExpandClick(
                                        order.Id,
                                        index
                                      )
                                    }
                                    cursor="pointer"
                                  >
                                    <ExpandLessIcon />
                                  </Button>
                                )}
                                {/* {enableUpdateOrderStatus && OrderId === index && (
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      this.handleSaveOrderTrackingStatus(
                                        order.Name,
                                        order.Id,
                                        index
                                      )
                                    }
                                  >
                                    <Done />
                                  </Button>
                                )} */}
                              </Grid>
                            )}

                            {Role === "QhubRunner" && (
                              <div>
                                {ExpandItemid !== index && OrderId !== index && (
                                  <div>
                                    {this.state.downloadPdf &&
                                      this.state.indexForClickedPdf ===
                                        index && <BlackLoader />}
                                    <Checkbox
                                      onChange={() => handleChange(order.Id)}
                                      checked={selectedCheckboxes[order.Id]}
                                      color="primary"
                                    />

                                    <Button
                                      onClick={() =>
                                        this.handledownloadZip(
                                          [order.Id],
                                          index
                                        )
                                      }
                                    >
                                      <PictureAsPdfIcon />
                                    </Button>
                                    <Grid
                                      item
                                      className="upload-button-wrapper"
                                      xs={2}
                                    >
                                      <span className="label">
                                        <AddPhotoAlternate />
                                      </span>
                                      <input
                                        type="file"
                                        id="recepietImg"
                                        accept="image/*"
                                        className="upload-box"
                                        onChange={e =>
                                          this.handleImageChange(
                                            e,
                                            order.Number
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Dialog
                                      open={this.state.open}
                                      onClose={this.handleClose}
                                    >
                                      <div>
                                        <img
                                          src={this.state.recepietImg}
                                          style={{ width: "100%" }}
                                        />
                                      </div>

                                      <DialogActions
                                        style={{ flexFlow: "column" }}
                                      >
                                        <Button
                                          color="primary"
                                          variant="outlined"
                                          onClick={this.handleUploadReciept}
                                        >
                                          Upload
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    {/* For Show Img */}
                                    <Dialog
                                      open={showImg}
                                      onClose={this.handleCloseShowImg}
                                      fullWidth
                                      maxWidth="md"
                                    >
                                      {!Showerror && (
                                        <div className="view-image-container">
                                          {this.state.imagesName.map(
                                            (name, index) => (
                                              <div
                                                key={index}
                                                style={{ position: "relative" }}
                                              >
                                                {/* <RotateLeftIcon
                                                                  className='image-rotate'
                                                                 id={index}
                                                                 onClick={()=> this.rotateleft(index)}
                                                                 /> */}
                                                <img
                                                  className={
                                                    this.state.isRotate
                                                      ? "rotate"
                                                      : ""
                                                  }
                                                  style={{
                                                    width: "90%",
                                                    position: "relative",
                                                    transform: `rotate(${
                                                      this.state
                                                        .selectedIndex === index
                                                        ? this.state.rotation
                                                        : 0
                                                    }deg)`
                                                  }}
                                                  src={`${process.env.REACT_APP_API_ENDPOINT}/api/image/orders/receipt?imageName=${name.Size}`}
                                                />
                                                <RotateRightIcon
                                                  onClick={() =>
                                                    this.rotate(index)
                                                  }
                                                  className="image-rotate"
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {Showerror && (
                                        <DialogContent>
                                          <Grid container justify="center">
                                            <h6>No Image Avaliable</h6>
                                          </Grid>
                                        </DialogContent>
                                      )}
                                      <Grid container justify="center">
                                        <DialogActions>
                                          <Button
                                            onClick={this.handleCloseShowImg}
                                            color="default"
                                            variant="contained"
                                          >
                                            Close
                                          </Button>
                                        </DialogActions>
                                      </Grid>
                                    </Dialog>
                                    <Button
                                      onClick={() =>
                                        this.handleViewReceiptImg(order.Number)
                                      }
                                    >
                                      <img
                                        style={{
                                          height: "30px",
                                          marginBottom: "5px"
                                        }}
                                        src={receiptImg}
                                      />
                                    </Button>

                                    <Button
                                      onClick={() =>
                                        this.ExpandItem(
                                          order.Name,
                                          order.Id,
                                          index
                                        )
                                      }
                                    >
                                      <ExpandMoreIcon />
                                    </Button>
                                  </div>
                                )}
                                {(ExpandItemid === index ||
                                  OrderId === index) && (
                                  <Button
                                    onClick={() =>
                                      this.handleCloseExpandClick(
                                        order.Id,
                                        index
                                      )
                                    }
                                    cursor="pointer"
                                  >
                                    <ExpandLessIcon />
                                  </Button>
                                )}
                              </div>
                            )}
                          </TableCell>
                          <TableCell>{order.Name}</TableCell>
                          <TableCell>
                            {this.handleDateFormat(order.ProcessedAt)}
                          </TableCell>
                          {
                            Role === 'Admin' && (  <TableCell>
                              {order.Customer === null ? "" : order.Customer.Name}
                            </TableCell>)
                          }
                          <TableCell style={{ minWidth: "258px" }}>
                            {OrderId !== index && (
                              <TextField
                                id="outlined-name"
                                margin="normal"
                                value={order.TrackingStatus || ""}
                                inputProps={{
                                  readOnly: true
                                }}
                              ></TextField>
                            )}

                            {ShowDropDownOrderStatus && OrderId === index && (
                              <FilterSelect
                                options={OrderStatusForWarehouseManager}
                                selected={selectedOrderStatus.label}
                                onChange={this.handleChange(
                                  "selectedOrderStatus",
                                  index
                                )}
                              />
                            )}
                          </TableCell>
                          <TableCell>{order.Status}</TableCell>
                          <TableCell>{order.TrackingNumber}</TableCell>
                          <TableCell>{order.TotalPrice}</TableCell>
                        </TableRow>,
                        <TableRow>
                          {ExpandItemid === index && !ShowDropDownOrderStatus && (
                            <TableCell
                              key={index}
                              colSpan={9}
                              style={{
                                paddingLeft: "20px",
                                minWidth: "514px",
                                width: "100%"
                              }}
                            >
                              <Table aria-labelledby="tableTitle">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Actions</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Category</TableCell>
                                    {/* <TableCell>Reference Number	</TableCell> */}
                                    <TableCell>Quantity</TableCell>
                                    <TableCell align='center'>Barcode</TableCell>
                                    {/* <TableCell>Color</TableCell> */}
                                    {/* <TableCell>ICGSize</TableCell>
                                    <TableCell>OnlineSize</TableCell> */}

                                    {/* <TableCell
                                            style={{ minWidth: "275px" }}
                                          >
                                            Tracking Status
                                          </TableCell>
                                           */}

                                    <TableCell>VariantTitle</TableCell>
                                    <TableCell>Vender</TableCell>
                                    {/* <TableCell>Status</TableCell> */}
                                    <TableCell style={{ textAlign: "center" }}>
                                      Location
                                    </TableCell>
                                    <TableCell>Price</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Items.length > 0 &&
                                    Items.map((item, index) => (
                                      <TableRow key={index}>
                                        {(Role === "QhubRunner" ||
                                          Role === "WarehouseManager" ||
                                          Role === "Admin") && (
                                          <TableCell>
                                            <Grid container>
                                              <Grid item>
                                                {
                                                  <Grid item container xs={12}>
                                                    <Button
                                                      // onClick={() =>
                                                      //   this.EditItemStatus(
                                                      //     order.Id,
                                                      //     index
                                                      //   )
                                                      // }
                                                      onClick={
                                                       ()=> this.openAddDialog(index, item)
                                                      }
                                                    >
                                                      <EditIcon cursor="pointer"></EditIcon>
                                                    </Button>
                                                    {/* {Role === "QhubRunner" && (
                                                      <Button
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={
                                                          this.openAddDialog
                                                        }
                                                      >
                                                        add
                                                      </Button>
                                                    )} */}
                                                  </Grid>
                                                }

                                                {/* {enableUpdate &&
                                                  OrderItemId === index && (
                                                    <Button
                                                      color="primary"
                                                      onClick={() =>
                                                        this.handleSaveTrackingStatus(
                                                          order.Name,
                                                          item.Barcode,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <Done />
                                                    </Button>
                                                  )} */}

                                                <Dialog
                                                // PaperProps={{style: {overflowY: 'visible'}}}
                                                  open={this.state.opendialog}
                                                  onClose={
                                                    this.handleCloseDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" ADD Actual Barcode "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent  style={{ overflowY: 'visible' }}>
                                                    <Grid
                                                      container
                                                      justify="center"
                                                    >
                                                      <Grid item xs={12}>
                                                        <BarcodeReaderExtra
                                                          handChangeAcutalBarCode={
                                                            this
                                                              .handChangeAcutalBarCode
                                                          }
                                                          handlechangeAlterBarcode = {
                                                            this.handlechangeAlterBarcode
                                                          }
                                                          existRelatedBarcode4 = {this.state.existRelatedBarcode4}
                                                          actualBarcode={this.state.actualBarcode}
                                                          selectedRelatedBarcodeStatus={this.state.selectedRelatedBarcodeStatus}
                                                        />
                                                        
                                                        <br/>
                                                      </Grid>
                                                      <Grid item xs={6}>
                                                        <FilterSelect
                                                        // fordialog={true}
                                                          options={
                                                            this.state
                                                              .relatedBarcodeStatus
                                                          }
                                                          onChange={this.handleChange(
                                                            "selectedRelatedBarcodeStatus",
                                                            index
                                                          )}
                                                          selected={
                                                            this.state
                                                              .selectedRelatedBarcodeStatus.label
                                                          }
                                                        />
                                                          <br />
                                                          
                                                        <Grid item xs={12}>
                                                           Comments
                                                          <textarea
                                                            className="description-input"
                                                            rows="4"
                                                            onChange={e =>
                                                              this.handleChangeComments(e.target.value)
                                                            }
                                                            // value={this.state.commentForItemStatus || ""}
                                                          />
                                                        </Grid>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                       
                                                      
                                                      </Grid>
                                                    </Grid>

                                                    {/* <Grid ixs={12} sm={3}>
                                                        <BarcodeReader
                                                          onError={this.handleError}
                                                          onScan={this.handleScan}
                                                        />
                                                        <input
                                                          type="text"
                                                          id="actualBarcode"
                                                          placeholder="Barcode"
                                                          className="barcode-input"
                                                          onChange={e => this.handChangeBarCode('actualBarcode', e.target.value)}
                                                          value={this.state.actualBarcode}
                                                        />
                                                       </Grid> */}
                                                  </DialogContent>
                                                  <DialogActions style={{marginBottom:10}}>
                                                    <Button
                                                      onClick={() =>
                                                        this.handleUpdateRelatedBarcodeStatus(
                                                          order.Number,
                                                          this.state.currentItem.Id,
                                                          this.state
                                                            .actualBarcode,
                                                          this.state
                                                            .selectedRelatedBarcodeStatus
                                                            
                                                          
                                                        )
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Update
                                                    </Button>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseDialog
                                                      }
                                                      color="secondary"
                                                      variant="contained"
                                                    >
                                                      Close
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>



                                                <Dialog
                                                  open={this.state.openMissingDialig}
                                                  onClose={
                                                    this.handleCloseMissingDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" ADD Missing Barcode "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent>
                                                  <Grid container justify="center">
            <Grid item xs={12}>
              <img src={barcodeIcon} alt="barcode" className="barcode-icon" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BarcodeReader
                onError={this.handleError}
                onScan={this.handleScan}
              />
              <input
                type="text"
                id="barcode"
                placeholder="Missing Barcode"
                className="barcode-input"
                onChange={ (event) => this.handlechangeAlterBarcode(event.target.value)}
                // value={barcode}
              />
            </Grid>
          </Grid>
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseMissingDialog
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Add
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>


                                                      
                                                <Dialog
                                                  open={this.state.openAlterDialog}
                                                  onClose={
                                                    this.handleCloseAlterDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" ADD Alter Barcode "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent>
                                                  <Grid container justify="center">
            <Grid item xs={12}>
              <img src={barcodeIcon} alt="barcode" className="barcode-icon" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BarcodeReader
                onError={this.handleError}
                onScan={this.handleScan}
              />
              <input
                type="text"
                id="barcode"
                placeholder="Alter Barcode"
                className="barcode-input"
                onChange={ (event) => this.handlechangeAlterBarcode(event.target.value)}
                // value={barcode}
              />
            </Grid>
          </Grid>
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseAlterDialog
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Add
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>

                                                




                                                <Dialog
                                                 PaperProps={{style: {overflowY: 'visible',height:'500px'}}}
                                               
                                                  open={this.state.opencolorDialog}
                                                  onClose={
                                                    this.handleCloseColorDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" Add New Color "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent style={{ overflowY: 'visible' }}>
                                                  <Grid container justify="center">
            
            <Grid item xs={12} sm={6}>
               {/* <FilterSelect
                    options={this.state.colorOptions}
                    onChange={selected =>
                      this.handleChangeColor("COLOR", selected.label)
                    }
                  /> */}
        <Autocomplete
        multiple
        id="tags-outlined"
        options={this.state.suggestionColors}
        getOptionLabel={(option) => option}
        onChange={this.onTagsChange}
        // defaultValue={[top100Films[13]]}
        // filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Colors"
            placeholder="Color"
          />
        )}
      />
              {/* <input
                type="text"
                id="barcode"
                placeholder="Alter Barcode"
                className="barcode-input"
                onChange={ (event) => this.handlechangeAlterBarcode(event.target.value)}
                // value={barcode}
              /> */}
            </Grid>
          </Grid>
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseColorDialog
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Add
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>

                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                        )}
                                        <TableCell
                                        className='item-imagee'
                                          style={{
                                            textAlign: "-webkit-center"
                                          }}
                                        >
                                          <Grid col={2}>{item.Title}</Grid>
                                          {item.ImageName !== null && (
                                            <Grid col={2}>
                                              <img
                                                height="94px"
                                                style={{
                                                  marginLeft: "1px"
                                                }}
                                                width="121px"
                                                src={`${process.env.REACT_APP_API_ENDPOINT}`
                                                  .concat("/api/images/")
                                                  .concat(item.ImageName)}
                                              />
                                            </Grid>
                                          )}
                                        </TableCell>
                                        {/* <TableCell>{item.ReferenceNo}</TableCell> */}
                                              <TableCell>{item.Category}</TableCell>
                                        <TableCell>{item.Quantity}</TableCell>
                                        <TableCell>
                                          {/* <Grid direction="column">
                                            {item.relatedBarcode4.map(
                                              barcode => (
                                                <Grid>
                                                  {" "}
                                                  <span>
                                                    {barcode.Barcode}
                                                  </span>
                                                  :{" "}<span>{barcode.Status}</span>{" "}
                                                  {barcode.Status ==='Available_With_Different_Barcode' && (barcode.AltBarcode)}
                                                </Grid>
                                              )
                                            )}
                                          </Grid> */}
        <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Barcode</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">AltBarcode</TableCell>
            <TableCell align="right">AltColor</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {item.relatedBarcode4.map(barcode => (
            <TableRow>
              <TableCell component="th" scope="row">
                {barcode.Barcode}
              </TableCell>
              <TableCell align="right">{barcode.Status}</TableCell>
          <TableCell align="right">{
            (barcode.AltBarcode !==''&& barcode.AltBarcode !== undefined ) && barcode.AltBarcode
          }</TableCell>
          <TableCell align="right">
            {
              (barcode.AltColor !==''&& barcode.AltColor !== undefined ) && barcode.AltColor
            }
          </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
                                        </TableCell>
                                        
                                        <TableCell>
                                          {item.VariantTitle}
                                        </TableCell>
                                        <TableCell>{item.Vender}</TableCell>
                                        {/* <TableCell>
                                          {OrderItemId !== index && (
                                            <TextField
                                              id="outlined-name"
                                              margin="normal"
                                              value={item.TrackingStatus || ""}
                                              inputProps={{
                                                readOnly: true
                                              }}
                                            ></TextField>
                                          )}
                                          {ShowDropDownStatus &&
                                            OrderItemId === index &&
                                            Role === "WarehouseManager" && (
                                              <FilterSelect
                                                options={
                                                  OrderItemStatusForWarehouseManager
                                                }
                                                selected={
                                                  selectedOrderItemStatus.label
                                                }
                                                onChange={this.handleChange(
                                                  "selectedOrderItemStatus",
                                                  index
                                                )}
                                              />
                                            )}
                                          {ShowDropDownStatus &&
                                            OrderItemId === index &&
                                            Role === "QhubRunner" && (
                                              <FilterSelect
                                                options={OrderItemStatus}
                                                selected={
                                                  selectedOrderItemStatus.label
                                                }
                                                onChange={this.handleChange(
                                                  "selectedOrderItemStatus",
                                                  index
                                                )}
                                              />
                                            )}
                                          {ShowDropDownStatus &&
                                            OrderItemId === index &&
                                            Role === "Admin" && (
                                              <FilterSelect
                                                options={
                                                  OrderItemStatusForAdmin
                                                }
                                                selected={
                                                  selectedOrderItemStatus.label
                                                }
                                                onChange={this.handleChange(
                                                  "selectedOrderItemStatus",
                                                  index
                                                )}
                                              />
                                            )}
                                        </TableCell> */}
                                        {}
                                        <TableCell>
                                          {item.ShortLocations === "" ? (
                                            <div></div>
                                          ) : (
                                            <div class="container-outer">
                                              <div class="container-inner">
                                                {item.ShortLocations}
                                              </div>
                                            </div>
                                          )}
                                        </TableCell>
                                        <TableCell>{item.TotalPrice}</TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          )}
                        </TableRow>
                      ];
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 150]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        {tableLoading && <GreenLoader />}
        <br />
      </div>
    );
  }
}

export default withStyles(styles)(ViewOrders);
