import { GET, PUT, POST, DELETE, getBase64, reform } from "./Methods";


export const getLocationsTypes = () => {
    return GET('/api/Lookup/location/types');
}

export const getLocations = (locationType) => {
    return GET(`/api/Lookup/locations?locationType=${locationType}`);
}

export const getAdsBySearch = (searchQuery) => {
    const query = {
        pageIndex: 0,
        pageSize: 0,
        ...searchQuery
    };
    return GET('/api/Advertisement', query);
}

export const createNewAd = (createQuery) => {
    return POST('/api/Advertisement', createQuery);
}

export const uploadAdImage = (uploadImgQuery) => {
    return PUT('/api/advertisement/image', uploadImgQuery);
}

export const editAd = (editQuery) => {
    return PUT('/api/advertisement', editQuery);
}

export const deleteAd = (deleteQuery) => {
    return DELETE('/api/advertisement?AdvertisementId=' + deleteQuery);
}