import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Manager from '../../pages/Manager/Manager';
import DataEntry from '../../pages/DataEntry/DataEntry';
import Validation from '../../pages/Validation/Validation';
import SectionsControl from '../../pages/SectionsControl/SectionsControl';
// import Designer from '../../pages/Designer/Designer';
import Photographer from '../../pages/Photographer/Photographer';
import Restaurants from '../../pages/Restaurants/Restaurants';
import UsersControl from '../../pages/UsersControl/UsersControl';
import InventoryControl from '../../pages/InventoryControl/InventoryControl';
import SrAccountant from '../../pages/SrAccountant/SrAccountant';
import { getRole } from '../../services/share/DataService';
import Questions from '../../pages/Questions/Questions';
import Orders from '../../pages/Orders/Orders';
import FunFactory from '../../pages/Funfactory/Funfactory';
import AdminControl from '../AdminControl/AdminControl';
import Transportation from '../../pages/Transportation/Transportation';
import QhubRunnerOrders from '../../pages/QhubRunnerOrders/QhubRunnerOrders';
import WareHouseOrders from '../../pages/WareHouseManagerOrders/WareHouseManagerOrders';
import Drivers from '../Transport/Drivers/Driver.component';
import Dashboard from '../Dashboard/dashboard.component';
import Store from '../../pages/Store/Store';
import StoreSetting from '../StoreSetting/StoreSetting';
import Locations from '../../pages/Locations/Locations';
import ProductCreation from '../../pages/ProductCreation/ProductCreation'
import StoreUsers from '../../pages/StoreUsers/StoreUsers';
import GreenLoader from "../GreenLoader/GreenLoader";
import MarketingAdControl from '../../pages/MarketingAdControl';

class PrivateRoute extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    const { onLogout } = this.props;
    const role = getRole();
    if (role === 'EditProductManager') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={Manager}
          />
          <Route
            exact
            path='/Validation'
            component={Validation}
          />
          <Route
            path='/photographer'
            component={Photographer}
          />

        </Switch>
      )

    }
    if (role === 'Admin') {
      return (
        <Switch>
          <Route
            exact
            path='/StoreUsers'
            component={StoreUsers}
          />
          <Route
            exact
            path='/'
            component={Manager}
          />
          <Route
            exact
            path='/ProductCreation'
            component={ProductCreation}
          />



          <Route
            path='/photographer'
            component={Photographer}
          />

          {/* <Route
            path='/Settings'
            component={StoreSetting}
          /> */}
          <Route
            path='/AdminControl'
            component={AdminControl}
          />
          <Route
            path='/Locations'
            component={Locations}
          />

          <Route
            path='/dashboard'
            component={Dashboard}
          />
          <Route
            path='/dataentry'
            component={DataEntry}
          />
          <Route
            path='/sectionscontrol'
            component={SectionsControl}
          />
          <Route
            path='/userscontrol'
            component={UsersControl}
          />
          <Route
            path='/restaurants'
            component={Restaurants}
          />
          <Route
            path='/questions'
            component={Questions}
          />
          <Route
            path='/shopifyorders'
            component={Orders}
          />
          <Route
            path='/funfactory'
            component={FunFactory}
          />
          <Route
            path='/Validation'
            component={Validation}
          />

          <Route
            component={Manager}
          />

        </Switch>
      );
    }
    if (role === 'Photographer') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={Photographer}
          />
          <Route
            component={Photographer}
          />
        </Switch>
      );
    }
    if (role === 'StoreManager') {
      return (
        <Switch>
          {/* <Route
            path='/'
            component={Store}
          /> */}


          <Route
            path='/Settings'
            component={StoreSetting}
          />
          <Route
            path='/Store'
            component={Store}
          />
          <Route
            component={Store}
          />

        </Switch>
      );
    }
    if (role === 'QA') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={Validation}
          />
          <Route
            component={Validation}
          />
        </Switch>
      );
    }
    if (role === 'StoreSupervisor') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={Locations}
          />
          <Route
            component={Locations}
          />
        </Switch>
      );
    }
    if (role === 'SrAccountant') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={SrAccountant}
          />
          <Route
            path='/inventorycontrol'
            component={InventoryControl}
          />
          <Route
            component={SrAccountant}
          />
        </Switch>
      );
    }
    if (role === 'Accountant') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={InventoryControl}
          />
          <Route
            component={InventoryControl}
          />
        </Switch>
      );
    }
    if (role === 'QCenterManager') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={Restaurants}
          />
          <Route
            path='/funfactory'
            component={FunFactory}
          />
          {/* <Route
            path='/transportation'
            component={Transportation}
          /> */}

        </Switch>
      );
    }
    if (role === 'Order') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={Orders}
          />
        </Switch>
      );
    }
    if (role === 'QCenterTransportation') {
      return (
        <Switch>
          <Route
            exact
            path='/'
            component={Transportation}
          />
          <Route
            path='/transportation'
            component={Transportation}
          />
          <Route
            path='/Drivers'
            component={Drivers}
          />
        </Switch>
      );
    }
    if (role === 'WarehouseManager') {
      return (
        <Switch>
          <Route
            path='/'
            component={Orders}
          />

        </Switch>
      );
    }
    if (role === 'QhubRunner') {
      return (
        <Switch>
          <Route
            path='/'
            component={Orders}
          />

        </Switch>
      );
    }
    if (role === 'MarketingAdmin') {
      return (
        <Switch>
          <Route
            component={MarketingAdControl}
          />
        </Switch>
      );
    }
    onLogout();
    return null;
  }
}
export default PrivateRoute;
