
import {
  GET,
  POST,
  PUT,
  DELETE,
  reform,
} from './actions';

export const updateColor = (Id, temp) => {
  return PUT(`api/inventory/color?color_id=${Id}`,temp);
};

export const updateName = query => {
  return PUT('api/inventory/Name',query);
}

export const getSections = () => GET('api/category/sections?pageIndex=0&pageSize=0')
  .then(res => reform(res.Results, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getCategories = (section) => {
  let categories = section.Categories;
  categories = reform(categories, 'Id', 'value');
  categories = reform(categories, 'Name', 'label');
  return categories;
};

export const getSubCategories = (category) => {
  let subCategories = category.SubCategories;
  subCategories = reform(subCategories, 'Id', 'value');
  subCategories = reform(subCategories, 'Name', 'label');
  return subCategories;
};

export const getSubSubCategories = (SubCategories) => {
  let subSubCategories = SubCategories.SubSubCategories;
  subSubCategories = reform(subSubCategories, 'Id', 'value');
  subSubCategories = reform(subSubCategories, 'Name', 'label');
  return subSubCategories;
};


export const getColors = () => GET('api/lookup/Colors')
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const updateSection = (Barcode, category, subCategory, subsubCategory) => {
  const query = {
    Barcode,
    CategoryId: category ? category.value : undefined,
    SubCategoryId: subCategory ? subCategory.value : undefined,
    SubSubCategoryId: subsubCategory ? subsubCategory.value : undefined,
  };
  return PUT('api/inventory/section', query);
};

export const updateDescAndExtraComm = (Barcode, Description, ExtraComments, Tags, ArabicDescription) => {
  const query = {
    Description,
    ExtraComments,
    Tags,
    Barcode,
    ArabicDescription
  };
  return PUT('/api/inventory/description/', query);
};

export const getItemByBarcode = barcode => GET('api/inventory/itembybarcode', { barcode });

export const uploadImage = (Barcode, picture) => {
  const myRegexp = /;base64,(.*)/;
  const match = myRegexp.exec(picture.data);
  const base64 = match[1];
  const query = {
    ImageCode: base64,
    Barcode,
  };
  return POST('api/image', query);
};


export const uploadTagsImage = (Barcode, picture) => {
  const myRegexp = /;base64,(.*)/;
  const match = myRegexp.exec(picture.data);
  const base64 = match[1];
  const query = {
    ImageCode: base64,
    Barcode,
  };
  return POST('/api/image/tagimage', query);
};


export const deleteImage = (id) => {
  const query = {
  };
  return DELETE(`api/image?id=${id}`, query);
};
export const deleteTagImage = (tagImageName) => {
  return DELETE(`/api/inventory/tagImage?tagImageName=${tagImageName}`);
};

export const getImagesForImageSlider = (productId, pageIndex, pageSize) => {
  const query = {
    pageIndex: 0,
    pageSize: 0,
    itemId: productId,
  };
  return GET(`api/images/`, query);
}

export const onSendNewImage = (NewImageId) => {
  return POST(`/api/images/order?id=${NewImageId}&index=1`);
}




