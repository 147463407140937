 import React, { Component } from 'react'
 import Skeleton from '@material-ui/lab/Skeleton';

 export default class ProductImage extends Component {
     constructor(props){
         super(props);
         this.state = {
             isLoaded: false
         }
     }
     handleImageLoad = evt => {
         this.setState({
             isLoaded: true,
         })
     }
     render() {
        var image = new Image();
        image.onload = () => {
            this.setState({
                isLoaded: true
            })
        }
       
        image.src = this.props.src;
         return (
             <React.Fragment>
                 {
                     this.state.isLoaded ? (<img
                     src={this.props.src}
                     style={{height:160,width:'100%'}}
                     />) : (
                            <React.Fragment>
                            <Skeleton animation="wave" variant="rect" height='6px' style={{borderRadius:5,marginLeft:5,marginBottom:4}} width='85%' />
                            <Skeleton animation="wave" variant="rect" height='6px' style={{borderRadius:5,marginLeft:5,marginBottom:4}} width='67%' />
                            <Skeleton animation="wave" variant="rect" height='6px' style={{borderRadius:5,marginLeft:5,marginBottom:0}} width='50%' />
                            <Skeleton animation="wave" height='150px' width="100%" />
                        </React.Fragment>
                          )
                 }
             </React.Fragment>
         )
     }
 }
 