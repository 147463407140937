import {
  GET,
  PUT,
  POST,
  DELETE,
} from './actions';

export const getLocations = (date, pageNum, rowPerPage) => {
  const params = {
    pageNo: pageNum,
    pageSize: rowPerPage,
    from: date.from,
    to: date.to,
    fromOrderNo: date.fromOrderNo,
    toOrderNo: date.toOrderNo,
    orderItemStatus: date.orderItemStatus,
  }
  return GET('/api/Order/ordersByLocation',params);

}