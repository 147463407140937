import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./SearchForm.scss";
import FilterSelect from '../../../components/FilterSelect/FilterSelect';

const SearchForm = ({
  selectedFromDate,
  selectedToDate,
  onSearch,
  onChange,
  handleChangeInput,
  selectedRelatedBarcodeStatus,
  relatedBarcodeStatus,
}) => (
  <Grid container justify='center'>
  <Grid item xs={9}>
    <Grid container className='date-entry-container' justify='center'>
     
      <Grid container justify="space-evenly" spacing={4}>
        <Grid item xs={12} sm={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <KeyboardDatePicker
              margin="normal"
              id="selectedFromDate"
              label="From"
              value={selectedFromDate}
              onChange={onChange("selectedFromDate")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="selectedToDate"
              label="To"
              value={selectedToDate}
              disableFuture="true"
              onChange={onChange("selectedToDate")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <div className="break"></div>
      <div className="break"></div>
     
      <div className="break"></div>
      <div className="break"></div> 
      <hr/>
      <Grid container justify='space-evenly' spacing={4} >
      <Grid item xs={12} sm={3}>
              <span>Order Number From</span>
              <input
              required
                type="number"
                className="rawabi-input"
                // value={this.state.englishName}
                onChange={e =>
                  handleChangeInput("orderFrom", e.target.value )
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <span>Order Number To</span>
              <input
              required
                type="number"
                className="rawabi-input"
                // value={this.state.englishName}
                onChange={e =>
                  handleChangeInput("orderTo", e.target.value )
                }
              />
            </Grid>
      </Grid>
      <br/>
      <Grid container justify="space-evenly" spacing={4} style={{marginTop:10}}>
      <Grid item xs={12} sm={3} className="">
      <h6 className="headerFont">Search By Item Status</h6>
         <FilterSelect
                                                          options={
                                                          relatedBarcodeStatus
                                                          }
                                                          selected={
                                                            
                                                          selectedRelatedBarcodeStatus
                                                          }
                                                          onChange={onChange('selectedRelatedBarcodeStatus')}
                                                         
                                                        
                                                        />
         </Grid>
         <Grid item sm={3}>

         </Grid>
      </Grid>
      <Grid
        container
        justify="space-evenly"
        spacing={4}
      >
        <Grid item xs={12} sm={2}>
          <Button
          style={{marginTop:'15px'}}
            variant="contained"
            color="primary"
            onClick={onSearch}
            fullWidth
          >
            <span className="button-text">Search</span>
          </Button>
        </Grid>
     
      </Grid>
    </Grid>
  </Grid>
  </Grid>
);

export default SearchForm;
