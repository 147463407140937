import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
    passwordDialog: {
    backgroundColor: "#313639",
    color: "white",
    textAlign: "center"
  },
  alertMessage: {
    backgroundColor: '#C34635',
    padding: '3px',
    color: '#FFF',
    textAlign: 'center',
    height: '20px',
    boxSizing: 'border-box',
    borderRadius: '35px',
    paddingBottom: '30px'
  },
});

export default function DilaogForResetPass(props) {
  const classes = useStyles();

  const { handleInputChange, handleSendNewPass, handleClickOpen, handleClose, isValidUserName, setOpen } = props

  return (
    <div>
        <Grid item xs={12}>
            <Button
                type='submit'
                variant='text'
                style={{backgroundColor:'#b3b3b3'}}
                fullWidth
                onClick={handleClickOpen}
                >
                <span >Forgot Your Password ?</span>
            </Button>
        </Grid>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle 
          style={{
              backgroundColor: "#313639",
              color: "white",
              textAlign: "center",
              minWidth:'400px'
            }} 
        className={classes.passwordDialog}
        id="alert-dialog-title">{"Reset Your Password"}
        </DialogTitle>
        <DialogContent style={{minWidth:'400px'}}>
        {isValidUserName && <div className={classes.alertMessage} >Incorrect User Name</div>}
        <TextField
              autoFocus
              margin="dense"
              id="pass1"
              name='pass1'
              label="Please Type Your User Name"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
            />
    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSendNewPass} color="primary" autoFocus>
            Send New Password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}