import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SectionsCell from "../Sections";
import FilterSelect from "../../../FilterSelect/FilterSelect";
import AutoComplete from "../../../AutoComplete/AutoComplete";
import "./EditDialog.scss";
import { update } from "../../../../actions/DataEntryAPIs";
import MessagesNotifications from "../../../MessagesNotifications/MessagesNotifications";
import { getImagesForImageSlider } from "../../../../actions/PhotographerAPIs";
import ImageGallery from "react-image-gallery";
import { withStyles } from "@material-ui/styles";
import { DialogContentText } from "@material-ui/core";
import { getRole } from "../../../../services/share/DataService";
import { deleteItem } from "../../../../actions/DataEntryAPIs";
import { DELETE } from "../../../../actions/actions";

const styles = theme => ({
  deleteButton: {
    position: "absolute",
    right: "22px"
  },
  dialogStyle: {
    backgroundColor: "#313639",
    color: "white",
    textAlign: "center"
  },
  dialogText: {
    color: "black",
    fontSize: "20px",
    textAlign: "center"
  }
});

class DialogExampleSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ColorId: props.product.ColorId,
      englishName: props.product.EnglishName,
      arabicName: props.product.ArabicName,
      EnglishDescription: props.product.EnglishDescription,
      ArabicDescription: props.product.ArabicDescription,
      newColor: props.product.Color,
      newSection: props.product.SectionId,
      newCategory: props.product.CategoryId,
      newSubCategory: props.product.SubCategoryId,
      newMiniCategory: props.product.SubSubCategoryId,
      productId: props.product._id,
      tagsInputValue: props.product.Tags,
      open: false,
      notificationMsg: "",
      ImagesArray: [],
      isAdmin: false,
      openDeleteDialog: false,
      openDeleteSkusDialog: false
    };
  }

  componentDidMount() {}

  handleOpen = () => {
    getImagesForImageSlider(this.props.product._id, 0, 0).then(res => {
      let array = [];
      res.Results.map(Image => {
        array.push({
          original: `${process.env.REACT_APP_API_ENDPOINT}`
            .concat("/api/images/")
            .concat(Image.Name),
          thumbnail: `${process.env.REACT_APP_API_ENDPOINT}`
            .concat("/api/images/")
            .concat(Image.Name),
          Id: Image.Id,
          Name: Image.Name
        });
        this.setState({
          ImagesArray: array,
          open: true
        });
      });
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onTodoChange = event => {
    const { id, value } = event.target;
    if (id === "newTags") {
      this.setState({
        [id]: value.replace(/[ ,]+/g, ",")
      });
    } else {
      this.setState({
        [id]: value
      });
    }
  };

  handleSetNewSection = (type, id) => {
    switch (type) {
      case "SECTION":
        this.setState({
          newSection: id,
          newCategory: "",
          newSubCategory: "",
          newMiniCategory: ""
        });
        break;
      case "CATEGORY":
        this.setState({
          newCategory: id,
          newSubCategory: "",
          newMiniCategory: ""
        });
        break;
      case "SUBCATEGORY":
        this.setState({
          newSubCategory: id,
          newMiniCategory: ""
        });
        break;
      case "MINICATEGORY":
        this.setState({
          newMiniCategory: id
        });
        break;
      default:
        break;
    }
  };

  handleChangeColor = event => {
    this.setState({
      newColor: event.label
    });
  };
  handleClickOpen = event => {
    this.setState({
      openDeleteDialog: true,
      openDeleteSkusDialog: true
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      openDeleteSkusDialog: false
    });
  };

  setTags = tags => {
    let tmp = "";
    tmp = tags.join(",");
    this.setState({
      tagsInputValue: tmp
    });
  };

  handleEditProduct = () => {
    const {
      englishName,
      arabicName,
      EnglishDescription,
      ArabicDescription,
      newColor,
      newSection,
      newCategory,
      newSubCategory,
      newMiniCategory,
      tagsInputValue,
      productId,
      ColorId
    } = this.state;

    const newData = {
      englishName,
      arabicName,
      EnglishDescription,
      ArabicDescription,
      newColor,
      newSection,
      newCategory,
      newSubCategory,
      newMiniCategory,
      tagsInputValue
    };

    this.handleCommitEdit(productId, ColorId, newData);
  };

  handleCommitEdit = (productId, ColorId, newData) => {
    this.setState({
      notificationMsg: ""
    });
    update(productId, ColorId, newData).then(() => {
      this.setState({
        open: false,
        notificationMsg: "ProductEditedSuccessfully"
      });
    });
  };

  handleDelete = () => {
    const { handleDeleteItem, index } = this.props;
    const { productId } = this.state;
    this.setState({
      notificationMsg: "",
      openDeleteDialog: false
    });
    deleteItem(productId)
      .then(res => {
        if (res === true) {
          handleDeleteItem(index);
          this.setState({
            openDeleteDialog: false,
            open: false,
            notificationMsg: "ItemDeleted"
          });
        } else {
          this.setState({
            openDeleteDialog: false,
            open: false,
            notificationMsg: "Error While Deleting"
          });
        }
      })
      .catch(error => {});
  };

  handleDeleteSkus = () => {
    this.setState({
      notificationMsg: ""
    });
    DELETE(`/api/inventory/sku?SizeId=${this.props.product.SizeId}`)
      .then(res => {
        this.setState({
          openDeleteSkusDialog: false,
          notificationMsg: "Deleted Successfully"
        });
      })
      .catch(error => {
        this.setState({
          openDeleteSkusDialog: false,
          notificationMsg: "Error while deleting skus"
        });
      });
  };
  openDeleteSkusDialogFunc = () => {
    this.setState({
      openDeleteSkusDialog: true
    });
  };

  render() {
    const { colorOptions } = this.props;
    const { classes } = this.props;
    const {
      englishName,
      arabicName,
      EnglishDescription,
      ArabicDescription,
      newColor,
      newSection,
      newCategory,
      newSubCategory,
      newMiniCategory,
      tagsInputValue,
      notificationMsg,
      ImagesArray
    } = this.state;

    const role = getRole();
    return (
      <div>
        {notificationMsg && <MessagesNotifications message={notificationMsg} />}
        <Button
          onClick={this.handleOpen}
          variant="contained"
          style={{ width: "197.359px" }}
          color="primary"
        >
          <span className="button-text"> Edit Product </span>
        </Button>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              backgroundColor: "#313639",
              color: "white",
              textAlign: "center"
            }}
            id="responsive-dialog-title"
          >
            {"Update Product Info"}
          </DialogTitle>
          <DialogContent className="dialogContet">
            <Grid container spacing={4}>
              <Grid item xs={5} className="ImageGallaryGrid">
                <ImageGallery
                  className="ImageGallaryClass"
                  items={ImagesArray}
                  showBullets={true}
                  showThumbnails={true}
                  showNav={false}
                  showPlayButton={false}
                  showFullscreenButton={true}
                />
              </Grid>

              <Grid item xs={6}>
                <Grid container spacing={4}>
                  <Grid item xs={6} className="card-input">
                    <TextField
                      variant="outlined"
                      id="englishName"
                      label="English Name"
                      value={englishName || ""}
                      onChange={this.onTodoChange}
                    />
                  </Grid>

                  <Grid item xs={6} className="card-input">
                    <TextField
                      variant="outlined"
                      className="arabicFields"
                      id="arabicName"
                      label="Arabic name"
                      value={arabicName || " "}
                      onChange={this.onTodoChange}
                    />
                  </Grid>
                </Grid>
                <hr></hr>
                <Grid item className="card-input">
                  <div>
                    <SectionsCell
                      sectionId={newSection}
                      categoryId={newCategory}
                      subCategoryId={newSubCategory}
                      miniCategoryId={newMiniCategory}
                      setNewSection={this.handleSetNewSection}
                    />
                  </div>
                </Grid>
                <Grid item className="card-input">
                  <div className="sectionwidth" style={{ marginTop: "20px" }}>
                    Color
                    <FilterSelect
                      placeholder={newColor}
                      options={colorOptions}
                      onChange={this.handleChangeColor}
                    />
                  </div>
                </Grid>
                <hr></hr>

                <Grid item className="card-input">
                  <AutoComplete
                    setTags={this.setTags}
                    tagsInputValue={tagsInputValue}
                  />
                </Grid>
                <hr></hr>
                <Grid container spacing={2}>
                  <Grid item xs={6} className="card-input">
                    <span>English Description</span>
                    <textarea
                      className="Description-textarea"
                      value={EnglishDescription || " "}
                      id="EnglishDescription"
                      onChange={this.onTodoChange}
                      rows="3"
                    />
                  </Grid>

                  <Grid item xs={6} className="card-input">
                    <span>Arabic Description</span>
                    <textarea
                      className="Description-textarea arabicFields"
                      value={ArabicDescription || " "}
                      id="ArabicDescription"
                      onChange={this.onTodoChange}
                      rows="3"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr></hr>
          </DialogContent>
          <Grid container justify="center">
            <DialogActions>
              <Button
                onClick={this.handleEditProduct}
                variant="contained"
                color="primary"
                autoFocus
              >
                Save
              </Button>

              <Button
                onClick={this.handleClose}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
              {role === "Admin" && (
                <React.Fragment>
                  <Button
                    className={classes.deleteButton}
                    onClick={this.handleClickOpen}
                    variant="contained"
                    color="secondary"
                    autoFocus
                  >
                    Delete RF
                  </Button>
                  <Button
                    style={{ position: "absolute", right: 140 }}
                    variant="contained"
                    onClick={this.openDeleteSkusDialogFunc}
                    color="secondary"
                    autoFocus
                  >
                    Delete SKU
                  </Button>
                </React.Fragment>
              )}
            </DialogActions>
          </Grid>

          <Dialog
            open={this.state.openDeleteDialog}
            onClose={this.closeDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.deletedialog}
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.dialogStyle}
            >
              {"Delete Product "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogText}
              >
                Are You Sure About That?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeDeleteDialog}
                color="default"
                variant="outlined"
                autoFocus
              >
                Close
              </Button>
              <Button
                onClick={this.handleDelete}
                color="secondary"
                variant="outlined"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openDeleteSkusDialog}
            onClose={this.closeDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.deletedialog}
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.dialogStyle}
            >
              {"Delete Product "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogText}
              >
                Are You Sure About That?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeDeleteDialog}
                color="default"
                variant="outlined"
                autoFocus
              >
                Close
              </Button>
              <Button
                onClick={this.handleDeleteSkus}
                color="secondary"
                variant="outlined"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(DialogExampleSimple);
