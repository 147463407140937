import React from 'react';
import { Grid } from '@material-ui/core';
import PhotographerTable from '../../PhotographerTable/PhotographerTable';
import SearchForm from './SearchForm';

const RequestsTab = ({ data, brands, onChange }) => (
  <Grid container>
    <Grid item xs={12}>
      <SearchForm brands={brands} onChange={onChange} />
    </Grid>
    <PhotographerTable data={data} />
  </Grid>
);

export default RequestsTab;
