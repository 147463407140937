import React from 'react';
import ResponsiveDrawer from './drawer/drawer.component';
class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    };
    
    render(){
        return(
            <div>
               <ResponsiveDrawer/>
            </div>
        );
    }
}

export default Dashboard;