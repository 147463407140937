import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FilterSelect from "../../components/FilterSelect/FilterSelect";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import WarningIcon from '@material-ui/icons/Warning';
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import InfoBox from "../../components/InfoBox/InfoBox";
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import LanguageIcon from '@material-ui/icons/Language';
import Tooltip from '@material-ui/core/Tooltip';
import BlackLoader from "../../components/BlackLoader/BlackLoader";
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";
import {
  getStores,
  getStoresData,
  DeleteStoreUser,
  addStoreUser
} from "../../actions/80/StoreAPIs";
import "./StoreUsers.scss";

export default class StoreUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selctedStore: { label: "ALL", value: null },
      stores: [],
      users: [],
      isSelected: false,
      data: {},
      Username: "",
      Email: "",
      notificationMsg: "",
      webMessage: "",
      mobileMessage: "",
      addingUser: true,
    };
  }
  componentDidMount() {
    getStores().then(res => {
      this.setState({
        stores: res
      });
    });

    getStoresData().then(res => {
      this.setState({
        data: res,
        users: res.ALL,
      });
    }).catch( error => {
      this.setState({
        notificationMsg: "Error while getting store users",
      })
    })
  }
  handleChangeStore = type => selectedValue => {
    getStoresData().then(res => {
      this.setState({
        data: res,
        users: res[selectedValue.label.toUpperCase().trim()] ? res[selectedValue.label.toUpperCase().trim()] : []
      });
    });
    this.setState({
      selctedStore: {label: selectedValue.label, value: null},
      // users: this.state.data[selectedValue.label]
      //   ? this.state.data[selectedValue.label]
      //   : [],
      isSelected: true
    });
  };
  handleChange = (value, id) => {
    this.setState({
      [id]: value
    });
  };
  handleAddUser = () => {
    this.setState({
      webMessage: '',
      mobileMessage: '',
      addStoreUser: true,
    });
    const params = {
      Email: this.state.Email,
      StoreName: this.state.selctedStore ? this.state.selctedStore.label : ""
    };
    
    this.state.Email !== ''&&  addStoreUser(params)
      .then(res => {
        if(res.status.MiddlewareAccountStatus){
          // console.log('middleware')
          // toast.success(<div><DoneOutlineIcon/><span style={{fontSize:30,fontFamily: 'Comic Sans MS'}}>{'Add user Successfully '}</span></div>, {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          // console.log('mmmmmmmmmmm')
        this.setState({
          webMessage: 'Add user Successfully',
        })
        };
        
          if(!res.status.MobileAccountStatus){
            // console.log('!!!!!!!!!!!!!!!11mobile')
            this.setState({
              mobileMessage: "user doesn't have an account on the mobile app",
              
              
            })
          }
          if(res.status.MobileAccountStatus){
            // console.log('!!!!!!!!!!!!!!!11mobile')
            this.setState({
              mobileMessage: "user also have an account on the mobile app",
              
              
            })
          }
    

        // if(res.status.MobileAccountStatus){
        //   toast.success(<div><DoneOutlineIcon/><span style={{fontSize:30,fontFamily: 'Comic Sans MS'}}>{'User have account on mobile APP '}</span></div>, {
        //     position: toast.POSITION.TOP_RIGHT
        //   });
        // }
        //   if(){
        //    this.setState({
        //      mobileMessage: "user doesn't have an account on the mobile app",
        //    })
        // }

      
        // if(res.success === false) {
        //   this.setState({
        //     notificationMsg: "user doesn't have an account on the mobile app",
        //     Email: "",
        //   })
        // }
        //   if(res.UserName !== undefined){ 
        //   const modifiedUsers = [...this.state.users, res];
        //   const temp = this.state.data;
        //   this.state.selctedStore.label &&
        //     (temp[this.state.selctedStore.label] = modifiedUsers);
        //   this.setState({
        //     data: temp,
        //     users: modifiedUsers,
        //     notificationMsg: "operation accomplished successfully",
        //     Email: ""
        //   });
        // }
      
        getStoresData().then(res => {
          // console.log(res)
          this.setState({
            data: res,
            users: res[this.state.selctedStore.label.toUpperCase()] ? res[this.state.selctedStore.label.toUpperCase()] : [],
            Email: '',
            addingUser: false,
          });
        })
        
      })
      .catch(err => {
        this.setState({
          notificationMsg: "Error during the operation"
        });
      });
  };
  handleDelete = (email, id) => {
    this.setState({
      notificationMsg: ""
    });
    DeleteStoreUser(email)
      .then(res => {
        getStoresData().then(res => {
          // console.log(res)
          this.setState({
            data: res,
            users: res[this.state.selctedStore.label.toUpperCase().trim()] ? res[this.state.selctedStore.label.toUpperCase().trim()] : [],
            notificationMsg: "operation accomplished successfully",

          });
        })
        // const modifiedUsers = this.state.users.filter(user => user.Id !== id);
        // const modifiedData = this.state.data;
        // modifiedUsers[this.state.selctedStore.label] = modifiedUsers;
        // modifiedData[this.state.selctedStore.label] = modifiedUsers;
        // this.setState({
        //   users: modifiedUsers,
        //   data: modifiedData,
        //   notificationMsg: "operation accomplished successfully"
        // });
      })
      .catch(err => {
        this.setState({
          notificationMsg: "Error during the operation"
        });
      });
  };
  render() {
    const { stores, users, selctedStore, data, isSelected } = this.state;
    // console.log(selctedStore.label);
    // console.log(',,,,,,,,,,,,');
    return (
      <div>
        {this.state.notificationMsg !== "" && (
          <MessagesNotifications message={this.state.notificationMsg} />
        )}
          {this.state.webMessage !== "" && (
          <MessagesNotifications message={this.state.webMessage} />
        )}
          {this.state.mobileMessage !== "" && (
          <MessagesNotifications message={this.state.mobileMessage} />
        )}
        <Grid container justify="center">
          <Grid item xs={11} sm={8}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Add Store User</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container justify='center'>
                  {/* <Grid  xs={12} sm={8}>
              <span>User Name</span>
              <input
                type="text"
                className="rawabi-input"
                 value={this.state.Username || ""}
                onChange={e =>
                  this.handleChange(e.target.value, "Username")
                }
              />
            </Grid> */}
                  <Grid xs={12} sm={5}>
                    <span>Email</span>
                    <input
                      required
                      id="email"
                      type="email"
                      className="rawabi-input"
                      value={this.state.Email}
                      onChange={e => this.handleChange(e.target.value, "Email")}
                    />
                  </Grid>
                  <Grid sm={3}/>
                  <Grid xs={12} sm={5}>
                    <FilterSelect
                      options={stores}
                      onChange={this.handleChangeStore("Store")}
                      selected={selctedStore}
                      //   disabled={isDisabled}
                    />
                  </Grid>
                  <Grid sm={3}/>
                 
                  {/* <Grid xs={12} sm={8}>
                  {this.state.addingUser && <BlackLoader/>}
                  </Grid> */}
                  <Grid xs={12} sm={8}>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ margin: "0 auto", marginTop: "10px" }}
                      onClick={this.handleAddUser}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} sm={8} style={{ marginTop: "40px" }}>
            {/* <Grid item xs={3}></Grid> */}
            <Paper style={{marginBottom:'40px'}}>
              <br />
              <Grid container justify="center">
                <Grid item xs={7} sm={3} style={{ margin: "0 auto" }}>
                  <span> Stores</span>
                  <FilterSelect
                    options={stores}
                    onChange={this.handleChangeStore("Store")}
                    selected={selctedStore}
                    //   disabled={isDisabled}
                  />
                  <br />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  style={{ margin: "0 auto", overflowX: "auto" }}
                >
                  {this.state.users.length > 0 ? (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>User Name</TableCell>
                          {/* <TableCell align="right">LastName</TableCell> */}
                          <TableCell align="center">Email</TableCell>
                          <TableCell>Device Type </TableCell>
                          <TableCell align="right">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.map(user => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {user.UserName}
                            </TableCell>
                            {/* <TableCell align="right">{user.LastName}</TableCell> */}
                            <TableCell align="right">{user.Email}</TableCell>
                            <TableCell align='right'>
                              <div className='device-type-container'>
                              <span>
                              {  user.IsMobileAccount &&(
                                 <Tooltip title='Mobile' >
                                 <SmartphoneIcon/>
                                 </Tooltip>
                              )}
                              </span>
                          <span>
                          { user.IsWebAccount && (
                           <Tooltip title='Website' >
                            <LanguageIcon/>
                           </Tooltip>
                         )}
                          </span>
                              </div>
                            
                              
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              <Button
                                onClick={() =>
                                  this.handleDelete(user.Email, user.Id)
                                }
                              >
                                {" "}
                                <DeleteOutlineIcon color="secondary" />{" "}
                              </Button>{" "}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <InfoBox message="Data Not Available" />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
