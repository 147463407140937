import React, { Component } from 'react';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import BlackLoader from '../BlackLoader/BlackLoader';
import NoImage from '../../assests/images/NoImage.png';
import './TransportationData.scss';
import { updateTransportation } from '../../actions/80/TransportationAPIs';
import MessagesNotifications from '../MessagesNotifications/MessagesNotifications';


class TransportationData extends Component {
  constructor() {
    super();
    this.state = {
      ImageName: '',
      CoverPhoto: '',
      isUpdating: false,
      imageType: null,
      notificationMsg:'',
      CreatedDate: '',
      UpdatedDate: '',
      ShowCanEditCover: false,
    };
  }

  componentDidMount() {
    const { Transportation } = this.props;
    if (Transportation) {
      this.setState({
        ImageName: Transportation.TransportationImageName || '',
        CoverPhoto:
          Transportation.TransportationImage
          && `data:image/jpeg;base64,${Transportation.TransportationImage}`,
        CreatedDate: Transportation.TransportationCreatedDate,
        UpdatedDate:  Transportation.TransportationUpdateDate
      });
    }
  }

  handleImageChange = (event) => {
    const { id } = event.target;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        this.setState({
          [id]: reader.result,
          ShowCanEditCover:true,
        });
      };
      image.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleUpdate = () => {
    const { CoverPhoto } = this.state;
    const { refreshTras , Transportation} = this.props;

    this.setState({
      isUpdating: true,
      notificationMsg:'',
    });
    const toUpdate = {
      TransportationImage : CoverPhoto,
      TransportationId: Transportation.TransportationId
    };
    updateTransportation(toUpdate).then(() => {
      refreshTras();
      this.setState({
        isUpdating: false,
        notificationMsg: 'TransportationUpdated',
        ShowCanEditCover: false,
      });
    })
    .catch(() => {
      this.setState({
        notificationMsg: 'updateTransportation',
      });
    });
  };

  setImage = (image, id) => {
    this.setState({
      [id]: image,

    });
  };

  render() {
    const {
      isUpdating,
      CoverPhoto,
      notificationMsg,
      ImageName,
      CreatedDate,
      UpdatedDate,
      ShowCanEditCover
    } = this.state;
    const { Transportation } = this.props;
    return (
      <div className='page-root'>
        {notificationMsg !== '' && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Paper>
          <Grid container spacing={10} justify='center'>
            {Transportation && (
              <Grid item xs={12} className='transportation-container'>
                <img
                  className='cover-photo'
                  src={CoverPhoto || NoImage}
                  alt='cover'
                />
              </Grid>
            )}
               <Grid container justify='center'>
              <Grid item className='upload-button-wrapper' xs={2}>
                <span className='label'>
                  <AddPhotoAlternate />
                  <p>Upload Cover</p>
                </span>
                <input
                  type='file'
                  id='CoverPhoto'
                  accept='image/*'
                  className='upload-box'
                  onChange={this.handleImageChange}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={10}
              justify='center'
              className='margin-button'
            >
              <Grid item xs={12} sm={3}>
                {ShowCanEditCover && (
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={this.handleUpdate}
                  >
                    <span className='button-text'>Update Cover Image</span>
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={10} justify='center'>
              <Grid item xs={12} sm={6}>
                <span>Image Name</span>
                <input
                  type='text'
                  className='rawabi-input'
                  id='ImageName'
                  readOnly
                  value={ImageName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={10} justify='center'>
              <Grid item xs={12} sm={6}>
                <span>Created Date</span>
                <input
                  type='text'
                  className='rawabi-input'
                  id='ImageName'
                  readOnly
                  value={CreatedDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={10} justify='center'>
              <Grid item xs={12} sm={6}>
                <span>Updated Date</span>
                <input
                  type='text'
                  className='rawabi-input'
                  id='ImageName'
                  readOnly
                  value={UpdatedDate}
                />
              </Grid>
            </Grid>  
            {isUpdating && (
              <Grid container justify='center'>
                <BlackLoader />
              </Grid>
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default TransportationData;
