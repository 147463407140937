import React from 'react';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InventoryTableToolbar from './InventoryTableToolbar';
import InventoryTableHead from './InventoryTableHead';
import '../../../assests/styles/TableStyle.scss';


/*
 for sorting & ordering the products descendingly/ascendingly.
*/
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class InventoryTable extends React.Component {
  constructor() {
    super();
    this.state = {
      order: 'asc',
      orderBy: '',
    };
  }

  // to sort the table items by a spicific property either ascending or descending.
  handleRequestSort = (event, property) => {
    const currentOrderBy = property;
    let currentOrder = 'desc';
    const { orderBy, order } = this.state;

    if (orderBy === property && order === 'desc') {
      currentOrder = 'asc';
    }
    this.setState({
      order: currentOrder,
      orderBy: currentOrderBy,
    });
  };

  render() {
    const {
      order,
      orderBy,
    } = this.state;
    const {
      data,
      count,
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
    } = this.props;
    return (
      <Paper className='table-root'>
        <InventoryTableToolbar />
        <div className='table-wrapper'>
          <Table aria-labelledby='tableTitle'>
            <InventoryTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={event => this.handleSelectAllClick(event.target.checked, data)}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(product => (
                  <TableRow
                    role='checkbox'
                    tabIndex={-1}
                    key={product.Id}
                  >
                    <TableCell>{product.Status}</TableCell>
                    <TableCell>{product.BarcodeOne}</TableCell>
                    <TableCell>{product.BarcodeTwo}</TableCell>
                    <TableCell>{product.InventoryNumber} / {product.Stock}</TableCell>
                    <TableCell>{product.UserName ? product.UserName : null}</TableCell>
                    <TableCell>{product.UpdateDate ? moment(product.UpdateDate).calendar() : null}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[15, 30, 100]}
          component='div'
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default InventoryTable;
