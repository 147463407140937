import React from "react";
import { ToastContainer, toast } from "react-toastify";
import ErrorIcon from '@material-ui/icons/Error';
import { style } from "react-toastify";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import WarningIcon from '@material-ui/icons/Warning';
import "react-toastify/dist/ReactToastify.css";
const errorInfo = {
  position: "top-right",
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}
class MessagesNotifications extends React.Component {
  handleMessage = (type, message) => {
    if (type === 'error') {
      return toast.error(<div style={{ display: 'flex', justifyContent: 'center' }}><ErrorIcon fontSize='large' /> <span style={{ fontSize: 20, fontFamily: 'Comic Sans MS' }}>{message}</span> </div>, errorInfo);
    }
    else if (type === 'success') {
      return toast.success(<div style={{ display: 'flex', justifyContent: 'center' }}><DoneOutlineIcon fontSize='large' /><span style={{ fontSize: 20, fontFamily: 'Comic Sans MS' }}>{message}</span></div>, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.warn(<div style={{ display: 'flex', justifyContent: 'center' }}><WarningIcon fontSize='large' /><span style={{ fontSize: 20, fontFamily: 'Comic Sans MS' }}>{message}</span></div>, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }
  componentDidMount() {
    const { message } = this.props;

    const ERROR_MESSAGES = {
      storeUsersError: 'Error while getting store users',
      downloadInvoiceError: 'Error while downloading  Invoice',
      generlErroeMeassage: 'Error during the operation',
      setTrackingNumberError: "Error while set tracking number",
      dispatchOrderError: "Error while dispatch orders",
      updateExcludedWareHouseError: "Erorr while Updating",
      updateNameError: "Error while updating the name",
      tokenExpried: "Token expired",
      SkusError: "Error while deleting skus",
      internalServerError: "Internal server error",
      getBrands: "Error while getting the brands",
      getAllWareHouses: "Error while getting the wearhouses",
      getAllSeasons: "Error while getting the seasons",
      getSections: "Error while getting the sections",
      productsFilter: "Error while filtering the products",
      changePage: "Error while changing the page",
      sendProducts: "Error while sending the products",
      getAllFamilies: "Error while getting the families",
      getAllSubFamilies: "Error while getting subfamilies",
      getProductColor: "Error while getting the product's colors",
      search: "Error while searching",
      updateBrandColor: "Error while updating brand's color",
      update: "Error while updating",
      exportFile: "Error while exporting the file",
      importFile: "Error while emporting the file",
      getImages: "Error while getting the images",
      replaceImage: "Error while replacing the image",
      getStatuses: "Error while getting the statuses",
      getStores: "Error while getting the stores",
      getData: "Error while getting the data",
      getQuestions: "Error while getting the questions list",
      getRestaurants: "Error while getting the resturants",
      getColors: "Error while getting the colors",
      createSection: "Error while creating new section",
      createCategory: "Error while creating new category",
      createSubCategory: "Error while creating new sub-category",
      createColor: "Error while creating new color",
      updateSection: "Error while updating the section",
      updateCategory: "Error while updating the category",
      updateSubCategory: "Error while updating the sub-category",
      updateColor: "Error while updating the color",
      deleteSection: "Error while deleting the section",
      deleteCategory: "Error while deleting the category",
      deleteSubCategory: "Error while deleting the sub-category",
      deleteColor: "Error while deleting the color",
      sync: "Error while synchronizing",
      onDropFile: "Error while validating the file(s)",
      getLog: "Error while getting the log",
      getInventoryCount: "Error while getting the count",
      checkItem: "Error while checking the item",
      login: "Error while logging in",
      deleteImage: "Error while deleting the image",
      uploadImage: "Error while uploading the image(s)",
      updateDescription: "Error while updating the description",
      BarcodeReader: "Error while reading the barcode",
      createRestaurant: "Error while creating new resturant",
      updateRestaurant: "Error while updating the resurant",
      createUser: "Error while creating new user",
      updateUser: "Error while updating the user",
      createMiniCategory: "Error while creating new mini-category",
      deleteMiniCategory: "Error while deleting a mini-category",
      deleteGame: "Delete all the games first",
      getEntertainment: "Error while getting the entertainment(s)",
      editGame: "Error while Editing Game",
      addGame: "Error while Adding Game",
      deleteGameFun: "Error while Deleting Game",
      deleteEntertainment: "Error while Deleting Entertainment",
      createEntertainment: "Error while Creating Entertainment",
      updateEntertainment: "Error while Updating Entertainment",
      updateTransportation: "Error while Updating Transportation",
      sendingImage: "Error while Send Profile Image",
      GetItemLocations: "Error While Get Items",
      OrderItemStatusNotUpdated: "Error While Update Status",
      OrderItemStatusNotUpdated: "Error While Update Order Item Status",
      OrderStatusNotUpdated: "Error Whle Update Order Status",
      itemNotDeleted: "Error While Deleting",
      passwordError: "Error while changing password",
      addTripError: "Error while adding Trip",
      editTripError: "Error while editing trip",
      removeTripError: "Error while removing trip",
      addDriverError: "Error while adding new driver",
      SyncingError: "Error while Syncing",
      getAdsSearch: "Error while getting the advertisement search results",
      getAllAds: "Error while getting all advertisement",
      getLocationsTypes: "Error while getting the advertisement locations types",
      createNewAdErr: "Error while creating new advertisement",
      createNewAdDuplicatedErr: "Advertisement is duplicated!",
      getLocations: "Error while getting the advertisement locations",
      EditAdInfoErr: "Error while editing advertisement info.",
      EditAdImgErr: "Error while editing advertisement image",
      DeleteAdErr: "Error while deleting advertisement"
    };

    const Success_Message = {
      userHaveMobile: 'user also have an account on the mobile app',
      addStoreUser: 'Add user Successfully',
      sendEmail: 'An email will be sent to you when the process finish',
      InvoiceMessage: "Please, select at least one order has tracking number",
      addUser: "user doesn't have an account on the mobile app",
      StoreDeLETED: 'Store Deleted',
      StorUpdated: 'Store Updated',
      activeOperation: "operation accomplished successfully",
      setTrackingNumber: "Set Successfully",
      dispatchOrderSucessfully: "Dispatch order Successfully",
      exportSizesSuccessfully: "SizesExportedSuccessfully",
      sendEmailSuccessfully: "Sent translation to your email successfully",
      SyncAllSuccessfully: "Synced All Successfully",
      UpdateNameSuccessfully: "Update Name Successfully",
      addDriverSuccessfully: "Add driver successfully",
      AddTripSuccessfully: "Add Trip Successfully",
      editTripSuccessfully: "Edit trip successfully",
      removeTripSuccessfully: "Remove Trip Successfully",
      SendSuccessfully: "Product Send Successfully",
      createUserSuccess: "User Created Successfully",
      updateUserSuccssfully: "User Updated Successfully",
      updateColorSuccessfully: "Color Updated Successfully",
      updateSectionSuccessfully: "Section Updated Successfully",
      updateDescriptionSuccessfully: "Item Updated Successfully",
      TageImageUploadSuccessfully: "Tage Image Uploaded Successfully",
      ImageUploadSuccessfully: "Image Uploaded Successfully",
      ProductEditedSuccessfully: "Product Edited Successfully ",
      BulkUpdatedSuccessfully: "Bulk Updated Successfully",
      ProductExportedSuccessfully: "Products Exported Successfully",
      ImportedSuccessfully: "Imported Successfully",
      ProfileChoosedSuccessfully: "Profile Image Updated Successfully",
      SyncedSuccessfully: "Product Synced Successfully",
      CreateSectionAdded: "Section Created Successfully",
      CreateCategoryAdded: "Category Created Successfully",
      SubCategoryAdded: "SubCategory Created Successfully",
      MINICategoryAdded: "MINICategory Created Successfully",
      ColorCreated: "Color Created Successfully",
      CategoryUpdated: "Category Updated Successfully",
      SubCategoryUpdated: "SubCategory Updated Successfully",
      MINICategoryUpdated: "MINICategory Updated Successfully",
      ColorUpdated: "Color Updated Successfully",
      SkusDeleted: "Deleted Successfully",
      SectionDeleted: "Section Deleted Successfully",
      CategoryDeleted: "Category Deleted Successfully",
      SubCategoryDeleted: "SubCategory Deleted Successfully",
      MINICategoryDeleted: " MINICategory Deleted Successfully",
      restaurantCreated: "Restaurant Created Successfully",
      RestaurantUpdated: "Restaurant Updated Successfully",
      ImageUploaded: "Image Uploaded Successfully",
      MenuImageDeleted: "Menu Image Deleted Successfully",
      ResturantDeleted: "Resturant Deleted Successfully",
      EntertainmentCreated: " Entertainment Created Successfully",
      EntertainmentUpdated: "Entertainment Updateded Successfully",
      GameDeleted: "Game Deleted Successfully",
      GameUpdated: "Game Updated Successfully",
      DeletedEntertainment: "Entertainment Deleted Successfully",
      TransportationUpdated: "Transportation Updated Successfully",
      OrderItemStatusUpdated: "Order Item Status Updated Successfully",
      OrderStatusUpdated: "Order Status Updated Successfully",
      ItemUpdated: "Item Status Updated",
      OrderUpdated: "Order Status Updated",
      OrderExported: "Order Exported Successfully",
      ItemVerified: "Item Verified Successfully",
      passwordChanged: "Changed Successfully",
      ItemDeleted: "Item Deleted Successfully",
      itemDownload: "Downloaded Successfully",
      EmailPassRecieve: "Password Send Successfully",
      UpdateExcludedWarehouse: "Excluded Warehouse Upadated successfully",
      createNewAdSuccess: "Advertisement created successfully",
      EditAdInfoSuccess: "Advertisement info. updated successfully",
      EditAdImgSuccess: "Advertisement image updated successfully",
      DeleteAdSuccess: "Advertisement deleted successfully"
    };

    switch (message) {
      // general errors
      case "tokenExpried":
        this.handleMessage('error', ERROR_MESSAGES.tokenExpried)
        break;
      case "Error while getting store users":
        this.handleMessage('error', ERROR_MESSAGES.storeUsersError)
        break;
      case "Error while downloading  Invoice":
        this.handleMessage('error', ERROR_MESSAGES.downloadInvoiceError)
        break;
      case "Error during the operation":
        this.handleMessage('error', ERROR_MESSAGES.generlErroeMeassage)
        break;
      case "Error while set tracking number":
        this.handleMessage('error', ERROR_MESSAGES.setTrackingNumberError)
        break;
      case "Error while dispatch orders":
        this.handleMessage('error', ERROR_MESSAGES.dispatchOrderError)
        break;
      case "Erorr while Updating":
        this.handleMessage('error', ERROR_MESSAGES.updateExcludedWareHouseError)
        break;
      case "Error while updating the name":
        this.handleMessage('error', ERROR_MESSAGES.updateNameError)
        break;
      case "Error while deleting skus":
        this.handleMessage('error', ERROR_MESSAGES.SkusError)
        break;
      case "Error while changing password":
        this.handleMessage('error', ERROR_MESSAGES.passwordError)
        break;
      case "Error while adding new driver":
        this.handleMessage('error', ERROR_MESSAGES.addDriverError)
        break;
      case "internalServerError":
        this.handleMessage('error', ERROR_MESSAGES.internalServerError)
        break;
      // specific errors
      case "getBrands":
        this.handleMessage('error', ERROR_MESSAGES.getBrands)
        break;
      case "getAllWareHouses":
        this.handleMessage('error', ERROR_MESSAGES.getAllWareHouses)
        break;
      case "getAllSeasons":
        this.handleMessage('error', ERROR_MESSAGES.getAllSeasons)
        break;
      case "Error while adding Trip":
        this.handleMessage('error', ERROR_MESSAGES.addTripError)
        break;
      case "getSections":
        this.handleMessage('error', ERROR_MESSAGES.getSections)
        break;
      case "productsFilter":
        this.handleMessage('error', ERROR_MESSAGES.productsFilter)
        break;
      case "changePage":
        this.handleMessage('error', ERROR_MESSAGES.changePage)
        break;
      case "sendProducts":
        this.handleMessage('error', ERROR_MESSAGES.sendProducts)
        break;
      case "Error while removing trip":
        this.handleMessage('error', ERROR_MESSAGES.removeTripError)
        break;
      case "getAllFamilies":
        this.handleMessage('error', ERROR_MESSAGES.getAllFamilies)
        break;
      case "getAllSubFamilies":
        this.handleMessage('error', ERROR_MESSAGES.getAllSubFamilies)
        break;
      case "getProductColor":
        this.handleMessage('error', ERROR_MESSAGES.getProductColor)
        break;
      case "search":
        this.handleMessage('error', ERROR_MESSAGES.search)
        break;
      case "updateBrandColor":
        this.handleMessage('error', ERROR_MESSAGES.updateBrandColor)
        break;
      case "update":
        this.handleMessage('error', ERROR_MESSAGES.update)
        break;
      case "exportFile":
        this.handleMessage('error', ERROR_MESSAGES.exportFile)
        break;
      case "importFile":
        this.handleMessage('error', ERROR_MESSAGES.importFile)
        break;
      case "getImages":
        this.handleMessage('error', ERROR_MESSAGES.getImages)
        break;
      case "replaceImage":
        this.handleMessage('error', ERROR_MESSAGES.replaceImage)
        break;
      case "getStatuses":
        this.handleMessage('error', ERROR_MESSAGES.getStatuses)
        break;
      case "getStores":
        this.handleMessage('error', ERROR_MESSAGES.getStores)
        break;
      case "getData":
        this.handleMessage('error', ERROR_MESSAGES.getData)
        break;
      case "getQuestions":
        this.handleMessage('error', ERROR_MESSAGES.getQuestions)
        break;
      case "getRestaurants":
        this.handleMessage('error', ERROR_MESSAGES.getRestaurants)
        break;
      case "getColors":
        this.handleMessage('error', ERROR_MESSAGES.getColors)
        break;
      case "createSection":
        this.handleMessage('error', ERROR_MESSAGES.createSection)
        break;
      case "createCategory":
        this.handleMessage('error', ERROR_MESSAGES.createCategory)

        break;
      case "createSubCategory":
        this.handleMessage('error', ERROR_MESSAGES.createSubCategory)

        break;
      case "createColor":
        this.handleMessage('error', ERROR_MESSAGES.createColor)

        break;
      case "updateSection":
        this.handleMessage('error', ERROR_MESSAGES.updateSection)

        break;
      case "updateCategory":
        this.handleMessage('error', ERROR_MESSAGES.updateCategory)

        break;
      case "updateSubCategory":
        this.handleMessage('error', ERROR_MESSAGES.updateSubCategory)

        break;
      case "updateColor":
        this.handleMessage('error', ERROR_MESSAGES.updateColor)

        break;
      case "Error while Syncing":
        this.handleMessage('error', ERROR_MESSAGES.SyncingError)

        break;
      case "deleteSection":
        this.handleMessage('error', ERROR_MESSAGES.deleteSection)

        break;
      case "deleteCategory":
        this.handleMessage('error', ERROR_MESSAGES.deleteCategory)

        break;
      case "deleteSubCategory":
        this.handleMessage('error', ERROR_MESSAGES.deleteSubCategory)
        break;
      case "deleteColor":
        this.handleMessage('error', ERROR_MESSAGES.deleteColor)

        break;
      case "sync":
        this.handleMessage('error', ERROR_MESSAGES.sync)

        break;
      case "onDropFile":
        this.handleMessage('error', ERROR_MESSAGES.onDropFile)

        break;
      case "getLog":
        this.handleMessage('error', ERROR_MESSAGES.getLog)

        break;
      case "getInventoryCount":
        this.handleMessage('error', ERROR_MESSAGES.getInventoryCount)

        break;
      case "checkItem":
        this.handleMessage('error', ERROR_MESSAGES.checkItem)

        break;
      case "login":
        this.handleMessage('error', ERROR_MESSAGES.login)

        break;
      case "deleteImage":
        this.handleMessage('error', ERROR_MESSAGES.deleteImage)

        break;
      case "uploadImage":
        this.handleMessage('error', ERROR_MESSAGES.uploadImage)

        break;
      case "updateDescription":
        this.handleMessage('error', ERROR_MESSAGES.updateDescription)

        break;
      case "BarcodeReader":
        this.handleMessage('error', ERROR_MESSAGES.BarcodeReader)

        break;
      case "createRestaurant":
        this.handleMessage('error', ERROR_MESSAGES.createRestaurant)

        break;
      case "Error while editing trip":
        this.handleMessage('error', ERROR_MESSAGES.editTripError)

        break;
      case "updateRestaurant":
        this.handleMessage('error', ERROR_MESSAGES.updateRestaurant)
        break;
      case "createUser":
        this.handleMessage('error', ERROR_MESSAGES.createUser)

        break;
      case "updateUser":
        this.handleMessage('error', ERROR_MESSAGES.updateUser)

        break;
      case "createMiniCategory":
        this.handleMessage('error', ERROR_MESSAGES.createMiniCategory)

        break;
      case "Error While Deleting":
        this.handleMessage('error', ERROR_MESSAGES.itemNotDeleted)

        break;
      case "deleteMiniCategory":
        this.handleMessage('error', ERROR_MESSAGES.deleteMiniCategory)

        break;
      case "deleteGame":
        this.handleMessage('error', ERROR_MESSAGES.deleteGame)
        break;
      case "getEntertainment":
        this.handleMessage('error', ERROR_MESSAGES.getEntertainment)
        break;
      case "OrderStatusNotUpdated":
        this.handleMessage('error', ERROR_MESSAGES.OrderStatusNotUpdated)
        break;
      case "OrderItemStatusNotUpdated":
        this.handleMessage('error', ERROR_MESSAGES.OrderItemStatusNotUpdated)
        break;
      case "GetItemLocations":
        this.handleMessage('error', ERROR_MESSAGES.GetItemLocations)
        break;
      case "getAdsSearch":
        this.handleMessage('error', ERROR_MESSAGES.getAdsSearch)
        break;
      case "getAllAds":
        this.handleMessage('error', ERROR_MESSAGES.getAllAds)
        break;
      case "getLocationsTypes":
        this.handleMessage('error', ERROR_MESSAGES.getLocationsTypes)
        break;
      case "createNewAdErr":
        this.handleMessage('error', ERROR_MESSAGES.createNewAdErr)
        break;
      case "createNewAdDuplicatedErr":
        this.handleMessage('error', ERROR_MESSAGES.createNewAdDuplicatedErr)
        break;
      case "getLocations":
        this.handleMessage('error', ERROR_MESSAGES.getLocations)
        break;
      case "EditAdInfoNewAdErr":
        this.handleMessage('error', ERROR_MESSAGES.EditAdInfoNewAdErr)
        break;
      case "EditAdImgErr":
        this.handleMessage('error', ERROR_MESSAGES.EditAdImgErr)
        break;
      case "DeleteAdErr":
        this.handleMessage('error', ERROR_MESSAGES.DeleteAdErr)
        break;


      case "Store Updated":
        this.handleMessage('success', Success_Message.StorUpdated)
        break;
      case "SendSuccessfully":
        this.handleMessage('success', Success_Message.SendSuccessfully)

        break;
      case "Set Successfully":
        this.handleMessage('success', Success_Message.setTrackingNumber)

        break;
      case "user doesn't have an account on the mobile app":
        this.handleMessage('warning', Success_Message.addStoreUser)
        break;
      case "Add user Successfully":
        this.handleMessage('success', Success_Message.addStoreUser)

        break;
      case "updateUserSuccssfully":
        this.handleMessage('success', Success_Message.updateUserSuccssfully)

        break;
      case "Color Updated Successfully":
        this.handleMessage('success', Success_Message.updateColorSuccessfully)

        break;
      case "createUserSuccess":
        this.handleMessage('success', Success_Message.createUserSuccess)

        break;
      case "updateDescriptionSuccessfully":
        this.handleMessage('success', Success_Message.updateDescriptionSuccessfully)

        break;
      case "TageImageUploadSuccessfully":
        this.handleMessage('success', Success_Message.TageImageUploadSuccessfully)

        break;
      case "Add driver successfully":
        this.handleMessage('success', Success_Message.addDriverSuccessfully)

        break;
      case 'An email will be sent to you when the process finish':
        this.handleMessage('success', Success_Message.sendEmail)

        break;
      case 'Store Deleted':
        this.handleMessage('success', Success_Message.StoreDeLETED)

        break;
      case "Changed Successfully":
        this.handleMessage('success', Success_Message.passwordChanged)

        break;
      case "operation accomplished successfully":
        this.handleMessage('success', Success_Message.activeOperation)

        break;
      case "Remove Trip Successfully":
        this.handleMessage('success', Success_Message.removeTripSuccessfully)

        break;
      case "Edit trip successfully":
        this.handleMessage('success', Success_Message.editTripSuccessfully)

        break;
      case "ImageUploadSuccessfully":
        this.handleMessage('success', Success_Message.ImageUploadSuccessfully)

        break;
      case "Update Name Successfully":
        this.handleMessage('success', Success_Message.UpdateNameSuccessfully)

        break;
      case "Sent translation to your email successfully":
        this.handleMessage('success', Success_Message.sendEmailSuccessfully)

        break;
      case 'user also have an account on the mobile app':
        this.handleMessage('success', Success_Message.userHaveMobile)

        break;

      case "ProductEditedSuccessfully":
        this.handleMessage('success', Success_Message.ProductEditedSuccessfully)

        break;
      case "BulkUpdatedSuccessfully":
        this.handleMessage('success', Success_Message.BulkUpdatedSuccessfully)

        break;
      case "Add Trip Successfully":
        this.handleMessage('success', Success_Message.AddTripSuccessfully)

        break;
      case "ProductExportedSuccessfully":
        this.handleMessage('success', Success_Message.ProductExportedSuccessfully)

        break;
      case "ImportedSuccessfully":
        this.handleMessage('success', Success_Message.ImportedSuccessfully)

        break;
      case "ProfileChoosedSuccessfully":
        this.handleMessage('success', Success_Message.ProfileChoosedSuccessfully)

        break;
      case "Please, select at least one order has tracking number":
        this.handleMessage('success', Success_Message.InvoiceMessage)

        break;
      case "Synced All Successfully":
        this.handleMessage('success', Success_Message.SyncAllSuccessfully)

        break;
      case "Dispatch order Successfully":
        this.handleMessage('success', Success_Message.dispatchOrderSucessfully)

        break;
      case "SyncedSuccessfully":
        this.handleMessage('success', Success_Message.SyncedSuccessfully)

        break;
      case "CreateSectionAdded":
        this.handleMessage('success', Success_Message.CreateSectionAdded)

        break;
      case "CreateCategoryAdded":
        this.handleMessage('success', Success_Message.CreateCategoryAdded)
        break;
      case "SubCategoryAdded":
        this.handleMessage('success', Success_Message.SubCategoryAdded)
        break;
      case "MINICategoryAdded":
        this.handleMessage('success', Success_Message.MINICategoryAdded)

        break;
      case "ColorCreated":
        this.handleMessage('success', Success_Message.ColorCreated)

        break;
      case "Section Updated Successfully":
        this.handleMessage('success', Success_Message.updateSectionSuccessfully)

        break;
      case "Deleted Successfully":
        this.handleMessage('success', Success_Message.SkusDeleted)

        break;
      case "CategoryUpdated":
        this.handleMessage('success', Success_Message.CategoryUpdated)


        break;
      case "SubCategoryUpdated":
        this.handleMessage('success', Success_Message.SubCategoryUpdated)


        break;
      case "MINICategoryUpdated":
        this.handleMessage('success', Success_Message.MINICategoryUpdated)

        break;
      case "SizesExportedSuccessfully":
        this.handleMessage('success', Success_Message.exportSizesSuccessfully)

        break;
      case "SectionDeleted":
        this.handleMessage('success', Success_Message.SectionDeleted)


        break;
      case "CategoryDeleted":
        this.handleMessage('success', Success_Message.CategoryDeleted)


        break;
      case "SubCategoryDeleted":
        this.handleMessage('success', Success_Message.SubCategoryDeleted)


        break;
      case "MINICategoryDeleted":
        this.handleMessage('success', Success_Message.MINICategoryDeleted)

        break;
      case "Restaurant Created Successfully":
        this.handleMessage('success', Success_Message.restaurantCreated)

        break;
      case "Excluded Warehouse Upadated successfully":
        this.handleMessage('success', Success_Message.UpdateExcludedWarehouse)

        break;
      case "RestaurantUpdated":
        this.handleMessage('success', Success_Message.RestaurantUpdated)

        break;
      case "ImageUploaded":
        this.handleMessage('success', Success_Message.ImageUploaded)

        break;
      case "MenuImageDeleted":
        this.handleMessage('success',)
        toast.success(Success_Message.MenuImageDeleted, {
          position: toast.POSITION.TOP_RIGHT
        });
        break;
      case "ResturantDeleted":
        this.handleMessage('success', Success_Message.ResturantDeleted)

        break;
      case "EntertainmentCreated":
        this.handleMessage('success', Success_Message.EntertainmentCreated)

        break;
      case "EntertainmentUpdated":
        this.handleMessage('success', Success_Message.EntertainmentUpdated)

        break;
      case "GameDeleted":
        this.handleMessage('success', Success_Message.GameDeleted)

        break;
      case "GameUpdated":
        this.handleMessage('success', Success_Message.GameUpdated)

        break;
      case "DeletedEntertainment":
        this.handleMessage('success', Success_Message.DeletedEntertainment)

        break;
      case "TransportationUpdated":
        this.handleMessage('success', Success_Message.TransportationUpdated)

        break;
      case "OrderItemStatusUpdated":
        this.handleMessage('success', Success_Message.OrderItemStatusUpdated)

        break;
      case "OrderStatusUpdated":
        this.handleMessage('success', Success_Message.OrderStatusUpdated)

        break;
      case "ItemUpdated":
        this.handleMessage('success', Success_Message.ItemUpdated)

        break;
      case "OrderUpdated":
        this.handleMessage('success', Success_Message.OrderUpdated)

        break;
      case "OrderExported":
        this.handleMessage('success', Success_Message.OrderExported)

        break;
      case "ItemDeleted":
        this.handleMessage('success', Success_Message.ItemDeleted)

        break;
      case "itemDownload":
        this.handleMessage('success', Success_Message.itemDownload)
        break;
      case "EmailPassRecieve":
        this.handleMessage('success', Success_Message.EmailPassRecieve);
        break;
      case "createNewAdSuccess":
        this.handleMessage('success', Success_Message.createNewAdSuccess)
        break;
      case "EditAdInfoSuccess":
        this.handleMessage('success', Success_Message.EditAdInfoSuccess)
        break;
      case "EditAdImgSuccess":
        this.handleMessage('success', Success_Message.EditAdImgSuccess)
        break;
      case "DeleteAdSuccess":
        this.handleMessage('success', Success_Message.DeleteAdSuccess)
        break;
    }
  }

  render() {
    return (
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

    );
  }
}

export default MessagesNotifications;
