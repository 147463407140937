import {
  GET,
  PUT,
  POST,
  reform,
  duplicate,
  DATA_ENTRY_DOWNLOAD,
  DELETE,
  getShopifyItemDescriptionDownload
} from './actions';

export const search = (query, page, pageSize) => {
  const displayStart = page * pageSize;
  const params = {
    barcode: query.barCode,
    reference_number: query.referenceNumber,
    filtration_type: query.filtrationType,
    brand: query.brand === 'ALL' ? undefined : query.brand,
    hasImages: query.hasImages,
    ecommerceOnly: query.inEccommerce,
    hasDescription: query.hasDescription,
    from: query.from,
    to: query.to,
    section: query.section,
    category_id: query.category_id,
    sub_category_id: query.sub_category_id,
    pageIndex: displayStart,
    pageSize,
    Size: query.size,
    discountFrom: query.discountFrom,
    discountTo: query.discountTo,
  };
  return GET('api/inventory/search', params);
};

export const validationSearch = (query, page, pageSize) => {
  const displayStart = page * pageSize;
  const params = {
    barcode: query.barCode,
    reference_number: query.referenceNumber,
    filtration_type: query.filtrationType,
    brand: query.brand === 'ALL' ? undefined : query.brand,
    hasImages: query.hasImages,
    ecommerceOnly: query.inEccommerce,
    hasDescription: query.hasDescription,
    from: query.from,
    to: query.to,
    section: query.section,
    category_id: query.category_id,
    sub_category_id: query.sub_category_id,
    pageIndex: displayStart,
    pageSize,
    Size: query.size,
    discountFrom: query.discountFrom,
    discountTo: query.discountTo,
    userRole: query.userRole,
    isVerified: query.isVerified,
    stockType: query.stockType,
    OnlinePriceFrom: query.OnlinePriceFrom,
    OnlinePriceTo: query.OnlinePriceTo,

  };
  return GET('/api/inventory/searchByRF', params);
};

export const updateDataEntryBulk = (query) => {

  const params = {
    barcode: query.barCode,
    reference_number: query.referenceNumber,
    filtration_type: query.filtrationType,
    brand: query.brand === 'ALL' ? null : query.brand,
    hasImages: query.hasImages,
    ecommerceOnly: query.inEccommerce,
    hasDescription: query.hasDescription,
    from: query.from,
    to: query.to,
    section: query.section,
    category_id: query.category_id,
    sub_category_id: query.sub_category_id,
    Size: query.size,
    discountFrom: query.discountFrom,
    discountTo: query.discountTo,
    tags: query.tags,
    stockType: query.stockType,
    OnlinePriceFrom: query.OnlinePriceFrom,
    OnlinePriceTo: query.OnlinePriceTo,

  };
  return GET('/api/inventory/bulkTag', params);
};

export const updateDataEntryBulkVerification = (query) => {

  const params = {
    barcode: query.barCode,
    reference_number: query.referenceNumber,
    filtration_type: query.filtrationType,
    brand: query.brand === 'ALL' ? null : query.brand,
    hasImages: query.hasImages,
    ecommerceOnly: query.inEccommerce,
    hasDescription: query.hasDescription,
    from: query.from,
    to: query.to,
    section: query.section,
    category_id: query.category_id,
    sub_category_id: query.sub_category_id,
    Size: query.size,
    discountFrom: query.discountFrom,
    discountTo: query.discountTo,
    isVerified: query.isVerified,
    stockType: query.stockType,
    OnlinePriceFrom: query.OnlinePriceFrom,
    OnlinePriceTo: query.OnlinePriceTo,
  };
  return GET('/api/inventory/bulkVerification', params);
};

export const getItem = id => GET('api/inventory/itembyid', { id });


export const update = (ItemId, ColorId, newData) => {
  
  const query = {
    ItemId,
    ColorId,
    EnglishName: newData.englishName,
    ArabichName: newData.arabicName,
    EnglishDescription: newData.EnglishDescription,
    ArabicDescription: newData.ArabicDescription,
    Color: newData.newColor,
    CategoryId: newData.newCategory,
    SubCategoryId: newData.newSubCategory,
    SubSubCategoryId: newData.newMiniCategory,
    Tags: newData.tagsInputValue,
    BrandId: newData.BrandId,
  };
  return PUT(`api/inventory/item?item_id=${ItemId}`, query);
};

export const updateBulk = (barCode, referenceNumber, Brand, product) => {
  const query = {
    ReferenceNumber: referenceNumber,
    Barcode: barCode,
    Name: product.newEnName,
    ArabicName: product.newArName,
    Description: product.newEnDescription,
    ArabicDescription: product.newArDescription,
    Brand,
  };
  return PUT('api/inventory/batchupdate', query);
};

export const getBrands = () => GET('api/inventory/brands', {})
  .then(res => reform(res, 'Name', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'ALL', value: 'ALL' , Id: null});
    return res;
  });

export const getSectionsList = () => GET('/api/lookup/ECSections', {})
  .then(res => reform(res, 'Name', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'ALL Sections', value: 'ALL' });
    return res;
  });

export const getCategoriesList = () => GET('/api/lookup/ECCategories', {})
  .then(res => reform(res, 'Name', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'ALL Categories', value: 'ALL' });
    return res;
  });

export const getSubCategoriesList = () => GET('/api/lookup/ECSubCategories', {})
  .then(res => reform(res, 'Name', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'ALL Sub Categories', value: 'ALL' });
    return res;
  });
  
  export const getSizesList = modal => GET(`/api/lookup/Sizes?brand=${modal.brand}&section=${modal.section}&category_id=${modal.category_id}&sub_category_id=${modal.sub_category_id}`)
  .then(res => reform(res, 'Size', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'All Sizes', value: 'ALL' });
    return res;
  });

  export const getRolesForValidationTab = modal => GET('/api/lookup/Roles')
  .then(res => reform(res, 'Role', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'All Roles', value: 'ALL' });
    return res;
  });

  export const getDiscountList = modal => GET(`/api/lookup/ProductDiscount`)
  .then(res => reform(res, 'Discount', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'All Discounts', value: 'ALL' });
    return res;
  });

export const updateBrandColor = (Brand, OldColor, NewColor) => {
  const query = {
    Brand,
    NewColor,
    OldColor,
  };
  return PUT('api/inventory/batchupdate', query);
};

export const sync = id => GET('api/inventory/syncbyid', { id });

export const exportFile = (query) => {
  const fileURL = 'api/inventory/exporttoexcel';
  const fileType = 'xlsx';
  const fileName = 'Data entry';
  const params = {
    barcode: query.barCode,
    reference_number: query.referenceNumber,
    filtration_type: query.filtrationType,
    brand: query.brand === 'ALL' ? undefined : query.brand,
    hasImages: query.hasImages,
    hasDescription: query.hasDescription,
    from: query.from,
    to: query.to,
    ecommerceOnly: query.inEccommerce,
    section: query.section,
    category_id: query.category_id,
    sub_category_id: query.sub_category_id,
    Size: query.size,
    discountFrom: query.discountFrom,
    discountTo: query.discountTo,
    stockType: query.stockType,
    OnlinePriceFrom: query.OnlinePriceFrom,
    OnlinePriceTo: query.OnlinePriceTo,

  };

  return DATA_ENTRY_DOWNLOAD(fileURL, fileName, fileType, params);
};

export const importFile = (file) => {
  const result = POST('/api/inventory/importexcel/', file);
  return result;
};

export const getTagAutoComplete = string => GET(`/api/inventory/autocomplete?qryStr=${string}`);
export const generateBarcode = () => GET('/api/inventory/barcodeGenerator');
export const generateRefrenceNumber = () => GET('/api/inventory/referenceGenerator');

export const getTagAutoCompleteForAdminControl = string => GET(`/api/Admin/autocomplete/allwarehouses?qryStr=${string}`);

export const updateExcludedList = string=> {
 return PUT(`api/Admin/autocomplete/excludedwarehouses?excludedValues=${string}`);
}

export const getExcludedwarehouses = () => GET(`/api/Admin/autocomplete/excludedwarehouses`);

export const mapSizes = (icgSize, onlineSize) => {
  return PUT(`api/Admin/sizeMapper?icgSize=${icgSize}&onlineSize=${onlineSize}`);
}

export const deleteItem = itemId => {
 return DELETE(`api/inventory/item?itemId=${itemId}`);
}
export const deleteSKu = Id => {
  return DELETE(`/api/inventory/sku?SizeId=${Id}`);
 }

export const getShopifyItemDescription = () => GET('/api/inventory/ShopifyItemDescription');
export const setpricesForItem = (params) => GET(`/api/inventory/Prices?itemid=${params.itemid}&sellingPriceOnline=${params.sellingPriceOnline}`);
export const setOriginalPrice = (params) => GET(`/api/inventory/Prices?itemid=${params.itemid}&sellingPrice	=${params.sellingPrice}`);
export const setQuantityForItem = (param, id) => PUT(`/api/inventory/editbarcode?itemId=${id}`,param);
export const syncAll = modal =>  POST('api/inventory/syncItembyid', modal);

export const addExternalProduction = modal => POST('/api/inventory/mwitem',modal);

export const dailyProductSync = () => POST('/api/ShopifyController/syncOldProductOnDemandArabic');

export const weeklyProductSync = () => POST('/api/ShopifyController/syncMonday');
export const TheNewProductSync = () => POST('/api/ShopifyController/syncNewProductsMonday');
export const addNewBarcodeToDatabase = (modal, id) => POST(`/api/inventory/addbarcode?itemId=${id}`, modal);


export const HideProduct = ( id, status ) => GET(`/api/inventory/hideOnlineProduct?productId=${id}&HideStatus=${status}`);
export const ActivateProduct = ( id, status ) => PUT(`/api/inventory/itemActivation?item_id=${id}&active=${status}`);
