import React, { Component } from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FilterSelect from '../../FilterSelect/FilterSelect';
import MessagesNotifications from '../../MessagesNotifications/MessagesNotifications';
import { createUser } from '../../../actions/UserAPIs';
import './CreateUser.scss';

class CreateUser extends Component {
  constructor() {
    super();
    this.state = {
      newUserName: '',
      newUserEmail: '',
      newUserRole: '',
      notificationMsg: '',
    };
  }

  handleChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  }

  handleRegister = () => {
    createUser(this.state);
  }

  render() {
    const { roleOptions } = this.props;
    const { notificationMsg } = this.state;
    return (
      <Paper className='create-user-paper'>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid container>
          <p className='title'>CREATE NEW USER</p>
        </Grid>
        <Grid container justify='center' spacing={2}>
          <Formik
            initialValues={{
              newUserName: '',
              newUserEmail: '',
              newUserRole: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              this.setState({
                notificationMsg: ''
              });
              createUser(values).then(() => {
                setSubmitting(false);
                this.setState({
                  notificationMsg: 'createUserSuccess'
                })
              })
                .catch(() => {
                  this.setState({
                    notificationMsg: 'createUser',
                  });
                });
            }}
            validationSchema={Yup.object().shape({
              newUserName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required'),
              newUserEmail: Yup.string().email('Invalid email').required('Required'),
              newUserRole: Yup.object().required('Required'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container justify='center' spacing={4}>
                    <Grid item xs={8}>
                      <span>User Name </span>
                      {errors.newUserName
                        && touched.newUserName
                        && <span className='error'> *{errors.newUserName}</span>}
                      <input
                        id='newUserName'
                        className='create-user-input'
                        onChange={handleChange}
                        value={values.newUserName}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <span>Email Address</span>
                      {errors.newUserEmail
                        && touched.newUserEmail
                        && <span className='error'> *{errors.newUserEmail}</span>}
                      <input
                        id='newUserEmail'
                        className='create-user-input'
                        onChange={handleChange}
                        value={values.newUserEmail}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <span>Role</span>
                      {errors.newUserRole
                        && touched.newUserRole
                        && <span className='error'> *{errors.newUserRole}</span>}
                      <FilterSelect
                        id='newUserRole'
                        options={roleOptions}
                        onChange={selected => setFieldValue('newUserRole', selected)}
                        value={values.newUserRole}
                      />
                    </Grid>
                    <Grid item xs={8} className='padding'>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        fullWidth
                        disabled={isSubmitting}
                      >
                        <span className='button-text'>CREATE USER</span>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Grid>
      </Paper>
    );
  }
}

export default CreateUser;
