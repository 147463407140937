import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import BarcodeReader from "react-barcode-reader";
import barcodeIcon from "../../assests/images/Barcode.png";
import { debounce } from "lodash";
export default class BarcodeReaderExtra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcode: "",
      alterbarcode: "",
    };
  }
  handChangeBarCode =   debounce((id, value) => {
    // this.setState({
    //   [id]: value
    // });
    // console.log(id,value)
    if(id === 'barcode'){
      this.props.handChangeAcutalBarCode(value);
    };
    if(id === 'alterbarcode'){
      this.props.handlechangeAlterBarcode(value)
    }
   
  },1000) 
  handleScan = barcode => {
    this.setState({
      barcode: barcode
    });
    this.props.handChangeAcutalBarCode(barcode);
  };
  handleError = err => {};

  render() {
    const { barcode } = this.state;
    // console.log(barcode);
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <img src={barcodeIcon} alt="barcode" className="barcode-icon" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
          {
            this.props.existRelatedBarcode4 ? (  <input
              //  value={actualBarcode}
              type="text"
              id="barcode"
              placeholder="Barcode"
              className="barcode-input"
              onChange={e => this.handChangeBarCode("barcode", e.target.value)}
            />) :(<input
               value={this.props.actualBarcode}
              type="text"
              id="barcode"
              placeholder="Barcode"
              className="barcode-input"
              onChange={e => this.handChangeBarCode("barcode", e.target.value)}
            />)
          }

{/* {
                                                          typeof(this.props.selectedRelatedBarcodeStatus)  !== 'string' ? null :  (this.props.selectedRelatedBarcodeStatus === 'Available_With_Different_Barcode' &&(
                                                         

                                                             <input
                                                             style={{marginTop:'40px'}}
                                                            //  value={actualBarcode}
                                                            type="text"
                                                            id="alterbarcode"
                                                            placeholder="Alter Barcode"
                                                            className="barcode-input"
                                                            onChange={e => this.handChangeBarCode("alterbarcode", e.target.value)}
                                                          />
                                                        

                                                        ))
                                                        } */}
        
        </Grid>
      </Grid>
    );
  }
}
