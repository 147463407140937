import React, { Component } from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import FilterSelect from '../../FilterSelect/FilterSelect';
import './CreateStore.scss';
import MessagesNotifications from '../../MessagesNotifications/MessagesNotifications';
import { getWarehouses } from '../../../actions/SrAccountantAPIs';

class CreateStore extends Component {
  constructor() {
    super();
    this.state = {
      selecteWarehouse: '',
      notificationMsg: '',
      warehouseOptions: [],
      buttonDisabled: true,
    };
  }

  componentDidMount() {
    getWarehouses().then(res => this.setState({
      warehouseOptions: res,
    }))
      .catch(() => {
        this.setState({
          notificationMsg: 'getWarehouses',
        });
      });
  }

  handleSelect = (id, selectedValue) => {
    this.setState({
      [id]: selectedValue,
    }, () => {
      const { selecteWarehouse } = this.state;
      this.setState({
        buttonDisabled: !selecteWarehouse,
      });
    });
  }

  render() {
    const {
      warehouseOptions,
      selecteWarehouse,
      buttonDisabled,
      notificationMsg,
    } = this.state;
    const { isCreating, onCreate } = this.props;
    return (
      <Paper className='stores-paper' elevation={1}>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid container>
          <p className='title'>CREATE STORE</p>
        </Grid>
        <Grid container justify='center'>
          <Grid item xs={12} sm={4}>
            <span>Store</span>
            <FilterSelect
              options={warehouseOptions}
              onChange={(selectedValue) => { this.handleSelect('selecteWarehouse', selectedValue); }}
              disabled={isCreating}
            />
          </Grid>
          <Grid container justify='center'>
            <Grid item xs={12} sm={4}>
              <br />
              <Button
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                onClick={() => onCreate(selecteWarehouse)}
                disabled={buttonDisabled || isCreating}
              >
                <span className='button-text'>CREATE</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default CreateStore;
