import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import FunFactorySelect from '../../components/Funfactory/FunFactorySelect/FunFactorySelect';
import FunfactorytData from '../../components/Funfactory/FunfactoryData/FunfactoryData';
import { getEntertainments, getEntertainment } from '../../actions/80/FunfactoryAPIs';
import MessagesNotifications from '../../components/MessagesNotifications/MessagesNotifications';

class FunFactory extends Component {
  constructor() {
    super();
    this.state = {
      Entertainments: [],
      EntertainmentInfo: '',
      isLoading: false,
      showCreate: false,
      notificationMsg: '',
    };
  }

  componentDidMount() {
    getEntertainments().then(res => {this.setState({
      Entertainments: res,
    })}
    );
  }

  handleSelectEntertainment = (entertainmentId) => {
    this.setState({
      isLoading: true,
      showCreate: false,
    });
    getEntertainment(entertainmentId)
      .then(res => {
        this.setState({
        EntertainmentInfo: res,
        isLoading: false,
      })

    });
  }

  handleShowCreate = () => {
    this.setState({
      showCreate: true,
      EntertainmentInfo: '',
    });
  }

  handleRefreshEntertainment = () => {
    getEntertainments()
      .then(res => this.setState({
        Entertainments: res,
      }));
  }

  handleRefreshGames = (id) => {
    this.setState({
      EntertainmentInfo: '',
    });
    getEntertainment(id)
      .then(res => this.setState({
        EntertainmentInfo: res,
      }));
  }

  afterDeleteEntertainment = () => {
    this.setState({
      Entertainments: [],
      EntertainmentInfo: '',
    });
    getEntertainments()
      .then(res => this.setState({
        Entertainments: res,
      }))
      .catch(() => {
        this.setState({
          notificationMsg: 'getEntertainment',
        });
      });
  }

  render() {
    const {
      Entertainments,
      EntertainmentInfo,
      isLoading,
      showCreate,
      notificationMsg,
    } = this.state;
    return (
      <Grid container justify='center' spacing={8}>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid item xs={12} sm={8}>
          <FunFactorySelect
            Entertainments={Entertainments}
            onSelectEntertainment={this.handleSelectEntertainment}
            onShowCreate={this.handleShowCreate}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          {EntertainmentInfo && !isLoading
            && (
            <FunfactorytData
              EntertainmentInfo={EntertainmentInfo}
              refreshEntertainments={this.handleRefreshEntertainment}
              handleRefreshGames={this.handleRefreshGames}
              onSelectEntertainment={this.handleSelectEntertainment}
              afterDeleteEntertainment={this.afterDeleteEntertainment}
            />
            )}
          {showCreate && !isLoading
          && (
          <FunfactorytData
            showCreate={showCreate}
            onSelectEntertainment={this.handleSelectEntertainment}
            refreshEntertainments={this.handleRefreshEntertainment}
          />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default FunFactory;
