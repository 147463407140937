import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AutoComplete from "../AutoComplete/AutoComplete";
import FileUploader from "../FileUploader/FileUploader";
import TagsInput from "react-tagsinput";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./AdminControl.scss";
import {
  updateExcludedList,
  getExcludedwarehouses,
  dailyProductSync,
  weeklyProductSync,
  TheNewProductSync ,
  mapSizes
} from "../../actions/DataEntryAPIs";
import {
  importSizeFile,
  exportSizeFile,
  getExcludedSKUs,
  updateExcludedSKUs,
  getExcludedProductIds,
  updateExcludedIDs,
  
} from "../../actions/AdminControlAPIs";
import MessagesNotifications from "../MessagesNotifications/MessagesNotifications";
import BlackLoader from "../BlackLoader/BlackLoader";
const styles = {};

class AdminControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagsInputValue: null,
      execludedInputValue: null,
      icgSize: "",
      dailyDialog: false,
      weeklyDialog: false,
      newproductDialog: false,
      exculdedArray: [],
      excludedIDS: [],
      excludedIdsForTags: null,
      whareHouseSize: "",
      notificationMsg: "",
      showloader: false,
      inputFile: null,
      isDailYSync: false,
      isWeeklySync: false,
      newProductSyncConfirmed: false,
      DeletedSkus: '',
      deletedIDS: '',
      filterSelected: {
        barCode: [],
        excludedproductIds: [],
      },
    };
  }
  closeDialog = () => {
    this.setState({
      dailyDialog: false,
      weeklyDialog: false,
      newproductDialog: false
    })
  }

  handleChange = id => selectedValue => {
    const { filterSelected } = this.state;
    // here you will get other data depeded in your choice.

    if (id === "barCode") {
      this.setState({
        filterSelected: {
          ...filterSelected,
          barCode: selectedValue
        }
      });
    }
    else{
      this.setState({
        filterSelected: {
          ...filterSelected,
          excludedproductIds: selectedValue
        }
      });

    }
  };

  hanldeUpdateExcludedSKUs = () => {
    this.setState({
      notificationMsg: ""
    });
    const { barCode } = this.state.filterSelected;
    let temp = "";
    for (let data of barCode) {
      temp += data + ",";
    }
    const modifedData = temp.slice(0, -1);
    updateExcludedSKUs(modifedData)
      .then(res => {
        this.setState({
          notificationMsg: "operation accomplished successfully"
        });
      })
      .catch(err => {
        this.setState({
          notificationMsg: "Error during the operation"
        });
      });
  };

  hanldeUpdateExcludedIDs = () => {
    this.setState({
      notificationMsg: ""
    });
    const { excludedproductIds } = this.state.filterSelected;
    let temp = "";
    for (let data of excludedproductIds) {
      temp += data + ",";
    }
    const modifedData = temp.slice(0, -1);
    updateExcludedIDs(modifedData)
      .then(res => {
        this.setState({
          notificationMsg: "operation accomplished successfully"
        });
      })
      .catch(err => {
        this.setState({
          notificationMsg: "Error during the operation"
        });
      });
  }

  componentDidMount() {
    getExcludedSKUs().then(res => {
      const temp = res === null ? [] : res.split(",");
      this.setState({
        exculdedArray: temp,
      })
      this.setState({
        execludedInputValue: res,
        filterSelected: {
          ...this.state.filterSelected,
          barCode: temp
        }
      });
    });

    getExcludedProductIds().then(res => {
      const temp = res === null ? [] : res.split(",");
      this.setState({
        excludedIDS: temp,
        excludedIdsForTags: res,
        filterSelected: {
          ...this.state.filterSelected,
          excludedproductIds: temp
        }
      });
    });
  }
  setTags = tags => {
    let tmp = "";
    tmp = tags.join(",");
    this.setState({
      tagsInputValue: tmp
    });
  };
  setTagsForExecludedSkus = tags => {
    let tmp = "";
    tmp = tags.join(",");
    this.setState({
      DeletedSkus: tmp
    });
  };

  setTagsForExecludedIDs = tags => {
    let tmp = "";
    tmp = tags.join(",");
    this.setState({
      deletedIDS: tmp
    });
  };
  

  handleInputChange = event => {
    const { id, value } = event.target;
    this.setState({
      [id]: value
    });
  };
  handleUpdate = () => {
    this.setState({
      notificationMsg: ""
    });
    updateExcludedList(this.state.tagsInputValue)
      .then(res =>
        this.setState({
          notificationMsg: "Excluded Warehouse Upadated successfully"
        })
      )
      .catch(error => {
        this.setState({
          notificationMsg: "Erorr while Updating"
        });
      });
  };

  handleExpand = (event, expanded) => {
    if (expanded) {
      getExcludedwarehouses().then(res => {
        this.setState({ tagsInputValue: res });
      });
    }
  };

  hanldeMapSize = () => {
    const { icgSize, whareHouseSize } = this.state;
    mapSizes(icgSize, whareHouseSize).then(res => {});
  };
  setFileData = data => inputFile => {
    this.setState({
      inputFile
    });
  };

  handleImport = data => () => {
    const { inputFile } = this.state;
    const inputData = new FormData();
    this.setState({
      showloader: true,
      notificationMsg: ""
    });

    inputData.append("File", inputFile);
    importSizeFile(inputData)
      .then(res => {
        this.setState({
          showloader: false,
          notificationMsg: "ImportedSuccessfully"
        });
      })
      .catch(err => {
        this.setState({
          notificationMsg: "importFile",
          showloader: false
        });
      });
  };
  handleExport = () => {
    this.setState({
      showloader: true,
      notificationMsg: ""
    });

    exportSizeFile()
      .then(() => {
        this.setState({
          showloader: false,
          notificationMsg: "SizesExportedSuccessfully"
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "exportFile",
          showloader: false
        });
      });
  };
 
  handleDeleteSKUS = () => {
    const temp = this.state.DeletedSkus.split(',')
    if(temp[0] === '') {
    }
    else{
   let barcodes = this.state.filterSelected.barCode;

    const modified =  barcodes.filter( sku => ! temp.includes(sku));
      this.setState({
        filterSelected: {
          ...this.state.filterSelected,
          barCode: modified
        }
      }, () => this.hanldeUpdateExcludedSKUs() );
   
      
    
    }

  }

  handleDeleteIDs = () => {
    const temp = this.state.deletedIDS.split(',')
    if(temp[0] === '') {
    }
    else{
   let barcodes = this.state.filterSelected.excludedproductIds;

    const modified =  barcodes.filter( sku => ! temp.includes(sku));
      this.setState({
        filterSelected: {
          ...this.state.filterSelected,
          excludedproductIds: modified
        }
      }, () => this.hanldeUpdateExcludedIDs() );
   
      
    
    }

  }

  dailySync = () => {
    this.setState({
      showloader: true,
      notificationMsg: 'An email will be sent to you when the process finish',
      dailyDialog: false,
    })
    dailyProductSync().then( res=>{
      this.setState({
        showloader: false,
        dailyDialog: false,
      })
    }).catch( err => {
      this.setState({
        notificationMsg: "Error during the operation",
        showloader: false,
        dailyDialog: false,
      })
    })
  }

  weeklySync = ()=> {
    this.setState({
      showloader: true,
      notificationMsg: 'An email will be sent to you when the process finish',
      weeklyDialog: false,
    })
    weeklyProductSync().then(res => {
      this.setState({
        showloader: false,
        weeklyDialog: false,
      })
    }).catch( err => {
      this.setState({
        notificationMsg: "Error during the operation",
        showloader: false,
        weeklyDialog: false,
      })
    })
  }

  newProductSync = () => {
    this.setState({
      showloader: true,
      notificationMsg: 'An email will be sent to you when the process finish',
      newproductDialog: false,
    })
    TheNewProductSync().then(res => {
      this.setState({
        showloader: false,
        newproductDialog: false,
      })
    }).catch(err => {
      this.setState({
        notificationMsg: "Error during the operation",
        showloader: false,
        newproductDialog: false,
      })
    })
    // this.setState({
    //   notificationMsg: '',
    // })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="root">
        {this.state.notificationMsg !== "" && (
          <MessagesNotifications message={this.state.notificationMsg} />
        )}
        <Grid style={{ width: "70%" }}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Size Mapping</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container justify="center" spacing={2}>
                <FileUploader
                  setFileData={this.setFileData()}
                  importData={this.handleImport()}
                  fileExtension={[".xlsx"]}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={this.handleExport}
                >
                  <span className="button-text">Export</span>
                </Button>
                <br />
                {this.state.showloader && <BlackLoader />}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <br />
          <br />

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Excluded SKUs</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container justify='center'>  
                <Grid item xs={10} sm={10} className="padding">
                  <span>SKUs</span>
                  <div className="tagInputContainer">
                    <TagsInput
                      type="text"
                      value={this.state.filterSelected.barCode}
                      onChange={this.handleChange("barCode")}
                    />
                  
                  </div>
                  </Grid>

                  <Grid container xs={10} sm={4} justify='center' >
                    <Button
                    style={{marginBottom:'10px'}}
                      variant="contained"
                      color="primary"
                      onClick={this.hanldeUpdateExcludedSKUs}
                    >
                      Update
                    </Button>
                  </Grid>
                
                 

              

                 
                  {/* <span>Delete Sku From List</span>
                  <hr/> */}
                   <Grid item xs={10} sm={10}>
                  
                  <hr/><br/>
                </Grid>
                
                <Grid item xs={10} sm={10}>
                  <AutoComplete
                    setTags={this.setTagsForExecludedSkus}
                    // tagsInputValue={this.state.execludedInputValue}
                    tagForsku={true}
                    exculdedArray={this.state.exculdedArray}
                  />
                </Grid>
                <Grid container xs={10} sm={4} justify='center'>
                  <Button
                  style={{marginTop:7}}
                    variant="contained"
                    color="primary"
                    // className="sync-buttons"
                    onClick={this.handleDeleteSKUS}
                  >
                  Delete
                  </Button>
                </Grid>
        
              </Grid>
             
            
            </ExpansionPanelDetails>
          </ExpansionPanel>



          <br />
          <br />

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Excluded IDs</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container justify='center'>  
                <Grid item xs={10} sm={10} className="padding">
                  <span>SKUs</span>
                  <div className="tagInputContainer">
                    <TagsInput
                      type="text"
                      value={this.state.filterSelected.excludedproductIds}
                      onChange={this.handleChange("IDS")}
                    />
                  
                  </div>
                  </Grid>

                  <Grid container xs={10} sm={4} justify='center' >
                    <Button
                    style={{marginBottom:'10px'}}
                      variant="contained"
                      color="primary"
                      onClick={this.hanldeUpdateExcludedIDs}
                    >
                      Update
                    </Button>
                  </Grid>
                
                 

              

                 
                  {/* <span>Delete Sku From List</span>
                  <hr/> */}
                   <Grid item xs={10} sm={10}>
                  
                  <hr/><br/>
                </Grid>
                
                <Grid item xs={10} sm={10}>
                  <AutoComplete
                    setTags={this.setTagsForExecludedIDs}
                    // tagsInputValue={this.state.execludedInputValue}
                    tagForIDs={true}
                    excludedIDS={this.state.excludedIDS}
                  />
                </Grid>
                <Grid container xs={10} sm={4} justify='center'>
                  <Button
                  style={{marginTop:7}}
                    variant="contained"
                    color="primary"
                    // className="sync-buttons"
                    onClick={this.handleDeleteIDs}
                  >
                  Delete
                  </Button>
                </Grid>
        
              </Grid>
             
            
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            style={{ marginTop: "2%" }}
            onChange={this.handleExpand}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Excluded Warehouse List
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid   direction='column'>
                <Grid container justify='center'>
                <Grid item xs={10} sm={10}>
                  <AutoComplete
                    setTags={this.setTags}
                    tagsInputValue={this.state.tagsInputValue}
                    tagForAdmin={true}
                  />
                </Grid>
                </Grid>
               
                  <Grid container justify='center'>
                  <Grid item  >
                  <Button
                  style={{marginTop:'10px'}}
                    variant="contained"
                    color="primary"
                    // className="sync-buttons"
                    onClick={this.handleUpdate}
                  >
                    update
                  </Button>
                </Grid>
                  </Grid>
              
                
              </Grid>
              <br/>
             
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <br/>


          <ExpansionPanel
            style={{ marginTop: "2%", marginBottom:'40px' }}
            onChange={this.handleExpand}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                 Sync
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container justify="center">
                <Grid item xs={10} sm={2}>
                  <Button color='primary' variant='contained' onClick={()=>{this.setState({
                    dailyDialog: true,
                  })}}>
                    Daily Sync
                  </Button>
                </Grid>
                <Grid item xs={10} sm={2}>
                  <Button color='primary' variant='contained' onClick={()=>{
                    this.setState({
                      weeklyDialog: true
                    })
                  }}>
                  Weekly Sync
                  </Button>
                </Grid>
                <Grid item xs={10} sm={3}>
                  <Button color='primary' variant='contained'  onClick={()=> {
                    this.setState({
                      newproductDialog: true,
                    })
                  }} >
                    New Product Sync
                  </Button>
                </Grid>

                  {
                  <Dialog open={this.state.dailyDialog} onClose={this.closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.deletedialog} fullWidth={true} maxWidth = {'xs'} >
                    <DialogTitle id="alert-dialog-title" style={{backgroundColor:'#313639',color:'white',textAlign:'center'}} >{"Daily Sync"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText  id="alert-dialog-description">
                             Are You Sure About That?
                       </DialogContentText>
                     </DialogContent>
                       <DialogActions>
                         <Button onClick={this.closeDialog} color="default" variant='outlined' autoFocus>
                           Close
                         </Button>
                         <Button onClick={this.dailySync} color="secondary" variant='outlined'>
                            Confirm
                         </Button>
                      </DialogActions>
               </Dialog>  
         
                  }
                         {
                  <Dialog open={this.state.weeklyDialog} onClose={this.closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.deletedialog} fullWidth={true} maxWidth = {'xs'} >
                    <DialogTitle id="alert-dialog-title" style={{backgroundColor:'#313639',color:'white',textAlign:'center'}} >{"Weekly Sync"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText  id="alert-dialog-description">
                             Are You Sure About That?
                       </DialogContentText>
                     </DialogContent>
                       <DialogActions>
                         <Button onClick={this.closeDialog} color="default" variant='outlined' autoFocus>
                           Close
                         </Button>
                         <Button onClick={this.weeklySync} color="secondary" variant='outlined'>
                            Confirm
                         </Button>
                      </DialogActions>
               </Dialog>  
         
                  }

{
                  <Dialog open={this.state.newproductDialog} onClose={this.closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.deletedialog} fullWidth={true} maxWidth = {'xs'} >
                    <DialogTitle id="alert-dialog-title" style={{backgroundColor:'#313639',color:'white',textAlign:'center'}} >{"New Product Sync"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText  id="alert-dialog-description">
                             Are You Sure About That?
                       </DialogContentText>
                     </DialogContent>
                       <DialogActions>
                         <Button onClick={this.closeDialog} color="default" variant='outlined' autoFocus>
                           Close
                         </Button>
                         <Button onClick={this.newProductSync} color="secondary" variant='outlined'>
                            Confirm
                         </Button>
                      </DialogActions>
               </Dialog>  
         
                  }
               

              </Grid>
              {this.state.showloader && <BlackLoader />}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AdminControl);
