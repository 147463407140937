import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import OrdersShow from "../../components/QhubRunnerOrders/OrderShow/ViewOrder";
import OrderSearchForm from "../../components/QhubRunnerOrders/OrderForm/OrderSearchForm";
import {
  getOrders,
  getOrderByOrderNumber,
  getItemByBarCode,
  SendNewRunnerRunnerStatus
} from "../../actions/OrderAPI";
import InfoBox from "../../components/InfoBox/InfoBox";
import GreenLoader from "../../components/GreenLoader/GreenLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BarcodeReader from "react-barcode-reader";
import barcodeIcon from "../../assests/images/Barcode.png";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FilterSelect from "../../components/FilterSelect/FilterSelect";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";
let filterData;

const OrderItemStatus = [
  { label: "Qhub", value: 0 },
  { label: "Transit", value: 1 }
];

class Orders extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      data: [],
      isExporting: false,
      ShowFull: false,
      page: 0,
      count: 0,
      rowsPerPage: 6,
      expanded: "",
      OrderNumber: "",
      OrderID: "",
      barcode: "",
      open: false,
      ResultsFromBarCode: "",
      selectedOrderItemStatus: { label: "Select", value: null },
      notificationMsg: "",
      OrderNo: ""
    };
  }

  handleSearch = () => {
    const { OrderNumber } = this.state;

    this.setState({
      isLoading: true,
      ShowFull: false
    });

    getOrderByOrderNumber(OrderNumber)
      .then(res => {
        if(res.Items.length > 0 ) {
          this.setState({
            data: res.Items,
            OrderID: res["OrderId"],
            OrderNo: res["OrderNo"],
            isLoading: false
          });
        }
        if(res.Items.length === 0 ) {
          this.setState({
            ShowFull: true,
            isLoading: false
          });
        }
      })
      .catch(() => {
        this.setState({
          ShowFull: true,
          notificationMsg: "search"
        });
      });
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage, page, data } = this.state;
    this.setState({
      page: newPage,
      isLoading: newPage * rowsPerPage >= data.length
    });
    if (newPage > page && newPage * rowsPerPage >= data.length) {
      getOrders(filterData, newPage, rowsPerPage).then(res => {
        const newData = data;
        res.Results.map(item => newData.push(item));
        this.setState({
          data: newData,
          isLoading: false
        });
      });
    }
  };

  handleChange = id => selectedValue => {
    if (id === "selectedOrderItemStatus") {
      this.setState({
        [id]: selectedValue.label
      });
    } else {
      this.setState({
        [id]: selectedValue.target.value
      });
    }
  };

  handleChangeBarCode = (type, selectedBarCode) => {
    const { OrderID, OrderNo } = this.state;
    switch (type) {
      case "BARCODE":
        this.setState({
          barcode: selectedBarCode
        });
        getItemByBarCode(OrderNo, selectedBarCode).then(res => {
          if (res !== null) {
            this.setState({
              open: true,
              ResultsFromBarCode: res
            });
          }
        });
    }
  };
  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleScan = barcode => {
    const { OrderNo } = this.state;
    this.setState({
      notificationMsg: "",
      data: null,
      barcode: barcode
    });
    getItemByBarCode(OrderNo, barcode).then(res => {
      if (res !== null) {
        this.setState({
          open: true,
          ResultsFromBarCode: res
        });
      }
    });
  };

  handleError = err => {
    this.setState({
      notificationMsg: "BarcodeReader"
    });
  };

  handleClose = type => {
    this.setState({
      open: false
    });
  };

  handleEditItemUsingBarCode = () => {
    this.setState({
      notificationMsg: ""
    });

    const {
      OrderNo,
      ResultsFromBarCode,
      selectedOrderItemStatus,
      data
    } = this.state;
    const seletedItem = data.find(x => x.Id === ResultsFromBarCode["Id"]);
    SendNewRunnerRunnerStatus(
      "#" + OrderNo,
      ResultsFromBarCode["Barcode"],
      selectedOrderItemStatus
    ).then(res => {
      if(res === null ){
        this.setState({
          notificationMsg: "OrderItemStatusNotUpdated",
          open: false
        })
      }else{
      seletedItem["TrackingStatus"] = selectedOrderItemStatus;
      this.setState({
        notificationMsg: "ItemUpdated",
        open: false
      })
     }
    }
    
    );
  };

  render() {
    const {
      data,
      count,
      isLoading,
      ShowFull,
      rowsPerPage,
      page,
      expanded,
      OrderNumber,
      OrderID,
      ResultsFromBarCode,
      selectedOrderItemStatus,
      open,
      notificationMsg,
      OrderNo
    } = this.state;
    return (
      <Grid container justify="center">
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <ExpansionPanel
              expanded={expanded === "searchPanel"}
              onChange={this.handleExpand("searchPanel")}
              className="data-entry-expand"
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="data-entry-expand-title">Search</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <OrderSearchForm
                  onSearch={this.handleSearch}
                  onChange={this.handleChange}
                  OrderNumber={OrderNumber}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          {data.length > 0 && !isLoading && (
            <Paper className="upload-scan-paper" elevation={1}>
              <Grid container>
                <p className="title">ITEM BARCODE</p>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <img
                    src={barcodeIcon}
                    alt="barcode"
                    className="barcode-icon"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <BarcodeReader
                    onError={this.handleError}
                    onScan={this.handleScan}
                  />
                  <input
                    type="text"
                    id="barcode"
                    placeholder="Barcode"
                    className="barcode-input"
                    onChange={e =>
                      this.handleChangeBarCode("BARCODE", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} sm={8}>
          {data.length > 0 && !isLoading && (
            <OrdersShow
              data={data}
              OrderID={OrderID}
              OrderNo={OrderNo}
              page={page}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangePage={this.handleChangePage}
            />
          )}
        </Grid>
        {isLoading && <GreenLoader />}

        {ShowFull && <InfoBox message="Data Not Available" />}

        <Dialog open={open} fullWidth>
          <DialogTitle
            style={{
              backgroundColor: "#192f38",
              color: "white",
              textAlign: "center"
            }}
            id="responsive-dialog-title"
          >
            {"Update Order Item Status"}
          </DialogTitle>
          <DialogContent style={{ marginTop: "41px" }}>
            <Grid container justify="center">
              <Grid item xs={6} style={{ minHeight: "120px" }}>
                <TextField
                  id="outlined-name"
                  margin="normal"
                  variant="outlined"
                  label="Current Status"
                  value={ResultsFromBarCode.TrackingStatus || ""}
                  inputProps={{
                    readOnly: true
                  }}
                  style={{ width: "100%" }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={6} style={{ minHeight: "120px" }}>
                <FilterSelect
                  options={OrderItemStatus}
                  selected={selectedOrderItemStatus.label}
                  onChange={this.handleChange("selectedOrderItemStatus")}
                />
              </Grid>
            </Grid>
            <hr></hr>
          </DialogContent>
          <Grid container justify="center">
            <DialogActions>
              <Button
                onClick={this.handleEditItemUsingBarCode}
                variant="contained"
                color="primary"
                autoFocus
              >
                Update
              </Button>

              <Button
                onClick={this.handleClose}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}

export default Orders;
