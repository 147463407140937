import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./Driver.styles.scss";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getDrivers } from "../../../actions/80/DriverAPIs";
import { addDriver } from "../../../actions/80/RainbowAPIs";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
const styles = theme => ({
  root: {
    margin: "0 auto",
    width: "70%",
    overflowX: "auto"
  },
  expandpanel: {
    backgroundColor: "#8CBA51"
  },
  panelexpand: {
    margin: "0 auto",
    width: "40%",
    overflowX: "auto"
  },
  "@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)": {
    root: {
      margin: "0 auto",
      width: "90%",
      overflowX: "auto"
    }
  },
  "@media only screen and (min-device-width: 768px) and (max-device-width: 1366px)": {
    root: {
      margin: "0 auto",
      width: "90%",
      overflowX: "auto"
    }
  },
  "@media all and (device-width: 768px) and (device-height: 1024px)": {
    root: {
      margin: "0 auto",
      width: "98%",
      overflowX: "auto"
    }
  }
});

class Drivers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterdArray: [],
      page: 0,
      count: 0,
      rowsPerPage: 6,
      username: "",
      firstname: "",
      lastname: "",
      password: "",
      email: "",
      phoneNumber: "",
      isexpanded: false,
      notificationMsg: "",
    };
  }
  componentDidMount() {
    getDrivers(this.state.page, this.state.rowsPerPage).then(res => {
      this.setState({
        filterdArray: res.Results
      });
    });
  }

  handleChange = (value, id) => {
    this.setState({
      [id]: value
    });
  };

  handleChangePage = (event, page) => {
    getDrivers(this.state.page, this.state.rowsPerPage).then(res => {
      this.setState({
        page: page
      });
    });
  };

  handleAddDriver = () => {
    const {
      username,
      firstname,
      lastname,
      password,
      email,
      phoneNumber
    } = this.state;
    const driverInfo = {
      UserName: username,
      Password: password,
      ConfirmPassword: password,
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      Barcode: "",
      Mobile: phoneNumber,
      IsDriver: "true"
    };
    addDriver(driverInfo)
      .then(res => {
        this.setState({
          isexpanded: false,
          notificationMsg: "Add driver successfully",
          username: '',
          firstname: '',
          lastname: '',
          password: '',
          email: '',
          phoneNumber: '',
        });
        getDrivers(this.state.page, this.state.rowsPerPage).then(res => {
          this.setState({
            filterdArray: res.Results
          });
        });
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error while adding new driver"
        });
      });
  };

  handlePanelstate = () => {
    this.setState({
      isexpanded: !this.state.isexpanded
    });
  };
  render() {
    const {
      username,
      firstname,
      lastname,
      password,
      email,
      phoneNumber
    } = this.state;
    const { classes } = this.props;
    return (
      <div className="driverbg">
        {this.state.notificationMsg && (
          <MessagesNotifications message={this.state.notificationMsg} />
        )}
        <Grid container className={classes.panelexpand}>
          <ExpansionPanel
            onChange={this.handlePanelstate}
            expanded={this.state.isexpanded}
            style={{ backgroundColor: "#8CBA51" }}
            className={classes.expandpanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>New Driver</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ backgroundColor: "#EEF0ED" }}>
              <Grid container>
                <Grid container justify="center">
                  <Grid item xs={12} sm={6}>
                    <span>User Name</span>
                    <input
                      type="text"
                      className="rawabi-input"
                      value={username}
                      onChange={e =>
                        this.handleChange(e.target.value, "username")
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={12} sm={6}>
                    <span>First Name</span>
                    <input
                      type="text"
                      className="rawabi-input"
                       value={firstname}
                      onChange={e =>
                        this.handleChange(e.target.value, "firstname")
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={12} sm={6}>
                    <span>Last Name </span>
                    <input
                      type="text"
                      className="rawabi-input"
                      value={lastname}
                      onChange={e =>
                        this.handleChange(e.target.value, "lastname")
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <Grid item xs={12} sm={6}>
                    <span>Password</span>
                    <input
                      type="password"
                      className="rawabi-input"
                      value={password || ""}
                      onChange={e =>
                        this.handleChange(e.target.value, "password")
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={12} sm={6}>
                    <span>Email</span>
                    <input
                      type="email"
                      className="rawabi-input"
                      value={email}
                      onChange={e => this.handleChange(e.target.value, "email")}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={12} sm={6}>
                    <span>Phone Number</span>
                    <input
                      required
                      type="number"
                      className="rawabi-input"
                      value={phoneNumber}
                      onChange={e =>
                        this.handleChange(e.target.value, "phoneNumber")
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <Button
                    onClick={this.handleAddDriver}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={username && firstname && lastname
                       && password && email && phoneNumber 
                       ? false 
                       : true}
                       
                  >
                    <span className="button-text">Add Driver</span>
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <br />
        <br />

        <Grid container justify="center">
          <Paper className={classes.root}>
            <div className="table-wrapper">
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell> UserName</TableCell>
                    <TableCell align="right">FirstName</TableCell>
                    <TableCell align="right">LastName</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Mobile</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filterdArray
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((row, index) => (
                      <TableRow key={row.Id}>
                        <TableCell component="th" scope="row">
                          {" "}
                          {row.UserName}{" "}
                        </TableCell>
                        <TableCell align="right">{row.FirstName}</TableCell>
                        <TableCell align="right">{row.LastName}</TableCell>
                        <TableCell align="right">{row.Email}</TableCell>
                        <TableCell align="right">{row.Mobile}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[6]}
              component="div"
              count={this.state.filterdArray.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
            />
          </Paper>
        </Grid>
        <br/>
        <br/>
      </div>
    );
  }
}
export default withStyles(styles)(Drivers);
