import React, { Component } from "react";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from "@material-ui/core/TableHead";
import { getColors } from '../../../actions/PhotographerAPIs';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import receiptImg from "../../../assests/images/icons8-receipt-24.png";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import { getRole } from "../../../services/share/DataService";
import FilterSelect from "../../FilterSelect/FilterSelect";
import Done from "@material-ui/icons/Done";
import DialogTitle from "@material-ui/core/DialogTitle";
import BarcodeReader from "react-barcode-reader";
import barcodeIcon from "../../../assests/images/Barcode.png";
import BarcodeReaderExtra from '../../barcodeReader/BarcodeReader';
import axios from 'axios';
import { debounce } from "lodash";
import {
  UploadReceiptImg,
  getImageNames,
  UpdateOrderItemStatus,
  getItemStatus,
  UpdateOrderItemStatusForRelatedBarcode,
} from "../../../actions/OrderAPI";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
import BlackLoader from "../../BlackLoader/BlackLoader";
import { getLocations } from "../../../actions/LocationsAPIs";
import "./viewLocations.scss";
import { CenterFocusStrong } from "@material-ui/icons";
const OrderItemStatus = [
  { label: "Missing", value: 0 },
  { label: "Available", value: 0 }
];
let tempData = {};
let Picklistpdforders = [];
let token = localStorage.getItem('access_token');
// let x = new Map();
export default class ViewLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recepietImg: "",
      rotation: 0,
      open: false,
      openImg: false,
      showImg: false,
      Showerror: false,
      imagesName: [],
      isEditing: false,
      selectedOrderItemStatus: { label: "Select", value: null },
      selectedRelatedBarcodeStatus: {label: "Select", value: null},
      EditIndex: null,
      expandedIndex: null,
      notificationMsg: "",
      opendialog: false,
      actualBarcode: "",
      relatedBarcodeStatus: [],
      existRelatedBarcode4: true,
      alterbarcode: "",
      openAlterDialog: false,
      flag: false,
      loading: false,
      selectedAll: false,
      checkedItems: this.props.checkboxstatus,
      opencolorDialog: false,
      colorOptions: [],
      COLOR: '',
      openMissingDialig: false,
      commentForItemStatus: '',
    };
  }
  componentDidMount(){
    getItemStatus().then(res => {
      this.setState({
        relatedBarcodeStatus: res
      });
    });
    getColors()
    .then(res =>
      this.setState({
        colorOptions: res
      })
    )
    .catch(() => {
      this.setState({
        notificationMsg: "getColors"
      });
    });

  }
 
  handleUpdateRelatedBarcodeStatus = (orderNo, orderItemId, orderItemBarcode, trackingStatus) => {
    const params = {
      orderNo: orderNo,
      orderItemId: orderItemId,
      orderItemBarcode: orderItemBarcode,
      trackingStatus: trackingStatus,
      altBarcode: this.state.alterbarcode,
      color: this.state.COLOR,
      comments: this.state.commentForItemStatus,
    }
    UpdateOrderItemStatusForRelatedBarcode(params).then(res => {
      this.props.onStatusEdit(this.state.selectedRelatedBarcodeStatus, this.state.EditIndex, this.state.actualBarcode,this.state.alterbarcode,this.state.COLOR);
    
     this.setState({
       notificationMsg: "operation accomplished successfully",
       opendialog: false,
     })
   
    })
    

  }
  handleViewReceiptImg = orderNumber => {
    getImageNames(orderNumber)
      .then(res => {
        this.setState({
          imagesName: res,
          showImg: true,
          OrderNo: orderNumber
        });
      })
      .catch(() => {
        this.setState({
          showImg: true,
          Showerror: true
        });
      });
  };

  handleCloseColorDialog = () => {
    this.setState({
      opencolorDialog: false,
    })
  }

  handleClose = () => {
    this.setState({
      showImg: false,
      Barcode: "",
      OrderNo: ""
    });
  };
  handleChangeCheckBox = (name, checked, orderIndex) => {
    // if(checked){
    //   const temp = this.state.life;
    //   temp.push(name)
    //   console.log(name);
    //   this.setState({
    //     life: temp
    //   })
    // }
    this.props.setCheckBoxStatus(name,checked);
    // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(name, checked) }));
    // this.props.setCheckBoxStatus(name,checked);
    // x.set(name, checked);
      let temp2 = orderIndex;
    if(this.props.page === 1){
      temp2.order = this.props.rowsPerPage +orderIndex.order
      
    }
    if(this.props.page >1){
      temp2.order = this.props.rowsPerPage*this.props.page +orderIndex.order
    }
    if(checked){
      Picklistpdforders.push(temp2);
    }
    else{
      const temp = Picklistpdforders.filter(res => res.order !== temp2.order )
      Picklistpdforders = temp;
    }
    // if(this.props.checkboxstatus.length > 0){
    //   this.setState({
    //     flag: true
    //   })
    // }
    // else{
    //   this.setState({
    //     flag: false
    //   })
    // }
  }
  handleDownloadPickListPDF = () =>{
    this.setState({
      notificationMsg: ''
    })
    const {data} = this.props;
    let temp = [];
     for ( let selectedOrder of Picklistpdforders){
      let paramsObject = {
        OrderNo:data[selectedOrder.order].OrdersItems[selectedOrder.items].OrderNo,
        Location: data[selectedOrder.order].Location,
        QTY: data[selectedOrder.order].OrdersItems[selectedOrder.items].Quantity,
        Barcodes: data[selectedOrder.order].OrdersItems[selectedOrder.items].Barcodes,

      }
      temp.push(paramsObject);
    }
    const parms = {
      orderItems: temp
    }
    this.setState({
      loading: true,
    })
    this.Order_DOWNLOAD_PDFS('/api/Order/LocationPicklistPdf', parms);
  }
  Order_DOWNLOAD_PDFS = (fileUrl, filter) => {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    return axios.post(fileUrl, filter,{Authorization: `bearer ${token}`, responseType: 'blob' })
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby.data);
        anchor.href = objectUrl;
        anchor.download = `OrderLocationPicklist.pdf`;
        anchor.click();
        this.setState({
          loading: false,
        })
      }).catch(err => {
        this.setState({
          loading: false,
          notificationMsg: 'Error during the operation',
        })
      });
  };
  handleUploadReciept = () => {
    const { recepietImg, OrderItemBarcode } = this.state;
    const { OrderNo } = this.state;
    var base64result = recepietImg.substr(recepietImg.indexOf(",") + 1);
    const toUploadImage = {
      OrderNo: OrderNo,
      OrderItemBarcode: OrderItemBarcode,
      ImageCode: base64result
    };

    UploadReceiptImg(toUploadImage)
      .then(() =>
        this.setState({
          open: false,
          showImg: false,
          notificationMsg: "operation accomplished successfully"
        })
      )
      .catch(error => {});
  };
  handleCloseShowImg = () => {
    this.setState({
      showImg: false,
      open: false
    });
  };
  handleScan = barcode => {
    this.setState({
      alterbarcode: barcode
    });
  };
  handleCloseAlterDialog = () => {
    this.setState({
      openAlterDialog: false
    })
  }
  rotate = index => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      selectedIndex: index,
      rotation: newRotation
    });
  };
  handleImageChange = (event, orderNumm) => {
    const { id } = event.target;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        this.setState({
          [id]: reader.result,
          open: true,
          OrderNo: orderNumm
        });
      };
      image.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  handleCloseMissingDialog = () => {
    this.setState({
      openMissingDialig: false,
    })
  }

  EditItemStatus = (data, index) => {
    tempData ={
      orderNo: data.OrderNo,
      orderItemId: data.Id,
    }
    this.setState({
      // isEditing: true,
       EditIndex: index,
      // expandedIndex: expandIndex,
      opendialog: true,
    });
    if(data.Barcodes.length == 1){
      this.setState({
        existRelatedBarcode4: false,
        actualBarcode: data.Barcodes[0].Barcode,
      })
    }
    
   
  };
  handlechangeAlterBarcode = debounce((value) => {
    // console.log(event.target.value)
    this.setState({
      alterbarcode: value
    });
  },1000) 

  handleChangeComments = debounce((value) => {
    // console.log(event.target.value)
    this.setState({
      commentForItemStatus: value
    });
  },1000) 
  
  handleChange = (id, index) => selectedValue => {
    // console.log(id,index,selectedValue);
    if (id === "selectedOrderItemStatus") {
      this.setState({
        [id]: selectedValue.label
      });
    }
    else if (id ==='selectedRelatedBarcodeStatus'){
      this.setState({
        [id]: selectedValue.label
      })
      if( selectedValue.label === 'Available_With_Different_Barcode'){
        this.setState({
          openAlterDialog: true,
        })
      }
      if(selectedValue.label === 'Available_With_Different_Color'){
        this.setState({
          opencolorDialog: true,
        })
      }
      if(selectedValue.label === 'Missing_With_Different_Barcode'){
        this.setState({
          openMissingDialig: true,
        })
      }
    }
    else {
      this.setState({
        [id]: selectedValue
      });
    }
  };

  saveTrackingStatus = (
    orderNo,
    orderItemBarcode,
    trackingStatus,
    index1,
    index
  ) => {
    const data = {
      orderNo,
      orderItemBarcode,
      trackingStatus
    };
    UpdateOrderItemStatus(data).then(res => {
      this.setState({
        isEditing: false
      });
      this.props.UpdateItemStatus(
        index1,
        index,
        this.state.selectedOrderItemStatus
      );
    });
  };
  handleChangeColor = (type, color) => {
    console.log(color);
    this.setState({
      COLOR: color
    })
  }
  handleCloseDialog = () => {
    this.setState({
      opendialog: false
    });
  };
  handChangeAcutalBarCode = value => {
    this.setState({
      actualBarcode: value
    });
  };
  render() {
    console.log(this.state.commentForItemStatus);
    const {
      data,
      page,
      count,
      rowsPerPage,
      onChangePage,
      onChangeRowsPerPage,
      onStatusEdit
    } = this.props;
    const {
      Showerror,
      showImg,
      selectedOrderItemStatus,
      isEditing,
      EditIndex,
      expandedIndex,
      notificationMsg
    } = this.state;

    return (
      
      <div>
        {
          this.props.checkboxstatus.length >0  &&  ( <Button style={{
            marginTop:'10px'
          }} variant='contained' color='primary' onClick={this.handleDownloadPickListPDF}>Pick List </Button>)
        } 
        <br/>
      {
        this.state.loading && <BlackLoader/>
      }
        <Grid container>
          {notificationMsg !== "" && (
            <MessagesNotifications message={notificationMsg} />
          )}
          <Paper className="table-root">
            <div className="table-wrapper">
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow></TableRow>
                </TableHead>

                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order, index1) => {
                      return (
                        <React.Fragment>
                          <TableRow className="border_bottom" key={order.Location}>
                            <ExpansionPanel defaultExpanded={true}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>{order.Location}</Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Table aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Actions</TableCell>
                                      <TableCell>Order No </TableCell>
                                      <TableCell>Image</TableCell>
                                      <TableCell>Reference Number</TableCell>
                                      <TableCell align="center">
                                        Barcode
                                      </TableCell>
                                      <TableCell align="center">
                                        Color
                                      </TableCell>
                                      <TableCell>Category</TableCell>
                                      <TableCell align="center">
                                        ICGSize
                                      </TableCell>
                                      <TableCell align="center">
                                        OnlineSize
                                      </TableCell>
                                      <TableCell align="right">
                                        Quantity
                                      </TableCell>
                                      {/* {getRole() === "StoreSupervisor" && (
                                        <TableCell align="center">
                                          Status
                                        </TableCell>
                                      )} */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {order.OrdersItems.map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          <Checkbox
                                          name={row.Id}
                                          checked={this.props.checkboxstatus.includes(row.Id)}
                                      onChange={(event) => this.handleChangeCheckBox(row.Id,event.target.checked, {order: index1, items: index})}
                                      color="primary"
                                    />
                                            <React.Fragment>
                                              {getRole() ===
                                              "StoreSupervisor" ? (
                                                <EditIcon
                                                  className="editIcon"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => this.EditItemStatus(row, index) }
                                                />
                                              ) : null}
                                            </React.Fragment>
                                          
                                          <Dialog
                                          //  PaperProps={{style: {overflowY: 'visible'}}}
                                                  open={this.state.opendialog}
                                                  onClose={
                                                    this.handleCloseDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" ADD Actual Barcode "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent style={{ overflowY: 'visible'}}>
                                                    <Grid
                                                      container
                                                      justify="center"
                                                    >
                                                      <Grid item xs={12}>
                                                        <BarcodeReaderExtra
                                                        selectedRelatedBarcodeStatus={this.state.selectedRelatedBarcodeStatus}
                                                        handlechangeAlterBarcode={this.handlechangeAlterBarcode}
                                                          handChangeAcutalBarCode={
                                                            this
                                                              .handChangeAcutalBarCode
                                                          }
                                                          existRelatedBarcode4={this.state.existRelatedBarcode4}
                                                          actualBarcode={this.state.actualBarcode}
                                                        />
                                                        <br />
                                                      </Grid>
                                                      <Grid item xs={6}>
        
                                                        <FilterSelect
                                                          options={
                                                            this.state
                                                              .relatedBarcodeStatus
                                                          }
                                                          onChange={this.handleChange(
                                                            "selectedRelatedBarcodeStatus",
                                                            index
                                                          )}
                                                          selected={
                                                            this.state
                                                              .selectedRelatedBarcodeStatus.label
                                                          }
                                                        />

                                                        <br />
                                                        <Grid item xs={12}>
                                                           Comments
                                                          <textarea
                                                            className="description-input"
                                                            rows="4"
                                                            onChange={e =>
                                                              this.handleChangeComments(e.target.value)
                                                            }
                                                            // value={this.state.commentForItemStatus || ""}
                                                          />
                                                        </Grid>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                      </Grid>
                                                    </Grid>

                                                    {/* <Grid ixs={12} sm={3}>
                                                        <BarcodeReader
                                                          onError={this.handleError}
                                                          onScan={this.handleScan}
                                                        />
                                                        <input
                                                          type="text"
                                                          id="actualBarcode"
                                                          placeholder="Barcode"
                                                          className="barcode-input"
                                                          onChange={e => this.handChangeBarCode('actualBarcode', e.target.value)}
                                                          value={this.state.actualBarcode}
                                                        />
                                                       </Grid> */}
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={() =>
                                                        this.handleUpdateRelatedBarcodeStatus(
                                                          tempData.orderNo,
                                                          tempData.orderItemId,
                                                          this.state
                                                            .actualBarcode,
                                                          this.state
                                                            .selectedRelatedBarcodeStatus
                                                            
                                                        )
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Update
                                                    </Button>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseDialog
                                                      }
                                                      color="secondary"
                                                      variant="contained"
                                                    >
                                                      Close
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>


                                                <Dialog
                                                  open={this.state.openMissingDialig}
                                                  onClose={
                                                    this.handleCloseMissingDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" ADD Missing Barcode "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent>
                                                  <Grid container justify="center">
            <Grid item xs={12}>
              <img src={barcodeIcon} alt="barcode" className="barcode-icon" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BarcodeReader
                onError={this.handleError}
                onScan={this.handleScan}
              />
              <input
                type="text"
                id="barcode"
                placeholder="Missing Barcode"
                className="barcode-input"
                onChange={ (event) => this.handlechangeAlterBarcode(event.target.value)}
                // value={barcode}
              />
              <br/>
               
            </Grid>
          </Grid>
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseMissingDialog
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Add
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>
                                                <Dialog
                                                  open={this.state.openAlterDialog}
                                                  onClose={
                                                    this.handleCloseAlterDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" ADD Alter Barcode "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent>
                                                  <Grid container justify="center">
            <Grid item xs={12}>
              <img src={barcodeIcon} alt="barcode" className="barcode-icon" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BarcodeReader
                onError={this.handleError}
                onScan={this.handleScan}
              />
              <input
                type="text"
                id="barcode"
                placeholder="Alter Barcode"
                className="barcode-input"
                onChange={ (event) => this.handlechangeAlterBarcode(event.target.value)}
                // value={barcode}
              />
            </Grid>
          </Grid>
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseAlterDialog
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Add
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>



                                                <Dialog
                                                 PaperProps={{style: {overflowY: 'visible',height:'500px'}}}
                                               
                                                  open={this.state.opencolorDialog}
                                                  onClose={
                                                    this.handleCloseColorDialog
                                                  }
                                                  fullWidth
                                                >
                                                  <DialogTitle className="dialog">
                                                    {" Add New Color "}
                                                  </DialogTitle>

                                                  <br />
                                                  <br />
                                                  <DialogContent style={{ overflowY: 'visible' }}>
                                                  <Grid container justify="center">
            
            <Grid item xs={12} sm={6}>
               <FilterSelect
                    options={this.state.colorOptions}
                    onChange={selected =>
                      this.handleChangeColor("COLOR", selected.label)
                    }
                  />
              {/* <input
                type="text"
                id="barcode"
                placeholder="Alter Barcode"
                className="barcode-input"
                onChange={ (event) => this.handlechangeAlterBarcode(event.target.value)}
                // value={barcode}
              /> */}
            </Grid>
          </Grid>
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={
                                                        this.handleCloseColorDialog
                                                      }
                                                      color="primary"
                                                      variant="contained"
                                                    >
                                                      Add
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>

                                          <Grid
                                            item
                                            className="upload-button-wrapper"
                                            xs={2}
                                          >
                                            <span className="label">
                                              <AddPhotoAlternate />
                                            </span>
                                            <input
                                              type="file"
                                              id="recepietImg"
                                              accept="image/*"
                                              className="upload-box"
                                              onChange={e =>
                                                this.handleImageChange(
                                                  e,
                                                  row.OrderNo
                                                )
                                              }
                                            />

                                            <Dialog
                                              open={this.state.open}
                                              onClose={this.handleClose}
                                            >
                                              <div>
                                                <img
                                                  src={this.state.recepietImg}
                                                  style={{ width: "100%" }}
                                                />
                                              </div>

                                              <DialogActions
                                                style={{ flexFlow: "column" }}
                                              >
                                                <Button
                                                  color="primary"
                                                  variant="outlined"
                                                  onClick={
                                                    this.handleUploadReciept
                                                  }
                                                >
                                                  Upload
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                            {/* For Show Img */}
                                            <Dialog
                                              open={showImg}
                                              onClose={this.handleCloseShowImg}
                                              fullWidth
                                              maxWidth="md"
                                            >
                                              {!Showerror && (
                                                <div className="view-image-container">
                                                  {this.state.imagesName.map(
                                                    (name, index) => (
                                                      <div key={index}
                                                        style={{
                                                          position: "relative"
                                                        }}
                                                      >
                                                        {/* <RotateLeftIcon
                                                                  className='image-rotate'
                                                                 id={index}
                                                                 onClick={()=> this.rotateleft(index)}
                                                                 /> */}
                                                        <img
                                                          className={
                                                            this.state.isRotate
                                                              ? "rotate"
                                                              : ""
                                                          }
                                                          style={{
                                                            width: "90%",
                                                            position:
                                                              "relative",
                                                            transform: `rotate(${
                                                              this.state
                                                                .selectedIndex ===
                                                              index
                                                                ? this.state
                                                                    .rotation
                                                                : 0
                                                            }deg)`
                                                          }}
                                                          src={`${process.env.REACT_APP_API_ENDPOINT}/api/image/orders/receipt?imageName=${name.Size}`}
                                                        />
                                                        <RotateRightIcon
                                                          onClick={() =>
                                                            this.rotate(index)
                                                          }
                                                          className="image-rotate"
                                                        />
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                              {Showerror && (
                                                <DialogContent>
                                                  <Grid
                                                    container
                                                    justify="center"
                                                  >
                                                    <h6>No Image Avaliable</h6>
                                                  </Grid>
                                                </DialogContent>
                                              )}
                                              <Grid container justify="center">
                                                <DialogActions>
                                                  <Button
                                                    onClick={
                                                      this.handleCloseShowImg
                                                    }
                                                    color="default"
                                                    variant="contained"
                                                  >
                                                    Close
                                                  </Button>
                                                </DialogActions>
                                              </Grid>
                                            </Dialog>
                                          </Grid>
                                          <Button
                                            onClick={() =>
                                              this.handleViewReceiptImg(
                                                row.OrderNo
                                              )
                                            }
                                          >
                                            <img
                                              style={{
                                                height: "30px",
                                                marginBottom: "5px"
                                              }}
                                              src={receiptImg}
                                            />
                                          </Button>
                                        </TableCell>
                                        <TableCell>{row.OrderNo}</TableCell>
                                        <TableCell>
                                          <img className='item-imagee' style={{borderRadius:'5px'}} height="94px" width='121px' src={`${process.env.REACT_APP_API_ENDPOINT}/api/images/{imagename}?imagename=${row.ImageURL}`} alt="Barcode Image"/>
                                        </TableCell>
                                            <TableCell>{row.ReferenceNumber}</TableCell>
                                        <TableCell align="right">
                                          {/* <Grid direction='column'>
                                          {
                                          row.Barcodes.map( barcode => (<Grid> <span>{barcode.Barcode}</span>:<span> {barcode.Status}</span>  </Grid>))
                                          }
                                          </Grid> */}
        <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Barcode</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">AltBarcode</TableCell>
            <TableCell align="right">AltColor</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {row.Barcodes.map(barcode => (
            <TableRow>
              <TableCell component="th" scope="row">
                {barcode.Barcode}
              </TableCell>
              <TableCell align="right">{barcode.Status}</TableCell>
          <TableCell align="right">{
            (barcode.AltBarcode !==''&& barcode.AltBarcode !== undefined ) && barcode.AltBarcode
          }</TableCell>
          <TableCell>{barcode.AltColor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
                                        </TableCell>
                                      <TableCell>{row.Color}</TableCell>
                                      <TableCell>{row.Category}</TableCell>
                                      <TableCell align="right">{row.ICGSize}</TableCell>
                                      <TableCell align="right">{row.OnlineSize}</TableCell>
                                        <TableCell align="right">
                                          {" "}
                                          {row.Quantity}
                                        </TableCell>
                                        {/* {getRole() === "StoreSupervisor" && (
                                          <TableCell align="center">
                                            {this.state.isEditing &&
                                            this.state.EditIndex === index &&
                                            this.state.expandedIndex ===
                                              index1 ? (
                                              <FilterSelect
                                                options={OrderItemStatus}
                                                selected={
                                                  selectedOrderItemStatus.label
                                                }
                                                onChange={this.handleChange(
                                                  "selectedOrderItemStatus",
                                                  index
                                                )}
                                              />
                                            ) : (
                                              <TextField
                                                id="outlined-name"
                                                margin="normal"
                                                value={row.Status}
                                                inputProps={{
                                                  readOnly: true,
                                                  style: { textAlign: "center" }
                                                }}
                                              ></TextField>
                                            )}
                                          </TableCell>
                                        )} */}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[6, 10, 15, 20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </div>
    );
  }
}
