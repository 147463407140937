import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { getQuestions } from '../../actions/80/QuestionsAPIs';
import QuestionCard from '../../components/Questions/QuestionCard/QuestionCard';
import MessagesNotifications from '../../components/MessagesNotifications/MessagesNotifications';
import './Questions.scss';

class Questions extends Component {
  constructor() {
    super();
    this.state = {
      questionsList: [],
      notificationMsg: '',
      showCreate: false,
    };
  }

  componentDidMount() {
    getQuestions().then(res => this.setState({
      questionsList: res,
    }))
      .catch(() => {
        this.setState({
          notificationMsg: 'getQuestions',
        });
      });
  }

  handleShowCreate = () => {
    this.setState(prevState => ({
      showCreate: !prevState.showCreate,
    }));
  }

  render() {
    const { questionsList, showCreate, notificationMsg } = this.state;
    return (
      <Grid container justify='center'>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid item xs={12} sm={9}>
          <Paper className='questions-paper'>
            <p className='title'>QUESTIONS</p>
            <Grid container justify='center'>
              <Button
                color='primary'
                onClick={this.handleShowCreate}
              >
                <span>+ ADD NEW QUESTION</span>
              </Button>
            </Grid>
            {showCreate && (
            <Grid container justify='center' >
              <Grid item xs={6}>
                <textarea />
                <Button
                  color='primary'
                  variant='contained'
                  fullWidth
                  onClick={this.handleshowCreate}
                >
                  <span className='button-text'>ADD</span>
                </Button>
              </Grid>
            </Grid>
            )}
            {questionsList.map(question => (
              <Grid container justify='center' key={question.Id}>
                <QuestionCard question={question} />
              </Grid>
            ))}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default Questions;
