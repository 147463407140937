import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Edit from "@material-ui/icons/Edit";
import CameraAlt from "@material-ui/icons/CameraAlt";
import Brush from "@material-ui/icons/Brush";
import Keyboard from "@material-ui/icons/Keyboard";
import Rwabi from "../../assests/images/Rwabi.png";
import { getRole } from "../../services/share/DataService";
import CustomizedMenus from "../dropdown/dropdown.component";
import "./NavBar.scss";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
      isDrowerOpen: false
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  // to open the drawer after pressing the menu button.
  openDrawer = () => {
    this.setState({
      isDrowerOpen: true
    });
  };

  // to close the drawer.
  closeDrawer = () => {
    this.setState({
      isDrowerOpen: false
    });
  };

  renderList = role => {
    const { onLogout } = this.props;
    switch (role) {
      case "Admin":
        return (
          <div>
            {/* <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Data Source Control
            </NavLink> */}
            {/* <NavLink
              exact
              to="/Validation"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Product Control
            </NavLink> */}
            {/* <NavLink
              exact
              to="/photographer"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Image Control
            </NavLink> */}
            <NavLink
              exact
              to="/userscontrol"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Users Control
            </NavLink>

            {/* <NavLink
              exact
              to="/sectionsControl"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Section Control
            </NavLink> */}
            {/* <NavLink
              exact
              to="/shopifyorders"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Orders Control
            </NavLink> */}
            {/* <NavLink
              exact
              to="/Locations"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Locations
            </NavLink> */}
            <NavLink
              exact
              to="/StoreUsers"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Store Users
            </NavLink>
            {/* <NavLink
              exact
              to="/ProductCreation"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              New Product 
             </NavLink> */}
            {/* <NavLink
              exact
              to="/AdminControl"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Settings
            </NavLink> */}

            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "StoreSupervisor":
        return (
          <div>
            <NavLink
              exact
              to="/Settings"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              QShops
            </NavLink>

            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case 'EditProductManager':
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Data Source Control
            </NavLink>
            <NavLink
              exact
              to="/Validation"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Product Control
            </NavLink>
            <NavLink
              exact
              to="/photographer"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Image Control
            </NavLink>

            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "Photographer":
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Image Control
            </NavLink>

            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "StoreManager":
        return (
          <div>
            <NavLink
              exact
              to="/Store"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Store
            </NavLink>

            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "QA":
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Product Control
            </NavLink>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "SrAccountant":
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Senior Accountant
            </NavLink>
            <NavLink
              exact
              to="/inventorycontrol"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Inventory Control
            </NavLink>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "Accountant":
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Inventory Control
            </NavLink>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "QCenterManager":
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Resturants
            </NavLink>
            <NavLink
              exact
              to="/funfactory"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Fun Factory
            </NavLink>

            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "QCenterTransportation":
        return (
          <div>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "Order":
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Orders Control
            </NavLink>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "WarehouseManager":
        return (
          <div>
            <NavLink
              exact
              to="/shopifyorders"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Orders Control
            </NavLink>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "QhubRunner":
        return (
          <div>
            <NavLink
              exact
              to="/shopifyorders"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Orders Control
            </NavLink>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );
      case "MarketingAdmin":
        return (
          <div>
            <NavLink
              exact
              to="/MarketingAdmin"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Marketing Control
            </NavLink>
            <div className="dropdown">
              <CustomizedMenus onlogout={onLogout} />
            </div>
          </div>
        );

      default:
        return (
          <div>
            <NavLink
              exact
              to="/"
              className="horNavbar-link"
              activeClassName="active-link"
            >
              Login
            </NavLink>
          </div>
        );
    }
  };

  renderSideList = role => {
    switch (role) {
      case "Admin":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/" className="navbar-link">
                <ListItem button key="manager">
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText primary="Data Source Control" />
                </ListItem>
              </NavLink>
              <NavLink to="/Validation" className="navbar-link">
                <ListItem button key="dataentry">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Product Control" />
                </ListItem>
              </NavLink>
              <NavLink to="/photographer" className="navbar-link">
                <ListItem button key="photographer">
                  <ListItemIcon>
                    <CameraAlt />
                  </ListItemIcon>
                  <ListItemText primary="Image Control" />
                </ListItem>
              </NavLink>
              <NavLink to="/userscontrol" className="navbar-link">
                <ListItem button key="userscontrol">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary=" Users Control" />
                </ListItem>
              </NavLink>
              <NavLink to="/sectionsControl" className="navbar-link">
                <ListItem button key="sectionsControl">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Section Control" />
                </ListItem>
              </NavLink>
              <NavLink to="/shopifyorders" className="navbar-link">
                <ListItem button key="shopifyorders">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Orders Control" />
                </ListItem>
              </NavLink>

              <NavLink to="/Store" className="navbar-link">
                <ListItem button key="Stores">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Stores" />
                </ListItem>
              </NavLink>
              <NavLink to="/Locations" className="navbar-link">
                <ListItem button key="Locations">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Locations" />
                </ListItem>
              </NavLink>

              <NavLink to="/AdminControl" className="navbar-link">
                <ListItem button key="AdminControl">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      case "Photographer":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/" className="navbar-link">
                <ListItem button key="photographer">
                  <ListItemIcon>
                    <CameraAlt />
                  </ListItemIcon>
                  <ListItemText primary="Image Control" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );

      case "StoreSupervisor":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/Settings" className="navbar-link">
                <ListItem button key="QShops">
                  <ListItemIcon>
                    <CameraAlt />
                  </ListItemIcon>
                  <ListItemText primary="QShops" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );

      case "QA":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/" className="navbar-link">
                <ListItem button key="dataentry">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Product Control" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      case "QCenterManager":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/" className="navbar-link">
                <ListItem button key="resturants">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Resturants" />
                </ListItem>
              </NavLink>
              <NavLink to="/funfactory" className="navbar-link">
                <ListItem button key="funfactory">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Funfactory" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      case "Order":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/" className="navbar-link">
                <ListItem button key="shopifyorders">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Orders Control" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      case "QhubRunner":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/shopifyorders" className="navbar-link">
                <ListItem button key="shopifyorders">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Orders Control" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      case "WarehouseManager":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/shopifyorders" className="navbar-link">
                <ListItem button key="shopifyorders">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Orders Control" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      case "StoreManager":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/Store" className="navbar-link">
                <ListItem button key="Store">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Store" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      case "MarketingAdmin":
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/MarketingAdmin" className="navbar-link">
                <ListItem button key="MarketingAdmin">
                  <ListItemIcon>
                    <Keyboard />
                  </ListItemIcon>
                  <ListItemText primary="Marketing Control" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
      default:
        return (
          <div className="navbar-list">
            <List onClick={this.closeDrawer}>
              <NavLink to="/" className="navbar-link">
                <ListItem button key="logoin">
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary="Login" />
                </ListItem>
              </NavLink>
            </List>
          </div>
        );
    }
  };

  render() {
    const { onLogout } = this.props;
    const { isDrowerOpen, width } = this.state;
    const { isAuthenticated } = this.props;
    let role;
    if (isAuthenticated) {
      role = getRole();
    }
    const list = this.renderList(role);
    const sideList = this.renderSideList(role);
    return (
      <div>
        <div className="navbar-root">
          <Toolbar>
            <NavLink to="/">
              <img src={Rwabi} alt="Rawabi" className="Rawabi-logo" />
            </NavLink>
            <div className="hor-navbar">
              {width >= 800 && <div className="hor-navbar-content">{list}</div>}
              {width < 800 && (
                <div className="hor-navbar-content">
                  <IconButton
                    aria-label="Menu"
                    onClick={this.openDrawer}
                    className="menu-button"
                  >
                    <MenuIcon />
                  </IconButton>
                  {localStorage.getItem("access_token") && (
                    <div className="dropdown">
                      <CustomizedMenus onlogout={onLogout} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Toolbar>
          <Drawer open={isDrowerOpen} onClose={this.closeDrawer}>
            {sideList}
          </Drawer>
        </div>
      </div>
    );
  }
}

export default NavBar;
