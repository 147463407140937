import React from 'react';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';

const MiniCategories = ({
  miniCategories,
  selectedSubCategory,
  selectedMiniCategory,
  newMiniCategory,
  newMiniCategoryArabic,
  editMiniCategory,
  editMiniCategoryArabic,
  showEditMiniCategory,
  onChange,
  onCreate,
  onSelect,
  onDelete,
  onUpdate,
  onShowEdit,
}) => (
  <Grid item sm={4} md={3} className='padding-left'>
    <p className='title'>MINI CATEGORIES</p>
    {miniCategories.map(miniCategory => (
      <Grid container className='padding-left'  key={miniCategory.Id}>
        <Grid item xs={miniCategory.Id === selectedMiniCategory.Id ? 8 : 12}>
          <button
            type='submit'
            className={miniCategory.Id === selectedMiniCategory.Id ? 'selected-list-button' : 'list-button'}
            key={miniCategory.Id}
            onClick={() => onSelect('MINICATEGORY', miniCategory)}
          >{miniCategory.Name}
          </button>
        </Grid>
        {miniCategory.Id === selectedMiniCategory.Id
         && (
         <Grid item xs={2}>
           <button
             type='submit'
             className='delete-button'
             onClick={() => onDelete('MINICATEGORY', miniCategory)}
           >
             <Close />
           </button>
         </Grid>
         )}
        {miniCategory.Id === selectedMiniCategory.Id
            && (
            <Grid item xs={2}>
              <button
                type='submit'
                className='edit-button'
                onClick={() => onShowEdit('MINICATEGORY')}
              >
                <EditIcon />
              </button>
            </Grid>
            )}
        {miniCategory.Id === selectedMiniCategory.Id && showEditMiniCategory
            && (
              <Grid container className='edit-box'>
                <Grid item xs={12}>
                  <p>Category Name:</p>
                  <input
                    id='editMiniCategory'
                    placeholder='sub-category english name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editMiniCategory}
                  />
                  <input
                    id='editMiniCategoryArabic'
                    placeholder='sub-category arabic name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editMiniCategoryArabic}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => onUpdate('MINICATEGORY')}
                  >
                    <span className='button-text'>Edit</span>
                  </Button>
                </Grid>
              </Grid>
            )}
      </Grid>
    ))}
    {!selectedSubCategory
        && <p>No sub category is selected.</p>}
    {miniCategories.length === 0 && selectedSubCategory
        && <p>No mini categories.</p>}
    {selectedSubCategory
        && (
          <div>
            <input
              id='newMiniCategory'
              placeholder='New mini-category english name'
              className='sections-control-input'
              onChange={onChange()}
              value={newMiniCategory}
            />
            <input
              id='newMiniCategoryArabic'
              placeholder='New mini-category arabic name'
              className='sections-control-input'
              onChange={onChange()}
              value={newMiniCategoryArabic}
            />
            <Button
              disabled={!(newMiniCategory || newMiniCategoryArabic)}
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => onCreate('MINICATEGORY')}
            >
              <span className='button-text'>Add Mini Category</span>
            </Button>
          </div>
        )}
  </Grid>
);

export default MiniCategories;
