import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TableIcon from "../../../assests/images/tableIcon.png";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import chartimage from "../../../assests/images/chart1.png";
import PieChartIcon from "@material-ui/icons/PieChart";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Example from "../charts/PieChart/piechart.component";
import Barchart from "../charts/barchart/barchart.component";
import Linechart from "../charts/lineChart/linechart.component";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CustomizedMenus from "../../dropdown/dropdown.component";
import { logout } from "../../../services/auth";
import Fab from "@material-ui/core/Fab";
import "./drawer.styles.scss";
import SalesTable from "../tables/salestables";
import { Grid } from "@material-ui/core";
import createHistory from "history/createBrowserHistory";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const showTables = () => {};

  const showPieChart = () => {};

  const handleLogout = () => {
    logout();
    const history = createHistory();
    history.go(0);
  };

  const drawer = (
    <div className="drawer">
      <div className={classes.toolbar} />

      <Divider />
      <List>
        <ListItem button key="00">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="DashBoard" />
        </ListItem>

        <ListItem button key="0" disabled>
          <ListItemIcon>
            <FaceIcon />
          </ListItemIcon>
          <ListItemText primary="CurrnetUser" style={{ color: "red" }} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key="01">
          <ListItemIcon button>
            {" "}
            <img src={TableIcon} alt="" srcset="" />{" "}
          </ListItemIcon>
          <ListItemText primary="Tables" />
        </ListItem>

        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {" "}
              <img src={chartimage} alt="" srcset="" /> <span>Charts</span>{" "}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container justify="center">
              <Grid item>
                PieChart
                <IconButton onClick={showPieChart}>
                  <PieChartIcon />
                </IconButton>
              </Grid>
              <Grid item>
                Equalizer
                <IconButton>
                  {" "}
                  <EqualizerIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            DashBoard
            <Grid className="dash-menu">
              <CustomizedMenus customcolor="white" onlogout={handleLogout} />
            </Grid>
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Grid
          container
          justify="center"
          spacing={9}
          className="main-container"
          style={{ margin: "0 auto" }}
        >
          <Grid item xs={3}>
            <Paper className="header-information">
              <div className="line"></div>
              <spn className="total">
                {" "}
                Total this month : &nbsp; &nbsp; &nbsp; 10000$
              </spn>
              <div className="total-box">
                {" "}
                <span>Sales</span>
                <AttachMoneyIcon />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className="header-information">
              <div className="line"></div>
              <spn className="total">
                {" "}
                Total this month : &nbsp; &nbsp; &nbsp; 10000$
              </spn>
              <div className="total-box groupb">
                {" "}
                <span>Sales</span>
                <AttachMoneyIcon />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className="header-information">
              <div className="line"></div>
              <spn className="total">
                {" "}
                Total this month : &nbsp; &nbsp; &nbsp; 10000$
              </spn>
              <div className="total-box groupa">
                {" "}
                <span>Sales</span>
                <AttachMoneyIcon />
              </div>
            </Paper>
          </Grid>
        </Grid>
        <div />
        <Grid container justify="center" className="tablecon">
          <Grid item xs={3} sm={8} md={11}>
            {" "}
            <SalesTable />
          </Grid>
        </Grid>
        <br />
        <Grid container justify="center" spacing={6} className="sales">
          <Grid
            item
            justify="center"
            xs={4}
            sm={6}
            md={4}
            className="piechart-container"
          >
            <Example />
          </Grid>
          <Grid
            item
            justify="center"
            xs={4}
            sm={6}
            md={4}
            className="bar-container"
          >
            <Barchart />
          </Grid>

          <Grid
            item
            justify="center"
            xs={4}
            sm={6}
            md={4}
            className="linechart-container"
          >
            <Linechart />
          </Grid>
        </Grid>
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
          dolor purus non enim praesent elementum facilisis leo vel. Risus at
          ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum
          quisque non tellus. Convallis convallis tellus id interdum velit
          laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed
          adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
          integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate
          eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo
          quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat
          vivamus at augue. At augue eget arcu dictum varius duis at consectetur
          lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien
          faucibus et molestie ac.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
          ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
          elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse
          sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat
          mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
          risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas
          purus viverra accumsan in. In hendrerit gravida rutrum quisque non
          tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
          morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  )
};

export default ResponsiveDrawer;
