import React from 'react';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import './Color.scss'
const Colors = ({
  colors,
  newColor,
  editColor,
  showEditColor,
  selectedColor,
  onChange,
  onCreate,
  onSelect,
  onDelete,
  onUpdate,
  onShowEdit,
}) => (
  <Grid item xs={12}>
    <p className='title colors-title'>COLORS</p>
    <Grid container>
      {colors.map(color => (
        <Grid item key={color.Id} xs={12} sm={4} className='padding-left'>
          <Grid container >
            <Grid item xs={color.Id === selectedColor.Id ? 10 : 12}>
              <button
                type='submit'
                className={color.Id === selectedColor.Id ? 'selected-list-button' : 'list-button'}
                onClick={() => onSelect('COLOR', color)}
              >{color.Name}
              </button>
            </Grid>
            {color.Id === selectedColor.Id
            && (
            <Grid item xs={2}>
              <button
                type='submit'
                className='edit-button'
                onClick={() => onShowEdit('COLOR')}
              >
                <EditIcon />
              </button>
            </Grid>
            )}
            {showEditColor && color.Id === selectedColor.Id && (

              <Grid container  className='edit-box '>
                <Grid item xs={12}>
                  <p>Color:</p>
                  <input
                    id='editColor'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editColor}
                  />
                  <button
                    type='submit'
                    className='done-button'
                    onClick={() => onUpdate('COLOR')}
                  >
                    <DoneIcon />
                  </button>
                  <button
                    type='submit'
                    className='delete-button'
                    onClick={() => onDelete('COLOR', color)}
                  >
                    <span>DELETE</span>
                  </button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sm={4}>
        <input
          id='newColor'
          placeholder='New Color'
          className='sections-control-input'
          onChange={onChange()}
          value={newColor}
        />
        <Button
          disabled={!newColor}
          type='submit'
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => onCreate('COLOR')}
        >
          <span className='button-text'>Add Color</span>
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

export default Colors;
