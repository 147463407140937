import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import { login, SetNewPass } from '../../services/auth';
import './LoginForm.scss';
import DilaogForResetPass from './DilaogForResetPass';
import MessagesNotifications from '../MessagesNotifications/MessagesNotifications';

class LoginForm extends Component {
  state = {
    loginErr: false,
    userName: '',
    open: false, 
    setOpen: false,
    isValidUserName: false,
    notificationMsg: '',
  };

  handleClickOpen = () => {
    this.setState({
      setOpen: true,
    })
  }
   handleClose = () => {
    this.setState({
      setOpen: false,
    })
  };
  handleInputChange = event => {
    this.setState({
      isValidUserName: false,
      userName: event.target.value
    })
  }
  
  handleSendNewPass = () => {
    const {userName} = this.state;
    SetNewPass(userName).then(res => {
      if(res.StatusCode === 400 ){
        this.setState({
          isValidUserName: true,
        })
      }else{
        this.setState({
          setOpen: false,
          isValidUserName: false,
          notificationMsg: 'EmailPassRecieve',
        })
      }
    })
  }
 
  render() {
    const { loginErr, open, setOpen, isValidUserName, notificationMsg } = this.state;
    return (
      <Formik
        initialValues={{
          userName: '',
          password: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          const { setUser } = this.props;
          login(values.userName, values.password)
            .then(() => {
              setUser();
              setSubmitting(false);
            })
            .catch(() => {
              this.setState({
                loginErr: true,
              });
              setSubmitting(false);
            });
        }}
        validationSchema={Yup.object().shape({
          userName: Yup.string().required('Required'),
          password: Yup.string().required('Required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <div>
                 {notificationMsg && (
                    <MessagesNotifications message={notificationMsg} />
                  )}
              <form className='login-form' onSubmit={handleSubmit}>
                {loginErr
                && (
                  <Grid
                    container
                    justify='center'
                    className='login-faild'
                  >
                    <p className='login-failed'>Login Faild!</p>
                  </Grid>
                )}
                {errors.userName
                && touched.userName
                && <span className='login-error'>{errors.userName}</span>}
                <Grid container justify='center'>
                  <Grid item xs={2}>
                    <AccountCircle className='login-icon' />
                  </Grid>
                  <Grid item xs={10}>
                    <input
                      id='userName'
                      className='login-input'
                      placeholder='User Name'
                      type='text'
                      value={values.userName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                {errors.password
                && touched.password
                && <span className='login-error'>{errors.password}</span>}
                <Grid container justify='center'>
                  <Grid item xs={2}>
                    <VpnKey className='login-icon' />
                  </Grid>
                  <Grid item xs={10}>
                    <input
                      id='password'
                      className='login-input'
                      placeholder='Password'
                      type='password'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container  justify='center' spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      fullWidth
                      disabled={isSubmitting}
                    >
                      <span className='button-text'>LOGIN</span>
                    </Button>
                  </Grid>
           
                </Grid>
              </form>
              <DilaogForResetPass
              handleInputChange={this.handleInputChange}
              handleSendNewPass={this.handleSendNewPass}
              handleClickOpen={this.handleClickOpen}
              handleClose={this.handleClose}
              open={open}
              setOpen={setOpen}
              isValidUserName={isValidUserName}
              ></DilaogForResetPass>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default LoginForm;
