import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import OrdersShow from "../../components/WareHouseManagerOrders/OrderShow/ViewOrder";
import OrderSearchForm from "../../components/WareHouseManagerOrders/OrderForm/OrderSearchForm";
import {
  getOrders,
  getOrderByOrderNumber,
  getOrdersById,
  SendNewRunnerRunnerStatus,
  getItemByBarCode,
  SendNewWarehouseManagerStatus
} from "../../actions/OrderAPI";
import InfoBox from "../../components/InfoBox/InfoBox";
import GreenLoader from "../../components/GreenLoader/GreenLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Close from "@material-ui/icons/Close";
import FilterSelect from "../../components/FilterSelect/FilterSelect";
import EditIcon from "@material-ui/icons/Edit";
import Done from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BarcodeReader from "react-barcode-reader";
import barcodeIcon from "../../assests/images/Barcode.png";
import Paper from "@material-ui/core/Paper";
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

let filterData;

const OrderItemStatusForWarehouseManager = [
  { label: "Packing Area", value: 0 }
];

const OrderStatusForWarehouseManager = [
  { label: "Dispatched", value: 0 },
  { label: "Dispatch Area", value: 0 }
];

class WareHouseOrders extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      data: [],
      ShowFull: false,
      page: 0,
      count: 0,
      rowsPerPage: 6,
      expanded: "",
      OrderNumber: "",
      OrderID: "",
      open: false,
      OrderInfo: "",
      OrderId: "",
      ShowDropDownOrderStatus: false,
      selectedOrderStatus: { label: "Select", value: null },
      selectedOrderItemStatus: { label: "Select", value: null },
      barcode: "",
      ResultsFromBarCode: "",
      OrderNo: ""
    };
  }

  componentDidMount() {}

  handleSearch = () => {
    const { OrderNumber, rowsPerPage } = this.state;

    this.setState({
      isLoading: true,
      page: 0,
      ShowFull: false
    });

    getOrderByOrderNumber(OrderNumber)
      .then(res => {
        if(res.Items.length > 0 ){
          this.setState({
            data: res.Items,
            OrderID: res["OrderId"],
            OrderNo: res["OrderNo"],
            isLoading: false
          });
        }
        if(res.Items.length === 0 ){
          this.setState({
            ShowFull: true,
            isLoading: false
          });
        }
      })
      .catch(() => {
        this.setState({
          notificationMsg: "search",
          ShowFull: true,
          isLoading: false
        });
      });
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage, page, data, OrderNumber } = this.state;
    this.setState({
      page: newPage,
      isLoading: newPage * rowsPerPage >= data.length
    });
    if (newPage > page && newPage * rowsPerPage >= data.length) {
      getOrders(filterData, OrderNumber,newPage, rowsPerPage).then(res => {
        const newData = data;
        res.Results.map(item => newData.push(item));
        this.setState({
          data: newData,
          isLoading: false
        });
      });
    }
  };

  handleChange = id => selectedValue => {
    if (id === "selectedOrderStatus" || id === "selectedOrderItemStatus") {
      this.setState({
        [id]: selectedValue.label
      });
    } else {
      this.setState({
        [id]: selectedValue.target.value
      });
    }
  };

  handleChangeBarCode = (type, selectedBarCode) => {
    const { OrderNo } = this.state;
    switch (type) {
      case "BARCODE":
        this.setState({
          barcode: selectedBarCode
        });
        getItemByBarCode(OrderNo, selectedBarCode).then(res => {
          if (res !== null) {
            this.setState({
              open: true,
              ResultsFromBarCode: res
            });
          }
        });
    }
  };

  handleScan = barcode => {
    const { OrderNo } = this.state;
    this.setState({
      notificationMsg: ""
    });
    getItemByBarCode(OrderNo, barcode).then(res => {
      if (res !== null) {
        this.setState({
          open: true,
          ResultsFromBarCode: res
        });
      }
    });
  };

  handleError = err => {
    this.setState({
      notificationMsg: "BarcodeReader"
    });
  };

  handleClose = type => {
    this.setState({
      open: false
    });
  };

  handleExpand = panel => (event, expanded) => {
    const { OrderNo } = this.state;
    this.setState({
      expanded: expanded ? panel : false
    });
    if (panel === "updateOrderStatus") {
      getOrdersById(OrderNo).then(res => {
        this.setState({
          OrderInfo: res
        });
      });
    }
  };

  handleDateFormat = date => {
    const Newdate = new Date(Date.parse(date));
    return (
      Newdate.getFullYear() +
      "/" +
      (Newdate.getMonth() + 1) +
      "/" +
      Newdate.getUTCDate()
    );
  };

  EditOrderStatus = (orderid, index) => {
    this.setState({
      ShowDropDownOrderStatus: true,
      enableUpdate: false,
      notificationMsg: ""
    });
  };

  handleSaveOrderTrackingStatus = () => {
    const { OrderNo, selectedOrderStatus, OrderInfo } = this.state;
    this.setState({
      notificationMsg: ""
    });
    SendNewWarehouseManagerStatus("#" + OrderNo, selectedOrderStatus).then(
      res => {
        if(res == null){
          this.setState({
            notificationMsg: "OrderStatusNotUpdated",
            ShowDropDownOrderStatus: false
          });
        }else{
          OrderInfo["TrackingStatus"] = selectedOrderStatus;
          this.setState({
            notificationMsg: "OrderUpdated",
            ShowDropDownOrderStatus: false
          });
        }
      }
    );
  };

  DisableUpdateOrder = () => {
    this.setState({
      ShowDropDownOrderStatus: false
    });
  };

  handleEditItemUsingBarCode = () => {
    const {
      OrderNo,
      ResultsFromBarCode,
      selectedOrderItemStatus,
      data
    } = this.state;
    this.setState({
      notificationMsg: ""
    });

    const seletedItem = data.find(x => x.Id === ResultsFromBarCode["Id"]);

    SendNewRunnerRunnerStatus(
      "#" + OrderNo,
      ResultsFromBarCode["Barcode"],
      selectedOrderItemStatus
    ).then(res => {
      if(res === null){
        this.setState({
          notificationMsg: 'OrderItemStatusNotUpdated'
        })
      }else{
        seletedItem["TrackingStatus"] = selectedOrderItemStatus;
        this.setState({
          notificationMsg: "ItemUpdated",
          open: false
        })
      }
    }
    );
  };
  render() {
    const {
      data,
      count,
      isLoading,
      ShowFull,
      rowsPerPage,
      page,
      expanded,
      OrderNumber,
      OrderInfo,
      ShowDropDownOrderStatus,
      selectedOrderStatus,
      selectedOrderItemStatus,
      open,
      ResultsFromBarCode,
      notificationMsg,
      OrderNo
    } = this.state;
    return (
      <Grid container justify="center">
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <ExpansionPanel
              expanded={expanded === "searchPanel"}
              onChange={this.handleExpand("searchPanel")}
              className="data-entry-expand"
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="data-entry-expand-title">Search</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <OrderSearchForm
                  onSearch={this.handleSearch}
                  onChange={this.handleChange}
                  OrderNumber={OrderNumber}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8}>
          {data.length > 0 && !isLoading && (
            <ExpansionPanel
              expanded={expanded === "updateOrderStatus"}
              onChange={this.handleExpand("updateOrderStatus")}
              className="data-entry-expand"
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="data-entry-expand-title">Update Order</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Actions</TableCell>
                      <TableCell>Order NO.</TableCell>
                      <TableCell style={{ minWidth: "275px" }}>
                        Tracking Status
                      </TableCell>
                      <TableCell>ProcessedAt</TableCell>
                      <TableCell>Total Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className="table-cell">
                        {!ShowDropDownOrderStatus && (
                          <Button onClick={() => this.EditOrderStatus()}>
                            <EditIcon />
                          </Button>
                        )}
                        {ShowDropDownOrderStatus && (
                          <Button onClick={() => this.DisableUpdateOrder()}>
                            <Close />
                          </Button>
                        )}
                        {ShowDropDownOrderStatus && (
                          <Button
                            color="primary"
                            onClick={() => this.handleSaveOrderTrackingStatus()}
                          >
                            <Done />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>{OrderInfo.Name}</TableCell>
                      <TableCell>
                        {!ShowDropDownOrderStatus && (
                          <TextField
                            id="outlined-name"
                            margin="normal"
                            value={OrderInfo.TrackingStatus || ""}
                            inputProps={{
                              readOnly: true
                            }}
                          ></TextField>
                        )}

                        {ShowDropDownOrderStatus && (
                          <FilterSelect
                            options={OrderStatusForWarehouseManager}
                            selected={selectedOrderStatus.label}
                            onChange={this.handleChange("selectedOrderStatus")}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {this.handleDateFormat(OrderInfo.ProcessedAt)}
                      </TableCell>
                      <TableCell>{OrderInfo.TotalPrice}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          {data.length > 0 && !isLoading && (
            
                <Paper
                  style={{ width: "100%" }}
                  className="upload-scan-paper"
                  elevation={1}
                >
               
                  <Grid container>
                    <p className="title">ITEM BARCODE</p>
                  </Grid>
                  <Grid container justify="center">
                    <Grid item xs={12}>
                      <img
                        src={barcodeIcon}
                        alt="barcode"
                        className="barcode-icon"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <BarcodeReader
                        onError={this.handleError}
                        onScan={this.handleScan}
                      />
                      <input
                        type="text"
                        id="barcode"
                        placeholder="Barcode"
                        className="barcode-input"
                        onChange={e =>
                          this.handleChangeBarCode("BARCODE", e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>

          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          {data.length > 0 && !isLoading && (
            <OrdersShow
              data={data}
              OrderNo={OrderNo}
              page={page}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangePage={this.handleChangePage}
            />
          )}
        </Grid>
        {isLoading && <GreenLoader />}

        {ShowFull && <InfoBox message="Data Not Available" />}

        <Dialog open={open} fullWidth>
          <DialogTitle
            style={{
              backgroundColor: "#192f38",
              color: "white",
              textAlign: "center"
            }}
            id="responsive-dialog-title"
          >
            {"Update Order Item Status"}
          </DialogTitle>
          <DialogContent style={{ marginTop: "41px" }}>
            <Grid container justify="center">
              <Grid item xs={6} style={{ minHeight: "120px" }}>
                <TextField
                  id="outlined-name"
                  margin="normal"
                  variant="outlined"
                  label="Current Status"
                  value={ResultsFromBarCode.TrackingStatus || ""}
                  inputProps={{
                    readOnly: true
                  }}
                  style={{ width: "100%" }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={6} style={{ minHeight: "120px" }}>
                <FilterSelect
                  options={OrderItemStatusForWarehouseManager}
                  selected={selectedOrderItemStatus.label}
                  onChange={this.handleChange("selectedOrderItemStatus")}
                />
              </Grid>
            </Grid>
            <hr></hr>
          </DialogContent>
          <Grid container justify="center">
            <DialogActions>
              <Button
                onClick={this.handleEditItemUsingBarCode}
                variant="contained"
                color="primary"
                autoFocus
              >
                Update
              </Button>

              <Button
                onClick={this.handleClose}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}

export default WareHouseOrders;
