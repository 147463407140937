import {
    GET,
    PUT,
    POST,
    DELETE,
    DATA_ENTRY_DOWNLOAD,
  } from './actions';


  export const importSizeFile = (file) => {
   const result = POST('/api/Admin/sizeMapper/importexcel', file);
  return result;
};

export const exportSizeFile = () => {
    const fileURL = 'api/Admin/sizeMapper/exporttoexcel';
    const fileType = 'xlsx';
    const fileName = 'Size Mapping';
    return DATA_ENTRY_DOWNLOAD(fileURL, fileName, fileType,null);
  };

  export const getExcludedSKUs = () =>  GET('/api/Admin/autocomplete/excludedSKUs');

  export const getExcludedProductIds = () =>  GET('/api/Admin/autocomplete/excludedproductIdsfromDelete');

  export const updateExcludedSKUs = stinglist => {
  return PUT(`/api/Admin/autocomplete/excludedSKUs?excludedValues=${stinglist}`);
};

export const updateExcludedIDs = stinglist => {
  return PUT(`/api/Admin/autocomplete/excludedproductIdsfromDelete?excludedValues=${stinglist}`);
};


 