import React from 'react';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import './Categories.scss'
const Categories = ({
  categories,
  selectedSection,
  selectedCategory,
  newCategory,
  newCategoryArabic,
  editCategory,
  editCategoryArabic,
  showEditCategory,
  onChange,
  onCreate,
  onSelect,
  onDelete,
  onUpdate,
  onShowEdit,
}) => (
  <Grid item sm={4} md={3} className='padding-left'>
    <p className='title'>CATEGORIES</p>
    {categories.map(category => (
      <Grid container className='padding-left' key={category.Id}>
        <Grid item xs={category.Id === selectedCategory.Id ? 8 : 12}>
          <button
            type='submit'
            className={category.Id === selectedCategory.Id ? 'selected-list-button' : 'list-button'}
            key={category.Id}
            onClick={() => onSelect('CATEGORY', category)}
          >{category.Name}
          </button>
        </Grid>
        {category.Id === selectedCategory.Id && selectedCategory.SubCategories.length === 0
         && (
         <Grid item xs={2}>
           <button
             type='submit'
             className='delete-button'
             onClick={() => onDelete('CATEGORY', category)}
           >
             <Close />
           </button>
         </Grid>
         )}
        {category.Id === selectedCategory.Id
            && (
            <Grid item xs={selectedCategory.SubCategories.length === 0 ? 2 : 4}>
              <button
                type='submit'
                className='edit-button'
                onClick={() => onShowEdit('CATEGORY')}
              >
                <EditIcon />
              </button>
            </Grid>
            )}
        {category.Id === selectedCategory.Id && showEditCategory
            && (
              <Grid container  className='edit-box padding-left'>
                <Grid item xs={12}>
                  <p>Category Name:</p>
                  <input
                    id='editCategory'
                    placeholder='category english name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editCategory}
                  />
                  <input
                    id='editCategoryArabic'
                    placeholder='category arabic name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editCategoryArabic || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => onUpdate('CATEGORY')}
                  >
                    <span className='button-text'>Edit</span>
                  </Button>
                </Grid>
              </Grid>
            )}
      </Grid>
    ))}
    {!selectedSection
        && <p>No section is selected.</p>}
    {categories.length === 0 && selectedSection
        && <p>No categories.</p>}
    {selectedSection
        && (
          <div>
            <input
              id='newCategory'
              placeholder='New category english name'
              className='sections-control-input'
              onChange={onChange()}
              value={newCategory}
            />
            <input
              id='newCategoryArabic'
              placeholder='New category arabic name'
              className='sections-control-input'
              onChange={onChange()}
              value={newCategoryArabic}
            />
            <Button
              disabled={!(newCategory || newCategoryArabic)}
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => onCreate('CATEGORY')}
            >
              <span className='button-text'>Add Category</span>
            </Button>
          </div>
        )}
  </Grid>
);

export default Categories;
