import React from 'react';
import Popup from 'reactjs-popup';
import { Button, Grid } from '@material-ui/core';
import './PopupModal.scss';


export default ({ barcodeList, productName }) => (
  <Grid item xs={6}>
    <Popup
      className='width'
      trigger={(
        <Button
          cursor='pointer'
          size='small'
          color='primary'
        >
      Barcodes
        </Button>
)}
      closeOnDocumentClick
    >
      <div className='popup-modal'>
        <ul className='popup-body'>
          {barcodeList.map((barcode, index) => (
            <li key={index}>{barcode}</li>
          ))}
        </ul>
      </div>
    </Popup>
  </Grid>

);
