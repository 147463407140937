import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage';
import './ImageCropper.scss';

class ImageCropper extends Component {
  state = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: null,
  }

  onCropChange = (crop) => {
    this.setState({
      crop,
    });
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  }

  onZoomChange = (zoom) => {
    this.setState({
      zoom,
    });
  }

  setCroppedImage = async () => {
    const { setImage, imageType } = this.props;
    const { croppedAreaPixels } = this.state;
    const { image } = this.props;
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
    );
    setImage(croppedImage, imageType);
  }

  render() {
    const { image, aspect, cropShape } = this.props;
    const { crop, zoom } = this.state;
    return (
      <div className='App'>
        <div className='crop-container'>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            cropShape={cropShape}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
        </div>
        <div className='controls'>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby='Zoom'
            onChange={(e, zoom) => this.onZoomChange(zoom)}
            classes={{ container: 'slider' }}
          />
          <Button
            onClick={this.setCroppedImage}
            color='primary'
          >
            <span>CROP</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default ImageCropper;
