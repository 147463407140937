import {
    GET,
    PUT,
    POST,
    DELETE,
    getBase64,
    reform,
  } from './Methods';

  export const  getDrivers = (page,pagesize) => {
   return  GET(`/api/Driver/Drivers?pageIndex=${page}&pageSize=${pagesize}`);
  }