import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import CreateUser from '../../components/UsersControl/CreateUser/CreateUser';
import UpdateUser from '../../components/UsersControl/UpdateUser/UpdateUser';
import { getRoles, getUsers } from '../../actions/UserAPIs';

class UsersControl extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      roleOptions: [],
    };
  }

  componentDidMount() {
    getRoles().then(res => this.setState({
      roleOptions: res,
    }));

    getUsers().then(res => this.setState({
      users: res,
    }));
  }

  refreshUsers = () => {
    getUsers().then(res => this.setState({
      users: res,
    }));
  }

  render() {
    const {
      roleOptions,
      users,
    } = this.state;
    return (
      <Grid container justify='center' >
        <Grid item xs={12} sm={4} className='padding'>
          <CreateUser
            roleOptions={roleOptions}
            onChange={this.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <UpdateUser
            users={users}
            roleOptions={roleOptions}
            refreshUsers={this.refreshUsers}
          />
        </Grid>
      </Grid>
    );
  }
}

export default UsersControl;
