import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
  getSections,
  createSection,
  createCategory,
  createSubCategory,
  createMiniCategory,
  deleteSection,
  deleteCategory,
  deleteSubCategory,
  deleteMiniCategory,
  updateSection,
  updateCategory,
  updateSubCategory,
  updateMiniCategory,
  updateColor,
  getColors,
  createColor,
  deleteColor,
} from '../../actions/SectionsControlAPIs';
import GreenLoader from '../../components/GreenLoader/GreenLoader';
import Sections from '../../components/SectionsControl/Sections/Sections';
import Categories from '../../components/SectionsControl/Categories/Categories';
import SubCategories from '../../components/SectionsControl/SubCategories/SubCategories';
import MiniCategories from '../../components/SectionsControl/MiniCategories/MiniCategories';
import Colors from '../../components/SectionsControl/Colors/Colors';
import MessagesNotifications from '../../components/MessagesNotifications/MessagesNotifications';
import './SectionsControl.scss';

class SectionsControl extends Component {
  constructor() {
    super();
    this.state = {
      sections: [],
      categories: [],
      subCategories: [],
      miniCategories: [],
      colors: [],
      selectedSection: '',
      selectedCategory: '',
      selectedSubCategory: '',
      selectedMiniCategory: '',
      selectedColor: '',
      newSection: '',
      newSectionArabic: '',
      newCategory: '',
      newCategoryArabic: '',
      newSubCategory: '',
      newSubCategoryArabic: '',
      newMiniCategory: '',
      newMiniCategoryArabic: '',
      newColor: '',
      editSection: '',
      editCategory: '',
      editCategoryArabic: '',
      editSubCategory: '',
      editSubCategoryArabic: '',
      editMiniCategory: '',
      editMiniCategoryArabic: '',
      editColor: '',
      notificationMsg: '',
      showEditSection: false,
      showEditCategory: false,
      showEditSubCategory: false,
      showEditMiniCategory: false,
      showEditColor: false,
      isColorsLoading: true,
      isSectionsLoading: true,
    };
  }

  componentDidMount() {
    getSections().then(sections => this.setState({
      sections,
      isSectionsLoading: false,
    }))
      .catch(() => {
        this.setState({
          notificationMsg: 'getSections',
        });
      });

    getColors().then(colors => this.setState({
      colors,
      isColorsLoading: false,
    }))
      .catch(() => {
        this.setState({
          notificationMsg: 'getColors',
        });
      });
  }

  // to change the state of each field.
  handleChange = () => (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };


  // to insert new section, category or sub-category.
  handleCreate = (type) => {
    this.setState({
        notificationMsg: ''
    })
    const {
      newSection,
      newSectionArabic,
      newCategory,
      newCategoryArabic,
      newColor,
      newSubCategory,
      newSubCategoryArabic,
      newMiniCategory,
      newMiniCategoryArabic,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
    } = this.state;
    switch (type) {
      case 'SECTION':
        createSection(newSection, newSectionArabic).then(() => {
          this.setState({
            notificationMsg: 'CreateSectionAdded',
          })
          getSections().then(sections => this.setState({
            sections,
            newSection: '',
            newSectionArabic: '',
          }))
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'createSection',
            });
          });
        break;
      case 'CATEGORY':
        createCategory(selectedSection.Id, newCategory, newCategoryArabic).then(() => {
          getSections().then((sections) => {
            const newSelectedSection = sections.find(section => section.Id === selectedSection.Id);
            this.setState({
              notificationMsg: 'CreateCategoryAdded',
            })
            this.setState({
              sections,
              newCategory: '',
              newCategoryArabic: '',
              selectedSection: newSelectedSection,
              categories: newSelectedSection.Categories,
            });
          })
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'createCategory',
            });
          });
        break;
      case 'SUBCATEGORY':
        createSubCategory(selectedCategory.Id, newSubCategory, newSubCategoryArabic).then(() => {
          this.setState({
            notificationMsg: 'SubCategoryAdded',
          })
          getSections().then((sections) => {
            const newSelectedSection = sections
              .find(section => section.Id === selectedSection.Id);
            const newSelectedCategory = newSelectedSection.Categories
              .find(category => category.Id === selectedCategory.Id);
            this.setState({
              sections,
              newSubCategory: '',
              newSubCategoryArabic: '',
              selectedSection: newSelectedSection,
              selectedCategory: newSelectedCategory,
              categories: newSelectedSection.Categories,
              subCategories: newSelectedCategory.SubCategories,
            });
          })
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'createSubCategory',
            });
          });
        break;
      case 'MINICATEGORY':
        createMiniCategory(selectedSubCategory.Id, newMiniCategory, newMiniCategoryArabic)
          .then(() => {
            this.setState({
              notificationMsg: 'MINICategoryAdded'
            })
            getSections().then((sections) => {
              const newSelectedSection = sections
                .find(section => section.Id === selectedSection.Id);
              const newSelectedCategory = newSelectedSection.Categories
                .find(category => category.Id === selectedCategory.Id);
              const newSelectedSubCategory = newSelectedCategory.SubCategories
                .find(subCategory => subCategory.Id === selectedSubCategory.Id);
              this.setState({
                sections,
                newMiniCategory: '',
                newMiniCategoryArabic: '',
                selectedSection: newSelectedSection,
                selectedCategory: newSelectedCategory,
                selectedSubCategory: newSelectedSubCategory,
                categories: newSelectedSection.Categories,
                subCategories: newSelectedCategory.SubCategories,
                miniCategories: newSelectedSubCategory.SubSubCategories,
              });
            })
              .catch(() => {
                this.setState({
                  notificationMsg: 'getSections',
                });
              });
          })
          .catch(() => {
            this.setState({
              notificationMsg: 'createMiniCategory',
            });
          });
        break;
      case 'COLOR':
        createColor(newColor).then(() => {
          this.setState({
            notificationMsg: 'ColorCreated'
          })
          getColors().then(colors => this.setState({
            colors,
            newColor: '',
          }))
            .catch(() => {
              this.setState({
                notificationMsg: 'getColors',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'createColor',
            });
          });
        break;
      default:
        break;
    }
  }

  handleSelect = (type, selected) => {
    switch (type) {
      case 'SECTION':
        this.setState({
          selectedSection: selected,
          selectedCategory: '',
          selectedSubCategory: '',
          categories: selected.Categories,
          subCategories: [],
          newCategory: '',
          newCategoryArabic: '',
          editSection: selected.Name,
          editSectionArabic: selected.OName,
          showEditSection: false,
        });
        break;
      case 'CATEGORY':
        this.setState({
          selectedCategory: selected,
          subCategories: selected.SubCategories,
          editCategory: selected.Name,
          editCategoryArabic: selected.OName,
          showEditCategory: false,
        });
        break;
      case 'SUBCATEGORY':
        this.setState({
          selectedSubCategory: selected,
          miniCategories: selected.SubSubCategories,
          editSubCategory: selected.Name,
          editSubCategoryArabic: selected.OName,
          showEditSubCategory: false,
        });
        break;
      case 'MINICATEGORY':
        this.setState({
          selectedMiniCategory: selected,
          editMiniCategory: selected.Name,
          editMiniCategoryArabic: selected.OName,
          showEditMiniCategory: false,
        });
        break;
      case 'COLOR':
        this.setState({
          selectedColor: selected,
          editColor: selected.Name,
          showEditColor: false,
        });
        break;
      default:
        break;
    }
  }

  handleUpdate = (type) => {
    const {
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedMiniCategory,
      selectedColor,
      editSection,
      editSectionArabic,
      editCategory,
      editCategoryArabic,
      editSubCategory,
      editSubCategoryArabic,
      editMiniCategory,
      editMiniCategoryArabic,
      editColor,
    } = this.state;
    this.setState({
      notificationMsg: ''
     })
    switch (type) {
      case 'SECTION':
        updateSection(selectedSection.Id, editSection, editSectionArabic).then(() => {
          this.setState({
            notificationMsg: 'SectionUpdated'
          })
          getSections().then(sections => this.setState({
            sections,
            showEditSection: false,
          }))
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'updateSection',
            });
          });
        break;
      case 'CATEGORY':
        updateCategory(selectedCategory.Id, selectedSection.Id, editCategory, editCategoryArabic)
          .then(() => {
            this.setState({
              notificationMsg: 'CategoryUpdated'
            })
            getSections().then(sections => this.setState({
              sections,
              showEditCategory: false,
            }, () => {
              const sectionData = sections.find(section => section.Id === selectedSection.Id);
              this.setState({
                categories: sectionData.Categories,
              });
            }))
              .catch(() => {
                this.setState({
                  notificationMsg: 'getSections',
                });
              });
          })
          .catch(() => {
            this.setState({
              notificationMsg: 'updateCategory',
            });
          });
        break;
      case 'SUBCATEGORY':
        updateSubCategory(selectedSubCategory.Id, selectedCategory.Id, editSubCategory, editSubCategoryArabic)
          .then(() => {
            this.setState({
              notificationMsg: 'SubCategoryUpdated'
            })
            getSections().then(sections => this.setState({
              sections,
              showEditSubCategory: false,
            }, () => {
              const sectionData = sections.find(section => section.Id === selectedSection.Id);
              const categoryData = sectionData.Categories.find(category => category.Id === selectedCategory.Id);
              this.setState({
                subCategories: categoryData.SubCategories,
              });
            }))
              .catch(() => {
                this.setState({
                  notificationMsg: 'getSections',
                });
              });
          })
          .catch(() => {
            this.setState({
              notificationMsg: 'getBrands',
            });
          });
        break;
      case 'MINICATEGORY':
        updateMiniCategory(selectedMiniCategory.Id, selectedSubCategory.Id, editMiniCategory, editMiniCategoryArabic)
          .then(() => {
            this.setState({
              notificationMsg: 'MiniCategoryUpdated'
            })
            getSections().then(sections => this.setState({
              sections,
              showEditMiniCategory: false,
            }, () => {
              const sectionData = sections.find(section => section.Id === selectedSection.Id);
              const categoryData = sectionData.Categories.find(category => category.Id === selectedCategory.Id);
              const subCategoryData = categoryData.SubCategories
                .find(subCategory => subCategory.Id === selectedSubCategory.Id);
              this.setState({
                miniCategories: subCategoryData.SubSubCategories,
              });
            }))
              .catch(() => {
                this.setState({
                  notificationMsg: 'getSections',
                });
              });
          })
          .catch(() => {
            this.setState({
              notificationMsg: 'getBrands',
            });
          });
        break;
      case 'COLOR':
        updateColor(selectedColor.Id, editColor).then(() => {
          this.setState({
            notificationMsg: 'ColorUpdated'
          })
          getColors().then(colors => this.setState({
            colors,
            editColor: '',
            selectedColor: '',
          }))
            .catch(() => {
              this.setState({
                notificationMsg: 'getColors',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'updateColor',
            });
          });
        break;
      default:
        break;
    }
  }

  handleDelete = (type, selected) => {
    const { selectedSection, selectedCategory, selectedSubCategory } = this.state;
    this.setState({
      notificationMsg: ''
    })
    switch (type) {
      case 'SECTION':
        deleteSection(selected.Id).then(() => {
          this.setState({
            notificationMsg: 'SectionDeleted'
          })
          getSections().then(sections => this.setState({
            sections,
            selectedSection: '',
          }))
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'deleteSection',
            });
          });
        break;
      case 'CATEGORY':
        deleteCategory(selected.Id).then(() => {
          this.setState({
            notificationMsg: 'CategoryDeleted'
          })
          getSections().then((sections) => {
            const newSelectedSection = sections.find(section => section.Id === selectedSection.Id);
            this.setState({
              sections,
              selectedSection: newSelectedSection,
              selectedCategory: '',
            }, () => {
              const sectionData = sections.find(section => section.Id === selectedSection.Id);
              this.setState({
                categories: sectionData.Categories,
              });
            });
          })
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'deleteCategory',
            });
          });
        break;
      case 'SUBCATEGORY':
        deleteSubCategory(selected.Id).then(() => {
          this.setState({
            notificationMsg: 'SUBCATEGORYDeleted'
          })
          getSections().then((sections) => {
            const newSelectedSection = sections
              .find(section => section.Id === selectedSection.Id);
            const newSelectedCategory = newSelectedSection.Categories
              .find(category => category.Id === selectedCategory.Id);
            this.setState({
              sections,
              selectedSection: newSelectedSection,
              selectedCategory: newSelectedCategory,
              selectedSubCategory: '',
            }, () => {
              const sectionData = sections.find(section => section.Id === selectedSection.Id);
              const categoryData = sectionData.Categories.find(category => category.Id === selectedCategory.Id);
              this.setState({
                subCategories: categoryData.SubCategories,
              });
            });
          })
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'deleteSubCategory',
            });
          });
        break;
      case 'MINICATEGORY':
        deleteMiniCategory(selected.Id).then(() => {
          this.setState({
            notificationMsg: 'MiniCategory'
          })
          getSections().then((sections) => {
            const newSelectedSection = sections
              .find(section => section.Id === selectedSection.Id);
            const newSelectedCategory = newSelectedSection.Categories
              .find(category => category.Id === selectedCategory.Id);
            const newSelectedSubCategory = newSelectedCategory.SubCategories
              .find(subCategory => subCategory.Id === selectedSubCategory.Id);
            this.setState({
              sections,
              selectedSection: newSelectedSection,
              selectedCategory: newSelectedCategory,
              selectedSubCategory: newSelectedSubCategory,
              selectedMiniCategory: '',
            }, () => {
              const sectionData = sections.find(section => section.Id === selectedSection.Id);
              const categoryData = sectionData.Categories.find(category => category.Id === selectedCategory.Id);
              const subCategoryData = categoryData.SubCategories
                .find(subCategory => subCategory.Id === selectedSubCategory.Id);
              this.setState({
                miniCategories: subCategoryData.SubSubCategories,
              });
            });
          })
            .catch(() => {
              this.setState({
                notificationMsg: 'getSections',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'deleteMiniCategory',
            });
          });
        break;
      case 'COLOR':
        deleteColor(selected.Id).then(() => {
          getColors().then(colors => this.setState({
            colors,
          }))
            .catch(() => {
              this.setState({
                notificationMsg: 'getColors',
              });
            });
        })
          .catch(() => {
            this.setState({
              notificationMsg: 'deleteColor',
            });
          });
        break;
      default:
        break;
    }
  }

  handleShowEdit = (type) => {
    switch (type) {
      case 'SECTION':
        this.setState(prevState => ({
          showEditSection: !prevState.showEditSection,
        }));
        break;
      case 'CATEGORY':
        this.setState(prevState => ({
          showEditCategory: !prevState.showEditCategory,
        }));
        break;
      case 'SUBCATEGORY':
        this.setState(prevState => ({
          showEditSubCategory: !prevState.showEditSubCategory,
        }));
        break;
      case 'MINICATEGORY':
        this.setState(prevState => ({
          showEditMiniCategory: !prevState.showEditMiniCategory,
        }));
        break;
      case 'COLOR':
        this.setState(prevState => ({
          showEditColor: !prevState.showEditColor,
        }));
        break;
      default:
        break;
    }
  }

  render() {
    const {
      sections,
      categories,
      subCategories,
      miniCategories,
      colors,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedMiniCategory,
      selectedColor,
      newSection,
      newSectionArabic,
      newCategory,
      newCategoryArabic,
      newSubCategory,
      newSubCategoryArabic,
      newMiniCategory,
      newMiniCategoryArabic,
      newColor,
      editSection,
      editSectionArabic,
      editCategory,
      editCategoryArabic,
      editSubCategory,
      editSubCategoryArabic,
      editMiniCategory,
      editMiniCategoryArabic,
      editColor,
      showEditSection,
      showEditCategory,
      showEditSubCategory,
      showEditMiniCategory,
      showEditColor,
      isColorsLoading,
      isSectionsLoading,
      notificationMsg,
    } = this.state;
    return (
      <Grid container justify='center' >
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid item xs={12} sm={9}>
          {isColorsLoading && isSectionsLoading && <GreenLoader />}
          {!isColorsLoading && !isSectionsLoading
            && (
              <Paper className='sections-control-paper' elevation={1}>
                <Grid container justify='center' >
                  <Sections
                    sections={sections}
                    selectedSection={selectedSection}
                    newSection={newSection}
                    newSectionArabic={newSectionArabic}
                    editSection={editSection}
                    editSectionArabic={editSectionArabic}
                    showEditSection={showEditSection}
                    onChange={this.handleChange}
                    onCreate={this.handleCreate}
                    onSelect={this.handleSelect}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                    onShowEdit={this.handleShowEdit}
                  />
                  <Categories
                    categories={categories}
                    selectedSection={selectedSection}
                    selectedCategory={selectedCategory}
                    newCategory={newCategory}
                    newCategoryArabic={newCategoryArabic}
                    editCategory={editCategory}
                    editCategoryArabic={editCategoryArabic}
                    showEditCategory={showEditCategory}
                    onChange={this.handleChange}
                    onCreate={this.handleCreate}
                    onSelect={this.handleSelect}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                    onShowEdit={this.handleShowEdit}
                  />
                  <SubCategories
                    subCategories={subCategories}
                    selectedCategory={selectedCategory}
                    selectedSubCategory={selectedSubCategory}
                    newSubCategory={newSubCategory}
                    newSubCategoryArabic={newSubCategoryArabic}
                    editSubCategory={editSubCategory}
                    editSubCategoryArabic={editSubCategoryArabic}
                    showEditSubCategory={showEditSubCategory}
                    onChange={this.handleChange}
                    onCreate={this.handleCreate}
                    onSelect={this.handleSelect}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                    onShowEdit={this.handleShowEdit}
                  />
                  <MiniCategories
                    miniCategories={miniCategories}
                    selectedSubCategory={selectedSubCategory}
                    selectedMiniCategory={selectedMiniCategory}
                    newMiniCategory={newMiniCategory}
                    newMiniCategoryArabic={newMiniCategoryArabic}
                    editMiniCategory={editMiniCategory}
                    editMiniCategoryArabic={editMiniCategoryArabic}
                    showEditMiniCategory={showEditMiniCategory}
                    onChange={this.handleChange}
                    onCreate={this.handleCreate}
                    onSelect={this.handleSelect}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                    onShowEdit={this.handleShowEdit}
                  />
                </Grid>
              </Paper>
            )}
          {!isColorsLoading && !isSectionsLoading
            && (
              <Paper className='sections-control-paper' elevation={1}>
                <Colors
                  colors={colors}
                  selectedColor={selectedColor}
                  newColor={newColor}
                  editColor={editColor}
                  showEditColor={showEditColor}
                  onChange={this.handleChange}
                  onCreate={this.handleCreate}
                  onSelect={this.handleSelect}
                  onDelete={this.handleDelete}
                  onUpdate={this.handleUpdate}
                  onShowEdit={this.handleShowEdit}
                />
              </Paper>
            )}
        </Grid>
      </Grid>
    );
  }
}

export default SectionsControl;
