import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FilterSelect from "../../FilterSelect/FilterSelect";
import FileUploader from "../../FileUploader/FileUploader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { getRole } from "../../../services/share/DataService";
import AutoComplete from "../../AutoComplete/AutoComplete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContentText } from "@material-ui/core";
import RangeSlider from "../../Slider/RangeSlider";
import "./dataEntryForms.scss";
import { useState } from "react";

const SearchForm = ({
  searchOptions,
  ECommenceOptions,
  HasImagesOptions,
  HasDescriptionOptions,
  barcodeOptions,
  bulkVerificationOptions,
  brandsOptions,
  selectedSearch,
  selectedBarCode,
  selectedBulkStatus,
  selectedBrand,
  selectedEcommerceOption,
  selectedDescriptionOption,
  selectedHasImageOption,
  referenceNumber,
  selectedFromDate,
  selectedToDate,
  barCode,
  setFileData,
  onChange,
  onSearch,
  onExport,
  onImport,
  isExporting,
  isImporting,
  sectionsOptions,
  categoriesOptions,
  subCategoriesOptions,
  sizesOptions,
  selectedSection,
  selectedCategory,
  selectedSubCategory,
  selectedSizes,
  selectedDiscount,
  downloadTranslation,
  discountOptions,
  handleSetDataEntryTags,
  handleBulkTgs,
  BulkVerification,
  setDiscountRange
}) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [
    openConfirmVerificationDialog,
    setOpenConfirmVerificationDialog
  ] = useState(false);
  const [tagsInputValue, setInputTags] = useState(null);

  const role = getRole();
  const handleBulkButton = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenVerificationDialog(false);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirm(false);
    setOpenConfirmVerificationDialog(false);
  };
  const handleCloseConfirmBulkVerification = () => {
    setOpenConfirmVerificationDialog(false);
  };

  const setTags = tags => {
    let tmp = "";
    tmp = tags.join(",");
    setInputTags(tmp);
    handleSetDataEntryTags(tmp);
  };

  const handleUpadteBulkTags = () => {
    handleBulkTgs();
    setOpenConfirm(false);
    setOpen(false);
  };

  const handleUpdateBulkVerification = () => {
    BulkVerification();
    setOpenVerificationDialog(false);
    setOpenConfirmVerificationDialog(false);
  };
  const hadleShowConfirmDialog = () => {
    setOpenConfirm(true);
  };
  const handleBulkVerification = () => {
    setOpenVerificationDialog(true);
  };
  const hadleShowConfirmBulkVerification = () => {
    setOpenConfirmVerificationDialog(true);
  };

  return (
    <Grid item xs={12}>
      <Grid container className="date-entry-container" justify="center">
        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={3}>
            <input
              type="text"
              placeholder="Bar Code"
              className="search-input"
              onChange={onChange("barCode")}
              value={barCode}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={searchOptions}
              selected={selectedSearch}
              onChange={onChange("selectedSearch")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={barcodeOptions}
              selected={selectedBarCode}
              onChange={onChange("selectedBarCode")}
            />
          </Grid>
        </Grid>

        <div className="break" />

        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={brandsOptions}
              selected={selectedBrand}
              onChange={onChange("selectedBrand")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <input
              type="text"
              className="search-input"
              placeholder="Reference Number"
              onChange={onChange("referenceNumber")}
              value={referenceNumber}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={ECommenceOptions}
              selected={selectedEcommerceOption}
              onChange={onChange("selectedEcommerceOption")}
            />
          </Grid>
        </Grid>

        <div className="break" />
        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={sectionsOptions}
              selected={selectedSection}
              onChange={onChange("selectedSection")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={categoriesOptions}
              selected={selectedCategory}
              onChange={onChange("selectedCategory")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={subCategoriesOptions}
              selected={selectedSubCategory}
              onChange={onChange("selectedSubCategory")}
            />
          </Grid>
        </Grid>
        <div className="break" />
        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={3}>
            <RangeSlider setDiscountRange={setDiscountRange} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FilterSelect
              options={sizesOptions}
              selected={selectedSizes}
              onChange={onChange("selectedSizes")}
            />
          </Grid>
        </Grid>

        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="selectedFromDate"
                label="From"
                value={selectedFromDate}
                onChange={onChange("selectedFromDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="selectedToDate"
                label="To"
                value={selectedToDate}
                disableFuture="true"
                onChange={onChange("selectedToDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3} className="hasImageSwitch">
            <Grid container>
              <Grid item xs={8} sm={6} style={{ paddingRight: "25px" }}>
                Has Image
                <FilterSelect
                  options={HasImagesOptions}
                  selected={selectedHasImageOption}
                  onChange={onChange("selectedHasImageOption")}
                />
              </Grid>
              <Grid item xs={8} sm={6}>
                Has Description
                <FilterSelect
                  options={HasDescriptionOptions}
                  selected={selectedDescriptionOption}
                  onChange={onChange("selectedDescriptionOption")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="break" />

        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSearch}
              fullWidth
            >
              <span className="button-text">Search</span>
            </Button>
          </Grid>
          {role !== "QA" && (
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onExport}
                disabled={isExporting}
              >
                <span className="button-text">Export</span>
              </Button>
            </Grid>
          )}

          {role !== "QA" && (
            <Grid item xs={12} sm={2}>
              <FileUploader
                setFileData={setFileData()}
                importData={onImport()}
                fileExtension={[".xlsx"]}
                isImporting={isImporting}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={downloadTranslation}
              disabled={isExporting}
            >
              <span className="button-text">Arabic Translation</span>
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleBulkButton}
            >
              <span className="button-text">Bulk Tags</span>
            </Button>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBulkVerification}
          >
            <span className="button-text">Bulk Verification</span>
          </Button>
        </Grid>
        <Dialog open={open} onClose={() => handleClose()} fullWidth>
          <DialogTitle className="dialog">{" Bulk Tags "}</DialogTitle>

          <br />
          <br />
          <DialogContent>
            <Grid container>
              <Grid item xs={12} style={{ minHeight: "200px" }}>
                <AutoComplete
                  setTags={setTags}
                  tagsInputValue={tagsInputValue}
                />
                <br />
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={hadleShowConfirmDialog}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title" className="confirm-dialog">
            {" Bulk Tags "}{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="confirm-dialog-text"
            >
              Are You Sure About That?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirmDialog}
              color="secondary"
              variant="outlined"
              autoFocus
            >
              Close
            </Button>
            <Button
              onClick={handleUpadteBulkTags}
              color="primary"
              variant="outlined"
            >
              confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openVerificationDialog}
          onClose={() => handleClose()}
          fullWidth
        >
          <DialogTitle className="dialog">{"Bulk Verification"}</DialogTitle>

          <br />
          <br />
          <DialogContent>
            <Grid container>
              <Grid item xs={12} style={{ minHeight: "200px" }}>
                <Grid container justify="center">
                  <Grid item xs={4}>
                    <FilterSelect
                      options={bulkVerificationOptions}
                      selected={selectedBulkStatus}
                      onChange={onChange("selectedBulkStatus")}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={hadleShowConfirmBulkVerification}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmVerificationDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title" className="confirm-dialog">
            {" Bulk Verification "}{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="confirm-dialog-text"
            >
              Are You Sure About That?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirmBulkVerification}
              color="secondary"
              variant="outlined"
              autoFocus
            >
              Close
            </Button>
            <Button
              onClick={handleUpdateBulkVerification}
              color="primary"
              variant="outlined"
            >
              confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default SearchForm;
