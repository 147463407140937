import React, { Component } from "react";
import SearchForm from "../../components/location/SearchForm/SearchForm";
import ViewLocation from "../../components/location/viewLocations/viewLocation";
import { getLocations } from "../../actions/LocationsAPIs";
import { getItemStatus } from '../../actions/OrderAPI';
import { Grid } from "@material-ui/core";
import GreenLoader from "../../components/GreenLoader/GreenLoader";
import Paper from "@material-ui/core/Paper";
import InfoBox from "../../components/InfoBox/InfoBox";
import axios from 'axios';
import Button from "@material-ui/core/Button";
import BlackLoader from "../../components/BlackLoader/BlackLoader";

import "./Locations.scss";
import { da } from "date-fns/locale";
let token = localStorage.getItem('access_token');
let filterData;
let currentItemStatus ;
export default class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      data: [],
      page: 0,
      count: 0,
      rowsPerPage: 6,
      loading: false,
      viewTable: false,
      loadingForMoreRows: false,
      showInfoBox: false,
      checkboxstatus: [],
      isDownloading: false,
      orderFrom: null,
      orderTo: null,
      relatedBarcodeStatus: [],
      selectedRelatedBarcodeStatus: { label: "ALL", value: null },
    };
  }
  
  setCheckBoxStatus =  (name,checked) => {
        if(checked){
          let temp = [...this.state.checkboxstatus, name];
          // temp.push(name)
          this.setState({
            checkboxstatus: temp,
          })
        }
      else{
        const data = [...this.state.checkboxstatus];
        const mdata = data.filter(res => res !== name)
        this.setState({
          checkboxstatus: mdata
        })
        // console.log('aaaaaaaaaaaaaaaa,,,,,,,,,,,,,')
        // let index = this.state.checkboxstatus.indexOf(name);
        // console.log(index);
        // if( this.state.checkboxstatus.length >0){
        //   const data = this.state.checkboxstatus.splice(index, 1);
        //   console.log(data);
        //   this.setState({
        //     checkboxstatus: data,
        //   })
        //   // this.setState({
        //   //   checkboxstatus: tempArray
        //   // })
         
        // }
      }

  }

  componentDidMount() {
    getItemStatus().then(res => {
      const temp = res;
      res.push({ label:'Unknown',value: 'Unknown'});
      res.push({ label:'ALL',value: 'ALL'})
      this.setState({
        relatedBarcodeStatus: temp
      });
    });
    this.setState({
      loading: true
    });
    const { selectedFromDate, selectedToDate, rowsPerPage, orderFrom, orderTo} = this.state;

    filterData = {
      from: selectedFromDate,
      to: selectedToDate,
      fromOrderNo: orderFrom,
      toOrderNo: orderTo,
    };

    getLocations(filterData, 0, rowsPerPage).then(res => {
      if (res.Results.length > 0) {
        this.setState({
          data: res.Results,
          count: res.Count,
          loading: false,
          viewTable: true
        });
      } else {
        this.setState({
          loading: false,
          showInfoBox: true
        });
      }
    });
  }
  onStatusEdit = (status, index, actualBarcode, alterbarcode, COLOR) => {
    const temp = [...this.state.data];
    let barcodeIndex ;
    const barcodesData =  temp[0].OrdersItems[index].Barcodes;
    for( let i=0; i< barcodesData.length ; i++){
      if(barcodesData[i].Barcode === actualBarcode.trim('')){
        barcodeIndex = i
      }
    }
    temp[0].OrdersItems[index].Barcodes[barcodeIndex].Status = status;
    if(alterbarcode !== ''){
      temp[0].OrdersItems[index].Barcodes[barcodeIndex].AltBarcode = alterbarcode;
      // temp[this.state.editIndex].relatedBarcode4[modifyIndex].AltBarcode = this.state.alterbarcode;
     }
     if(COLOR !== ''){
      temp[0].OrdersItems[index].Barcodes[barcodeIndex].AltColor = COLOR;
     }
    this.setState({
      data:temp
    });
  };

  handleChange = id => selectedValue => {
    this.setState({
      [id]: selectedValue,
      data: []
    });
  };
  handleDateFormat = date => {
    const Newdate = new Date(Date.parse(date));
    return (
      Newdate.getFullYear() +
      "-" +
      (Newdate.getMonth() + 1) +
      "-" +
      Newdate.getUTCDate()
    );
  };
  downloadAllPickList = () => {
    this.setState({
      isDownloading: true,
    })
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      return axios.get(`/api/Order/LocationPicklistPdfAll?from=${this.handleDateFormat(this.state.selectedFromDate)}&to=${this.handleDateFormat(this.state.selectedToDate)}`,{Authorization: `bearer ${token}`, responseType: 'blob' })
        .then((blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby.data);
          anchor.href = objectUrl;
          anchor.download = `OrderLocationPicklist.pdf`;
          anchor.click();
          this.setState({
            isDownloading: false,
          })
        }).catch(err => {
          this.setState({
            isDownloading: false,
            notificationMsg: 'Error during the operation',
          })
        });
    
  }

  handleChangeRowsPerPage = event => {
    this.setState({
      loadingForMoreRows: true
    });
    getLocations(filterData, 0, parseInt(event.target.value, 10)).then(res => {
      this.setState({
        data: res.Results,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        loadingForMoreRows: false
      });
    });
  };
  
 
    
  

  handleChangePage = (event, newPage) => {
    // const  datatemp = this.state.checkboxstatus
    const { rowsPerPage, page, data } = this.state;
    this.setState({
      page: newPage,
      loading: newPage * rowsPerPage >= data.length,
      viewTable: false,
      // checkboxstatus: datatemp
    });
    if (newPage > page && newPage * rowsPerPage >= data.length) {
      getLocations(filterData, newPage, rowsPerPage).then(res => {
        res.Results.map(order => {
          this.setState(prevState => ({
            data: [...prevState.data, order],
            loading: false,
            viewTable: true
          }));
        });
      });
    } else {
      this.setState({
        viewTable: true
      });
    }
  };

  handleSearch = () => {
    const {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      showInfoBox,
      orderFrom,
      orderTo,
      selectedRelatedBarcodeStatus,
    } = this.state;
    this.setState({
      //   isLoading: true,
      page: 0,
      data: [],
      loading: true,
      showInfoBox: false,
      checkboxstatus: [],
    });
  
      if(this.state.selectedRelatedBarcodeStatus.label === 'Unknown'){
        currentItemStatus = '';
      }
      else if(this.state.selectedRelatedBarcodeStatus.label === 'ALL'){
        currentItemStatus = null;
      }
      else{
        currentItemStatus = this.state.selectedRelatedBarcodeStatus.label;
      }
  
    filterData = {
      from: selectedFromDate,
      to: selectedToDate,
      fromOrderNo: orderFrom,
      toOrderNo: orderTo,
      orderItemStatus: currentItemStatus,
    };

    getLocations(filterData, 0, rowsPerPage).then(res => {
      if (res.Results.length > 0) {
        this.setState({
          data: res.Results,
          count: res.Count,
          loading: true,
          viewTable: true,
          showInfoBox: false
        });
      } else {
        this.setState({
          loading: false,
          showInfoBox: true
        });
      }
      this.setState({
        loading: false
      });
    });
  };

  handleChangeInput = (type, value) => {
    this.setState({
      [type]: value
    })
  }
  
  render() {
    const {
      data,
      page,
      count,
      rowsPerPage,
      selectedFromDate,
      selectedToDate,
      loading,
      viewTable,
      loadingForMoreRows,
      showInfoBox
    } = this.state;
    return (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} style={{margin:'0 25px'}}>
            <Paper>
              <SearchForm
              relatedBarcodeStatus={this.state.relatedBarcodeStatus}
              selectedRelatedBarcodeStatus={this.state.selectedRelatedBarcodeStatus}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                handleChangeInput={this.handleChangeInput}
              />
            </Paper>
          </Grid>

          {loading && <GreenLoader />}
          <Grid item xs={10}>
            {showInfoBox ? <InfoBox message="Data Not Available" /> : null}
          </Grid>
          <Grid item xs={12} style={{margin: '0 25px'}}>
            {data.length > 0 && viewTable && (
              <React.Fragment>
                <h2 className="location-title">Locations</h2>
                <Button color='primary' variant='contained' onClick = {this.downloadAllPickList} >
                Export All
                </Button>
                <br/>
                {
                  this.state.isDownloading && <BlackLoader/>
                } 
                <ViewLocation
                  setCheckBoxStatus={this.setCheckBoxStatus}
                  checkboxstatus={this.state.checkboxstatus}
                  data={data}
                  page={page}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  onStatusEdit={this.onStatusEdit}
                />
              </React.Fragment>
            )}
          </Grid>
          {loadingForMoreRows && <GreenLoader />}
        </Grid>
        <br />
      </div>
    );
  }
}
