import React, { Component } from 'react';
import NumericInput from 'react-numeric-input';
import './Incrementor.scss';

export default class Incrementor extends Component {
  constructor() {
    super();
    this.state = {
      quantity: 0,
    };
  }

  componentDidMount() {
    const { currentQuantity } = this.props;
    this.setState({ quantity: currentQuantity });
  }

  handleChange = (value) => {
    const { maxQuantity } = this.props;
    this.setState({
      quantity: value < maxQuantity ? value : maxQuantity,
    }, () => {
      this.changeQuantity();
    });
  }

  changeQuantity = () => {
    const { quantity } = this.state;
    const {
      referenceNumber,
      barCode,
      color,
      onIncrementDecrement,
    } = this.props;
    onIncrementDecrement(referenceNumber, barCode, color, quantity);
  }

  render() {
    const { quantity } = this.state;
    const { maxQuantity } = this.props;
    return (
      <NumericInput
        min={0}
        max={maxQuantity}
        value={quantity}
        onChange={value => this.handleChange(value)}
        className='numeric-input'
      />
    );
  }
}
