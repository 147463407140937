import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Stores from '../../components/SrAccountant/Stores/Stores';
import GreenLoader from '../../components/GreenLoader/GreenLoader';
import InventoryTable from '../../components/SrAccountant/InventoryTable/InventoryTable';
import InfoBox from '../../components/InfoBox/InfoBox';
import CreateStore from '../../components/SrAccountant/CreateStore/CreateStore';
import {
  createStore,
  clearStore,
  getStatuses,
  getItems,
  getStores,
} from '../../actions/SrAccountantAPIs';

class SrAccountant extends Component {
  constructor() {
    super();
    this.state = {
      storeOptions: [],
      statusOptions: [],
      selectedStore: '',
      selectedStatus: '',
      data: [],
      page: 0,
      count: 0,
      rowsPerPage: 15,
      isLoading: false,
      isCreating: false,
      isClearing: false,
      showTable: false,
    };
  }

  componentDidMount() {
    getStatuses().then((res) => {
      this.setState({
        statusOptions: [{ label: 'ALL', value: undefined }, ...res],
      });
    });

    getStores().then((res) => {
      this.setState({
        storeOptions: res,
      });
    });
  }

  handleCreate = (warehouse) => {
    this.setState({
      isCreating: true,
    });
    createStore(warehouse.value).then(() => {
      getStores().then((res) => {
        this.setState({
          storeOptions: res,
          isCreating: false,
        });
      });
    });
  }

  handleClear = (storeId) => {
    this.setState({
      isClearing: true,
    });
    clearStore(storeId).then(() => this.setState({
      isClearing: false,
    }));
  }

  handleFind = (selectedStore, selectedStatus) => {
    const { page, rowsPerPage } = this.state;
    this.setState({
      selectedStore,
      selectedStatus,
      isLoading: true,
      showTable: false,
      page: 0,
    });
    getItems(selectedStore.value, selectedStatus.value, page, rowsPerPage)
      .then(res => this.setState({
        data: res.Results,
        count: res.Count,
        isLoading: false,
        showTable: true,
      }));
  }

  handleChangePage = (event, newPage) => {
    const {
      selectedStore,
      selectedStatus,
      rowsPerPage,
      page,
      data,
    } = this.state;
    this.setState({
      page: newPage,
      isLoading: (newPage * rowsPerPage >= data.length),
      showTable: !(newPage * rowsPerPage >= data.length),
    });
    if (newPage > page && newPage * rowsPerPage >= data.length) {
      getItems(selectedStore.value, selectedStatus.value, newPage, rowsPerPage)
        .then((res) => {
          const newData = data;
          res.Results.map(item => newData.push(item));
          this.setState({
            data: newData,
            isLoading: false,
            showTable: true,
          });
        });
    }
  };

  handleChangeRowsPerPage = (event) => {
    const { selectedStore, selectedStatus } = this.state;
    this.setState({
      rowsPerPage: event.target.value,
      page: 0,
    }, () => this.handleFind(selectedStore, selectedStatus));
  };

  render() {
    const {
      statusOptions,
      storeOptions,
      data,
      count,
      page,
      rowsPerPage,
      isLoading,
      isCreating,
      isClearing,
      showTable,
    } = this.state;
    return (
      <Grid container justify='center'>
        <Grid item xs={12} sm={9}>
          <CreateStore
            onCreate={this.handleCreate}
            isCreating={isCreating}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Stores
            onFind={this.handleFind}
            onClear={this.handleClear}
            isClearing={isClearing}
            statusOptions={statusOptions}
            storeOptions={storeOptions}
          />
        </Grid>
        {isLoading
          && (
            <Grid container justify='center'>
              <GreenLoader />
            </Grid>
          )}
        {data.length === 0 && showTable
          && <InfoBox message='No Data Available!' />
        }
        {data.length > 0 && showTable
          && (
            <Grid item xs={12} sm={9}>
              <InventoryTable
                data={data}
                page={page}
                count={count}
                rowsPerPage={rowsPerPage}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Grid>
          )}
      </Grid>
    );
  }
}

export default SrAccountant;
