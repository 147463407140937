import React from 'react';
import TextField from '@material-ui/core/TextField';

const height = 3;
export default ({ productInfo }) => (
  <TextField
    inputProps={{
      style: {
        height,
      },
      readOnly: true,
    }}
    className='textField'
    id='outlined-name'
    label='Color'
    margin='normal'
    variant='outlined'
    value={productInfo.Name}
  />
);
