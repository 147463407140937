import
    {GET,
    DATA_ENTRY_DOWNLOAD,
    PUT,
    Order_Print,
    Order_DOWNLOAD_PDFS,
    Trans_DOWNLOAD,
    InvoiceDownload,
    POST
} from './actions';

export const inovieDownloadTest = Params => POST('/api/Order/InvoicePDFs', Params)

export const getOrders = (SearchData, OrderNumber, page, pageSize) => {

  let arrayofParams;
  if(OrderNumber === ''){
    arrayofParams = {
      from: SearchData.from,
      to: SearchData.to,
      pageNo: page,
      pageSize,
      orderStatus: SearchData.status,
      // itemStatus: SearchData.itemStatus === 'Unknown'? null : SearchData.itemStatus,
    };
  }
  if(OrderNumber !== ''){
    arrayofParams = {
      from: SearchData.from,
      to: SearchData.to,
      pageNo: page,
      pageSize,
      orderNo: OrderNumber,
      orderStatus: SearchData.status,
      // itemStatus: SearchData.itemStatus === 'Unknown'? null : SearchData.itemStatus,
    };
  }
    return GET('/api/Order/orders', arrayofParams );
  };
  export const getItemStatus = () =>
  GET("/api/lookup/Order/Item/Status/All")
    .then(res => res.map(status => (
      {label: status, value: status}
    )))

  export const getOrderByOrderNumber = (OrderNum) => GET(`/api/Order/items?orderNo=${OrderNum}`);

  export const getStatus = () => GET('api/lookup/Order/Item/Status/');


export const getLoations = orderId => 
{
  const  OrderNumber = orderId.replace("#","");
  return GET(`/api/Order/items?orderNo=${OrderNumber}`)
}

export const exportFile = (id) => {
    const fileURL = `/api/Order/exportExcel?orderId=${id}`;
    const fileType = 'xlsx';
    const fileName = 'Order';
  
    return DATA_ENTRY_DOWNLOAD(fileURL, fileName, fileType);
  };
  

export const getOrdersById = orderId => GET(`/api/Order/order?orderNo=${orderId}`);

export const getItemByBarCode = (orderNumber, barcode) => GET(`/api/Order/item/?orderNo=${orderNumber}&itemBarcode=${barcode}`);

export const SendNewRunnerRunnerStatus = (orderName, itemid, selectedStatus) => {
  const  OrderNumber = orderName.replace("#","");
  return PUT(`/api/Order/item/status?orderNo=${OrderNumber}&orderItemBarcode=${itemid}&trackingStatus=${selectedStatus}`);
}
export const UpdateOrderItemStatus = Params => {
  return PUT(`/api/Order/item/status?orderNo=${Params.orderNo}&orderItemBarcode=${Params.orderItemBarcode}&trackingStatus=${Params.trackingStatus}`);
}

  export const UpdateOrderItemStatusForRelatedBarcode = Params => {
  return PUT(`/api/Order/item/status?orderNo=${Params.orderNo}&orderItemId=${Params.orderItemId}&orderItemBarcode=${Params.orderItemBarcode}&trackingStatus=${Params.trackingStatus}&altBarcode=${Params.altBarcode}&color=${Params.color}&comments=${Params.comments}`);
}


export const setShopifyTrackingNumber = params => {
  return PUT(`/api/Order/shopifyTrackingNo`, params);
}

export const SendNewWarehouseManagerStatus = (orderName, selectedStatus) => {
  const  OrderNumber = orderName.replace("#","");
  return PUT(`/api/Order/status?orderNo=${OrderNumber}&trackingStatus=${selectedStatus}`);
}

export const DownloadPdfFile = (orderid) => {
  // return Order_DOWNLOAD(`/api/Order/OrderPdf?orderId=${orderid}`);
}
export const downloadZipfile = (data,flag) => {
  let params= null;
  if(flag){
    params=data
  }
  else
  {
 params = {
    from: data.selectedFromDate,
    to: data.selectedToDate,
    pageNo: data.page,
    pageSize : data.rowsPerPage,
    ordersId: data.selected,
    orderStatus: data.status
  };
}
  return Order_DOWNLOAD_PDFS(`/api/Order/OrdersPDFs`, params)
}
export const downloadZipfileWasel = data => {
  const params = {
    from: data.selectedFromDate,
    to: data.selectedToDate,
    pageNo: data.page,
    pageSize : data.rowsPerPage,
    ordersId: data.selected,
    orderStatus: data.status,
  };
  return Order_DOWNLOAD_PDFS(`/api/Order/WasselOrdersPDFs`, params)
}


export const printTable = (SearchData,ordersId) =>{
  const params = {
    from: SearchData.selectedFromDate,
    to: SearchData.selectedToDate,
    pageNo: SearchData.page,
    pageSize : SearchData.rowsPerPage,
    ordersId: ordersId,
    orderStatus: SearchData.status
  };
  return Order_Print(`/api/Order/OrdersTablePdf`, params)
}

export const UploadReceiptImg = (SearchData) =>{
  return POST(`/api/image/orders/receipt`, SearchData )
}

export const DispatchOrder  = ids => {
  return POST('/api/Order/shopifyStatus', ids );
}

export const downloadTranPdf = (data, status) => {
  const params = {
    from: data.selectedFromDate,
    to: data.selectedToDate,
    pageNo: data.page,
    pageSize : data.rowsPerPage,
    ordersId: data.selected,
    orderStatus: data.status
  };
  return Trans_DOWNLOAD(`/api/Order/TransportationPdf?status=${status}`, params);   
}

export const getImage = (orderNo, barcode) => {
 return GET(`/api/image/order/item?orderNo=${orderNo}&orBarcode=${barcode}`);
}
 
export const getImageUrl = imageName => {
  return GET(`/api/image/order/item?imageName=${imageName}`);
}

export const getImageNames = orderNo => {
  return GET(`/api/images/orders/receipt?orderNo=${orderNo}`);
 }

export const downloadInvoice =  data => {
  const params = {
    from: data.selectedFromDate,
    to: data.selectedToDate,
    pageNo: data.page,
    pageSize : data.rowsPerPage,
    ordersId: data.selected,
    orderStatus: data.status
  };
  return InvoiceDownload(`/api/Order/InvoicePDFs`, params);
}

