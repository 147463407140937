import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { changeUserPass } from '../../actions/UserAPIs';
import UserImage from '../../assests/images/user.png';
import MessagesNotifications from '../MessagesNotifications/MessagesNotifications';

const useStyles = makeStyles({
    passwordDialog: {
    backgroundColor: "#313639",
    color: "white",
    textAlign: "center"
  },
  alertMessage: {
    backgroundColor: '#C34635',
    padding: '20px',
    color: '#FFF',
    textAlign: 'center',
    height: '52px',
    boxSizing: 'border-box',
    borderRadius: '35px',
    paddingBottom: '39px'
  },
});

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },

}))(MenuItem);

export default function CustomizedMenus(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pass1, setPass1] = React.useState('');
  const [pass2, setPass2] = React.useState('');
  const [isValidPass, setValidPass] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [notification, setNotification] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { onlogout } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleInputChange = event => {
    if (event.target.name === 'pass1') {
      setPass1(event.target.value);
    }
    else if (event.target.name === 'pass2') {
      setPass2(event.target.value);
    }
    else {
    }
  }

  const changePassword = () => {
  
    if (pass1 && pass2) {
      if (pass1 === pass2) {
        setNotification('');
        changeUserPass(pass1).then(res => {
          setNotification('Changed Successfully');
        }).catch(error => {
          setNotification('Error while changing password');
        })
        setOpen(false);
      } else {
        setValidPass(true)
        setIsEmpty(false);
      }

    } else {
      setIsEmpty(true);
      setValidPass(false)
    }
    setNotification('');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    onlogout();
  }
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setIsEmpty(false);
    setValidPass(false);
  };

  return (
    <div>
      {notification && (
        <MessagesNotifications message={notification} />
      )}

    
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color={props.customcolor ? props.customcolor : 'primary'}
        onClick={handleClick}
      >
        <img src={UserImage} alt="userImage"  />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemText primary="Change Password" onClick={handleOpenDialog} />
        </StyledMenuItem>

        <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.passwordDialog}>Password Reset</DialogTitle>
          <DialogContent>
            {isValidPass && <div className={classes.alertMessage} >Passowrd not match</div>}
            {isEmpty && <div className={classes.alertMessage} >Empty Password</div>}
            <TextField
              autoFocus
              margin="dense"
              id="pass1"
              name='pass1'
              label="Password"
              type="Password"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
            />
            <TextField
              name='pass2'
              autoFocus
              margin="dense"
              id="pass2"
              label="Confirm Password"
              type="Password"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant="outlined" color="secondary">
              Cancel
          </Button>
            <Button onClick={changePassword} variant="outlined" color="primary">
              Change
          </Button>
          </DialogActions>
        </Dialog>

        <StyledMenuItem>
          <ListItemText primary="Logout" onClick={handleLogOut} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
