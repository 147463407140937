import React from 'react';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PhotographerTableToolbar from './PhotographerTableToolbar';
import PhotographerTableHead from './PhotographerTableHead';
import '../../../assests/styles/TableStyle.scss';

/*
 for sorting & ordering the products descendingly/ascendingly.
*/
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class PhotographerTable extends React.Component {
  constructor() {
    super();
    this.state = {
      order: 'asc',
      orderBy: 'Description',
      selected: [],
      selectedData: [],
      page: 0,
      rowsPerPage: 10,
      isSending: false,
    };
  }

  // to sort the table items by a spicific property either ascending or descending.
  handleRequestSort = (event, property) => {
    const currentOrderBy = property;
    let currentOrder = 'desc';
    const { orderBy, order } = this.state;

    if (orderBy === property && order === 'desc') {
      currentOrder = 'asc';
    }
    this.setState({
      order: currentOrder,
      orderBy: currentOrderBy,
    });
  };

  // to select all or none.
  handleSelectAllClick = (isChecked, data) => {
    const newSelected = [];
    const newSelectedData = [];
    if (isChecked) {
      data.forEach((product) => {
        newSelected.push(product.id);
        newSelectedData.push({
          ReferenceNumber: product.ReferenceNumber,
          BarCode: product.Barcode,
        });
      });
      this.setState({
        selected: newSelected,
        selectedData: newSelectedData,
      });
    } else {
      this.setState({
        selected: [],
        selectedData: [],
      });
    }
  };

  // click on one of the rows to select it or not. if selected, it will add the data in two arrays
  // newSelected array just has the id of the selected items.
  // new selected array has the data of the selected items.
  handleClick = (id, data) => {
    const { selected, selectedData } = this.state;
    const selectedIndex = selected.indexOf(id);
    const selectedProduct = data.find(product => product.id === id);
    let newSelected = [];
    let newSelectedData = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedData = newSelectedData.concat(selectedData, {
        ReferenceNumber: selectedProduct.ReferenceNumber,
        BarCode: selectedProduct.Barcode,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1),
      );
    }
    this.setState(
      {
        selected: newSelected,
        selectedData: newSelectedData,
      },
    );
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return selected.indexOf(id) !== -1;
  }

  setPicturesData = product => (pictures) => {
    this.setState(prevState => ({
      pictures: {
        ...prevState.pictures,
        [product.id]: {
          picturesData: pictures,
          ReferenceNumber: product.ReferenceNumber,
          BarCode: product.Barcode,
        },
      },
    }));
  }

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      isSending,
    } = this.state;
    const { data } = this.props;

    return (
      <Grid item xs={12}>
        <Paper className='table-root'>
          <PhotographerTableToolbar
            numSelected={selected.length}
            onSend={this.handleSendButton}
            upload={this.handleUpload}
            isSending={isSending}
          />
          <div className='table-wrapper'>
            <Table className='table' aria-labelledby='tableTitle'>
              <PhotographerTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={event => this.handleSelectAllClick(event.target.checked, data)}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((product, index) => {
                    const isSelected = this.isSelected(product.id, data);
                    return (
                      <TableRow
                        hover
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isSelected}
                      >
                        <TableCell>{product.Item.Name}</TableCell>
                        <TableCell>{product.Item.ReferenceNumber}</TableCell>
                        <TableCell>{product.Color.Name}</TableCell>
                        <TableCell />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    );
  }
}

export default PhotographerTable;
