
import {
  GET,
  POST,
  reform,
  duplicate,
  getUnique,
  toArray,
  toArrayInts,
  addIndex,
} from './actions';

let FilterQuery;
let data;
let totalRecords;
let totalUnit;

export const getTotalRecords = () => (totalRecords);
export const getTotalUnit = () => (totalUnit);

export const getSelections = () => GET('api/inventory/items')
  .then(res => reform(res, '_id', 'id'));

export const getRequestsBrands = () => GET('api/inventory/brands')
  .then(res => reform(res, 'Name', 'value'))
  .then(res => duplicate(res, 'value', 'label'))
  .then((res) => {
    res.unshift({ label: 'ALL', value: 'ALL' });
    return res;
  });

export const getSelectionsByBrand = brand => GET('api/inventory/items', { brand })
  .then(res => reform(res, '_id', 'id'));

export const getAllBrands = () => GET('lookup/Brands', {})
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getAllSections = (marcas) => {
  const marcaArray = toArray(marcas);
  const query = {
    Marca: marcaArray,
  };
  return POST('lookup/Sections', query)
    .then(res => duplicate(res, 'SectionName', 'label'))
    .then(res => reform(res, 'SectionName', 'value'))
    .then(res => getUnique(res, 'value'));
};

export const getProductColor = referenceNumber => GET('stock/ItemColorAndSize', { referenceNumber });

export const getProductSizes = referenceNumber => GET('/stock/Sizes', { referenceNumber });

export const productsFilter = (selected) => {
  data = [];
  const pageSize = selected.rowsPerPage;
  let brandIds = toArrayInts(selected.brand);
  let warehouseIds = toArray(selected.wareHouse);
  const season = selected.season.value || '-1';
  const section = selected.section.value || -1;
  const familyId = selected.family.value || -1;
  const subFamilyId = selected.subFamily.value || -1;
  const referenceNo = selected.referenceNo || '';

  if (brandIds.length === 0) {
    brandIds = [-1];
  }

  if (warehouseIds.length === 0) {
    warehouseIds = ['-1'];
  }

  FilterQuery = {
    brandId: brandIds,
    warehouseId: warehouseIds,
    season,
    section,
    familyId,
    subFamilyId,
    referenceNo,
    pageSize,
    displayStart: 0,
  };

  return POST('stock/stockforinventorycontrol', FilterQuery)
    .then((res) => {
      totalRecords = res.Count;
      totalUnit = res.TotalUnit;
      res.Results.map(item => data.push(item));
      return addIndex(res.Results);
    });
};

export const changePage = (page, pageSize) => {
  const displayStart = pageSize * page;
  FilterQuery = { ...FilterQuery, displayStart };
  return POST('stock/stockforinventorycontrol', FilterQuery)
    .then((res) => {
      res.Results.map(item => data.push(item));
      return addIndex(data);
    });
};

export const sendProducts = (products) => {
  const query = {
    Items: products,
  };
  return POST('inventory/items', query);
};

export const uploadImages = (pictures) => {
  const myRegexp = /;base64,(.*)/;
  Object.keys(pictures).forEach((key) => {
    const barCode = pictures[key].BarCode;
    pictures[key].picturesData.forEach((picture) => {
      const match = myRegexp.exec(picture.data);
      const base64 = match[1];
      const query = {
        ImageCode: base64,
        Barcode: barCode,
      };
      return POST('inventory/addimage', query);
    });
  });
};

export const getItemByBarcode = barcode => GET('api/inventory/itembybarcode', { barcode });

export const getSections = () => GET('/category/sections', {
  pageIndex: 0,
  pageSize: 0,
})
  .then(res => reform(res.Results, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getCategories = (section) => {
  let categories = section.Categories;
  categories = reform(categories, 'Id', 'value');
  categories = reform(categories, 'Name', 'label');
  return categories;
};

export const getSubCategories = (category) => {
  let subCategories = category.SubCategories;
  subCategories = reform(subCategories, 'Id', 'value');
  subCategories = reform(subCategories, 'Name', 'label');
  return subCategories;
};
