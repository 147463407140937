import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PhotographerTabs from '../../components/Photographer/PhotographerTabs/PhotographerTabs';
import RequestsTab from '../../components/Photographer/PhotographerTabs/RequestsTab/RequestsTab';
import UploadTab from '../../components/Photographer/PhotographerTabs/UploadTab/UploadTab';
import MessagesNotifications from '../../components/MessagesNotifications/MessagesNotifications';
import {
  productsFilter,
  getSelections,
  getSelectionsByBrand,
  getRequestsBrands,
} from '../../actions/productsActions';
import './Photographer.scss';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Products extends Component {
  constructor() {
    super();
    this.state = {
      searchFields: [],
      brands: [],
      brand: [],
      referenceNo: '',
      barCode: '',
      data: [],
      isLoading: false,
      showTable: false,
      tabs: ['Upload Images'],
      currentTab: 'Upload Images',
      notificationMsg: '',
    };
  }

  componentDidMount() {
    getRequestsBrands().then((res) => {
      this.setState({
        brands: res,
      });
    })
      .catch(() => {
        this.setState({
          notificationMsg: 'getBrands',
        });
      });
  }

  // to change the filter items.
  handleChange = id => (selectedValue) => {
    // here you will get other data depeded in your choice.
    if (id === 'brand') {
      this.setState({
        [id]: selectedValue,
      }, () => this.getRequests(selectedValue.value));
    }
  }

  getRequests = (brand) => {
    if (brand === 'ALL') {
      getSelections().then((res) => {
        this.setState({
          data: res,
        });
      })
        .catch(() => {
          this.setState({
            notificationMsg: 'getData',
          });
        });
    } else {
      getSelectionsByBrand(brand).then((res) => {
        this.setState({
          data: res,
        });
      })
        .catch(() => {
          this.setState({
            notificationMsg: 'getData',
          });
        });
    }
  }

  // to submit filter.
  handleSubmit = () => {
    this.setState({
      isLoading: true,
      showTable: false,
    });
    productsFilter(this.state).then((res) => {
      this.setState({
        data: res,
        isLoading: false,
        showTable: true,
      });
    })
      .catch(() => {
        this.setState({
          notificationMsg: 'productsFilter',
        });
      });
  };

  handleChangeTab = (tab) => {
    this.setState({ currentTab: tab });
  }

  render() {
    const {
      data,
      tabs,
      currentTab,
      brands,
      notificationMsg,
    } = this.state;
    return (
      <Grid container justify='center' >
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <PhotographerTabs
          tabs={tabs}
          currentTab={currentTab}
          onChangeTab={this.handleChangeTab}
        />
        {currentTab === 'Upload Images'
          && (
          <Grid item xs={12} sm={11}>
            <UploadTab />
          </Grid>
          )}

        {currentTab === 'Requests'
          && (
            <Grid item xs={12} sm={9}>
              <RequestsTab
                data={data}
                brands={brands}
                onChange={this.handleChange}
              />
            </Grid>
          )}
      </Grid>
    );
  }
}

export default Products;
