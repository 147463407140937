import React from "react";
import { Grid } from "@material-ui/core";
import ReactDOM from 'react-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { getRole } from "../../../services/share/DataService";
import "../../../assests/styles/CardStyle.scss";
import noImage from "../../../assests/images/NoImage.png";
import ProductImage from '../../ProductImage/ProductImage';
import { sync, setpricesForItem,setOriginalPrice } from "../../../actions/DataEntryAPIs";
import {
  getColors,
  getImagesForImageSlider,
  onSendNewImage
} from "../../../actions/PhotographerAPIs";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
import PopupModal from "./PopupModal/PopupModal";
import "./DataEntryCard.scss";
import ImageGallery from "react-image-gallery";
import PopupModalForTagImage from "../PopUpForTagImage/PopupModalForTagImage";
import GreenLoader from "../../GreenLoader/GreenLoader";
import EditDialog from "../DataEntryCard/EditDialog/EditDialog";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import { debounce } from "lodash";
const temp = [];
const activateStatus = [];

class DataEntryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "Description",
      colorOptions: [],
      isSyncing: false,
      DataForCards: [],
      ImageId: null,
      ImageSliderShow: false,
      SendingSuccssfully: false,
      notificationMsg: "",
      selectedImage: "",
      ImageArray: [],
      loadingImages: false,
      productId: null,
      isSelected: false,
      showOrHidden: [],
      activateOrUnActivate: [],
      isEditingQunatity: false,
      editIndex: null,
      modifiedPrice: null,
      isEditingOriginalPrice: false,
      modifiedOriginalPrice: null,
      popupLeftView: false,
      // loaded: false,
    };
  }
  setModifiedPrice = e => {
    this.setState({
      modifiedPrice: e.target.value,
    })
  }
  setModifiedOriginalPrice = e => {
    this.setState({
      modifiedOriginalPrice: e.target.value,
    })
  }
  componentDidMount() {
    for (let i of this.props.data) {
      temp.push(i.Hide);
      activateStatus.push(i.IsVerified);
    }
    this.setState({
      showOrHidden: temp,
      activateOrUnActivate: activateStatus
    });
    getColors()
      .then(res =>
        this.setState({
          colorOptions: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getColors"
        });
      });
  }

  // to sort the table items by a spicific property either ascending or descending.
  handleRequestSort = (event, property) => {
    const currentOrderBy = property;
    let currentOrder = "desc";
    const { orderBy, order } = this.state;

    if (orderBy === property && order === "desc") {
      currentOrder = "asc";
    }
    this.setState({
      order: currentOrder,
      orderBy: currentOrderBy
    });
  };

  onSlide(currentIndex) {
    this.setState({
      ImageId: currentIndex
    });
  }

  handleSync = itemId => () => {
    this.setState({
      isSyncing: true,
      productId: itemId,
      notificationMsg: ""
    });
    sync(itemId)
      .then(() => {
        this.setState({
          isSyncing: false,
          notificationMsg: "SyncedSuccessfully"
        });
      })
      .catch(() => {
        this.setState({
          isSyncing: false,
          notificationMsg: "sync"
        });
      });
  };

  BarCodeDialog = () => {
    this.setState({
      open: true,
      isClicked: true
    });
  };
  handleImgError = (e) => {
console.log('error on load'+ e)  }

  keypressed = (e ,id,index,forOriginalPrice) => {
    if(forOriginalPrice){
      // handleEditOriginalPrice
      this.props.clearMessage();
      if(e.keyCode == 13){
        setOriginalPrice({
          itemid: id,
          sellingPrice: this.state.modifiedOriginalPrice,
        }).then(res => {
        this.props.handleEditOriginalPrice(index,this.state.modifiedOriginalPrice);
        this.setState({
          isEditingOriginalPrice: false,
        })
        this.props.showSuccessMessage();
        }).catch(err => {
          this.setState({
            isEditingOriginalPrice: false,
          })
          this.props.showErrorMessage();
        })
        // put the login here
     }
     if(e.key === 'Escape'){
      this.setState({
        isEditingOriginalPrice: false
      })
     }
    }
    else{
      this.props.clearMessage();
      if(e.keyCode == 13){
        setpricesForItem({
          itemid: id,
          sellingPriceOnline: this.state.modifiedPrice,
        }).then(res => {
        this.props.handleEditOnlinePrice(index,this.state.modifiedPrice);
        this.setState({
          isEditingQunatity: false,
        })
        this.props.showSuccessMessage();
        }).catch(err => {
          this.setState({
            isEditingQunatity: false,
          })
          this.props.showErrorMessage();
        })
        // put the login here
     }
     if(e.key === 'Escape'){
      this.setState({
        isEditingQunatity: false
      })
     }
    }
  }
  handleEditImage = (product, index) => () => {
    const { onEditItem } = this.props;
    onEditItem(index);
    this.setState({
      ImageArray: [],
      loadingImages: true
    });

    if (product.ImageName !== null) {
      getImagesForImageSlider(product._id, 0, 0)
        .then(res => {
          let array = [];
          res.Results.map(Image => {
            array.push({
              original: `${process.env.REACT_APP_API_ENDPOINT}`
                .concat("/api/images/")
                .concat(Image.Name),
              Id: Image.Id,
              Name: Image.Name
            });
            this.setState({
              ImageArray: array,
              loadingImages: false
            });
          });
        })
        .catch(() => {
          this.setState({
            notificationMsg: "getImages"
          });
        });
    }
    this.setState({
      ImageSliderShow: true
    });
  };

  handleCancelEditImage = (product, index) => () => {
    const { onEditItem } = this.props;
    onEditItem(null);
    this.setState({
      ImageSliderShow: false
    });
  };

  handleSaveSelectedImg = (product, index) => () => {
    let selectedImage = "";
    const { ImageArray } = this.state;
    ImageArray.map((image, index) => {
      if (this.state.ImageID !== null) {
        if (this.state.ImageId === index) {
          selectedImage = image;
        }
      }
    });
    product.ImageName = selectedImage.Name;
    this.setState({
      notificationMsg: ""
    });
    onSendNewImage(selectedImage.Id)
      .then(res => {
        const { onEditItem } = this.props;
        onEditItem(null);
        this.setState({
          ImageSliderShow: false,
          notificationMsg: "ProfileChoosedSuccessfully",
          SendingSuccssfully: true
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "sendingImage"
        });
      });
  };
  handleClickOutsideOriginalPriceInput = () => {
    this.setState({
      isEditingOriginalPrice: false,
    })
  }

  handleClickOutsideOnlinePriceInput = () => {
    this.setState({
      isEditingQunatity: false,
    })
  }
  handleImageLoaded = index =>  {
    // this.setState({ loaded: true });
    // console.log('image loaded with index'+index)
    this.props.updateImageLoadingStatus(index)

  }

   imageStyle = index => {
     if(!this.props.loadedStatus[index]){
       return { display: "none" };
     }
     else{
       return {};
     }
   } 
  render() {
    const {
      colorOptions,
      notificationMsg,
      tagsInputValue,
      ImageSliderShow,
      ImageArray,
      loadingImages,
      loaded,
    } = this.state;
    const { editItemId, data, handleUpdateDta } = this.props;
    const role = getRole();
    return (
      <Grid>
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <div className="card-root">
          <Grid container justify="center" spacing={8} className="text-align">
            {data.length > 0 &&
              data.map((product, index) => (
                <Grid
                  item
                  md={2}
                  sm={false}
                  key={index}
                  className="card-center"
                >
                  <Card className="min-height-card">
                    {role !== "QA" && (
                      <Checkbox
                        checked={this.props.checkedValues.includes(index)}
                        onChange={() => this.props.handleCheck(index)}
                        color="primary"
                      />
                    )}
                    <div className="brand-head">
                      <div className="brand-img">
                        {editItemId === product._id && ImageSliderShow && (
                          <Grid>
                            <ImageGallery
                              items={ImageArray}
                              showThumbnails={false}
                              showNav={true}
                              showPlayButton={false}
                              showFullscreenButton={false}
                              showIndex={true}
                              onSlide={this.onSlide.bind(this)}
                            />
                          </Grid>
                        )}

                        {editItemId === product._id && loadingImages && (
                          <Grid container justify="center">
                            <GreenLoader />
                          </Grid>
                        )}
                        {product.ImageName !== null && editItemId !== product._id && (
                          <React.Fragment>
                             {!this.props.loadedStatus[index] && (<React.Fragment> 
                              <Skeleton animation="wave" variant="rect" height='6px' style={{borderRadius:5,marginLeft:5,marginBottom:4}} width='85%' />
                            <Skeleton animation="wave" variant="rect" height='6px' style={{borderRadius:5,marginLeft:5,marginBottom:4}} width='67%' />
                            <Skeleton animation="wave" variant="rect" height='6px' style={{borderRadius:5,marginLeft:5,marginBottom:0}} width='50%' />
                            <Skeleton animation="wave" height='150px' width="100%" />
                             </React.Fragment>) }
                             <img  className='cardImage' src ={`${process.env.REACT_APP_API_ENDPOINT}`.concat("/api/images/").concat(product.ImageName)} style={this.imageStyle(index)} onLoad={() => this.handleImageLoaded(index)} onError={ () => this.handleImgError(`${process.env.REACT_APP_API_ENDPOINT}`.concat("/api/images/").concat(product.ImageName))} />
                          </React.Fragment>
                          // <CardMedia
                          //   component="img"
                          //   height="160"
                          //   image={`${process.env.REACT_APP_API_ENDPOINT}`
                          //     .concat("/api/images/")
                          //     .concat(product.ImageName)}
                          // />
                          // <ProductImage src={`${process.env.REACT_APP_API_ENDPOINT}`.concat("/api/images/").concat(product.ImageName)}/>
                        )}

                        {editItemId === product._id &&
                          product.ImageName !== null &&
                          !ImageSliderShow && (
                            <CardMedia
                              component="img"
                              height="160"
                              image={`${process.env.REACT_APP_API_ENDPOINT}`
                                .concat("/api/images/")
                                .concat(product.ImageName)}
                            />
                          )}
                        {editItemId === product._id && product.ImageName === null && (
                          <CardMedia
                            component="img"
                            height="160"
                            image={noImage}
                          />
                        )}
                        {editItemId !== product._id && product.ImageName === null && (
                          <CardMedia
                            component="img"
                            height="160"
                            image={noImage}
                          />
                        )}
                      </div>
                    </div>
                    <div className="brand-name">
                      {product.Brand}{" "}
                      <p style={{ marginBottom: "0" }}>
                        RF.NO : {product.ReferenceNumber}{" "}
                      </p>
                    </div>
                    {editItemId !== product._id && (
                      <CardContent className="content-container">
                        <Grid
                          container
                          item
                          xs={12}
                          justify="center"
                          spacing={2}
                        >
                          {editItemId !== product._id && product.ImageName !== null && (
                            <Grid item className="margin-bottom">
                              {role !== "QA" && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={this.handleEditImage(product, product._id)}
                                  disabled={!this.props.loadedStatus[index]}
                                >
                                  <span className="button-text">
                                    Choose Profile Image
                                  </span>
                                </Button>
                              )}
                            </Grid>
                          )}

                          <Grid item className="margin-bottom">
                            <EditDialog
                            handleUpdateBrand={this.props.handleUpdateBrand}
                            brandsOptions={this.props.brandsOptions}
                            loaded={this.props.loadedStatus[index]}
                            handleUpdateDataForSlectedCard={this.props.handleUpdateDataForSlectedCard}
                              product={product}
                              colorOptions={colorOptions}
                              tagsInputValue={tagsInputValue}
                              setTags={this.setTags}
                              handleCommitEdit={this.handleCommitEdit}
                              onEditItem={this.props.onEditItem}
                              handleDeleteItem={this.props.handleDeleteItem}
                              productInfo={product}
                                handleUpdateDta={handleUpdateDta}
                                handleEditQuantity={this.props.handleEditQuantity}
                                dataIndex={index}
                                showSuccessMessage={this.props.showSuccessMessage}
                                showErrorMessage={this.props.showErrorMessage}
                                clearMessage={this.props.clearMessage}
                                handleAddNewBarcode ={this.props.handleAddNewBarcode}
                            ></EditDialog>
                            <br />
                          </Grid>
                          <Grid item style={{ position: "relative" }}>
                            <span className="price">Original</span>
                            <span className="price">Online</span>
                          </Grid>
                          <Grid item style={{ position: "relative" }}>
                            {
                              this.state.isEditingOriginalPrice && this.state.editIndex === index ? <input onBlur={this.handleClickOutsideOriginalPriceInput} autoFocus type='number' style={{width:'100px'}} onChange={ e=>this.setModifiedOriginalPrice(e)} onKeyDown={e =>this.keypressed(e,product._id,index,true)}/> :
                              <span
      
                              onClick={()=> this.setState({
                                isEditingOriginalPrice: true,
                                editIndex: index
                              })}
                              className="price editPrice"
                              style={{ textAlign: "center", color: "black",cursor:'pointer' }}
                            >{`${product.Price} ILS`}</span>
                            }
                            
                            {
                              this.state.isEditingQunatity && this.state.editIndex === index ?<input onBlur={this.handleClickOutsideOnlinePriceInput} autoFocus type='number' style={{width:'100px'}} onChange={ e=>this.setModifiedPrice(e)} onKeyDown={e =>this.keypressed(e,product._id,index,false)}/> :  <span
                              onClick={()=> this.setState({
                                isEditingQunatity: true,
                                editIndex: index
                              })}
                              className="price editPrice"
                              style={{ textAlign: "center", color: "black", cursor:'pointer'}}
                            >
                              {" "}
                              {`${product.SellingPrice} ILS`}
                            </span>
                            }
                           
                          </Grid>
                        </Grid>
                      </CardContent>
                    )}
                    {editItemId !== product._id && (
                      <div>
                        <hr></hr>
                        <CardActions>
                          {product.TagImages.length > 0 && (
                            <Grid item className="margin-bottom">
                              <PopupModalForTagImage
                                disabled={!this.props.loadedStatus[index]}
                                TagImages={product.TagImages}
                              />
                            </Grid>
                          )}
                                                 
                          {role !== "QA" && (
                            <Button
                              onClick={this.handleSync(product._id)}
                              cursor="pointer"
                              size="small"
                              color="primary"
                              disabled={!this.props.loadedStatus[index]}
                            >
                              Sync
                            </Button>
                          )}
                          
                          {editItemId !== product._id && (
                            <Grid item XS={5} style={{marginLeft: product.TagImages.length > 0 ?  '17px' : '90px'}} id={index} onClick={()=>{
                              const elem = document.getElementById(index);
                              var leftPos = elem.getBoundingClientRect().right;
                              if(leftPos >1000){
                                this.setState({popupLeftView:true})
                              }else{
                                this.setState({popupLeftView:false})
                              }
                            }}>
                              <PopupModal
                               disabled={!this.props.loadedStatus[index]}
                               popupLeftView={this.state.popupLeftView}
                                productInfo={product}
                                handleUpdateDta={handleUpdateDta}
                                handleEditQuantity={this.props.handleEditQuantity}
                                dataIndex={index}
                                showSuccessMessage={this.props.showSuccessMessage}
                                showErrorMessage={this.props.showErrorMessage}
                                clearMessage={this.props.clearMessage}
                                handleAddNewBarcode ={this.props.handleAddNewBarcode}
                              />
                            </Grid>
                          )}
                        </CardActions>
                        <CardActions>
                          {role === "Admin" && (
                            <Button
                              onClick={() =>
                                this.props.handleActive(
                                  product._id,
                                  !product.IsVerified
                                )
                              }
                              cursor="pointer"
                              size="small"
                              color="primary"
                              style={{ marginLeft: "13px" }}
                              disabled={!this.props.loadedStatus[index]}
                            >
                              {product.IsVerified ? "Unactivate" : "Activate"}
                            </Button>
                          )}
                          {role === "Admin" && (
                            <Button
                            style={{marginLeft:'80px'}}
                              onClick={() =>
                                this.props.handleHide(
                                  product._id,
                                  !product.Hide
                                )
                              }
                              cursor="pointer"
                              size="small"
                              color="primary"
                              disabled={!this.props.loadedStatus[index]}
                            >
                              {product.Hide ? "Unhide" : "Hide"}
                            </Button>
                          )}
                        </CardActions>
                      </div>
                    )}
                    {ImageSliderShow && editItemId === product._id && (
                      <div>
                        <hr></hr>
                        <CardActions>
                          <Grid
                            container
                            item
                            xs={12}
                            justify="center"
                            spacing={2}
                          >
                            {ImageSliderShow && editItemId === product._id && (
                              <Grid item md={6} className="margin-bottom">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  fullWidth
                                  onClick={this.handleCancelEditImage(
                                    product,
                                    index
                                  )}
                                >
                                  <span className="button-text">Cancel</span>
                                </Button>
                              </Grid>
                            )}
                            {ImageSliderShow && editItemId === product._id && (
                              <Grid item md={6} className="margin-bottom">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={this.handleSaveSelectedImg(
                                    product,
                                    index
                                  )}
                                >
                                  <span className="button-text">Save</span>
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </CardActions>
                      </div>
                    )}
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default DataEntryCard;
