import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Barcode from '../../components/InventoryControl/Barcode/Barcode';
import { getStatuses, getStores } from '../../actions/InventoryControlAPIs';
import MessagesNotifications from '../../components/MessagesNotifications/MessagesNotifications';

class InventoryControl extends Component {
  constructor() {
    super();
    this.state = {
      storeOptions: [],
      statusOptions: [],
      notificationMsg: '',
    };
  }

  componentDidMount() {
    getStatuses().then((res) => {
      this.setState({
        statusOptions: [{ label: 'ALL', value: undefined }, ...res],
      });
    })
      .catch(() => {
        this.setState({
          notificationMsg: 'getStatuses',
        });
      });

    getStores().then((res) => {
      this.setState({
        storeOptions: res,
      });
    })
      .catch(() => {
        this.setState({
          notificationMsg: 'getStores',
        });
      });
  }

  render() {
    const {
      statusOptions,
      storeOptions,
      notificationMsg,
    } = this.state;
    return (
      <Grid container justify='center' >
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid item xs={12} sm={9}>
          <Barcode
            statusOptions={statusOptions}
            storeOptions={storeOptions}
          />
        </Grid>
      </Grid>
    );
  }
}

export default InventoryControl;
