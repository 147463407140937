import React, { Component } from "react";
import SearchForm from "../location/SearchForm/SearchForm";
import ViewLocation from "../location/viewLocations/viewLocation";
import { getLocations } from "../../actions/LocationsAPIs";
import { Grid } from "@material-ui/core";
import GreenLoader from "../GreenLoader/GreenLoader";
import Paper from "@material-ui/core/Paper";
import InfoBox from "../InfoBox/InfoBox";
import MessagesNotifications from "../MessagesNotifications/MessagesNotifications";
import "./StoreSetting.scss";

let filterData;
export default class StoreSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFromDate: new Date(),
      selectedToDate: new Date(),
      data: [],
      page: 0,
      count: 0,
      rowsPerPage: 6,
      loading: false,
      viewTable: false,
      loadingForMoreRows: false,
      showInfoBox: false,
      notificationMsg: ""
    };
  }
  componentDidMount() {
    this.setState({
      loading: true
    });
    const { selectedFromDate, selectedToDate, rowsPerPage } = this.state;

    filterData = {
      from: selectedFromDate,
      to: selectedToDate
    };

    getLocations(filterData, 0, rowsPerPage).then(res => {
      if (res.Results.length > 0) {
        this.setState({
          data: res.Results,
          count: res.Count,
          loading: false,
          viewTable: true
        });
      } else {
        this.setState({
          loading: false,
          showInfoBox: true
        });
      }
    });
  }

  handleChange = id => selectedValue => {
    this.setState({
      [id]: selectedValue,
      data: []
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      loadingForMoreRows: true
      //  data: [],
    });
    getLocations(filterData, 0, parseInt(event.target.value, 10)).then(res => {
      this.setState({
        data: res.Results,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        loadingForMoreRows: false
      });
    });
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage, page, data } = this.state;
    this.setState({
      page: newPage,
      loading: newPage * rowsPerPage >= data.length,
      viewTable: false
    });
    if (newPage > page && newPage * rowsPerPage >= data.length) {
      getLocations(filterData, newPage, rowsPerPage).then(res => {
        res.Results.map(order => {
          this.setState(prevState => ({
            data: [...prevState.data, order],
            loading: false,
            viewTable: true
          }));
        });
      });
    } else {
      this.setState({
        viewTable: true
      });
    }
  };

  handleSearch = () => {
    const {
      selectedFromDate,
      selectedToDate,
      rowsPerPage,
      showInfoBox
    } = this.state;
    this.setState({
      page: 0,
      data: [],
      loading: true,
      showInfoBox: false
    });

    filterData = {
      from: selectedFromDate,
      to: selectedToDate
    };

    getLocations(filterData, 0, rowsPerPage).then(res => {
      if (res.Results.length > 0) {
        this.setState({
          data: res.Results,
          count: res.Count,
          loading: true,
          viewTable: true,
          showInfoBox: false
        });
      } else {
        this.setState({
          loading: false,
          showInfoBox: true
        });
      }
      this.setState({
        loading: false
      });
    });
  };

  UpdateItemStatus = (index1, index, status) => {
    const temp = this.state.data;
    temp[index1].OrdersItems[index].Status = status;
    this.setState({
      data: temp,
      notificationMsg: "operation accomplished successfully"
    });
  };

  render() {
    const {
      data,
      page,
      count,
      rowsPerPage,
      selectedFromDate,
      selectedToDate,
      loading,
      viewTable,
      loadingForMoreRows,
      showInfoBox,
      notificationMsg
    } = this.state;
    return (
      <div>
        {notificationMsg !== "" && (
          <MessagesNotifications message={this.state.notificationMsg} />
        )}
        <Grid container justify="center">
          <Grid item xs={10}>
            <Paper>
              <SearchForm
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
              />
            </Paper>
          </Grid>

          {loading && <GreenLoader />}
          <Grid item xs={10}>
            {showInfoBox ? <InfoBox message="Data Not Available" /> : null}
          </Grid>
          <Grid item xs={10}>
            {data.length > 0 && viewTable && (
              <React.Fragment>
                <h2 className="location-title">Locations</h2>
                <ViewLocation
                  data={data}
                  page={page}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  UpdateItemStatus={this.UpdateItemStatus}
                />
              </React.Fragment>
            )}
          </Grid>
          {loadingForMoreRows && <GreenLoader />}
        </Grid>
        <br />
      </div>
    );
  }
}
