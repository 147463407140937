import React from 'react';
import FlipMove from 'react-flip-move';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import MessagesNotifications from '../../MessagesNotifications/MessagesNotifications';
import './ImageUploaderForTags.scss';


class ImageUploaderForTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: props.currentPictures ? props.currentPictures.picturesData : [],
      files: [],
      notAcceptedFileType: [],
      notAcceptedFileSize: [],
      notificationMsg: '',
    };
    this.inputElement = '';
  }

  // Handle file validation
  onDropFile = (files) => {
    const { notAcceptedFileType, notAcceptedFileSize } = this.state;
    const { setPicturesData, maxFileSize } = this.props;
    const allFilePromises = [];
    // Iterate over all uploaded files
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      // Check for file extension
      if (!this.hasExtension(f.name)) {
        const newArray = notAcceptedFileType.slice();
        newArray.push(f.name);
        this.setState({ notAcceptedFileType: newArray });
      }
      // Check for file size
      if (f.size > maxFileSize) {
        const newArray = notAcceptedFileSize.slice();
        newArray.push(f.name);
        this.setState({ notAcceptedFileSize: newArray });
      }
      allFilePromises.push(this.readFile(f));
    }

    Promise.all(allFilePromises).then((newFilesData) => {
      const { pictures } = this.state;
      const picturesData = pictures;
      const files = this.state.files.slice();

      newFilesData.forEach((newFileData) => {
        let newPicture = {};
        newPicture = {
          data: newFileData.dataURL,
          name: newFileData.file.name,
        };
        picturesData.push(newPicture);
        files.push(newFileData.file);
      });
      this.setState({ pictures: picturesData, files },
        () => {
          setPicturesData(picturesData);
        });
    })
      .catch(() => {
        this.setState({
          notificationMsg: 'onDropFile',
        });
      });
  }

  onUploadClick = (e) => {
    this.setState({
      pictures: [],
    });
    // Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
    e.target.value = null;
  }

  /*
     Read a file and return a promise that when resolved gives the file itself and the data URL
   */
  readFile = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    // Read the image via FileReader API and save image result in state.
    reader.onload = (e) => {
      // Add the file name to the data URL
      const dataURL = e.target.result;
      resolve({
        file,
        dataURL,
      });
    };
    reader.readAsDataURL(file);
  })

  /*
   On button click, trigger input file to open
   */
  triggerFileUpload = () => {
    this.inputElement.click();
  }

  /* Check file extension (onDropFile) */
  hasExtension = (fileName) => {
    const { imgExtension } = this.props;
    const pattern = `(${imgExtension.join('|').replace(/\./g, '\\.')})$`;
    return new RegExp(pattern, 'i').test(fileName);
  }

  /*
   Remove the image from state
   */
  removeImage = (picture) => {
    const { pictures, files } = this.state;
    const { setPicturesData } = this.props;
    const removeIndex = pictures.findIndex(e => e === picture);
    const filteredPictures = pictures.filter((e, index) => index !== removeIndex);
    const filteredFiles = files.filter((e, index) => index !== removeIndex);

    this.setState({ pictures: filteredPictures, files: filteredFiles }, () => {
      setPicturesData(filteredPictures);
    });
  }

  /*
   Check if any errors && render
   */
  renderErrors() {
    const { notAcceptedFileType, notAcceptedFileSize } = this.state;
    let notAccepted = '';
    if (notAcceptedFileType.length > 0) {
      notAccepted = notAcceptedFileType.map((error, index) => (
        <div className='error-message' key={index}>
            is not a supported file extension
        </div>
      ));
    }
    if (notAcceptedFileSize.length > 0) {
      notAccepted = notAcceptedFileSize.map((error, index) => (
        <div className='error-message' key={index}>
            Max file size: 5mb, accepted: jpg|gif|png
        </div>
      ));
    }
    return notAccepted;
  }

  /*
   Render preview images
   */
  renderPreview() {
    return (
      <div className='upload-picture-wrapper'>
        <FlipMove enterAnimation='fade' leaveAnimation='fade' className='file-item'>
          {this.renderPreviewPictures()}
        </FlipMove>
      </div>
    );
  }

  renderPreviewPictures = () => {
    const { pictures } = this.state;
    if (pictures.length > 0) {
      return pictures.map((picture, index) => (
        <div key={index} className='upload-picture-container'>
          <button
            type='button'
            className='delete-button'
            onClick={() => this.removeImage(picture)}
          >
            x
          </button>
          <img src={picture.data} className='uploadPicture' alt='preview' />
        </div>
      ));
    }
  }

  render() {
    const { notificationMsg } = this.state;
    return (
      <div className='file-uploader'>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <div className='file-container'>
          <div className='errors-container'>
            {this.renderErrors()}
          </div>
          <button
            type='button'
            className='choose-file-button'
            onClick={this.triggerFileUpload}
          >
            <AddPhotoAlternate fontSize='large' />
          </button>
          <input
            type='file'
            ref={(input) => { this.inputElement = input; }}
            onChange={e => this.onDropFile(e.target.files)}
            onClick={this.onUploadClick}
            accept='image/*'
          />
          {this.renderPreview()}
        </div>
      </div>
    );
  }
}

export default ImageUploaderForTags;
