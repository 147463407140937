import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const PhotographerTableToolbar = ({ numSelected }) => (
  <Toolbar className='table-toolbar'>
    <div className='table-title'>
      {numSelected > 0 ? (
        <Typography color='inherit' variant='subtitle1'>
          {numSelected} selected
        </Typography>
      ) : (
        <Typography variant='h6' id='tableTitle'>
              Products
        </Typography>
      )}
    </div>
  </Toolbar>
);

PhotographerTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default PhotographerTableToolbar;
