import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LoginForm from '../../components/LoginForm/LoginForm';
import Rwabi from '../../assests/images/Rwabi.png';
import './Login.scss';

const Login = ({ setUser }) => (
  <div className='login-container'>
    <Paper className='login-paper'>
      <Grid container justify='center'>
        <img src={Rwabi} alt='user-login' className='login-image' />
      </Grid>
      <LoginForm setUser={setUser} />
    </Paper>
  </div>
);

export default Login;
