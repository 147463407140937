import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./OrderSearchForm.scss";
import FilterSelect from '../../FilterSelect/FilterSelect';

const SearchForm = ({
  selectedFromDate,
  selectedToDate,
  OrderNumber,
  onSearch,
  onChange,
  OrderStatusForOrderSearch,
  selectedOrderStatus,
  handlePrintTable,
  disabledbutton,
  relatedBarcodeStatus,
  selectedRelatedBarcodeStatus,
}) => (
  <Grid item xs={12}>
    <Grid container className='date-entry-container' justify='center'>
     
      <Grid container justify="space-evenly" spacing={4}>
        <Grid item xs={12} sm={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <KeyboardDatePicker
              margin="normal"
              id="selectedFromDate"
              label="From"
              value={selectedFromDate}
              onChange={onChange("selectedFromDate")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="selectedToDate"
              label="To"
              value={selectedToDate}
              disableFuture="true"
              onChange={onChange("selectedToDate")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <div className="break"></div>
      <div className="break"></div>
      <Grid container justify="space-evenly" spacing={4}>
        <Grid item xs={12} sm={3} className="">
          <h6 className="headerFont">Search By Order Number</h6>
          <input
                type='text'
                id="OrderNumber"
                placeholder='Order Number'
                className='search-input'
                value={OrderNumber}
                onChange={onChange('OrderNumber')}
              />
          </Grid>
        <Grid item xs={12} sm={3} className="">
          <h6 className="headerFont">Search/Print By Order Status</h6>
          <FilterSelect
            placeholder='Select Status'
            options={OrderStatusForOrderSearch}
            selected={selectedOrderStatus}
            onChange={onChange('selectedOrderStatus')}
          />
         </Grid>
      
      </Grid>
      {/* <Grid container justify="space-evenly" spacing={4} style={{marginTop:10}}>
      <Grid item xs={12} sm={3} className="">
      <h6 className="headerFont">Search By Item Status</h6>
         <FilterSelect
                                                          options={
                                                          relatedBarcodeStatus
                                                          }
                                                          selected={
                                                            
                                                            selectedRelatedBarcodeStatus
                                                          }
                                                          onChange={onChange('selectedRelatedBarcodeStatus')}
                                                         
                                                        
                                                        />
         </Grid>
         <Grid item sm={3}>

         </Grid>
      </Grid> */}
      <div className="break"></div>
      <div className="break"></div>
      <Grid
        container
        justify="space-evenly"
        spacing={4}
      >
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSearch}
            fullWidth
          >
            <span className="button-text">Search</span>
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrintTable}
              fullWidth
              disabled={!disabledbutton}
              >
              Print Table
            </Button>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default SearchForm;
