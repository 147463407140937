import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FilterSelect from '../../FilterSelect/FilterSelect';
import './FunFactorySelect.scss';

export default ({ Entertainments, onShowCreate, onSelectEntertainment }) => (
  <Paper className='stores-paper' elevation={1}>
    <Grid container justify='center'>
      <Grid item xs={12} sm={3}>
          Entertainment
        <FilterSelect
          options={Entertainments}
          onChange={selected => onSelectEntertainment(selected.value)}
        />
      </Grid>
    </Grid>
    <Grid container justify='center'>
      <Grid item xs={12} sm={3}>
        <Button
          color='primary'
          onClick={onShowCreate}
        >
          <span>+ Create Entertainment</span>
        </Button>
      </Grid>
    </Grid>
  </Paper>
);
