import React, { Component } from "react";
import {
    Grid,
} from '@material-ui/core/';

import AdblockerDetecting from './AdblockerDetecting';
import SearchForm from './SearchForm';
import AdsView from './AdsView';
import {
    getLocationsTypes,
    getAdsBySearch
} from '../../actions/80/MarketingAdApis';
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";

class MarketingAdControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCreateForm: false,
            locationsTypes: [],
            ads: [],
            loading: true,
            notificationMsg: "",
            isSearching: false,
            usingBlocker: false,
        };
    }

    async componentDidMount() {
        let response = await getLocationsTypes();
        if (response) {
            this.setState({
                locationsTypes: response,
                loading: false
            })
            this.getAllAds();
        } else {
            this.setNotificationMsg("getLocationsTypes");
        }
        const usingBlocker = await this.checkAdBlocker();
        this.setState({ usingBlocker: usingBlocker });
    }

    checkAdBlocker = async () => {
        let isBlocked;

        async function tryRequest() {
            try {
                return fetch(
                    new Request(
                        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
                        {
                            method: "HEAD",
                            mode: "no-cors"
                        }
                    )
                )
                    .then(function (response) {
                        // Google Ads request succeeded, so likely no ad blocker
                        isBlocked = false;
                        return isBlocked;
                    })
                    .catch(function (e) {
                        // Request failed, likely due to ad blocker
                        isBlocked = true;
                        return isBlocked;
                    });
            } catch (error) {
                // fetch API error; possible fetch not supported (old browser)
                // Marking as a blocker since there was an error and so
                // we can prevent continued requests when this function is run
                isBlocked = true;
                return isBlocked;
            }
        }

        return isBlocked !== undefined ? isBlocked : await tryRequest();
    }


    getAllAds = async () => {
        let response = await getAdsBySearch();
        if (response) {
            response.Results && this.setState({ ads: response.Results });
        } else {
            this.setNotificationMsg("getAllAds");
        }
    }

    onSearch = async (searchQuery) => {
        this.setState({ isSearching: true })
        let response = await getAdsBySearch(searchQuery);
        if (response) {
            response.Results && this.setState({
                ads: response.Results,
                isSearching: false
            });
        } else {
            this.setNotificationMsg("getAdsSearch");
        }
    }

    setNotificationMsg = (msg) => {
        this.setState({
            notificationMsg: msg
        });
    }


    render() {
        return (
            <React.Fragment>
                {
                    this.state.notificationMsg !== "" && (
                        <MessagesNotifications message={this.state.notificationMsg} />
                    )
                }
                {
                    (this.state.usingBlocker) ? (
                        <Grid container justify="center" spacing={8}>
                            <Grid item xs={12} sm={8}>
                                <AdblockerDetecting />
                            </Grid>
                        </Grid>
                    ) :
                        !this.state.loading && (
                            <Grid container justify="center" spacing={8}>
                                <Grid item xs={12} sm={8}>
                                    <SearchForm
                                        locationsTypes={this.state.locationsTypes}
                                        onShowCreateAd={(value) => this.setState({ showCreateForm: value })}
                                        onSearch={this.onSearch}
                                        isSearching={this.state.isSearching}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <AdsView
                                        locationsTypes={this.state.locationsTypes}
                                        adsData={this.state.ads}
                                        onShowMsg={(msg) => this.setNotificationMsg(msg)}
                                        refreshTable={this.getAllAds}
                                    />
                                </Grid>
                            </Grid>
                        )
                }
            </React.Fragment>
        );
    }
}

export default MarketingAdControl;
