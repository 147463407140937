import {
  GET,
  POST,
  PUT,
  DELETE,
} from './actions';

export const getSections = () => GET('api/category/sections', {
  pageIndex: 0,
  pageSize: 0,
})
  .then(sections => sections.Results);


export const getColors = () => GET('api/lookup/Colors');

export const createSection = (sectionName, newSectionArabic) => {
  const query = {
    Name: sectionName,
    OName: newSectionArabic,
  };
  return POST('api/category/section', query);
};

export const createCategory = (SectionId, categoryName, categoryNameArabic) => {
  const query = {
    Name: categoryName,
    OName: categoryNameArabic,
    SectionId,
  };
  return POST('api/category', query);
};

export const createSubCategory = (CategoryId, subCategoryName, subCategoriesArabic) => {
  const query = {
    Name: subCategoryName,
    OName: subCategoriesArabic,
    CategoryId,
  };
  return POST('api/category/subcategory', query);
};

export const createMiniCategory = (SubCategoryId, miniCategoryName, miniCategoriesArabic) => {
  const query = {
    Name: miniCategoryName,
    OName: miniCategoriesArabic,
    SubCategoryId,
  };
  return POST('/api/subsubcategory', query);
};

export const createColor = (Name) => {
  const query = {
    Name,
  };
  return POST('api/lookup/Color', query);
};

export const deleteSection = id => DELETE(`api/category/section?id=${id}`);

export const deleteCategory = id => DELETE(`api/category?id=${id}`);

export const deleteSubCategory = id => DELETE(`api/category/subcategory?id=${id}`);

export const deleteMiniCategory = id => DELETE(`api/subsubcategory?id=${id}`);

export const deleteColor = id => DELETE(`api/lookup/removecolor?id=${id}`);

export const updateSection = (Id, Name, OName) => {
  const query = {
    Id,
    Name,
    OName,
  };
  return PUT(`api/category/section?id=${Id}`, query);
};

export const updateCategory = (Id, sectionId, Name, OName) => {
  const query = {
    Id,
    sectionId,
    Name,
    OName,
  };
  return PUT(`api/category?id=${Id}`, query);
};

export const updateSubCategory = (Id, categoryId, Name, OName) => {
  const query = {
    Id,
    categoryId,
    Name,
    OName,
  };
  return PUT(`api/category/subcategory?id=${Id}`, query);
};

export const updateMiniCategory = (Id, SubCategoryId, Name, OName) => {
  const query = {
    Id,
    SubCategoryId,
    Name,
    OName,
  };
  return PUT(`/api/subsubcategory?id=${Id}`, query);
};

export const updateColor = (Id, Name) => {
  const query = {
    Id,
    Name,
  };
  return PUT('api/lookup/Color', query);
};
