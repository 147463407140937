import {
    GET,
    POST,
    DELETE,
    PUT,
    getBase64,
  } from './Methods';


  export const getTransportation = () => GET(`/api/transportation`);

  export const updateTransportation = (TransportationData) => {
    const query = {
      TransportationImage: TransportationData.TransportationImage && getBase64(TransportationData.TransportationImage),
      TransportationId: TransportationData.TransportationId
    };
    return POST(`/api/transportation`, query);
  };

  export const getTransportationTrips = () => GET('api/transportation/admintrip');

  export const getTripsByDay = day => GET(`api/transportation/admintrip?day=${day}`) ;

  export const getTripsByRoute = route => GET(`api/transportation/admintrip?tripRoute=${route}`) ;

  export const getTripByDayAndRoute = ( day, route) => (GET(`api/transportation/admintrip?tripRoute=${route}&day=${day}`));

  export const deleteTrip = id => DELETE(`api/transportation/admintrip?id=${id}`);

  export const editTrip = query => PUT('api/transportation/admintrip', query);

  export const addTrip = query => POST(`api/transportation/admintrip`, query);

  