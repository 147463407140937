import React from 'react';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import FilterSelect from '../../../FilterSelect/FilterSelect';
import './RequestsTab.scss';

const SearchForm = ({ brands, onChange }) => (
  <Paper className='request-search-paper' elevation={1}>
    <Grid container>
      <p className='title'>Brands</p>
    </Grid>
    <Grid container justify='center'>
      <Grid item xs={12} sm={3}>
        <FilterSelect
          options={brands}
          onChange={onChange('brand')}
        />
      </Grid>
    </Grid>
  </Paper>
);

export default SearchForm;
