import React from 'react';
import { Grid } from '@material-ui/core';
import './PhotographerTabs.scss';

export default (props) => {
  const { tabs, currentTab, onChangeTab } = props;
  return (
    <Grid item xs={12} sm={9}>
      {tabs.map(tab => (
        <button
          key={tab}
          type='submit'
          className={(currentTab === tab && 'photographer-tab-clicked') || 'photographer-tab'}
          onClick={() => onChangeTab(tab)}
        >
          {tab}
        </button>
      ))}
    </Grid>
  );
};
