import React from 'react';
import { Info } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import './InfoBox.scss';

export default ({ message }) => (
  <Grid container className='info-box' justify='center'>
    <Info fontSize='large' color='action' />
    <Grid item xs={12}>
      <p className='message'>{message}</p>
    </Grid>

  </Grid>
);
