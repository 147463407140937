import React from 'react';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';

const Categories = ({
  subCategories,
  selectedCategory,
  selectedSubCategory,
  newSubCategory,
  newSubCategoryArabic,
  editSubCategory,
  editSubCategoryArabic,
  showEditSubCategory,
  onChange,
  onCreate,
  onSelect,
  onDelete,
  onUpdate,
  onShowEdit,
}) => (
  <Grid item sm={4} md={3} className='padding-left'>
    <p className='title'>SUB CATEGORIES</p>
    {subCategories.map(subCategory => (
      <Grid container className='padding-left' key={subCategory.Id}>
        <Grid item xs={subCategory.Id === selectedSubCategory.Id ? 8 : 12}>
          <button
            type='submit'
            className={subCategory.Id === selectedSubCategory.Id ? 'selected-list-button' : 'list-button'}
            key={subCategory.Id}
            onClick={() => onSelect('SUBCATEGORY', subCategory)}
          >{subCategory.Name}
          </button>
        </Grid>
        {subCategory.Id === selectedSubCategory.Id
         && (
         <Grid item xs={2}>
           <button
             type='submit'
             className='delete-button'
             onClick={() => onDelete('SUBCATEGORY', subCategory)}
           >
             <Close />
           </button>
         </Grid>
         )}
        {subCategory.Id === selectedSubCategory.Id
            && (
            <Grid item xs={2}>
              <button
                type='submit'
                className='edit-button'
                onClick={() => onShowEdit('SUBCATEGORY')}
              >
                <EditIcon />
              </button>
            </Grid>
            )}
        {subCategory.Id === selectedSubCategory.Id && showEditSubCategory
            && (
              <Grid container  className='edit-box'>
                <Grid item xs={12}>
                  <p>Category Name:</p>
                  <input
                    id='editSubCategory'
                    placeholder='sub-category english name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editSubCategory}
                  />
                  <input
                    id='editSubCategoryArabic'
                    placeholder='sub-category arabic name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editSubCategoryArabic}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => onUpdate('SUBCATEGORY')}
                  >
                    <span className='button-text'>Edit</span>
                  </Button>
                </Grid>
              </Grid>
            )}
      </Grid>
    ))}
    {!selectedCategory
        && <p>No category is selected.</p>}
    {subCategories.length === 0 && selectedCategory
        && <p>No sub categories.</p>}
    {selectedCategory
        && (
          <div>
            <input
              id='newSubCategory'
              placeholder='New sub-category english name'
              className='sections-control-input'
              onChange={onChange()}
              value={newSubCategory}
            />
            <input
              id='newSubCategoryArabic'
              placeholder='New sub-category arabic name'
              className='sections-control-input'
              onChange={onChange()}
              value={newSubCategoryArabic}
            />
            <Button
              disabled={!(newSubCategory || newSubCategoryArabic)}
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => onCreate('SUBCATEGORY')}
            >
              <span className='button-text'>Add Sub Category</span>
            </Button>
          </div>
        )}
  </Grid>
);

export default Categories;
