import {
    GET,
    PUT,
    POST,
    DELETE,
    getBase64,
    reform,
  } from './Methods';

  export const addDriver = driver => {
   return POST('/api/Rainbow/register', driver);
  } 