import React, { Component } from 'react';
import { Paper, Grid } from '@material-ui/core';
import FilterSelect from '../../FilterSelect/FilterSelect';
import MessagesNotifications from '../../MessagesNotifications/MessagesNotifications';
import './UpdateUser.scss';
import { updateUser } from '../../../actions/UserAPIs';

class UpdateUser extends Component {
  constructor() {
    super();
    this.state = {
      userId: '',
      userRole: '',
      userEmail: '',
      notificationMsg: '',
    };
  }

  handleFindUserDetails = (userDetails) => {
    const { roleOptions } = this.props;
    this.setState({
      userId: userDetails.value,
      userRole: roleOptions.find(role => role.value === userDetails.Roles[0].Id),
      userEmail: userDetails.Email,
    });
  }

  handleChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  }

  handleUpdate = () => {
    const { refreshUsers } = this.props;
    this.setState({
      notificationMsg: ''
    });
    updateUser(this.state)
      .then(() => {
        this.setState({
          notificationMsg: 'updateUserSuccssfully',
        })
        refreshUsers();
      })
      .catch(() => {
        this.setState({
          notificationMsg: 'updateUser',
        });
      });
  }

  render() {
    const { users, roleOptions } = this.props;
    const { userRole, userEmail, notificationMsg } = this.state;
    return (
      <Paper className='update-user-paper'>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid container>
          <p className='title'>EDIT USERS</p>
        </Grid>
        <Grid container justify='center' spacing={4}>
          <Grid item xs={9}>
            <span>User Name</span>
            <FilterSelect
              options={users}
              onChange={selected => this.handleFindUserDetails(selected)}
            />
          </Grid>
          <Grid item xs={9}>
            <span>Email Address</span>
            <input
              className='update-user-input'
              value={userEmail}
              onChange={e => this.handleChange('userEmail', e.target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <span>Role</span>
            <FilterSelect
              options={roleOptions}
              onChange={selected => this.handleChange('userRole', selected)}
              selected={userRole}
            />
          </Grid>
         
          <Grid item xs={4} className='padding'>
            <button
              type='submit'
              className='update-user-button'
              onClick={this.handleUpdate}
            >
              Update User
            </button>
          </Grid>
          <Grid item xs={4} className='padding'>
            <button
              type='submit'
              className='update-user-button'
            >
              Deactivate
            </button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default UpdateUser;
