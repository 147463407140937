import React, { Component } from 'react';
import Incrementor from '../../Incrementor/Incrementor';

class QuantityCell extends Component {
  getCurrentQuantity = (referenceNumber, barCode, color) => {
    const { selectedData } = this.props;
    const selectedProduct = selectedData.find(
      product => (product.ReferenceNumber === referenceNumber
        && product.BarCode === barCode
      ),
    );
    const selectedColor = selectedProduct.Colors.find(product => product.Name === color);
    return selectedColor.NumberOfItems;
  }

  handleIncrementDecrement = (referenceNumber, barCode, color, quantity) => {
    const { selectedData } = this.props;
    const selectedProduct = selectedData.find(
      product => (product.ReferenceNumber === referenceNumber
        && product.BarCode === barCode
      ),
    );
    const selectedColor = selectedProduct.Colors.find(product => product.Name === color);
    selectedColor.NumberOfItems = quantity;
  }

  render() {
    const { product, productInfo } = this.props;
    return (
      <div className='quantity-cell'>
        <span>Quantity: </span>
        <span>{productInfo.NumberOfItems}</span>
        {productInfo.NumberOfItems > 1
        && <span>Items</span>}
        {productInfo.NumberOfItems === 1
        && <span>Item</span>}
        <Incrementor
          referenceNumber={product.ReferenceNumber}
          barCode={product.Barcode1}
          color={productInfo.Name}
          onIncrementDecrement={this.handleIncrementDecrement}
          maxQuantity={productInfo.NumberOfItems}
          currentQuantity={
            this.getCurrentQuantity(product.ReferenceNumber, product.Barcode1, productInfo.Name)
          }
        />
      </div>
    );
  }
}

export default QuantityCell;
