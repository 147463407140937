import React from 'react';
import Popup from 'reactjs-popup';
import  { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import './PopupModalForTagImage.scss';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

export default ({ TagImages }) => {
  const [rotation, setRotation] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);

  

   const rotate = ( index) => {
    let newRotation = rotation + 90;
    if(newRotation >= 360){
     newRotation =- 360;
    }
     setRotation(newRotation);
     setSelectedIndex(index);
    }
    
  const rotateleft = index => { 
   let newRotation = rotation - 90;
   if(newRotation >= 360){
      newRotation =- 360;
     }
     setRotation(newRotation);
     setSelectedIndex(index);
    }
    const chnageStyle = ()=>{
      document.getElementById('popup-modal').style.height='90vh';
      document.getElementById('popup-modal').style.overflowY = "scroll";
    }

return (
  <Grid item xs={12}>
    <Popup
      className='width'
      trigger={(
        <Button
          cursor='pointer'
          size='small'
          color='primary'
          onClick={chnageStyle}
        >
       Tag Image
        </Button>
      )}
      closeOnDocumentClick
      modal
    >
      <div className='popup-modal' id='tag-img' >
        {
          TagImages.map( (image , index) =>(
            <div style={{position:'relative'}}>
            {/* <RotateLeftIcon
            className='image-rotate-left'
           id={index}
           onClick={()=> rotateleft(index)}
           /> */}
           <img
            src={`${process.env.REACT_APP_API_ENDPOINT}/api/images/tagimage/{imagename}?imagename=${image}`}
            style={{position: 'relative',transform: `rotate(${selectedIndex === index ? rotation : 0}deg)`}}
            className="TagImage"
            alt="preview"
              />
           <RotateRightIcon onClick={() => rotate(index)} className='image-rotate-right' />
           </div>
          ))
        }
        
      </div>
    </Popup>
  </Grid>
)};
