import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import RestaurantSelect from "../../components/Store/RestaurantSelect/RestaurantSelect";
import RestaurantData from "../../components/Store/RestaurantData/RestaurantData";
import {
  getStores,
  getRestaurant,
  getRestaurantImage
} from "../../actions/80/StoreAPIs";
import GreenLoader from "../../components/GreenLoader/GreenLoader";
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";

class Store extends Component {
  constructor() {
    super();
    this.state = {
      restaurants: [],
      restaurantInfo: "",
      notificationMsg: "",
      isLoading: false,
      showCreate: false,
      menuImages: [],
      resturantImages: ""
    };
  }

  componentDidMount() {
    getStores()
      .then(res =>
        this.setState({
          restaurants: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getRestaurants"
        });
      });
  }

  handleSelectRestaurant = restaurantId => {
    this.setState({
      restaurantInfo: "",
      isLoading: true,
      showCreate: false,
      resturantImages: ""
    });
    getRestaurant(restaurantId)
      .then(res => {
        this.setState({
          restaurantInfo: res,
          isLoading: false,
          showCreate: false
        });
      })

      .catch(() => {
        this.setState({
          notificationMsg: "getRestaurant"
        });
      });
  };

  handleShowCreate = () => {
    this.setState({
      showCreate: true,
      restaurantInfo: ""
    });
  };

  handleRefreshRestaurants = () => {
    getStores()
      .then(res =>
        this.setState({
          restaurants: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getRestaurants"
        });
      });
  };

  afterDeleteRes = () => {
    this.setState({
      restaurants: [],
      restaurantInfo: ""
    });
    getStores()
      .then(res =>
        this.setState({
          restaurants: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getRestaurants"
        });
      });
  };
  printMessage = () => {
    this.setState({
      notificationMsg: "operation accomplished successfully"
    });
  };

  render() {
    const {
      restaurants,
      restaurantInfo,
      isLoading,
      showCreate,
      notificationMsg,
      menuImages,
      resturantImages
    } = this.state;

    return (
      <Grid container justify="center" spacing={8}>
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Grid item xs={12} sm={8}>
          <RestaurantSelect
            restaurants={restaurants}
            onSelectRestaurant={this.handleSelectRestaurant}
            onShowCreate={this.handleShowCreate}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          {isLoading && <GreenLoader />}
          {restaurantInfo && !isLoading && (
            <RestaurantData
              printMessage={this.printMessage}
              info={restaurantInfo}
              menuImages={menuImages}
              refreshRestaurants={this.handleRefreshRestaurants}
              refreshMenuImages={this.handleRefreshMenuImage}
              afterDeleteRes={this.afterDeleteRes}
              resturantImages={resturantImages}
            />
          )}
          {showCreate && !isLoading && (
            <RestaurantData
              printMessage={this.printMessage}
              showCreate={showCreate}
              onSelectRestaurant={this.handleSelectRestaurant}
              refreshRestaurants={this.handleRefreshRestaurants}
              afterDeleteRes={this.afterDeleteRes}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default Store;
