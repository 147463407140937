import React from 'react';
import './LogList.scss';
import { Paper, Grid } from '@material-ui/core';
import { Done, Close, Remove } from '@material-ui/icons';
import moment from 'moment';

const renderIcon = (status) => {
  switch (status) {
    case 'Pass':
      return <Done fontSize='large' />;
    case 'InMinus':
      return <Remove fontSize='large' />;
    case 'NA':
      return <Close fontSize='large' />;
    default:
      return null;
  }
};

export default ({ logs }) => (
  <div className='logs-container'>
    {logs.map((log, index) => (
      <Paper className={log.Status} key={index}>
        <Grid container>
          <Grid item xs={2}>
            {renderIcon(log.Status)}
          </Grid>
          <Grid item xs={9}>
            {log.Barcode}
            <br />
            <span className='time'>• {moment(log.TransactionDate).calendar()}</span>
          </Grid>
          <Grid item xs={1}>
            count
            <br />
            {log.InventoryCount}
          </Grid>
        </Grid>
      </Paper>
    ))}
  </div>
);
