import {
  GET,
  POST,
  reform,
  duplicate,
  getUnique,
  toArray,
  toArrayInts,
  addIndex,
} from './actions';

let totalUnit;
let totalRecords;
let FilterQuery;
let data;

export const getAllWareHouses = () => GET('api/lookup/WareHouse', {})
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getAllBrands = () => GET('api/lookup/Brands', {})
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getAllSeasons = () => GET('api/lookup/Seasons', {})
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getAllSections = (brands) => {
  const marcaArray = toArray(brands);
  const query = {
    Marca: marcaArray,
  };
  return POST('api/lookup/Sections', query)
    .then(res => duplicate(res, 'SectionName', 'label'))
    .then(res => reform(res, 'SectionName', 'value'))
    .then(res => getUnique(res, 'value'));
};

export const getAllFamilies = (marcas, sectionName) => {
  const marcaArray = toArray(marcas);
  const query = {
    Marca: marcaArray,
    SectionName: sectionName,
  };
  return POST('api/lookup/Families', query)
    .then(res => reform(res, 'Id', 'value'))
    .then(res => reform(res, 'FamilyName', 'label'));
};

export const getAllSubFamilies = (marcas, sectionName, familyId) => {
  const marcaArray = toArray(marcas);
  const query = {
    Marca: marcaArray,
    SectionName: sectionName,
    Family: familyId,
  };
  return POST('api/lookup/SubFamilies', query)
    .then(res => reform(res, 'Id', 'value'))
    .then(res => reform(res, 'SubfamilyName', 'label'));
};

export const getSections = () => GET('api/category/sections?pageIndex=0&pageSize=0')
  .then(res => reform(res.Results, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getCategories = (section) => {
  let categories = section.Categories;
  categories = reform(categories, 'Id', 'value');
  categories = reform(categories, 'Name', 'label');
  return categories;
};

export const getSubCategories = (category) => {
  let subCategories = category.SubCategories;
  subCategories = reform(subCategories, 'Id', 'value');
  subCategories = reform(subCategories, 'Name', 'label');
  return subCategories;
};

export const getProductColor = referenceNumber => GET('api/stock/ItemColorAndSize', { referenceNumber });

export const getTotalUnit = () => (totalUnit);

export const getTotalRecords = () => (totalRecords);

export const changePage = (page, pageSize) => {
  const displayStart = pageSize * page;
  FilterQuery = { ...FilterQuery, displayStart };
  return POST('api/stock/stockforinventorycontrol', FilterQuery)
    .then((res) => {
      res.Results.map(item => data.push(item));
      return addIndex(data);
    });
};

export const productsFilter = (selected, pageSize, pageIndex) => {
  data = [];
  let brandIds = toArrayInts(selected.brand);
  let warehouseIds = toArray(selected.wareHouse);
  const season = selected.season.value || '-1';
  const section = selected.section.value || -1;
  const familyId = selected.family.value || -1;
  const subFamilyId = selected.subFamily.value || -1;
  const referenceNo = selected.referenceNo || '';
  const Barcodes = selected.barCode || [];

  if (brandIds.length === 0) {
    brandIds = [-1];
  }

  if (warehouseIds.length === 0) {
    warehouseIds = ['-1'];
  }
  FilterQuery = {
    brandId: brandIds,
    warehouseId: warehouseIds,
    season,
    section,
    familyId,
    subFamilyId,
    referenceNo,
    Barcodes,
    pageSize,
    pageIndex: pageIndex || 0,
    displayStart: 0,
  };

  return POST('api/stock/stockforinventorycontrol', FilterQuery)
    .then((res) => {
      totalRecords = res.Count;
      totalUnit = res.TotalUnit;
      res.Results.map(item => data.push(item));
      return addIndex(res.Results);
    });
};

export const sendProducts = (products) => {
  const query = {
    Items: products,
  };
  return POST('api/inventory/items', query);
};
