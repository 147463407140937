import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import '../../../assests/styles/TableStyle.scss';
import '../../../assests/styles/CardStyle.scss';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FilterSelect from '../../FilterSelect/FilterSelect';
import PricesCell from './PricesCell';
import SizesCell from './SizesCell';
import ColorsCell from './ColorsCell';
import QuantityCell from './QuantityCell';
import { stableSort, getSorting } from '../../DataEntry/DataEntryCard/utilitiez';

class ManagerCard extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  isSelected = (id) => {
    const { selected } = this.props;
    return selected.indexOf(id) !== -1;
  }

  getCategoryOptions = (type, referenceNumber) => {
    const { selectedData } = this.props;
    const selectedProduct = selectedData.find(
      product => (product.ReferenceNumber === referenceNumber),
    );
    switch (type) {
      case 'CATEGORY':
        return selectedProduct.categories;
      case 'SUBCATEGORY':
        return selectedProduct.subCategories;
      default:
        break;
    }
  }

  getColorOptions = (colors) => {
    const colorOptions = [];
    colors.map(color => (
      colorOptions.push({
        value: color.Name,
        label: color.Name,
      })));
    return colorOptions;
  };

  getSelectedColor = (referenceNumber) => {
    const { selectedData } = this.props;
    selectedData.find(
      product => (product.ReferenceNumber === referenceNumber),
    );
  }

  getSelectedValue = (type, referenceNumber) => {
    const { selectedData } = this.props;
    const selectedProduct = selectedData.find(
      product => (product.ReferenceNumber === referenceNumber),
    );

    switch (type) {
      case 'SECTION':
        return selectedProduct.section;
      case 'CATEGORY':
        return selectedProduct.category;
      case 'SUBCATEGORY':
        return selectedProduct.subCategory;
      default:
        break;
    }
  }


  render() {
    const {
      order,
      orderBy,
    } = this.state;
    const {
      data,
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
      handleChange,
      isSelectAll,
      selectedData,
      totalRecords,
      colors,
      eccommerceSections,
      onChangeSection,
      onChangePrice,
      selectedCheckboxes,
    } = this.props;
    const height = 3;
    return (
      <Grid item xs={12}>
        <Grid container justify='center'>
          {stableSort(data, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((product) => {
              const isSelected = this.isSelected(product.id, data);
              const selectedProduct = selectedData.find(
                theSelected => theSelected.ReferenceNumber === product.ReferenceNumber,
              );
              return (
                <Grid item xs={3} key={product.id} className='card-manager'>
                  <Card>
                    <div>
                      {(
                        <CardHeader
                          title={
                            <h6> {`Name : ${product.Description}`} </h6>
                          }
                          action={(
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  onChange={() => handleChange(product.id)}
                                  checked={selectedCheckboxes[product.id]}
                                  value='checkedA'
                                  color='primary'
                                />
                                )}
                              label='Select'
                            />
                          )}
                          subheader={`RF.NO : ${product.ReferenceNumber}`}
                        />
                      )}
                      <CardContent>
                        <Grid container spacing={2}>
                          {(!isSelected || isSelectAll) && (
                            <Grid item xs style={{ textAlign: 'center' }}>
                              <TextField
                                className='textField'
                                id='outlined-name'
                                label='Tag Price'
                                margin='normal'
                                variant='outlined'
                                value={Math.floor(product.Price * 100) / 100}
                                inputProps={{
                                  style: {
                                    height,
                                  },
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          )}
                          {(isSelected && !isSelectAll) && (
                            <Grid item xs>
                              <PricesCell
                                productRefNo={product.ReferenceNumber}
                                price={Math.floor(selectedProduct.price * 100) / 100}
                                onChangePrice={onChangePrice}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={2}>
                          {(isSelected && !isSelectAll) && (
                            <Grid item xs>
                              <div className='section-cell'>
                                <FilterSelect
                                  placeholder='Section'
                                  options={eccommerceSections}
                                  onChange={onChangeSection(
                                    'SECTION',
                                    product.ReferenceNumber,
                                  )}
                                  selected={this.getSelectedValue(
                                    'SECTION',
                                    product.ReferenceNumber,
                                  )}
                                />
                              </div>
                              <div className='section-cell'>
                                <FilterSelect
                                  placeholder='Category'
                                  options={this.getCategoryOptions(
                                    'CATEGORY',
                                    product.ReferenceNumber,
                                  )}
                                  onChange={onChangeSection(
                                    'CATEGORY',
                                    product.ReferenceNumber,
                                  )}
                                  selected={this.getSelectedValue(
                                    'CATEGORY',
                                    product.ReferenceNumber,
                                  )}
                                />
                              </div>
                              <div className='section-cell'>
                                <FilterSelect
                                  placeholder='Sub Category'
                                  options={this.getCategoryOptions(
                                    'SUBCATEGORY',
                                    product.ReferenceNumber,
                                  )}
                                  onChange={onChangeSection(
                                    'SUBCATEGORY',
                                    product.ReferenceNumber,
                                  )}
                                  selected={this.getSelectedValue(
                                    'SUBCATEGORY',
                                    product.ReferenceNumber,
                                  )}
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={2}>
                          {(isSelected && !isSelectAll) && (
                            <Grid item xs>
                              <hr />
                              {colors[product.ReferenceNumber]
                                      && colors[product.ReferenceNumber].map(
                                        (productInfo, index) => (
                                          <div key={index}>
                                            <Grid container spacing={2}>
                                              <Grid item xs>
                                                <div className='field-width'>
                                                  <ColorsCell productInfo={productInfo} />
                                                </div>
                                              </Grid>
                                              <Grid item xs>
                                                <div className='field-width'>
                                                  <QuantityCell
                                                    product={product}
                                                    productInfo={productInfo}
                                                    selectedData={selectedData}
                                                  />
                                                </div>
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                              <Grid item xs>
                                                <div>
                                                  <SizesCell sizes={productInfo.Sizes} />
                                                </div>
                                              </Grid>
                                            </Grid>
                                            <hr />
                                          </div>
                                        ),
                                      )}
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
        <TablePagination
          component='div'
          rowsPerPageOptions={[20]}
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    );
  }
}

export default ManagerCard;
