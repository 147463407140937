import {
  GET,
  PUT,
  POST,
  DELETE,
  getBase64,
  reform,
} from './Methods';

export const getRestaurants = () => GET('/api/restaurant/restaurants/')
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'RestaurantName', 'label'));

export const getRestaurant = restaurantId => GET(`/api/restaurant/${restaurantId}`);

export const createRestaurant = (restaurantData) => {
  const query = {
    RestaurantName: restaurantData.restaurantName,
    RestaurantDescription: restaurantData.restaurantDescription,
    RestaurantArabicName: restaurantData.restaurantArabicName,
    RestaurantArabicDescription : restaurantData.arabicDescription,
    PhoneNumber: restaurantData.phoneNumber,
    Email: restaurantData.email,
    ExtNumber: restaurantData.ExtNumber

  };
  return POST('/api/restaurant', query);
};

export const toDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
          callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

 export const updateRestaurant = async  (restaurantData) => {

      toDataUrl(restaurantData.cover, (myBase64) => {
      restaurantData.cover = myBase64;
      });
      toDataUrl(restaurantData.logo, (myBase64) => {
      restaurantData.logo = myBase64;
      
   });
   await sleep(500);
  const query = {
    RestaurantId: restaurantData.Id,
    RestaurantName: restaurantData.restaurantName,
    RestaurantDescription: restaurantData.restaurantDescription,
    RestaurantArabicDescription: restaurantData.arabicDescription,
    RestaurantArabicName : restaurantData.restaurantArabicName,
    PhoneNumber: restaurantData.phoneNumber,
    Email: restaurantData.email,
    ExtNumber: restaurantData.ExtNumber,
  };
  return PUT(`/api/restaurant/`, query);
};


export const uploadImage = (restaurantId, picture, type) => {
  const myRegexp = /;base64,(.*)/;
  const match = myRegexp.exec(picture.data);
  const base64 = match[1];
  var base64result = base64.substr(base64.indexOf(',') + 1);
  picture = base64result
  const query = {
    Restaurant_Id: restaurantId,
    RestaurantImageType: type,
    RestaurantImage : picture,
  };
  
  return POST(`api/restaurant/image`, query);
};

export const getRestaurantImage = restaurantId => GET(`/api/restaurant/${restaurantId}`);

export const DeleteMenuImage = id => DELETE(`/api/restaurant/image/${id}`);

export const DeleteResturant = id => DELETE(`/api/restaurant/${id}`);

export const getImagesBase64 = restImageID => GET(`/api/restaurant/image/${restImageID}`);

// export const UploadImage = restImageID => GET(`/api/restaurant/image/${restImageID}`);

export const UploadImage =  async (ImageData) => {
  toDataUrl(ImageData.RestaurantImage, (myBase64) => {
    var base64result = myBase64.substr(myBase64.indexOf(',') + 1);
    ImageData.RestaurantImage = base64result;
    });

  await sleep(500);
  const query = {
    Restaurant_Id: ImageData.Restaurant_Id,
    RestaurantImageType:ImageData.RestaurantImageType,
    RestaurantImage : ImageData.RestaurantImage,
  };
  return POST('api/restaurant/image', query);
};