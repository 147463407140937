import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_80,
});

// to get the data from API using axios.
export const GET = (API, params) => instance.get((API), { params })
  .then(res => res.data);

// to post the data from API using axios.
export const POST = (API, Q) => instance.post(API, Q)
  .then(res => res.data);

// to update the data from API using axios.
export const PUT = (API, Q) => instance.put(API, Q)
  .then(res => res.data);

// to delete the data from API using axios.
export const DELETE = (API, Q) => instance.delete(API, Q)
  .then(res => res.data);

// to change the properety name for the returned data and name it as I want.
export const reform = (arr, oldKey, newKey) => {
  const newArr = arr.map(({ [oldKey]: value, ...rest }) => ({ [newKey]: value, ...rest }));
  return newArr;
};

export const getBase64 = (image) => {
  const myRegexp = /;base64,(.*)/;
  const match = myRegexp.exec(image);
  return match[1];
};
