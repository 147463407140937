import React, { Component } from "react";
import {
    Grid,
    Typography,
    CardContent,
    Card
} from '@material-ui/core/';
import { Error } from '@material-ui/icons';

class AdblockerDetecting extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Card style={{
                backgroundColor: "#8bc34a",
                paddingBottom: '50px'
            }}>
                <CardContent>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Error
                                style={{
                                    color: '#fff',
                                    width: '100px',
                                    height: 'auto'
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h4"
                                style={{ color: "#fff" }}
                            >
                                AdBlock Detected
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Card >
                                <CardContent>
                                    <Typography variant="subtitle2">
                                        {` Sure, ad-blocking software does a great job at blocking ads,
                                        but it also blocks some useful and important features of our website.
                                         For the best possible site experience please take a moment to disable your AdBlocker.`}
                                        <br /><br />
                                        {`We appreciate your understanding and cooperation!`}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export default AdblockerDetecting;
