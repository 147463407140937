import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getRole } from "../../services/share/DataService";
import SearchForm from "../../components/Validation/DataEntryForms/SearchForm";
import Button from "@material-ui/core/Button";
import {
  syncAll,
  ActivateProduct,
  HideProduct,
  updateDataEntryBulk,
  updateDataEntryBulkVerification,
  deleteSKu
} from "../../actions/DataEntryAPIs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  validationSearch,
  updateBulk,
  updateBrandColor,
  getBrands,
  exportFile,
  importFile,
  getSectionsList,
  getCategoriesList,
  getSubCategoriesList,
  getSizesList,
  getShopifyItemDescription,
  getDiscountList,
  getRolesForValidationTab
} from "../../actions/DataEntryAPIs";
import DataEntryCard from "../../components/Validation/DataEntryCard/DataEntryCard";
import "./Validation.scss";
import GreenLoader from "../../components/GreenLoader/GreenLoader";
import BlackLoader from "../../components/BlackLoader/BlackLoader";
import InfoBox from "../../components/InfoBox/InfoBox";
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";

const barcodeOptions = [
  { label: "Barcode1", value: "Barcode1" },
  { label: "Barcode2", value: "Barcode2" },
  { label: "Barcode3", value: "Barcode3" }
];

const bulkVerificationOptions = [
  { label: "Activated", value: true },
  { label: "Deactivated", value: false }
];

const bulkOptions = [
  { label: "Verified", value: true },
  { label: "Unverified", value: false }
];

const searchOptions = [
  { label: "Equals", value: "Equals" },
  { label: "Starts with", value: "StartsWith" },
  { label: "contains", value: "Contains" },
  { label: "Ends with", value: "EndsWith" }
];

const ECommenceOptions = [
  { label: "All", value: null },
  { label: "INECOMMERCE", value: true },
  { label: "NOT IN ECOMMERCE", value: false }
];

const HasImagesOptions = [
  { label: "All", value: null },
  { label: "HAS IMAGE", value: true },
  { label: "NO IMAGE", value: false }
];

const HasDescriptionOptions = [
  { label: "All", value: null },
  { label: "HAS DESCRIPTION", value: true },
  { label: "NO DESCRIPTION", value: false }
];

let filterData;
let filterSize = {
  brand: "",
  section: "",
  category_id: "",
  sub_category_id: ""
};

class Validation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDiscount: { label: "ALL Discounts", value: "ALL", Id: null },
      selectedRole: { label: "ALL QA Users", value: "ALL", Id: null },
      selectedBarCode: { label: "Barcode1", value: "Barcode1" },
      selectedBulkStatus: { label: "All", value: "ALL" },
      selectedBulkVerificationStatus: { label: "All", value: "ALL" },
      selectedSearch: { label: "Equals", value: "Equals" },
      selectedBrand: { label: "ALL", value: "ALL" },
      selectedSection: { label: "ALL Sections", value: "ALL", Id: null },
      selectedCategory: { label: "ALL Categories", value: "ALL", Id: null },
      selectedSubCategory: {
        label: "ALL Sub Categories",
        value: "ALL",
        Id: null
      },
      selectedSizes: { label: "All Sizes", value: "ALL", Id: null },
      selectedEcommerceOption: { label: "ALL", value: null },
      selectedHasImageOption: { label: "All", value: null },
      selectedDescriptionOption: { label: "All", value: null },
      hasImages: false,
      hasDesc: false,
      inEccommerce: false,
      selectedFromDate: null,
      selectedToDate: null,
      count: 0,
      pageSize: 10,
      page: 0,
      referenceNumber: "",
      barCode: "",
      notificationMsg: "",
      expanded: "searchPanel",
      data: [],
      editItemId: null,
      isLoading: false,
      isUpdatingColor: false,
      isUpdatingBulk: false,
      isUpdatingItem: false,
      isExporting: false,
      enableUpdate: false,
      inputFile: null,
      isImporting: false,
      checkedValues: [],
      selectedIDS: [],
      dataEntrytags: null,
      discountfrom: 0,
      discountTo: 100,
      onlinePriceFrom: 0,
      onlinePriceTo: 10000,
      selectedStockOption: {label:'ALL', value: 'ALL'},
      loadedStatus: [],
    };
  }
  handleUpdateDta = (SkuId, productID) => {
    this.setState({
      notificationMsg: ""
    });
    const { data } = this.state;

    deleteSKu(SkuId)
      .then(res => {
        const cloneData = data;
        const index = data.findIndex(product => product._id === productID);
        const temp = data.filter(product => product._id === productID);
        const modified = temp[0].BarcodesExtraData.filter(
          barcode => barcode.SizeId !== SkuId
        );
        cloneData[index].BarcodesExtraData = modified;
        this.setState({
          data: cloneData,
          notificationMsg: "operation accomplished successfully"
        });
      })
      .catch(err => {
        this.setState({
          notificationMsg: "Error during the operation"
        });
      });
  };

  handleUpdateDataForSlectedCard =  (productID,arabicName,englishName,arabicDescription,englishDescription,tags,section,category,subCategory,miniCategory, newBrand) => {
      const temp = [...this.state.data];
      const index = temp.findIndex(res => res._id === productID);
      if(index !== -1){
        temp[index].ArabicName = arabicName;
        temp[index].ArabicDescription = arabicDescription;
        temp[index].EnglishName = englishName;
        temp[index].EnglishDescription = englishDescription;
        temp[index].Tags = tags;
        temp[index].Brand= newBrand.label;
        temp[index].BrandId= newBrand.Id;

        if(section !== {}){
          temp[index].SectionId = section.value;
          temp[index].SectionName = section.label;
        }
        if(category !== {}){
          temp[index].CategoryId = category.value;
          temp[index].CategoryName = category.label;
        }
        if(subCategory !== {}){
          temp[index].SubCategoryId = subCategory.value;
          temp[index].SubCategoryName = subCategory.label;
        }
        if(miniCategory !== {}){
          temp[index].SubSubCategoryId = miniCategory.value;
          temp[index].SubSubCategoryName = miniCategory.label;
        }
      }
      this.setState({
        data: temp
      })
  }
  componentDidMount() {
    getBrands()
      .then(res => {
        this.setState({
          brandsOptions: res
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "getBrands"
        });
      });
    getSectionsList().then(res => {
      this.setState({
        sectionsOptions: res
      });
    });
    getCategoriesList().then(res => {
      this.setState({
        categoriesOptions: res
      });
    });

    getSubCategoriesList().then(res => {
      this.setState({
        subCategoriesOptions: res
      });
    });
    getSizesList(filterSize).then(res => {
      this.setState({
        sizesOptions: res
      });
    });
    getDiscountList().then(res => {
      this.setState({
        discountOptions: res
      });
    });

    getRolesForValidationTab().then(res => {
      this.setState({
        rolesOptions: res
      });
    });
  }

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleUpdateBrand = (productID, newBreand) => {
    const temp = this.state.data;
    const index = temp.findIndex(product => product.Id === productID);
    temp[index].Brand = newBreand.label;
    temp[index].BrandId = newBreand.Id;
    this.setState({
      data: temp,
    })
  }
  // to change the filter items.
  handleChange = id => selectedValue => {
    if (id === "referenceNumber" || id === "barCode") {
      this.setState({
        [id]: selectedValue.target.value,
        enableUpdate: false
      });
    } else if (id === "hasImages" || id === "hasDesc") {
      this.setState({
        [id]: selectedValue.target.checked,
        enableUpdate: false
      });
    } else {
      this.setState({
        [id]: selectedValue,
        enableUpdate: false
      });
      if (id === "selectedBrand") {
        filterSize = {
          ...filterSize,
          brand: selectedValue.value === "ALL" ? "" : selectedValue.value
        };
      } else if (id === "selectedSection") {
        filterSize = {
          ...filterSize,
          section: selectedValue.value === "ALL" ? "" : selectedValue.Id
        };
      } else if (id === "selectedCategory") {
        filterSize = {
          ...filterSize,
          category_id: selectedValue.value === "ALL" ? "" : selectedValue.Id
        };
      } else if (id === "selectedSubCategory") {
        filterSize = {
          ...filterSize,
          sub_category_id: selectedValue.value === "ALL" ? "" : selectedValue.Id
        };
      } else {
      }
    }
    getSizesList(filterSize).then(res => {
      this.setState({
        sizesOptions: res
      });
    });
  };
  handleSetDataEntryTags = tags => {
    this.setState({
      dataEntrytags: tags
    });
  };

  handleSearch = () => {
    this.setState({
      data:[],
      count: 0,
      pageSize: 10,
      page: 0,
      editItemId: null,
      
    })
    const {
      barCode,
      referenceNumber,
      selectedSearch,
      selectedBrand,
      pageSize,
      selectedEcommerceOption,
      selectedHasImageOption,
      selectedDescriptionOption,
      selectedFromDate,
      selectedToDate,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedDiscount,
      selectedBulkVerificationStatus,
      selectedRole,
      selectedSizes,
      selectedBulkStatus,
      discountfrom,
      discountTo
    } = this.state;
    this.setState({
      isLoading: true,
      page: 0,
      editItemId: null
    });

    filterData = {
      barCode,
      referenceNumber,
      filtrationType: selectedSearch.value,
      brand: selectedBrand.value,
      inEccommerce: selectedEcommerceOption.value,
      from: selectedFromDate,
      to: selectedToDate,
      hasDescription: selectedDescriptionOption.value,
      hasImages: selectedHasImageOption.value,
      section: selectedSection.Id,
      category_id: selectedCategory.Id,
      sub_category_id: selectedSubCategory.Id,
      size: selectedSizes.value === "ALL" ? null : selectedSizes.value,
      userRole: selectedRole.value === "ALL" ? null : selectedRole.value,
      isVerified:
      selectedBulkStatus.value === "ALL" ? null : selectedBulkStatus.value,
      discountFrom: discountfrom,
      discountTo: discountTo,
      stockType: this.state.selectedStockOption.label === 'ALL' ? null : this.state.selectedStockOption.label,
      OnlinePriceFrom: this.state.onlinePriceFrom,
      OnlinePriceTo: this.state.onlinePriceTo,
    };

    validationSearch(filterData, 0, pageSize)
      .then(res => {
        this.setState({
          data: res.Results,
          count: res.Count,
          isLoading: false,
          enableUpdate: true
        }, () => {
          let temp = [];
          for( let i = 0 ; i < this.state.data.length; i++){
            temp.push(false);
          } 
          this.setState({
            loadedStatus: temp
          })
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "search"
        });
      });
  };

  setDiscountRange = (low, high) => {
    this.setState({
      discountfrom: low,
      discountTo: high
    });
  };
  setOnlinePriceRange = (low, high) => {
    this.setState({
      onlinePriceFrom: low,
      onlinePriceTo: high
    });
  };
  fetchData = () => {
    this.setState({
      editItemId: null,
    })
    const {
      barCode,
      referenceNumber,
      selectedSearch,
      selectedBrand,
      pageSize,
      page,
      selectedEcommerceOption,
      selectedHasImageOption,
      selectedDescriptionOption,
      selectedFromDate,
      selectedToDate,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedSizes,
      selectedDiscount,
      selectedRole,
      selectedBulkStatus,
      discountfrom,
      discountTo,
      data
    } = this.state;

    filterData = {
      barCode,
      referenceNumber,
      filtrationType: selectedSearch.value,
      brand: selectedBrand.value,
      inEccommerce: selectedEcommerceOption.value,
      from: selectedFromDate,
      to: selectedToDate,
      hasDescription: selectedDescriptionOption.value,
      hasImages: selectedHasImageOption.value,
      section: selectedSection.Id,
      category_id: selectedCategory.Id,
      sub_category_id: selectedSubCategory.Id,
      size: selectedSizes.value === "ALL" ? null : selectedSizes.value,
      discountFrom: discountfrom,
      discountTo: discountTo,
      userRole: selectedRole.value === "ALL" ? null : selectedRole.value,
      isVerified:
        selectedBulkStatus.value === "ALL" ? null : selectedBulkStatus.value,
        stockType: this.state.selectedStockOption.label === 'ALL' ? null : this.state.selectedStockOption.label,
        OnlinePriceFrom: this.state.onlinePriceFrom,
        OnlinePriceTo: this.state.onlinePriceTo,
    };

    validationSearch(filterData, page + 1, pageSize)
      .then(res => {
        this.setState({
          page: page + 1,
          data: [...data, ...res.Results],
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "search"
        });
      });
  };

  handleEditItem = index => {
    this.setState({
      editItemId: index
    });
  };

  handleUpdateColor = (oldColor, newColor) => {
    const { pageSize, page, selectedBrand } = this.state;
    this.setState({
      page: 0,
      notificationMsg: ""
    });
    this.setState({
      isUpdatingColor: true
    });
    updateBrandColor(selectedBrand.value, oldColor, newColor)
      .then(() => {
        validationSearch(filterData, page, pageSize)
          .then(res => {
            this.setState({
              data: res.Results,
              count: res.Count,
              isLoading: false,
              enableUpdate: true
            });
          })
          .catch(() => {
            this.setState({
              notificationMsg: "search"
            });
          });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "updateBrandColor"
        });
      });
  };

  handleUpdateBulk = product => {
    const {
      barCode,
      referenceNumber,
      selectedBrand,
      pageSize,
      page
    } = this.state;

    this.setState({
      isUpdatingBulk: true,
      notificationMsg: ""
    });
    updateBulk(barCode, referenceNumber, selectedBrand.value, product)
      .then(() => {
        this.setState({
          notificationMsg: "BulkUpdatedSuccessfully"
        });
        validationSearch(filterData, page, pageSize)
          .then(res => {
            this.setState({
              data: res.Results,
              count: res.Count,
              isLoading: false,
              enableUpdate: true
            });
          })
          .catch(() => {
            this.setState({
              notificationMsg: "search"
            });
          });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "update"
        });
      });
  };
  handleEditOnlinePrice = (index , price) => {
      const temp = this.state.data;
      temp[index].SellingPrice = price;
      this.setState({
        data: temp,
      })
        }
        handleEditOriginalPrice = (index, price) => {
          const temp = this.state.data;
          temp[index].Price = price;
          this.setState({
            data: temp,
          })
        }
    showSuccessMessage = () =>{
      this.setState({
        notificationMsg: "operation accomplished successfully",
      })
    }
    showErrorMessage = () => {
      this.setState({
        notificationMsg: "Error during the operation",
      })
    }
    clearMessage = () => {
      this.setState({
        notificationMsg:'',
      })
    }
        handleEditQuantity = (objectIndex , dataIndex, quantity) => {
          const temp = this.state.data;
          temp[objectIndex].BarcodesExtraData[dataIndex].QTY = quantity;
          this.setState({
            data: temp,
          })
        }

        handleAddNewBarcode = (cardIndex, barcodeInfo) => {
          const temp = this.state.data;
          temp[cardIndex].BarcodesExtraData.unshift(barcodeInfo);
          this.setState({
            data: temp,
          })
        }

  handleExport = () => {
    const {
      barCode,
      referenceNumber,
      selectedSearch,
      selectedBrand,
      selectedDescriptionOption,
      selectedHasImageOption,
      selectedEcommerceOption,
      selectedFromDate,
      selectedToDate,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedSizes,
      selectedDiscount,
      selectedRole,
      discountfrom,
      discountTo
    } = this.state;

    filterData = {
      barCode,
      referenceNumber,
      filtrationType: selectedSearch.value,
      brand: selectedBrand.value,
      inEccommerce: selectedEcommerceOption.value,
      from: selectedFromDate,
      to: selectedToDate,
      hasDescription: selectedDescriptionOption.value,
      hasImages: selectedHasImageOption.value,
      section: selectedSection.Id,
      category_id: selectedCategory.Id,
      sub_category_id: selectedSubCategory.Id,
      size: selectedSizes.value === "ALL" ? null : selectedSizes.value,
      discountFrom: discountfrom,
      discountTo: discountTo,
      stockType: this.state.selectedStockOption.label === 'ALL' ? null : this.state.selectedStockOption.label,
      OnlinePriceFrom: this.state.onlinePriceFrom,
      OnlinePriceTo: this.state.onlinePriceTo,
    };
    this.setState({
      isExporting: true,
      notificationMsg: ""
    });

    exportFile(filterData)
      .then(() => {
        this.setState({
          isExporting: false,
          notificationMsg: "ProductExportedSuccessfully"
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "exportFile"
        });
      });
  };

  setFileData = data => inputFile => {
    this.setState({
      inputFile
    });
  };

  handleImport = data => () => {
    const { inputFile } = this.state;
    const inputData = new FormData();

    this.setState({
      isImporting: true,
      notificationMsg: ""
    });

    inputData.append("File", inputFile);
    importFile(inputData)
      .then(res => {
        this.setState({
          isImporting: false,
          notificationMsg: "ImportedSuccessfully"
        });
      })
      .catch(err => {
        this.setState({
          notificationMsg: "importFile",
          isImporting: false
        });
      });
  };

  handleDeleteItem = index => {
    const { data } = this.state;
    data.splice(index, 1);
    this.setState({
      data: data
    });
  };

  downloadTranslation = () => {
    this.setState({
      notificationMsg: ""
    });
    getShopifyItemDescription().then(() => {
      this.setState({
        notificationMsg: "Sent translation to your email successfully"
      });
    });
  };

  handleCheck = x => {
    const a = this.state.checkedValues;
    const b = a.includes(x) ? a.filter(c => c !== x) : [...a, x];
    const array = [];
    const { data } = this.state;
    for (let i of b) {
      array.push(data[i]._id);
    }
    this.setState({
      checkedValues: b,
      selectedIDS: array
    });
  };

  synkAll = () => {
    this.setState({
      notificationMsg: ""
    });
    const { selectedIDS } = this.state;
    const modal = {
      itemIds: selectedIDS
    };
    syncAll(modal)
      .then(res => {
        if (res.message) {
          this.setState({
            notificationMsg: res.message,
            selectedIDS: [],
            checkedValues: []
          });
          toast.error(res.message);
        } else {
          this.setState({
            selectedIDS: [],
            checkedValues: [],
            notificationMsg: "Synced All Successfully"
          });
        }
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error while Syncing"
        });
      });
  };

  handleActive = (productID, status) => {
    this.setState({
      notificationMsg: ""
    });
    ActivateProduct(productID, status)
      .then(res => {
        const temp = this.state.data;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i]._id === productID) {
            temp[i].IsVerified = !temp[i].IsVerified;
          }
        }
        this.setState({
          data: temp,
          notificationMsg: "operation accomplished successfully"
        });
      })
      .catch(res => {
        this.setState({
          notificationMsg: "Error during the operation"
        });
      });
  };
  handleBulkTgs = () => {
    this.setState({
      notificationMsg: ""
    });
    const {
      barCode,
      referenceNumber,
      selectedSearch,
      selectedBrand,
      pageSize,
      selectedEcommerceOption,
      selectedHasImageOption,
      selectedDescriptionOption,
      selectedFromDate,
      selectedToDate,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedDiscount,
      selectedSizes,
      discountfrom,
      discountTo,
      dataEntrytags
    } = this.state;

    filterData = {
      barCode,
      referenceNumber,
      filtrationType: selectedSearch.value,
      brand: selectedBrand.value,
      inEccommerce: selectedEcommerceOption.value,
      from: selectedFromDate,
      to: selectedToDate,
      hasDescription: selectedDescriptionOption.value,
      hasImages: selectedHasImageOption.value,
      section: selectedSection.Id,
      category_id: selectedCategory.Id,
      sub_category_id: selectedSubCategory.Id,
      size: selectedSizes.value === "ALL" ? null : selectedSizes.value,
      discountFrom: discountfrom,
      discountTo: discountTo,
      tags: dataEntrytags,
      stockType: this.state.selectedStockOption.label === 'ALL' ? null : this.state.selectedStockOption.label,
      OnlinePriceFrom: this.state.onlinePriceFrom,
      OnlinePriceTo: this.state.onlinePriceTo,
    };

    updateDataEntryBulk(filterData).then(res => {
      this.setState({
        notificationMsg: "operation accomplished successfully"
      });
    });
  };

  BulkVerification = () => {
    this.setState({
      notificationMsg: ""
    });
    const {
      barCode,
      referenceNumber,
      selectedSearch,
      selectedBrand,
      pageSize,
      selectedEcommerceOption,
      selectedHasImageOption,
      selectedDescriptionOption,
      selectedFromDate,
      selectedToDate,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedDiscount,
      selectedSizes,
      selectedBulkVerificationStatus,
      discountfrom,
      discountTo
    } = this.state;

    filterData = {
      barCode,
      referenceNumber,
      filtrationType: selectedSearch.value,
      brand: selectedBrand.value,
      inEccommerce: selectedEcommerceOption.value,
      from: selectedFromDate,
      to: selectedToDate,
      hasDescription: selectedDescriptionOption.value,
      hasImages: selectedHasImageOption.value,
      section: selectedSection.Id,
      category_id: selectedCategory.Id,
      sub_category_id: selectedSubCategory.Id,
      size: selectedSizes.value === "ALL" ? null : selectedSizes.value,
      discountFrom: discountfrom,
      discountTo: discountTo,
      isVerified:
      selectedBulkVerificationStatus.value === "ALL" ? null : selectedBulkVerificationStatus.value,
        stockType: this.state.selectedStockOption.label === 'ALL' ? null : this.state.selectedStockOption.label,
      OnlinePriceFrom: this.state.onlinePriceFrom,
      OnlinePriceTo: this.state.onlinePriceTo,
    };

    updateDataEntryBulkVerification(filterData).then(res => {
      this.setState({
        notificationMsg: "operation accomplished successfully"
      });
    });
  };

  handleHide = (productID, status) => {
    this.setState({
      notificationMsg: ""
    });

    HideProduct(productID, status)
      .then(res => {
        const temp = this.state.data;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i]._id === productID) {
            temp[i].Hide = !temp[i].Hide;
          }
        }
        this.setState({
          data: temp,
          notificationMsg: "operation accomplished successfully"
        });
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error during the operation"
        });
      });
  };
  updateImageLoadingStatus = index => {
    const temp = this.state.loadedStatus;
    temp[index] = true;
    this.setState({
      loadedStatus: temp,
    })
  }

  render() {
    // console.log(this.state.data.length);

    const {
      selectedBarCode,
      selectedBulkStatus,
      selectedSearch,
      selectedEcommerceOption,
      selectedHasImageOption,
      selectedDescriptionOption,
      selectedBrand,
      selectedFromDate,
      selectedToDate,
      hasImages,
      hasDesc,
      inEccommerce,
      referenceNumber,
      barCode,
      expanded,
      data,
      editItemId,
      brandsOptions,
      isLoading,
      isUpdatingColor,
      isUpdatingBulk,
      isUpdatingItem,
      enableUpdate,
      count,
      isExporting,
      isImporting,
      notificationMsg,
      sectionsOptions,
      categoriesOptions,
      subCategoriesOptions,
      sizesOptions,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedSizes,
      selectedDiscount,
      selectedBulkVerificationStatus,
      selectedRole,
      discountOptions,
      rolesOptions
    } = this.state;
    const role = getRole();
    return (
      <Grid>
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Grid container justify="center">
          <Grid item xs={12} sm={11}>
            <ExpansionPanel
              expanded={expanded === "searchPanel"}
              onChange={this.handleExpand("searchPanel")}
              className="data-entry-expand"
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <span className="data-entry-expand-title">Search</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <SearchForm
                  selectedStockOption={this.state.selectedStockOption}
                  setDiscountRange={this.setDiscountRange}
                  setOnlinePriceRange={this. setOnlinePriceRange}
                  BulkVerification={this.BulkVerification}
                  handleBulkTgs={this.handleBulkTgs}
                  handleSetDataEntryTags={this.handleSetDataEntryTags}
                  referenceNumber={referenceNumber}
                  barCode={barCode}
                  searchOptions={searchOptions}
                  selectedSearch={selectedSearch}
                  selectedEcommerceOption={selectedEcommerceOption}
                  selectedHasImageOption={selectedHasImageOption}
                  selectedDescriptionOption={selectedDescriptionOption}
                  selectedSection={selectedSection}
                  selectedCategory={selectedCategory}
                  selectedSubCategory={selectedSubCategory}
                  selectedSizes={selectedSizes}
                  ECommenceOptions={ECommenceOptions}
                  bulkOptions={bulkOptions}
                  HasImagesOptions={HasImagesOptions}
                  HasDescriptionOptions={HasDescriptionOptions}
                  barcodeOptions={barcodeOptions}
                  bulkVerificationOptions={bulkVerificationOptions}
                  selectedBarCode={selectedBarCode}
                  selectedBulkStatus={selectedBulkStatus}
                  selectedDiscount={selectedDiscount}
                  selectedBulkVerificationStatus={selectedBulkVerificationStatus}
                  selectedRole={selectedRole}
                  discountOptions={discountOptions}
                  rolesOptions={rolesOptions}
                  brandsOptions={brandsOptions}
                  sectionsOptions={sectionsOptions}
                  categoriesOptions={categoriesOptions}
                  subCategoriesOptions={subCategoriesOptions}
                  sizesOptions={sizesOptions}
                  selectedBrand={selectedBrand}
                  selectedFromDate={selectedFromDate}
                  selectedToDate={selectedToDate}
                  hasImages={hasImages}
                  hasDesc={hasDesc}
                  inEccommerce={inEccommerce}
                  onChange={this.handleChange}
                  onSearch={this.handleSearch}
                  onExport={this.handleExport}
                  onImport={this.handleImport}
                  downloadTranslation={this.downloadTranslation}
                  setFileData={this.setFileData}
                  isExporting={isExporting}
                  isImporting={isImporting}
                  onEditItem={this.handleEditItem}
                  handleDeleteItem={this.handleDeleteItem}
                />
              </ExpansionPanelDetails>
              {isExporting && (
                <Grid container justify="center">
                  <BlackLoader /> Exporting...
                </Grid>
              )}
              {isImporting && (
                <Grid container justify="center">
                  <BlackLoader /> Importing...
                </Grid>
              )}
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={12}>
            {isLoading && <GreenLoader />}
            {data.length > 0 && !isLoading && (
              <div>
                <Grid item xs={12} sm={10} style={{ marginTop: "20px" }}>
                  <h5 style={{marginLeft:'75px'}}> SKUs : {count} </h5>

                  {this.state.selectedIDS.length > 0 && (
                    <Button
                      onClick={this.synkAll}
                      color="secondary"
                      variant="contained"
                    >
                      Sync
                    </Button>
                  )}
                </Grid>
                <InfiniteScroll
                  style={{
                    // overflowX: "hidden",
                    marginLeft: "100px",
                    // overflowX: "scroll"
                  }}
                  dataLength={data.length} // This is important field to render the next data
                  next={this.fetchData}
                  hasMore={data.length !== count && data.length > 0}
                  loader={<GreenLoader />}
                  endMessage={<InfoBox message="You have seen all images!" />}
                >
                  <DataEntryCard
                    handleUpdateBrand ={this.handleUpdateBrand}
                    brandsOptions= {this.state.brandsOptions}
                    loadedStatus={this.state.loadedStatus}
                    updateImageLoadingStatus= {this.updateImageLoadingStatus}
                    handleUpdateDta={this.handleUpdateDta}
                    data={data}
                    editItemId={editItemId}
                    onEditItem={this.handleEditItem}
                    isUpdatingItem={isUpdatingItem}
                    handleDeleteItem={this.handleDeleteItem}
                    handleCheck={this.handleCheck}
                    checkedValues={this.state.checkedValues}
                    handleActive={this.handleActive}
                    handleHide={this.handleHide}
                    handleEditOnlinePrice={this.handleEditOnlinePrice}
                    handleEditOriginalPrice={this.handleEditOriginalPrice}
                    handleEditQuantity={this.handleEditQuantity}
                    showSuccessMessage={this.showSuccessMessage}
                    showErrorMessage={this.showErrorMessage}
                    clearMessage={this.clearMessage}
                    handleAddNewBarcode={this.handleAddNewBarcode}
                    handleUpdateDataForSlectedCard={this.handleUpdateDataForSlectedCard}
                  />
                </InfiniteScroll>
              </div>
            )}
            {data.length === 0 && !isLoading && (
              <InfoBox message="Data Not Available" />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Validation;
