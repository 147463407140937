
import {
  GET,
  POST,
  reform,
  DOWNLOAD,
} from './actions';
import { getRole } from '../services/share/DataService';
import { getInvontoryFile } from './DownloadAPIs';

export const getWarehouses = () => GET('api/lookup/WareHouse', {})
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));


export const getStatuses = () => GET('api/TInventory/status')
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getStores = () => GET('api/TInventory/storelist')
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));


export const getItems = (storeId, status, page, pageSize) => {
  const displayStart = page * pageSize;
  return GET('api/TInventory/items', {
    storeId,
    displayStart,
    pageSize,
    status,
  });
};

export const createStore = (WarehouseId) => {
  const query = {
    WarehouseId,
  };
  return POST('api/TInventory/sync', query);
};

export const clearStore = (StoreId) => {
  const query = {
    StoreId,
  };
  return POST('api/TInventory/clear', query);
};

export const exportFile = (selectedStore, selectedStatus) => {
  const fileUrl = getInvontoryFile(selectedStore, selectedStatus);
  const fileType = 'xlsx';
  const fileName = `${selectedStore.label}-${selectedStatus.label}`;
  const role = getRole();

  if (role === 'Admin' || role === 'Accountant' || role === 'SrAccountant') {
    return DOWNLOAD(fileUrl, fileName, fileType);
  }
};
