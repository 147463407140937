
import {
  GET,
  POST,
  PUT,
  reform,
} from './actions';

// getUserinfo
export const getUsers = () => GET('/api/usermanagement/users', {
  pageIndex: 0,
  pageSize: 0,
})
  .then(res => reform(res.Results, 'Id', 'value'))
  .then(res => reform(res, 'UserName', 'label'));

export const getRoles = () => GET('api/usermanagement/roles')
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const createUser = (userData) => {
  const { newUserName, newUserEmail, newUserRole } = userData;
  const query = {
    UserName: newUserName,
    Email: newUserEmail,
    RoleId: newUserRole.value,
  };
  return POST('api/usermanagement/register', query);
};

export const updateUser = (userData) => {
  const { userEmail, userId, userRole } = userData;
  const query = {
    Id: userId,
    Email: userEmail,
  };
  return PUT(`api/usermanagement/UserInfo?roleId=${userRole.value}`, query);
};

export const changeUserPass = newpass => {
  return POST(`api/usermanagement/password?password=${newpass}`);
}
