import axios from 'axios';
import { getJWT, checkUser } from '../services/share/DataService';

let token = getJWT();
axios.defaults.headers.common = { Authorization: `bearer ${token}` };
if (getJWT()) {
  setInterval(() => {
    checkUser();
    token = getJWT();
    axios.defaults.headers.common = { Authorization: `bearer ${token}` };
  }, 7000);
}

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

// to get the data from API using axios.
const GET = (API, params) => axios.get((API), { params })
  .then(res => res.data);

// to post the data from API using axios.
const POST = (API, Q) => axios.post(API, Q)
  .then(res => res.data);

// to update the data from API using axios.
const PUT = (API, Q) => axios.put(API, Q)
  .then(res => res.data);

// to delete the data from API using axios.
const DELETE = (API, Q) => axios.delete(API, Q)
  .then(res => res.data);

// to download file.
const DOWNLOAD = (fileUrl, fileName, fileType) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  return axios.get(fileUrl, { Authorization: `bearer ${token}`, responseType: 'blob' })
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby.data);
      anchor.href = objectUrl;
      anchor.download = `${fileName}.${fileType}`;
      anchor.click();
    });
};

const DATA_ENTRY_DOWNLOAD = (fileUrl, fileName, fileType, params) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  return axios.get(fileUrl, { params, Authorization: `bearer ${token}`, responseType: 'blob' })
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby.data);
      anchor.href = objectUrl;
      anchor.download = `${fileName}.${fileType}`;
      anchor.click();
    });
};

const Order_Print = (fileUrl, filter) => {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    return axios.post(fileUrl, filter,{Authorization: `bearer ${token}`, responseType: 'blob' })
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby.data);
        anchor.href = objectUrl;
        anchor.download = `Order.pdf`;
        anchor.click();
      });
};

const Order_DOWNLOAD_PDFS = (fileUrl, filter) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  return axios.post(fileUrl, filter,{Authorization: `bearer ${token}`, responseType: 'blob' })
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby.data);
      anchor.href = objectUrl;
      anchor.download = `Orders.pdf`;
      anchor.click();
    });
};

const Trans_DOWNLOAD = (fileUrl, filter) => {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    return axios.post(fileUrl, filter,{Authorization: `bearer ${token}`, responseType: 'blob' })
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby.data);
        anchor.href = objectUrl;
        anchor.download = `OrderTransportation.pdf`;
        anchor.click();
      });
  
};

export  const InvoiceDownload = (fileUrl, filter) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  return axios.post(fileUrl, filter,{Authorization: `bearer ${token}`, responseType: 'blob' })
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby.data);
      anchor.href = objectUrl;
      anchor.download = `Invoice.pdf`;
      anchor.click();
    });

};

// to set token when the user logged in or change token.
const setToken = () => {
  token = getJWT();
  axios.defaults.headers.common = { Authorization: `bearer ${token}` };
};

// to change the properety name for the returned data and name it as I want.
const reform = (arr, oldKey, newKey) => {
  const newArr = arr.map(({ [oldKey]: value, ...rest }) => ({ [newKey]: value, ...rest }));
  return newArr;
};

// to add id for the data returned from filter.
const addIndex = (arr) => {
  const newArr = arr.map((product, index) => ({ ...product, id: index }));
  return newArr;
};

// to duplicate properety & its value.
const duplicate = (arr, oldKey, newKey) => {
  const newArr = arr.map(({ [oldKey]: value, ...rest }) => ({
    [oldKey]: value,
    [newKey]: value,
    ...rest,
  }));
  return newArr;
};

// to get just the unique objects by a specific value in property.
function getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr.map(e => e[comp])
  // store the keys of the unique objects
    . map((e, i, final) => final.indexOf(e) === i && i)
  // eliminate the dead keys & return unique objects
    .filter(e => arr[e]).map(e => arr[e]);
  return unique;
}

// some queries wants the data as array. so I push all values to array and put it in the query.
const toArray = (items) => {
  const idArray = [];
  items.map(item => idArray.push(item.value));
  return idArray;
};

// some queries wants the data as array. so I push all values (as Integers)-
// to array and put it in the query.
const toArrayInts = (items) => {
  const idArray = [];
  items.map(item => idArray.push(parseInt(item.value, 10)));
  return idArray;
};

export {
  GET,
  POST,
  PUT,
  DELETE,
  DOWNLOAD,
  DATA_ENTRY_DOWNLOAD,
  setToken,
  reform,
  duplicate,
  getUnique,
  toArray,
  toArrayInts,
  addIndex,
  Order_Print,
  Order_DOWNLOAD_PDFS,
  Trans_DOWNLOAD,
};
