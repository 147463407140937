import React, { useState, useEffect } from 'react';
import deburr from 'lodash.deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { getTagAutoComplete, getTagAutoCompleteForAdminControl } from '../../actions/DataEntryAPIs';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  tag: {
    zIndex:0
  },
}));

export default ({ setTags, tagsInputValue, tagForAdmin, tagForsku, exculdedArray, excludedIDS, tagForIDs }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const[foAdmin, setFoAdmin] = useState(tagForAdmin);
  const[forSkus, setForSkus] = useState(tagForsku)
  const [forExcludedIDs, setForExcludedIDs] = useState(tagForIDs);
  const classes = useStyles();
  useEffect(() => {
    let tags = [];
    if (tagsInputValue) {
      tags = tagsInputValue.split(',');
      setSelectedItem(tags);
    }
  }, [tagsInputValue]);

  const renderInput = (inputProps) => {
    const {
      InputProps, classes, ref, ...other
    } = inputProps;

    return (
      <TextField
         className={classes.tag}
          variant="outlined"
          InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  };

  const renderSuggestion = (suggestionProps) => {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selected,
    } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selected || '').indexOf(suggestion) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={suggestion}
        selected={isHighlighted}
        component='div'
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion}
      </MenuItem>
    );
  };


  const getSuggestions = (value, { showEmpty = false } = {}) => {
    const input = deburr(value.trim()).toLowerCase();
    const inputLength = input.length;
    let count = 0;
    return inputLength === 0 && !showEmpty
      ? []
      : suggestions.filter((suggestion) => {
        const keep = count < 5 && suggestion.slice(0, inputLength).toLowerCase() === input;
        if (keep) {
          count += 1;
        }
        return keep;
      });
  };

  const handleKeyDown = (event) => {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
     setInputValue(input);
     if (foAdmin) {( getTagAutoCompleteForAdminControl(input)
      .then(res => {
        setSuggestions(res);
      }))}
      else if(forSkus === true){
        const temp = exculdedArray.filter( value => value.includes(input));
        setSuggestions(temp)
      }
      else if ( forExcludedIDs === true){
        const temp = excludedIDS.filter( value => value.includes(input));
        setSuggestions(temp)
      }
      else{
        (getTagAutoComplete(input)
        .then(res => {
          if(!res.includes(input)){
            const temp = res;
            temp.push(input)
            setSuggestions(temp);
          }
          else{
            setSuggestions(res);
          }
        
        }));
      }
    
  };

  const handleChange = (item) => {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setSelectedItem(newSelectedItem);
    setInputValue('');
    setTags(newSelectedItem);
  };
  
  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
    setTags(newSelectedItem);
  };
  return (
    <Downshift
      id='downshift-multiple'
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      }) => {
        const {
          onBlur, onChange, onFocus, ...inputProps
        } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: 'Type here',
        });
        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              label: 'Tags',
              InputLabelProps: getLabelProps(),
              InputProps: {
                startAdornment: selectedItem.map(item => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={handleDelete(item)}
                  />
                )),
                onBlur,
                onChange: (event) => {
                  handleInputChange(event);
                  onChange(event);
                },
                onFocus,
              },
              inputProps,
            })}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue2).map((suggestion, index) => renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({ item: suggestion }),
                  highlightedIndex,
                  selectedItem: selectedItem2,
                }))}
              </Paper>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};
