import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import BarcodeReader from "react-barcode-reader";
import barcodeIcon from "../../../../assests/images/Barcode.png";
import ImageUploaderForTags from "../../ImageUploaderForTags/ImageUploaderForTags";
import ImageUploader from "../../../ImageUploader/ImageUploader";
import AutoComplete from "../../../AutoComplete/AutoComplete";
import { debounce } from "lodash";
import { sync } from "../../../../actions/DataEntryAPIs";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import {
  getItemByBarcode,
  updateColor,
  uploadImage,
  deleteImage,
  deleteTagImage,
  getSections,
  getCategories,
  getSubCategories,
  getSubSubCategories,
  updateSection,
  updateDescAndExtraComm,
  uploadTagsImage,
  getColors,
  updateName
} from "../../../../actions/PhotographerAPIs";
import "./UploadTab.scss";
import GreenLoader from "../../../GreenLoader/GreenLoader";
import InfoBox from "../../../InfoBox/InfoBox";
import MessagesNotifications from "../../../MessagesNotifications/MessagesNotifications";
import BlackLoader from "../../../BlackLoader/BlackLoader";
import FilterSelect from "../../../FilterSelect/FilterSelect";
import "./UploadTab.scss";
const styles = {
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    marginTop: "30px",
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: 5
  }
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: "53px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class UploadTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempBarcodes: [],
      colorId: null,
      barCodeList: [],
      barCodeTags: ["BarCodes"],
      selectedChckBoxes: [],
      selectedId: [],
      selectedBarCodes: [],
      pictures: [],
      barcode: "",
      productDescription: "",
      extraComments: "",
      productColor: "",
      productData: null,
      sections: [],
      categories: [],
      subCategories: [],
      subSubCategories: [],
      colorOptions: [],
      selectedSection: "",
      selectedCategory: "",
      selectedSubCategory: "",
      selectedSubSubCategory: "",
      notificationMsg: "",
      isUploading: false,
      isFinding: false,
      isEditingColor: false,
      isEditingSection: false,
      isEditingDescription: false,
      isEditingName: false,
      TagsImages: [],
      isUploadingTag: false,
      tagsInputValue: null,
      itemSizeId: null,
      englishName: "",
      arabicName: "",
      itemID: null
    };
  }

  handleChangeCheckBoxes = index => event => {
    const tempArray = this.state.selectedChckBoxes;
    tempArray[index] = !tempArray[index];
    this.setState({
      selectedChckBoxes: tempArray
    });
  };
  componentDidMount() {
    getSections()
      .then(res =>
        this.setState({
          sections: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getSections"
        });
      });

    getColors()
      .then(res =>
        this.setState({
          colorOptions: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getColors"
        });
      });
  }

  setTags = tags => {
    let tmp = "";
    tmp = tags.join(",");
    this.setState({
      tagsInputValue: tmp
    });
  };

  showItems = debounce(() => {
    this.setState({
      tempBarcodes: []
    });
    const { sections } = this.state;
    if (this.state.barcode !== "") {
      getItemByBarcode(this.state.barcode)
        .then(res => {
          for (let item of res.ItemColor.Sizes) {
            this.state.barCodeList.push(item.Barcode);
            this.state.selectedId.push({ Id: item.Id });
          }
          for (let item of this.state.barCodeList) {
            this.state.selectedChckBoxes.push(true);
          }
          const selectedSection = sections.find(
            section => section.value === res.SectionId
          );

          this.setState(
            {
              colorId: res.ColorId,
              tagsInputValue: res.tags,
              productData: res,
              arabicName: res.ArabicName,
              englishName: res.EnglishName,
              isFinding: false,
              itemID: res.ItemColor.ItemId,
              selectedSection,
              categories: selectedSection ? getCategories(selectedSection) : [],
              productColor: res.ItemColor ? res.ItemColor.Name : "",
              productDescription: res.Description,
              arabicDescription: res.ArabicDescription,
              extraComments: res.ExtraComments,
              itemSizeId: res.Id
            },
            () => {
              const { categories } = this.state;
              const selectedCategory = categories.find(
                category => category.value === res.CategoryId
              );
              this.setState(
                {
                  selectedCategory,
                  subCategories: selectedCategory
                    ? getSubCategories(selectedCategory)
                    : []
                },
                () => {
                  const { subCategories } = this.state;
                  this.setState(
                    {
                      selectedSubCategory: subCategories.find(
                        subCategory => subCategory.value === res.SubCategoryId
                      )
                    },
                    () => {
                      const selectedSubCategory = subCategories.find(
                        subCategory => subCategory.value === res.SubCategoryId
                      );
                      this.setState(
                        {
                          selectedSubCategory,
                          subSubCategories: selectedSubCategory
                            ? getSubSubCategories(selectedSubCategory)
                            : []
                        },
                        () => {
                          const { subSubCategories } = this.state;
                          this.setState({
                            selectedSubSubCategory: subSubCategories.find(
                              subsubCategory =>
                                subsubCategory.value === res.SubSubCategoryId
                            )
                          });
                        }
                      );
                    }
                  );
                }
              );
            }
          );
        })
        .catch(() => {
          this.setState({
            notificationMsg: "getData"
          });
        });
    } else {
      this.setState({
        isFinding: false
      });
    }
  }, 1000);

  handleChange = (type, value) => {
    const { sections } = this.state;
    switch (type) {
      case "BARCODE":
        this.setState({
          barCodeList: [],
          selectedChckBoxes: [],
          selectedId: [],
          tempBarcodes: [],
          barcode: value,
          isFinding: true,
          productData: null
        });
        this.showItems();
        break;
        case "arabicDescription":
          this.setState({
            arabicDescription: value,
          })
      case "COLOR":
        this.setState({
          productColor: value
        });
        break;
      case "DESCRIPTION":
        this.setState({
          productDescription: value
        });
        break;
      case "EXTRACOMMENTS":
        this.setState({
          extraComments: value
        });
        break;
      case "arabicName":
        this.setState({
          arabicName: value
        });
        break;
      case "englishName":
        this.setState({
          englishName: value
        });
        break;
      default:
        break;
    }
  };
  removeTagImage = tagImageName => {
    const { productData } = this.state;
    this.setState({
      notificationMsg: ""
    });

    let BarCode;
    if (productData.Barcode !== null) {
      BarCode = productData.Barcode;
    }
    if (productData.Barcode === null && productData.Barcode2 !== null) {
      BarCode = productData.Barcode2;
    }

    if (
      productData.Barcode === null &&
      productData.Barcode2 === null &&
      productData.Barcode4 !== null
    ) {
      BarCode = productData.Barcode3;
    }
    deleteTagImage(tagImageName)
      .then(() =>
        getItemByBarcode(BarCode)
          .then(res => {
            this.setState({
              productData: res,
              productColor: res.ItemColor ? res.ItemColor.Name : "",
              itemSizeId: res.Id
            });
          })
          .catch(() => {
            this.setState({
              notificationMsg: "getData"
            });
          })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "deleteImage"
        });
      });
  };

  handleChangeSection = type => selectedValue => {
    switch (type) {
      case "SECTION":
        this.setState({
          selectedSection: selectedValue,
          selectedCategory: "",
          selectedSubCategory: ""
        });
        break;
      case "CATEGORY":
        this.setState({
          selectedCategory: selectedValue,
          selectedSubCategory: ""
        });
        break;
      case "SUBCATEGORY":
        this.setState({
          selectedSubCategory: selectedValue,
          selectedSubSubCategory: ""
        });
        break;
      case "SUBSUBCATEGORY":
        this.setState({
          selectedSubSubCategory: selectedValue
        });
        break;
      default:
        break;
    }
  };

  setPicturesData = product => pictures => {
    // console.log(pictures)
    this.setState({
      pictures
    });
  };

  setImageTagsData = product => TagsImages => {
    this.setState({
      TagsImages
    });
  };
  removeImage = id => {
    const { productData } = this.state;
    this.setState({
      notificationMsg: ""
    });

    let BarCode;
    if (productData.Barcode !== null) {
      BarCode = productData.Barcode;
    }
    if (productData.Barcode === null && productData.Barcode2 !== null) {
      BarCode = productData.Barcode2;
    }

    if (
      productData.Barcode === null &&
      productData.Barcode2 === null &&
      productData.Barcode4 !== null
    ) {
      BarCode = productData.Barcode3;
    }
    deleteImage(id)
      .then(() =>
        getItemByBarcode(BarCode)
          .then(res => {
            this.setState({
              productData: res,
              productColor: res.ItemColor ? res.ItemColor.Name : "",
              itemSizeId: res.Id
            });
          })
          .catch(() => {
            this.setState({
              notificationMsg: "getData"
            });
          })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "deleteImage"
        });
      });
  };

  handleUpload = () => {
    const { productData, pictures } = this.state;
    this.setState({
      notificationMsg: ""
    });
    this.setState({
      isUploading: true
    });

    let BarCode;
    if (productData.Barcode !== null) {
      BarCode = productData.Barcode;
    }
    if (productData.Barcode === null && productData.Barcode2 !== null) {
      BarCode = productData.Barcode2;
    }

    if (
      productData.Barcode === null &&
      productData.Barcode2 === null &&
      productData.Barcode4 !== null
    ) {
      BarCode = productData.Barcode3;
    }
    uploadImage(BarCode, pictures[0])
      .then(() => {
        this.setState({
          ImageUploadSuccessfully: "Image Uploaded Successfuly"
        });
        getItemByBarcode(BarCode)
          .then(res => {
            this.handleUploaded(pictures[0]);
            this.setState({
              productData: res,
              productColor: res.ItemColor ? res.ItemColor.Name : "",
              itemSizeId: res.Id
            });
            if (pictures.length > 0) {
              this.handleUpload();
            }
          })
          .catch(() => {
            this.setState({
              notificationMsg: "getData"
            });
          });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "UploadImage"
        });
      });
  };

  handleEditColor = () => {
    const { productData, productColor, itemSizeId } = this.state;
    this.setState({
      notificationMsg: ""
    });
    this.setState({
      isEditingColor: true
    });

    this.state.tempBarcodes.push({ Id: this.state.itemSizeId });
    for (let i = 0; i < this.state.selectedChckBoxes.length; i++) {
      if (this.state.selectedChckBoxes[i] === true) {
        this.state.tempBarcodes.push(this.state.selectedId[i]);
      }
    }

    const temp = {
      Name: this.state.productColor,
      ItemId: productData.ItemColor.ItemId,
      Sizes: this.state.tempBarcodes
    };
    updateColor(productData.ColorId, temp)
      .then(() =>
        this.setState({
          isEditingColor: false,
          notificationMsg: "Color Updated Successfully"
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "updateColor"
        });
      });
    this.setState({
      tempBarcodes: []
    });
  };

  handleUpdateSection = () => {
    const {
      barcode,
      selectedCategory,
      selectedSubCategory,
      selectedSubSubCategory
    } = this.state;
    this.setState({
      isEditingSection: true,
      notificationMsg: ""
    });
    updateSection(
      barcode,
      selectedCategory,
      selectedSubCategory,
      selectedSubSubCategory
    )
      .then(() =>
        this.setState({
          isEditingSection: false,
          notificationMsg: "Section Updated Successfully"
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "updateSection"
        });
      });
  };

  handleDescAndExtraComm = () => {
    const {
      barcode,
      productDescription,
      extraComments,
      tagsInputValue,
      arabicDescription,
    } = this.state;
    this.setState({
      isEditingDescription: true,
      notificationMsg: ""
    });
    updateDescAndExtraComm(
      barcode,
      productDescription,
      extraComments,
      tagsInputValue,
      arabicDescription
    )
      .then(() =>
        this.setState({
          isEditingDescription: false,
          notificationMsg: "updateDescriptionSuccessfully"
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "updateDescription"
        });
      });
  };

  handleUploaded = picture => {
    const { pictures } = this.state;
    const array = pictures;
    const index = array.indexOf(picture);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState(
        {
          pictures: array
        },
        () => {
          this.setState({ isUploading: pictures.length > 0 });
        }
      );
    }
  };
  handleUpdateName = () => {
    this.setState({
      isEditingName: true,
      notificationMsg: ""
    });
    const query = {
      _id: this.state.itemID,
      Name: this.state.englishName,
      ArabicName: this.state.arabicName
    };

    updateName(query)
      .then(res => {
        this.setState({
          isEditingName: false,
          notificationMsg: "Update Name Successfully"
        });
      })
      .catch(error => {
        this.setState({
          notificationMsg: "Error while updating the name"
        });
      });
  };
  handleScan = barcode => {
    this.setState({
      isFinding: true
    });
    this.setState({
      notificationMsg: ""
    });
    getItemByBarcode(barcode)
      .then(res =>
        this.setState({
          barcode,
          productData: res,
          productColor: res.ItemColor ? res.ItemColor.Name : "",
          productDescription: res.Description,
          arabicDescription: res.ArabicDescription,
          isFinding: false,
          itemSizeId: res.Id
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getData"
        });
      });
  };

  handleError = err => {
    this.setState({
      notificationMsg: "BarcodeReader"
    });
  };

  handleUploadTagsImages = () => {
    const { productData, TagsImages } = this.state;
    this.setState({
      isUploadingTag: true,
      notificationMsg: ""
    });

    let BarCode;
    if (productData.Barcode !== null) {
      BarCode = productData.Barcode;
    }
    if (productData.Barcode === null && productData.Barcode2 !== null) {
      BarCode = productData.Barcode2;
    }

    if (
      productData.Barcode === null &&
      productData.Barcode2 === null &&
      productData.Barcode4 !== null
    ) {
      BarCode = productData.Barcode3;
    }
    uploadTagsImage(BarCode, TagsImages[0])
      .then(() => {
        this.setState({
          notificationMsg: "TageImageUploadSuccessfully"
        });
        getItemByBarcode(BarCode)
          .then(res => {
            this.handleUploadedTags(TagsImages[0]);
            this.setState({
              productData: res,
              productColor: res.ItemColor ? res.ItemColor.Name : "",
              itemSizeId: res.Id
              // TagsImages: [],
            });
            if (TagsImages.length > 0) {
              this.handleUploadTagsImages();
            }
          })
          .catch(() => {
            this.setState({
              notificationMsg: "getData"
            });
          });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "UploadImage"
        });
      });
  };

  handleUploadedTags = picture => {
    const { TagsImages } = this.state;
    const array = TagsImages;
    const index = array.indexOf(picture);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState(
        {
          TagsImages: array
        },
        () => {
          this.setState({ isUploadingTag: TagsImages.length > 0 });
        }
      );
    }
  };

  handleSync = itemId => () => {
    this.setState({
      notificationMsg: ""
    });
    sync(itemId)
      .then(() => {
        this.setState({
          notificationMsg: "SyncedSuccessfully"
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "sync"
        });
      });
  };

  render() {
    const {
      pictures,
      product,
      productData,
      productColor,
      colorOptions,
      productDescription,
      arabicDescription,
      extraComments,
      isUploading,
      isEditingColor,
      isEditingSection,
      isEditingDescription,
      barcode,
      sections,
      selectedSection,
      selectedCategory,
      selectedSubCategory,
      selectedSubSubCategory,
      isFinding,
      notificationMsg,
      TagsImages,
      isUploadingTag,
      englishName,
      arabicName,
      isEditingName
    } = this.state;
    const { classes } = this.props;
    return (
      <Grid>
        <Paper className="upload-scan-paper" elevation={1}>
          {notificationMsg !== "" && (
            <MessagesNotifications message={notificationMsg} />
          )}
          <Grid container>
            <p className="title">BARCODE</p>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12}>
              <img src={barcodeIcon} alt="barcode" className="barcode-icon" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <BarcodeReader
                onError={this.handleError}
                onScan={this.handleScan}
              />
              <input
                type="text"
                id="barcode"
                placeholder="Barcode"
                className="barcode-input"
                onChange={e => this.handleChange("BARCODE", e.target.value)}
                value={barcode}
              />
            </Grid>
          </Grid>
        </Paper>
        {isFinding && <GreenLoader />}
        {productData && productData.Id === 0 && !isFinding && (
          <InfoBox message="Barcode can't be found!" />
        )}

        {productData !== null && productData.Id !== 0 && (
          <Paper className="update-details-paper" elevation={1}>
            <Grid container justify="center">
              <Grid item xs={12} sm={3} className="padding">
                <p className="title">COLOR</p>
                <Grid item xs={12} className="padding">
                  Color
                  <input
                    type="text"
                    id="productColor"
                    placeholder="Color"
                    className="color-input"
                    onChange={e => this.handleChange("COLOR", e.target.value)}
                    value={productColor}
                    disabled
                  />
                  <br />
                  <FilterSelect
                    options={colorOptions}
                    onChange={selected =>
                      this.handleChange("COLOR", selected.label)
                    }
                  />
                  {this.state.barCodeList.length > 0 && (
                    <FormControl className={classes.formControl}>
                      <Select
                        multiple
                        value={this.state.barCodeTags}
                        // onChange={handleChange}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => selected}
                        MenuProps={MenuProps}
                      >
                        {this.state.barCodeList.map((name, index) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={this.state.selectedChckBoxes[index]}
                              onChange={this.handleChangeCheckBoxes(index)}
                              color="primary"
                              style={{ marginRight: 7 }}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} className="padding">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleEditColor}
                    fullWidth
                  >
                    <span className="button-text">Update Color</span>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {isEditingColor && <BlackLoader />}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} className="padding">
                <p className="title">SECTION</p>
                <Grid item xs={12}>
                  Section
                  <FilterSelect
                    options={sections}
                    onChange={this.handleChangeSection("SECTION")}
                    selected={selectedSection}
                  />
                </Grid>
                <Grid item xs={12}>
                  Category
                  <FilterSelect
                    options={
                      selectedSection ? getCategories(selectedSection) : []
                    }
                    onChange={this.handleChangeSection("CATEGORY")}
                    selected={selectedCategory}
                  />
                </Grid>
                <Grid item xs={12}>
                  Sub Category
                  <FilterSelect
                    options={
                      selectedCategory ? getSubCategories(selectedCategory) : []
                    }
                    onChange={this.handleChangeSection("SUBCATEGORY")}
                    selected={selectedSubCategory}
                  />
                </Grid>
                <Grid item xs={12}>
                  Sub Sub Category
                  <FilterSelect
                    options={
                      selectedSubCategory
                        ? getSubSubCategories(selectedSubCategory)
                        : []
                    }
                    onChange={this.handleChangeSection("SUBSUBCATEGORY")}
                    selected={selectedSubSubCategory}
                  />
                </Grid>
                <Grid item xs={12}>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleUpdateSection}
                    fullWidth
                  >
                    <span className="button-text">Update Section</span>
                  </Button>
                </Grid>
                <Grid item xs={12} className="padding">
                  {isEditingSection && <BlackLoader />}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} className="padding">
                <p className="title">NAME</p>
                <Grid item xs={12} className="padding">
                  <span>English Name</span>
                  <input
                    type="text"
                    className="rawabi-input"
                    value={englishName}
                    onChange={e =>
                      this.handleChange("englishName", e.target.value)
                    }
                  />
                  <br /> <br />
                  <span> Arabic Name</span>
                  <input
                    type="text"
                    className="rawabi-input"
                    value={arabicName}
                    onChange={e =>
                      this.handleChange("arabicName", e.target.value)
                    }
                  />
                  <br /> <br /> <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleUpdateName}
                    fullWidth
                  >
                    <span className="button-text">Update Name</span>
                  </Button>
                  <Grid item xs={12}>
                    {isEditingName && <BlackLoader />}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {isEditingColor && <BlackLoader />}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} className="padding">
                <p className="title">DESCRIPTION</p>
                <Grid item xs={12}>
                 English Description
                  <textarea
                    className="description-input"
                    rows="4"
                    onChange={e =>
                      this.handleChange("DESCRIPTION", e.target.value)
                    }
                    value={productDescription || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                 Arabic Description
                  <textarea
                    className="description-input"
                    rows="4"
                    onChange={e =>
                      this.handleChange("arabicDescription", e.target.value)
                    }
                    value={this.state.arabicDescription || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  Extra Comments
                  <textarea
                    className="description-input"
                    rows="4"
                    onChange={e =>
                      this.handleChange("EXTRACOMMENTS", e.target.value)
                    }
                    value={extraComments || ""}
                  />
                </Grid>
                <br />
                <Grid item xs={12}>
                  <AutoComplete
                    setTags={this.setTags}
                    tagsInputValue={this.state.tagsInputValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleDescAndExtraComm}
                    fullWidth
                  >
                    <span className="button-text">Update</span>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {isEditingDescription && <BlackLoader />}
                </Grid>
              </Grid>
            </Grid>
            <Button
              className="synk-button"
              variant="contained"
              color="secondary"
              onClick={this.handleSync(this.state.productData.ItemColor.ItemId)}
            >
              <span className="button-text">Sync</span>
            </Button>
          </Paper>
        )}

        {productData !== null && productData.Id !== 0 && (
          <Grid item xs={12}>
            <Paper className="upload-images-paper" elevation={1}>
              <Grid container>
                <p className="title">IMAGES TAGS</p>
              </Grid>
              <Grid container justify="center">
                <ImageUploaderForTags
                  setPicturesData={this.setImageTagsData(product)}
                  maxFileSize={8388608}
                  imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
                />
                {isUploadingTag && (
                  <div className="uploading">
                    <p className="uploading-text ">UPLOADING</p>
                    <BlackLoader />
                  </div>
                )}
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} sm={2}>
                  {TagsImages.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleUploadTagsImages}
                      fullWidth
                    >
                      <span className="button-text">Upload</span>
                    </Button>
                  )}
                  <br />
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item xs={12} sm={10}>
                  <div className="upload-picture-wrapper">
                    {productData.TagImages.length > 0 &&
                      productData.TagImages.map((image, index) => (
                        <div key={index} className="upload-picture-container">
                          <button
                            type="button"
                            className="delete-button"
                            onClick={() => this.removeTagImage(image)}
                          >
                            x
                          </button>
                          <img
                            src={`${process.env.REACT_APP_API_ENDPOINT}/api/images/tagimage/{imagename}?imagename=${image}`}
                            className="uploadPicture"
                            alt="preview"
                          />
                        </div>
                      ))}
                    {productData.TagImages.length === 0 && (
                      <InfoBox message="Photos not available!" />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {productData !== null && productData.Id !== 0 && (
          <Grid item xs={12}>
            <Paper className="upload-images-paper" elevation={1}>
              <Grid container>
                <p className="title">PRODUCT IMAGES</p>
              </Grid>
              <Grid container justify="center">
                <ImageUploader
                  setPicturesData={this.setPicturesData(product)}
                  maxFileSize={8388608}
                  imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
                />
                {isUploading && (
                  <div className="uploading">
                    <p className="uploading-text ">UPLOADING</p>
                    <BlackLoader />
                  </div>
                )}
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} sm={2}>
                  {pictures.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleUpload}
                      fullWidth
                    >
                      <span className="button-text">Upload</span>
                    </Button>
                  )}
                  <br />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} sm={10}>
                  <div className="upload-picture-wrapper">
                    {productData.ItemColor.Images.length > 0 &&
                      productData.ItemColor.Images.map((image, index) => (
                        <div key={index} className="upload-picture-container">
                          <button
                            type="button"
                            className="delete-button"
                            onClick={() => this.removeImage(image.Id)}
                          >
                            x
                          </button>
                          <img
                            src={`${process.env.REACT_APP_API_ENDPOINT}/api/images/{imagename}?imagename=${image.Name}`}
                            className="uploadPicture"
                            alt="preview"
                          />
                        </div>
                      ))}
                    {productData.ItemColor.Images.length === 0 && (
                      <InfoBox message="Photos not available!" />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(UploadTab);
