import React from "react";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { getRole } from "../../../services/share/DataService";
import "../../../assests/styles/CardStyle.scss";
import noImage from "../../../assests/images/NoImage.png";
import { sync } from "../../../actions/DataEntryAPIs";
import {
  getColors,
  getImagesForImageSlider,
  onSendNewImage
} from "../../../actions/PhotographerAPIs";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
import PopupModal from "../../PopupModal/PopupModal";
import "./DataEntryCard.scss";
import ImageGallery from "react-image-gallery";
import PopupModalForTagImage from "../PopUpForTagImage/PopupModalForTagImage";
import GreenLoader from "../../GreenLoader/GreenLoader";
import EditDialog from "../DataEntryCard/EditDialog/EditDialog";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
const temp = [];
const activateStatus = [];

class DataEntryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "Description",
      colorOptions: [],
      isSyncing: false,
      DataForCards: [],
      ImageId: null,
      ImageSliderShow: false,
      SendingSuccssfully: false,
      notificationMsg: "",
      selectedImage: "",
      ImageArray: [],
      loadingImages: false,
      productId: null,
      isSelected: false,
      showOrHidden: [],
      activateOrUnActivate: []
    };
  }

  componentDidMount() {
    for (let i of this.props.data) {
      temp.push(i.Hide);
      activateStatus.push(i.IsVerified);
    }
    this.setState({
      showOrHidden: temp,
      activateOrUnActivate: activateStatus
    });
    getColors()
      .then(res =>
        this.setState({
          colorOptions: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getColors"
        });
      });
  }

  // to sort the table items by a spicific property either ascending or descending.
  handleRequestSort = (event, property) => {
    const currentOrderBy = property;
    let currentOrder = "desc";
    const { orderBy, order } = this.state;

    if (orderBy === property && order === "desc") {
      currentOrder = "asc";
    }
    this.setState({
      order: currentOrder,
      orderBy: currentOrderBy
    });
  };

  onSlide(currentIndex) {
    this.setState({
      ImageId: currentIndex
    });
  }

  handleSync = itemId => () => {
    this.setState({
      isSyncing: true,
      productId: itemId,
      notificationMsg: ""
    });
    sync(itemId)
      .then(() => {
        this.setState({
          isSyncing: false,
          notificationMsg: "SyncedSuccessfully"
        });
      })
      .catch(() => {
        this.setState({
          isSyncing: false,
          notificationMsg: "sync"
        });
      });
  };

  BarCodeDialog = () => {
    this.setState({
      open: true,
      isClicked: true
    });
  };

  handleEditImage = (product, index) => () => {
    const { onEditItem } = this.props;
    onEditItem(index);
    this.setState({
      ImageArray: [],
      loadingImages: true
    });

    if (product.ImageName !== null) {
      getImagesForImageSlider(product._id, 0, 0)
        .then(res => {
          let array = [];
          res.Results.map(Image => {
            array.push({
              original: `${process.env.REACT_APP_API_ENDPOINT}`
                .concat("/api/images/")
                .concat(Image.Name),
              Id: Image.Id,
              Name: Image.Name
            });
            this.setState({
              ImageArray: array,
              loadingImages: false
            });
          });
        })
        .catch(() => {
          this.setState({
            notificationMsg: "getImages"
          });
        });
    }
    this.setState({
      ImageSliderShow: true
    });
  };

  handleCancelEditImage = (product, index) => () => {
    const { onEditItem } = this.props;
    onEditItem(null);
    this.setState({
      ImageSliderShow: false
    });
  };

  handleSaveSelectedImg = (product, index) => () => {
    let selectedImage = "";
    const { ImageArray } = this.state;
    ImageArray.map((image, index) => {
      if (this.state.ImageID !== null) {
        if (this.state.ImageId === index) {
          selectedImage = image;
        }
      }
    });
    product.ImageName = selectedImage.Name;
    this.setState({
      notificationMsg: ""
    });
    onSendNewImage(selectedImage.Id)
      .then(res => {
        const { onEditItem } = this.props;
        onEditItem(null);
        this.setState({
          ImageSliderShow: false,
          notificationMsg: "ProfileChoosedSuccessfully",
          SendingSuccssfully: true
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "sendingImage"
        });
      });
  };

  render() {
    const {
      colorOptions,
      notificationMsg,
      tagsInputValue,
      ImageSliderShow,
      ImageArray,
      loadingImages
    } = this.state;
    const { editItemId, data } = this.props;
    const role = getRole();
    return (
      <Grid>
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <div className="card-root">
          <Grid container justify="center" spacing={8} className="text-align">
            {data.length > 0 &&
              data.map((product, index) => (
                <Grid
                  item
                  md={2}
                  sm={false}
                  key={index}
                  className="card-center"
                >
                  <Card className="min-height-card">
                    {role !== "QA" && (
                      <Checkbox
                        checked={this.props.checkedValues.includes(index)}
                        onChange={() => this.props.handleCheck(index)}
                        color="primary"
                      />
                    )}
                    <div className="brand-head">
                      <div className="brand-img">
                        {editItemId === index && ImageSliderShow && (
                          <Grid>
                            <ImageGallery
                              items={ImageArray}
                              showThumbnails={false}
                              showNav={true}
                              showPlayButton={false}
                              showFullscreenButton={false}
                              showIndex={true}
                              onSlide={this.onSlide.bind(this)}
                            />
                          </Grid>
                        )}

                        {editItemId === index && loadingImages && (
                          <Grid container justify="center">
                            <GreenLoader />
                          </Grid>
                        )}
                        {product.ImageName !== null && editItemId !== index && (
                          <CardMedia
                            component="img"
                            height="160"
                            image={`${process.env.REACT_APP_API_ENDPOINT}`
                              .concat("/api/images/")
                              .concat(product.ImageName)}
                          />
                        )}

                        {editItemId === index &&
                          product.ImageName !== null &&
                          !ImageSliderShow && (
                            <CardMedia
                              component="img"
                              height="160"
                              image={`${process.env.REACT_APP_API_ENDPOINT}`
                                .concat("/api/images/")
                                .concat(product.ImageName)}
                            />
                          )}
                        {editItemId === index && product.ImageName === null && (
                          <CardMedia
                            component="img"
                            height="160"
                            image={noImage}
                          />
                        )}
                        {editItemId !== index && product.ImageName === null && (
                          <CardMedia
                            component="img"
                            height="160"
                            image={noImage}
                          />
                        )}
                      </div>
                    </div>
                    <div className="brand-name">
                      {product.Brand}{" "}
                      <p style={{ marginBottom: "0" }}>
                        RF.NO : {product.ReferenceNumber}{" "}
                      </p>
                      <p>BarCode : {product.Barcode}</p>
                    </div>
                    {editItemId !== index && (
                      <CardContent className="content-container">
                        <Grid
                          container
                          item
                          xs={12}
                          justify="center"
                          spacing={2}
                        >
                          {editItemId !== index && product.ImageName !== null && (
                            <Grid item className="margin-bottom">
                              {role !== "QA" && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={this.handleEditImage(product, index)}
                                >
                                  <span className="button-text">
                                    Choose Profile Image
                                  </span>
                                </Button>
                              )}
                            </Grid>
                          )}

                          <Grid item className="margin-bottom">
                            <EditDialog
                              product={product}
                              colorOptions={colorOptions}
                              tagsInputValue={tagsInputValue}
                              setTags={this.setTags}
                              handleCommitEdit={this.handleCommitEdit}
                              onEditItem={this.props.onEditItem}
                              handleDeleteItem={this.props.handleDeleteItem}
                            ></EditDialog>
                            <br />
                          </Grid>
                          <Grid item className="sizequantity-container">
                            <span className="dat-entry-size">
                              {" "}
                              <span className="size">Size :</span> &nbsp;{" "}
                              {product.Size}{" "}
                            </span>{" "}
                            &nbsp; &nbsp; &nbsp;
                            <span className="dat-entry-Quantity">
                              {" "}
                              <span className="size">QTY:</span> &nbsp;{" "}
                              {product.Quantity}
                            </span>
                          </Grid>
                        </Grid>
                      </CardContent>
                    )}
                    {editItemId !== index && (
                      <div>
                        <hr></hr>
                        <CardActions>
                          {product.TagImages.length > 0 && (
                            <Grid item className="margin-bottom">
                              <PopupModalForTagImage
                                TagImages={product.TagImages}
                              />
                            </Grid>
                          )}
                          {editItemId !== index && (
                            <Grid style={{ float: "right", marginLeft: 13 }}>
                              <PopupModal barcodeList={product.BarcodeList} />
                            </Grid>
                          )}
                          {role !== "QA" && (
                            <Button
                              onClick={this.handleSync(product._id)}
                              cursor="pointer"
                              size="small"
                              color="primary"
                            >
                              Sync
                            </Button>
                          )}
                        </CardActions>
                        <CardActions>
                          {role === "Admin" && (
                            <Button
                              onClick={() =>
                                this.props.handleActive(
                                  product._id,
                                  !product.IsVerified
                                )
                              }
                              cursor="pointer"
                              size="small"
                              color="primary"
                              style={{ marginLeft: "13px" }}
                            >
                              {product.IsVerified ? "Unactivate" : "Activate"}
                            </Button>
                          )}
                          {role === "Admin" && (
                            <Button
                              onClick={() =>
                                this.props.handleHide(
                                  product._id,
                                  !product.Hide
                                )
                              }
                              cursor="pointer"
                              size="small"
                              color="primary"
                            >
                              {product.Hide ? "Unhide" : "Hide"}
                            </Button>
                          )}
                        </CardActions>
                      </div>
                    )}
                    {ImageSliderShow && editItemId === index && (
                      <div>
                        <hr></hr>
                        <CardActions>
                          <Grid
                            container
                            item
                            xs={12}
                            justify="center"
                            spacing={2}
                          >
                            {ImageSliderShow && editItemId === index && (
                              <Grid item md={6} className="margin-bottom">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  fullWidth
                                  onClick={this.handleCancelEditImage(
                                    product,
                                    index
                                  )}
                                >
                                  <span className="button-text">Cancel</span>
                                </Button>
                              </Grid>
                            )}
                            {ImageSliderShow && editItemId === index && (
                              <Grid item md={6} className="margin-bottom">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={this.handleSaveSelectedImg(
                                    product,
                                    index
                                  )}
                                >
                                  <span className="button-text">Save</span>
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </CardActions>
                      </div>
                    )}
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default DataEntryCard;
