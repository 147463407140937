import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Paper from "@material-ui/core/Paper";
import "./OrderSearchForm.scss";

const SearchForm = ({
  onSearch,
  onChange,
  OrderNumber
}) => (
  <Grid item xs={12}>
    <Grid container className='date-entry-container' justify='center' spacing={6}>
     
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={3}>
            <input
              type='text'
              placeholder='Order Number'
              className='search-input'
              onChange={onChange('OrderNumber')}
              value={OrderNumber}
            />
          </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSearch}
            fullWidth
          >
            <span className="button-text">Search</span>
          </Button>
        </Grid>

      </Grid>
      <div className="break"></div>
    
    </Grid>
  </Grid>
);

export default SearchForm;
