import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { getTransportation } from '../../actions/80/TransportationAPIs';
import TransportationData from '../../components/Transportation/TransportationData';
import GreenLoader from '../../components/GreenLoader/GreenLoader';
import Transport from '../../components/Transport/transport.component';
class Transportation extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount() {}

  render() {
  return (  
    <div>
     <br/>
     <Transport/>
  </div>
  );
  }
}

export default Transportation;
