import React, { Component } from 'react';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import Done from '@material-ui/icons/Done';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import './FanfactoryData.scss';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import BlackLoader from '../../BlackLoader/BlackLoader';
import MessagesNotifications from '../../MessagesNotifications/MessagesNotifications';
import ImageCropper from '../../ImageCropper/ImageCropper';
import {
  updateEntertainment,
  createEntertainment,
  DeleteEntertainment,
  DeleteGame,
  AddNewGame,
  EditGame,
  updateEntertainmentCover,
  updateGameCoverImage
} from '../../../actions/80/FunfactoryAPIs';
import noImage from '../../../assests/images/NoImage.png';
import ImageUploader from '../FunFactoryImageUploader/ImageUploader';

class FunfactorytData extends Component {
  constructor() {
    super();
    this.state = {
      Name: '',
      ArabicName: '',
      ArabicDescription: '',
      Description: '',
      CoverPhoto: '',
      Games: [],
      coverErr: false,
      isUpdating: false,
      isUpdated: false,
      openCrop: false,
      imageType: null,
      notificationMsg: '',
      openAddNewGame: false,
      toUploadPicture: '',
      GameName: '',
      GameDescription: '',
      isSendingGame: false,
      isEditing: false,
      EditGameid: null,
      CardImage: '',
      EditedGameName: '',
      EditedGameDesc: '',
      errorMessages: false,
      CoverPhotoChanged: false,
      email: '',
      phoneNumber: '',
      ExtNumber: '',
    };
  }

  componentDidMount() {
    const { EntertainmentInfo } = this.props;
    this.setState({
      notificationMsg: 'success',
    });
    
    if (EntertainmentInfo) {
      if(EntertainmentInfo.EntertainmentImages.length > 0 && EntertainmentInfo.EntertainmentImages !== null ){
       this.setState({
        CoverPhoto:`${process.env.REACT_APP_API_ENDPOINT_80}`
        .concat(`/${EntertainmentInfo.EntertainmentImages[0].EntertainmentImageUrl}`),
       })
      }
      this.setState({
        Name: EntertainmentInfo.EntertainmentName || '',
        Description: EntertainmentInfo.EntertainmentDescription || '',
        Games: EntertainmentInfo.EntertainmentGames,
        ArabicName: EntertainmentInfo.EntertainmentArabicName,
        ArabicDescription: EntertainmentInfo.EntertainmentArabicDescription,
        email: EntertainmentInfo.Email,
        phoneNumber: EntertainmentInfo.PhoneNumber,
        ExtNumber: EntertainmentInfo.ExtNumber,

      });
    }
  }

  handleChange = event => {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  };

  handleClose = type => {
    this.setState({
      openCrop: false
    });
  };

  setImage = (image, id) => {
    this.setState({
      [id]: image,
      openCrop: false
    });
  };

  handleImageChange = event => {
    const { id } = event.target;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        if (id === 'CoverPhoto') {
          
            this.setState({
              imageType: 'CoverPhoto',
              [id]: reader.result,
              CoverPhotoChanged: true
            });
          }
        
      };
      image.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleCreate = () => {
    const { Name, Description, CoverPhoto } = this.state;
    const { onSelectEntertainment, refreshEntertainments } = this.props;

    const toCreate = {
      Name,
      Description,
    };
    this.setState({
      notificationMsg: '',
    })
    createEntertainment(toCreate).then(res => {
      var base64result = CoverPhoto.substr(CoverPhoto.indexOf(',') + 1);
      const toSaveCoverImage = {
        Entertainment_Id: res.Id,
        EntertainmentImage: base64result,
      }
      updateEntertainmentCover(toSaveCoverImage).then(() => {
        onSelectEntertainment(res.Id);
      })
      this.setState({
        notificationMsg: 'EntertainmentCreated',
      })
    
    
    }).catch(() => {
      this.setState({
        notificationMsg: 'createEntertainment',
      });
    })
  };

  handleUpdate = () => {
    const { Name,ArabicName,ArabicDescription, Description, email, phoneNumber, ExtNumber} = this.state;
    const { EntertainmentInfo, refreshEntertainments } = this.props;

    const toUpdate = {
      Id: EntertainmentInfo.EntertainmentId,
      Name,
      Description,
      phoneNumber,
      email,
      ExtNumber,
      ArabicName,
      ArabicDescription,
    };

    this.setState({
      isUpdating: true,
      isUpdated: false,
      notificationMsg: '',
    });

    updateEntertainment(toUpdate).then(() => {
      this.setState({
        isUpdating: false,
        isUpdated: true,
      })
      refreshEntertainments();
      this.setState({
        notificationMsg: 'EntertainmentUpdated',
      });
    }).catch(() => {
      this.setState({
        notificationMsg: 'updateEntertainment',
      });
    })
  };

  handleDeleteGame = id => {
    const { handleRefreshGames, EntertainmentInfo } = this.props;
    this.setState({
      notificationMsg: '',
    })
    DeleteGame(id).then(() => {
      this.setState({
        notificationMsg: 'GameDeleted'
      })
      handleRefreshGames(EntertainmentInfo.EntertainmentId);
    }
    
    ).catch(() => {
      this.setState({
        notificationMsg: 'deleteGameFun',
      });
    })
  };

  handleDeleteEntertainment = () => {
    const { EntertainmentInfo, afterDeleteEntertainment } = this.props;
    this.setState({
      notificationMsg: '',
    })
    DeleteEntertainment(EntertainmentInfo.EntertainmentId).then(res => {
      if (res === 409) {
        this.setState({
          notificationMsg: 'deleteEntertainment'
        });
      } else {
        this.setState({
          notificationMsg: 'DeletedEntertainment'
        })
        afterDeleteEntertainment();
      }
    });
  };

  OpenAddNewGameForm = () => {
    this.setState({
      openAddNewGame: true
    });
  };

  handleCloseAddGame = () => {
    this.setState({
      openAddNewGame: false
    });
  };

  handleAddNewGame = () => {
    const { EntertainmentInfo, handleRefreshGames } = this.props;
    const { toUploadPicture, GameName, GameDescription } = this.state;

    if (toUploadPicture === '') {
      this.setState({
        errorMessages: true
      });
    } else {
      this.setState({
        notificationMsg: '',
      });

      const toSend = {
        EntertainmentId: EntertainmentInfo.EntertainmentId,
        GameName: GameName,
        GameDescription: GameDescription,
      };
      AddNewGame(toSend).then(res => {
        var base64result = toUploadPicture[0].data.substr(toUploadPicture[0].data.indexOf(',') + 1);

        const toSaveGameImage = {
          Game_id : res.Id,
          GameImage: base64result
        }
        updateGameCoverImage(toSaveGameImage).then(() => {
          this.setState({
            GameCreated:'Game Created Successfully',
          })
          handleRefreshGames(EntertainmentInfo.EntertainmentId);
        })
      
      }).catch(() => {
        this.setState({
          notificationMsg: 'addGame',
        });
      })
    }
  };

  handleEditGame = index => {
    this.setState({
      isEditing: true,
      EditGameid: index
    });
  };

  handleCardImageChange = event => {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        this.setState({
          CardImage: reader.result
        });
      };
      image.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleSaveGame = (GameId, index) => {
    const { EditedName, EditedDesc, CardImage, Games } = this.state;
    const { EntertainmentInfo, handleRefreshGames } = this.props;
    this.setState({
      notificationMsg: '',
      EditGameid: null,
      isEditing: false,
    })
    let toUpdate = {
      EntertainmentId: EntertainmentInfo.EntertainmentId,
      GameId: GameId,
      GameDescription: EditedDesc || Games[index]['GameDescription'],
      GameName: EditedName || Games[index]['GameName']
    };
    
    EditGame(toUpdate).then(() => {
      if(CardImage !== ''){
        var base64result = CardImage.substr(CardImage.indexOf(',') + 1);

        const toUpdateGameImage = {
          GameImage: base64result,
          Game_id: GameId,
        }
        updateGameCoverImage(toUpdateGameImage).then(() => {
          this.setState({
            isEditing: false,
            notificationMsg: 'GameUpdated'
          });
          handleRefreshGames(EntertainmentInfo.EntertainmentId);
        })
      }
      if(CardImage === ''){
      handleRefreshGames(EntertainmentInfo.EntertainmentId);
      }
    }).catch(() => {
      this.setState({
        notificationMsg: 'editGame',
      });
    })
  };

  handleCanceSaveGame = GameId => {
    this.setState({
      isEditing: false,
      EditGameid: null
    });
  };

  setPicturesData = product => toUploadPicture => {
    this.setState({
      toUploadPicture
    });
  };

  handleChangeNDesc = (value, id) => {
    this.setState({
      [id]: value
    });
  };

  handleUploadCoverImage = () => {
    const { CoverPhoto } = this.state;
    const { EntertainmentInfo } = this.props;
    var base64result = CoverPhoto.substr(CoverPhoto.indexOf(',') + 1);

    const toUpdate = {
      Entertainment_Id: EntertainmentInfo.EntertainmentId,
      EntertainmentImage: base64result,
    }
    updateEntertainmentCover(toUpdate).then(() => {
      this.setState({
        notificationMsg: 'EntertainmentUpdated',
        CoverPhotoChanged: false
      });
    }).catch(() => {
      this.setState({
        notificationMsg: 'updateEntertainment',
      });
    })

  }

  render() {
    const {
      ArabicDescription,
      ArabicName,
      Name,
      Description,
      CoverPhoto,
      Games,
      coverErr,
      isUpdating,
      isUpdated,
      notificationMsg,
      openAddNewGame,
      isSendingGame,
      isEditing,
      CardImage,
      EditGameid,
      errorMessages,
      CoverPhotoChanged,
      ExtNumber,
      phoneNumber,
      email,

    } = this.state;
    const { EntertainmentInfo, showCreate } = this.props;

    return (
      <div className='page-root'>
        {notificationMsg !== '' && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Paper>
          <Grid container justify='center'>
            <Grid item xs={12} className='cover-container'>
              <img
                className='cover-photo'
                src={CoverPhoto || noImage}
                alt='cover'
              />
              <Grid item className='upload-button-wrapper' xs={2}>
                <span className='label'>
                  <AddPhotoAlternate />
                  <p>Upload Cover</p>
                </span>
                <input
                  type='file'
                  id='CoverPhoto'
                  accept='image/*'
                  className='upload-box'
                  onChange={this.handleImageChange}
                />
              </Grid>
            </Grid>
            
            <Grid container justify='center'>
              <Grid item xs={6} sm={3}>
                {CoverPhotoChanged  && !showCreate &&
                <Button
                variant="contained"
                color="primary"
                onClick = {this.handleUploadCoverImage}
                fullWidth
                 >
                  Upload Cover Image
                </Button>
                }
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <Grid item xs={12} sm={6}>
                <span> English Name</span>
                <input
                  type='text'
                  className='rawabi-input'
                  id='Name'
                  value={Name}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <Grid item xs={12} sm={6}>
                <span> Arabic Name</span>
                <input
                  type='text'
                  className='rawabi-input'
                  id='ArabicName'
                  value={ArabicName}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>Email</span>
              <input
                required
                id='email'
                type="email"
                className="rawabi-input"
                value={email}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>Phone Number</span>
              <input
                id='phoneNumber'
                required
                type="number"
                className="rawabi-input"
                value={phoneNumber}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>ExtNumber</span>
              <input
                id='ExtNumber'
                type="number"
                className="rawabi-input"
                value={ExtNumber}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
            <Grid container justify='center'>
              <Grid item xs={12} sm={6}>
                <span>English Description</span>
                <textarea
                  className='Description-textarea'
                  rows='4'
                  id='Description'
                  value={Description}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container justify='center'>
              <Grid item xs={12} sm={6}>
                <span>Arabic Description</span>
                <textarea
                  className='Description-textarea'
                  rows='4'
                  id='ArabicDescription'
                  value={ArabicDescription}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <hr />
            </Grid>
            <Grid container justify='center' className='margin-button'>
              {EntertainmentInfo && (
                <Grid
                  container
                  justify='space-evenly'
                  className='margin-bottom'
                >
                  <Grid item md={2} className='margin-bottom'>
                    <Button
                      onClick={this.handleUpdate}
                      variant='contained'
                      color='primary'
                      fullWidth
                      disabled={isUpdating}
                    >
                      <span className='button-text'>Update</span>
                    </Button>
                  </Grid>
                  <Grid item md={2} className='margin-bottom'>
                    <Button
                      onClick={this.handleDeleteEntertainment}
                      variant='contained'
                      color='secondary'
                      fullWidth
                      disabled={isUpdating}
                    >
                      <span className='button-text'>Delete Entertainment</span>
                    </Button>
                  </Grid>
                  <Grid container justify='center' className='margin-bottom'>
                    <Grid item md={2} className='margin-bottom'>
                      <Button
                        onClick={this.OpenAddNewGameForm}
                        variant='contained'
                        style={{
                          borderRadius: 35,
                          backgroundColor: '#21b6ae'
                        }}
                        fullWidth
                        disabled={isUpdating}
                      >
                        <span className='button-text'>Add New Game</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {EntertainmentInfo && openAddNewGame && (
              <Grid container className='date-entry-container' justify='center'>
                <Grid container justify='center'>
                  <div className='break' />
                  <Grid item xs={12} sm={6} className='padding-top'>
                    <input
                      className='search-input'
                      placeholder='Game Name'
                      id='GameName'
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <div className='break' />
                  <Grid item xs={12} sm={6} className='padding-top'>
                    <span> Game Description</span>
                    <textarea
                      className='Description-textarea'
                      rows='4'
                      id='GameDescription'
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid container justify='center'>
                    <ImageUploader
                      setPicturesData={this.setPicturesData({})}
                      maxFileSize={8388608}
                      imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                    />
                  </Grid>
                </Grid>
                {errorMessages && (
                  <Grid container justify='center'>
                    <div className='uploading'>
                      <p style={{ color: 'red' }}>
                        Please Insert An Image for Game
                      </p>
                    </div>
                  </Grid>
                )}
                <div className='break' />

                <Grid container justify='space-evenly'>
                  <Grid item md={2} className='margin-bottom '>
                    <Button
                      onClick={this.handleCloseAddGame}
                      variant='contained'
                      style={{
                        borderRadius: 35
                      }}
                      color='secondary'
                      fullWidth
                    >
                      <span className='button-text'>Cancel</span>
                    </Button>
                  </Grid>
                  <Grid item md={2} className='margin-bottom '>
                    <Button
                      onClick={this.handleAddNewGame}
                      variant='contained'
                      style={{
                        borderRadius: 35,
                        backgroundColor: '#21b6ae'
                      }}
                      fullWidth
                    >
                      <span className='button-text'>Add</span>
                    </Button>
                  </Grid>
                </Grid>
                {isSendingGame && (
                  <Grid container justify='center'>
                    <div className='uploading'>
                      <p className='uploading-text '>Sending</p>
                      <BlackLoader />
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container justify='space-evenly'>
              {Games.length > 0 &&
                Games.map((Game, index) => (
                  <Grid
                    item
                    xs={10}
                    sm={6}
                    style={{ padding: '40px' }}
                    key={index}
                  >
                    <Card className='date-card-height'>
                      <CardActionArea>
                        {EditGameid !== index && Game.GameImages.length > 0 && (
                          <CardMedia
                            component='img'
                            height='140'
                            title='Contemplative Reptile'
                            image={`${process.env.REACT_APP_API_ENDPOINT_80}`
                            .concat(`/${Game.GameImages[0].GameImageUrl}`)}
                          />
                        )}
                        {Game.GameImages.length === 0 && EditGameid !== index && (
                          <CardMedia
                            component='img'
                            height='140'
                            title='Contemplative Reptile'
                            image={noImage}
                          />
                        )}
                        {isEditing && EditGameid === index &&  (
                          <CardMedia
                            component='img'
                            height='140'
                            image={CardImage || noImage}
                          />
                        )}

                        <CardContent>
                          <Grid container justify='center'>
                            {isEditing && EditGameid === index && (
                              <Grid
                                item
                                className='upload-button-wrapperr'
                                xs={4}
                              >
                                <span className='label'>
                                  <AddPhotoAlternate />
                                  <p>Upload Image</p>
                                </span>
                                <input
                                  type='file'
                                  id='GameImage'
                                  accept='image/*'
                                  className='upload-boxr'
                                  onChange={this.handleCardImageChange}
                                />
                              </Grid>
                            )}
                          </Grid>

                          {isEditing && EditGameid === index && (
                            <Grid className='padding-top'>
                              <input
                                className='rawabi-input'
                                type='text'
                                placeholder={Game.GameName}
                                onChange={e =>
                                  this.handleChangeNDesc(
                                    e.target.value,
                                    'EditedName'
                                  )
                                }
                              />
                            </Grid>
                          )}

                          {isEditing && EditGameid === index && (
                            <Grid className='padding-top'>
                              <span> Game Description</span>
                              <textarea
                                type='text'
                                placeholder={Game.GameDescription}
                                className='Description-textarea'
                                rows='4'
                                onChange={e =>
                                  this.handleChangeNDesc(
                                    e.target.value,
                                    'EditedDesc'
                                  )
                                }
                              />
                            </Grid>
                          )}

                          {EditGameid !== index && (
                            <Typography
                              gutterBottom
                              variant='h5'
                              component='h2'
                            >
                              {Game.GameName}
                            </Typography>
                          )}
                          {EditGameid !== index && (
                            <Typography
                              variant='body2'
                              color='textSecondary'
                              component='p'
                            >
                              {Game.GameDescription}
                            </Typography>
                          )}
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        {!isEditing && EditGameid !== index && (
                          <Button
                            onClick={() => this.handleEditGame(index)}
                            variant='contained'
                            color='primary'
                            fullWidth
                            disabled={isUpdating}
                          >
                            <span className='button-text'>Edit Game</span>
                          </Button>
                        )}
                        {isEditing && EditGameid === index && (
                          <Button
                            onClick={() => this.handleSaveGame(Game.GameId, index)}
                            variant='contained'
                            color='primary'
                            fullWidth
                            disabled={isUpdating}
                          >
                            <span className='button-text'>Save</span>
                          </Button>
                        )}
                        {isEditing && EditGameid === index && (
                          <Button
                            onClick={() => this.handleCanceSaveGame(Game.GameId)}
                            variant='contained'
                            color='primary'
                            fullWidth
                            disabled={isUpdating}
                          >
                            <span className='button-text'>Cancel</span>
                          </Button>
                        )}

                        <Button
                          onClick={() => this.handleDeleteGame(Game.GameId)}
                          variant='contained'
                          color='secondary'
                          fullWidth
                          disabled={isUpdating}
                        >
                          <span className='button-text'>Delete</span>
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            <Grid container justify='center' className='margin-button'>
              {showCreate && (
                <Grid item md={4}>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={this.handleCreate}
                  >
                    <span className='button-text'>CREATE</span>
                  </Button>
                </Grid>
              )}
              {isUpdating && (
                <Grid container justify='center'>
                  <BlackLoader />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default FunfactorytData;
