import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  ColumnChooser,
  TableColumnVisibility,
  TableBandHeader,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import './salestable.style.scss';
import myimage from '../../../assests/images/user.png';

export default () => {
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['avgIls','avgUSD','YTD2019','TOTAL2018']);
  const [showbox, setShowbox] = useState(true);
  const [checkedBoxes,setCheckedboxes] = useState([true,true,true,true,true,false]);
  const [checkboxlables,setCheckboxlabel]= useState(['currentDate','monthToDate','thisMonth','previous month','prevoius Year','zz']);
  let products =[
    { csales: 0, cGrossProfit: 'DevExtreme', msales: 'DevExpress',mGrossProfit:'amr',VSLastmonth: 0,
     VSLastyear: 'DevExtreme', premonthsales: 'DevExpress',premonthProfit:'amr',
     preyearsales: 'DevExtreme', preyearProfit: 'DevExpress',avgIls:'amr',avgUSD:44,YTD2019:55,TOTAL2018:55,
    
    }
  ];

  products=  products.map(function(data){
    data.csales = <div>sadsad <img src={myimage}/></div> ;
    return data;
 });
    // const [tableColumnExtensions] = useState([
    //     { columnName: 'csales', width: '140' },
    //     { columnName: 'cGrossProfit', width:  '140' },
    //     { columnName: 'msales', width:  '140' },
    //     { columnName: 'mGrossProfit', width:  '140' },
    //     { columnName: 'VSLastmonth', width:  '140' },
    //     { columnName: 'VSLastyear', width:  '140' },
    //     { columnName: 'premonthsales', width:  '140' },
    //     { columnName: 'premonthProfit', width:  '140' },
    //     { columnName: 'preyearsales', width:  '140' },
    //     { columnName: 'preyearProfit', width:  '140' },
    //     { columnName: 'avgIls', width:  'auto' },
    //     { columnName: 'avgUSD', width:  'auto' },
    //     { columnName: 'YTD2019', width: 'auto' },
    //     { columnName: 'TOTAL2018', width: '140' },
    //   ]);
    const [columns] = useState([
        { name: 'csales', title: 'currentDate' },
        { name: 'cGrossProfit', title: 'current Gross Profit' },

        { name: 'msales', title: 'monthDate' },
        { name: 'mGrossProfit', title: 'month Gross Profit' },

        { name: 'VSLastmonth', title: 'VSLastmonth' },
        { name: 'VSLastyear', title: 'VSLastyear' },

        { name: 'premonthsales', title: 'premonthsales' },
        { name: 'premonthProfit', title: 'premonth Profit' },

        { name: 'preyearsales', title: 'preyearsales' },
        { name: 'preyearProfit', title: 'preyearProfit' },

        { name: 'avgIls', title: 'avgIls' },
        { name: 'avgUSD', title: 'avgUSD' },
        { name: 'YTD2019', title: 'preyearsales' },
        { name: 'TOTAL2018', title: 'TOTAL 2018' },
      



     
      ]);


      const [columnBands] = useState([
        {
          title: 'CURRNETDATE',
          children: [
            { columnName: 'csales' },
            { columnName: 'cGrossProfit' },
          ],
        },
        {
          title: 'Month To Date',
          children: [
            { columnName: 'msales'},
             { columnName: 'mGrossProfit'},
          ],
            },

            {
              title: 'ThisMonth',
              children: [
                { columnName: 'VSLastmonth' },
                { columnName: 'VSLastyear' },
              ],
            },
            {
                title: 'Previous Month',
                children: [
                  { columnName: 'premonthsales' },
                  { columnName: 'premonthProfit' },
                ],
              },
              {
                title: 'Previous Year',
                children: [
                  { columnName: 'preyearsales' },
                  { columnName: 'preyearProfit' },
                ],
              },
              {
                title: 'zz',
                children: [
                  { columnName: 'avgIls' },
                  { columnName: 'avgUSD' },
                  { columnName: 'YTD2019' },
                  { columnName: 'TOTAL2018' },
                ],
              },
          
      ],)

      const [rows] = useState(products);
      const toggleCheckboxes = () => {
        setShowbox(!showbox);
      }
      const handleChange = event => type => {
        checkedBoxes[event]= !checkedBoxes[event];
        const newSelectedArray = [];
        for(let item of checkedBoxes){
          newSelectedArray.push(item);
        }
        setCheckedboxes(newSelectedArray);
        switch(event){
          case '5':
           if(checkedBoxes[5]){
            if(hiddenColumnNames.includes('avgIls')){
              const filterdarray = hiddenColumnNames.filter(item => (item !=='avgIls'&& item!=='avgUSD'&& item!=='YTD2019'&& item!=='TOTAL2018'));
              setHiddenColumnNames(filterdarray);
            }
           }
           else{
            const filterdarray = [];
            for(let item of hiddenColumnNames){
              filterdarray.push(item);
            }
             filterdarray.push('avgIls');
             filterdarray.push('avgUSD');
             filterdarray.push('YTD2019');
             filterdarray.push('TOTAL2018');
             setHiddenColumnNames(filterdarray);
           }
           break;
           case '4':
              if(checkedBoxes[4]){
                if(hiddenColumnNames.includes('preyearsales')){
                  const filterdarray = hiddenColumnNames.filter(item => (item !=='preyearsales'&& item!=='preyearProfit'));
                  setHiddenColumnNames(filterdarray);
                }
               }
               else{
                const filterdarray = [];
            for(let item of hiddenColumnNames){
              filterdarray.push(item);
            }
                filterdarray.push('preyearsales');
                filterdarray.push('preyearProfit');
                 setHiddenColumnNames(filterdarray);
               }
               break;
               case '3':
                if(checkedBoxes[3]){
                  if(hiddenColumnNames.includes('premonthsales')){
                    const filterdarray = hiddenColumnNames.filter(item => (item !=='premonthsales'&& item!=='premonthProfit'));
                    setHiddenColumnNames(filterdarray);
                  }
                 }
                 else{
                  const filterdarray = [];
              for(let item of hiddenColumnNames){
                filterdarray.push(item);
              }
                  filterdarray.push('premonthsales');
                  filterdarray.push('premonthProfit');
                   setHiddenColumnNames(filterdarray);
                 }
                 break;
                 case '2':
                  if(checkedBoxes[2]){
                    if(hiddenColumnNames.includes('VSLastmonth')){
                      const filterdarray = hiddenColumnNames.filter(item => (item !=='VSLastmonth'&& item!=='VSLastyear'));
                      setHiddenColumnNames(filterdarray);
                    }
                   }
                   else{
                    const filterdarray = [];
                for(let item of hiddenColumnNames){
                  filterdarray.push(item);
                }
                    filterdarray.push('VSLastmonth');
                    filterdarray.push('VSLastyear');
                     setHiddenColumnNames(filterdarray);
                   }
                   break;
                   case '1':
                  if(checkedBoxes[1]){
                    if(hiddenColumnNames.includes('msales')){
                      const filterdarray = hiddenColumnNames.filter(item => (item !=='msales'&& item!=='mGrossProfit'));
                      setHiddenColumnNames(filterdarray);
                    }
                   }
                   else{
                    const filterdarray = [];
                for(let item of hiddenColumnNames){
                  filterdarray.push(item);
                }
                    filterdarray.push('msales');
                    filterdarray.push('mGrossProfit');
                     setHiddenColumnNames(filterdarray);
                   }
                   break;
                   case '0':
                    if(checkedBoxes[0]){
                      if(hiddenColumnNames.includes('csales')){
                        const filterdarray = hiddenColumnNames.filter(item => (item !=='csales'&& item!=='cGrossProfit'));
                        setHiddenColumnNames(filterdarray);
                      }
                     }
                     else{
                      const filterdarray = [];
                  for(let item of hiddenColumnNames){
                    filterdarray.push(item);
                  }
                      filterdarray.push('csales');
                      filterdarray.push('cGrossProfit');
                       setHiddenColumnNames(filterdarray);
                     }
                     break;
        }
       
       
      }
  return (
      <div>
           <IconButton onClick={toggleCheckboxes}> <VisibilityOffIcon/></IconButton>

        {showbox && ( <Paper style={{backgroundColor:'#58595B',color:'white'}}>
          <div>

      {checkedBoxes.map((item, index) => (
        <div className='checkbox-container'>
           <Checkbox
        checked={item}
        onChange={handleChange(`${index}`)}
      /> 
        <span>{checkboxlables[index]}</span>
       
      </div> 
      ))}

        

        </div>
     
          </Paper>)}
          <Paper style={{backgroundColor:'#ea5e5e',height:'70px',zIndex:-1,textAlign:'center',fontWeight:'bold',fontSize:23,fontFamily:'Arial Narrow Bold',paddingTop:5}}>
            Sales Table
          </Paper>

    <Paper style={{zIndex:0}}>
      <Grid 
        rows={rows}
        columns={columns}
      >
        <Table  />
        <TableHeaderRow />
        <TableBandHeader
            columnBands={columnBands}
          />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
     {/* <IconButton> <VisibilityOffIcon/></IconButton> */}
        
        
      </Grid>
    </Paper>

      </div>

  );
};
