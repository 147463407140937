import React from 'react';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';

const Sections = ({
  sections,
  selectedSection,
  newSection,
  newSectionArabic,
  editSection,
  editSectionArabic,
  showEditSection,
  onChange,
  onCreate,
  onSelect,
  onDelete,
  onUpdate,
  onShowEdit,
}) => (
  <Grid item sm={4} md={3}>
    <p className='title'>SECTIONS</p>
    {sections.map(section => (
      <Grid container  key={section.Id}>
        <Grid item xs={section.Id === selectedSection.Id ? 8 : 12}>
          <button
            type='submit'
            className={section.Id === selectedSection.Id ? 'selected-list-button' : 'list-button'}
            onClick={() => onSelect('SECTION', section)}
          >{section.Name}
          </button>
        </Grid>
        {section.Id === selectedSection.Id && selectedSection.Categories.length === 0
            && (
              <Grid item xs={2}>
                <button
                  type='submit'
                  className='delete-button'
                  onClick={() => onDelete('SECTION', section)}
                >
                  <Close />
                </button>
              </Grid>
            )}
        {section.Id === selectedSection.Id
            && (
              <Grid item xs={selectedSection.Categories.length === 0 ? 2 : 4}>
                <button
                  type='submit'
                  className='edit-button'
                  onClick={() => onShowEdit('SECTION')}
                >
                  <EditIcon />
                </button>
              </Grid>
            )}
        {section.Id === selectedSection.Id && showEditSection
            && (
              <Grid container  className='edit-box padding'>
                <Grid item xs={12}>
                  <p>Section Name:</p>
                  <input
                    id='editSection'
                    placeholder='section english name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editSection}
                  />
                  <input
                    id='editSectionArabic'
                    placeholder='section arabic name'
                    className='sections-control-input'
                    onChange={onChange()}
                    value={editSectionArabic}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => onUpdate('SECTION')}
                  >
                    <span className='button-text'>Edit</span>
                  </Button>
                </Grid>
              </Grid>
            )}
      </Grid>
    ))}
    <input
      id='newSection'
      placeholder='New section english name'
      className='sections-control-input'
      onChange={onChange()}
      value={newSection}
    />
    <input
      id='newSectionArabic'
      placeholder='New section arabic name'
      className='sections-control-input'
      onChange={onChange()}
      value={newSectionArabic || ''}
    />
    <Button
      disabled={!(newSection || newSectionArabic)}
      type='submit'
      variant='contained'
      color='primary'
      fullWidth
      onClick={() => onCreate('SECTION')}
    >
      <span className='button-text'>Add Section</span>
    </Button>
  </Grid>
);

export default Sections;
