import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { getColors } from '../../actions/PhotographerAPIs';
import {getBrands, addExternalProduction, generateBarcode, generateRefrenceNumber} from '../../actions/DataEntryAPIs';
import FilterSelect from "../../components/FilterSelect/FilterSelect";
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";
import CreateIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip';
import BlackLoader from "../../components/BlackLoader/BlackLoader";
export default class ProductCreation extends Component {
    constructor(props){
        super(props);
        this.state = {
          colorOptions: [],
          COLOR: '',
          seletedColrs: [],
          colorsInformation: [],
          sizesInfromation: [],
          data:{},
          // colorView: {},
          sizeName: '',
          sizeQuantity: '',
          sizeBarocde: '',
          sizeBarocde2: '',
          sizeBarocde3: '',
          brandsOptions: [],
          selectedBrand: { label: "ALL", value: "ALL" },
          selectedIndex: null,
          showAddOperation: false,
          showAddSizesIcon: false,
          englishName: '',
          tagCost: '',
          tagPrice: '',
          referenceNumber: '',
          notificationMsg: '',
          showValidMessage: false,
          onlinePrice: '',
          isGenerateReferenceNumber: false,
          isGenerateBarcode: false,
          isGenerateBarcode2: false,
          isGenerateBarcode3: false,
          
        }
    }
    handleChange = (type, value) => {
      if( type === 'COLOR'){
        this.setState({
          showAddOperation: true,
          showAddSizesIcon: false,
          sizesInfromation: [],
        })
      }
      // this.setState({
      //   sizesInfromation: []
      // })
      // if(type === 'colorView' && value !== ''){
      //   this.setState({
      //     colorView: {label: value, value: value}
      //   })
      //   const index = this.state.colorsInformation.findIndex(res => res.name === value);
      //   console.log(index)
      //   if(index !== -1){
      //     this.setState({
      //       sizesInfromation: this.state.colorsInformation[index].sizes
      //     })
      //   }
      //   else{
      //     this.setState({
      //       sizesInfromation: [],
      //     })
      //   }
      // }
        this.setState({
          // sizesInfromation: [],
          [type]: value,
        })
      
 
       
    
      }
      clearData = () => {
        this.setState({
          colorOptions: [],
          COLOR: '',
          seletedColrs: [],
          colorsInformation: [],
          sizesInfromation: [],
          data:{},
          // colorView: {},
          sizeName: '',
          sizeQuantity: '',
          sizeBarocde: '',
          sizeBarocde2: '',
          sizeBarocde3: '',
          brandsOptions: [],
          selectedBrand: { label: "ALL", value: "ALL" },
          selectedIndex: null,
          showAddOperation: false,
          englishName: '',
          tagCost: '',
          tagPrice: '',
          referenceNumber: '',
          onlinePrice: '',
        })
      }

    componentDidMount(){
      getColors()
      .then(res =>
        this.setState({
          colorOptions: res
        })
      )
      .catch(() => {
        this.setState({
          notificationMsg: "getColors"
        });
      });
      getBrands()
      .then(res => {
        this.setState({
          brandsOptions: res
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "getBrands"
        });
      });
    }
     handleListItemClick = (event, index) => {
      const colorIndex = this.state.colorsInformation.findIndex(res => res.Name === this.state.seletedColrs[index]);
      this.setState({
        selectedIndex: index,
        COLOR: this.state.seletedColrs[colorIndex],
        sizesInfromation: this.state.colorsInformation[colorIndex].Sizes
      });
      
  
    };

    handleGenerateBarcode = inputType => {
      if(inputType === 'sizeBarocde'){
        this.setState({
          isGenerateBarcode: true,
          sizeBarocde: '',
        })
        generateBarcode().then(res => {
          this.setState({
            [inputType]: res.Barcode,
            isGenerateBarcode: false,
          })
        })
      }
      if(inputType === 'sizeBarocde2'){
        this.setState({
          isGenerateBarcode2: true,
          sizeBarocde2: '',
        })
        generateBarcode().then(res => {
          this.setState({
            [inputType]: res.Barcode,
            isGenerateBarcode2: false,
          })
        })
      }
      if(inputType === 'sizeBarocde3'){
        this.setState({
          isGenerateBarcode3: true,
          sizeBarocde3: '',
        })
        generateBarcode().then(res => {
          this.setState({
            [inputType]: res.Barcode,
            isGenerateBarcode3: false,
          })
        })
      }
   
    }

    handleGenerateRefrenceNumber = inputType => {
      this.setState({
        referenceNumber: '',
        isGenerateReferenceNumber: true,
      })
      generateRefrenceNumber().then(res => {
          this.setState({
            [inputType]: res.Reference,
            isGenerateReferenceNumber: false,
          })
      })
    }
    deleteColor = color => {
      const AddedColors = this.state.seletedColrs.filter(res => res !== color);
      const temp = this.state.colorsInformation.filter(res => res.name !== color);
      this.setState({
        seletedColrs: AddedColors,
        colorsInformation: temp,
        sizesInfromation: [],
      })

    }
    handleAddProduct = () => {
      this.setState({
        notificationMsg: '',
      })
      if(this.state.englishName !=='' &&
         this.state.tagCost!== ''     &&
         this.state.referenceNumber !=='' &&
         this.state.COLOR !== '' &&
         this.state.selectedBrand.label !== 'ALL' &&
         this.state.sizesInfromation !== [] &&
         this.state.colorsInformation !== []
         ){
        const temp ={
          Name: this.state.englishName.trim(),
          VatCost: this.state.tagCost,
          SellingPrice: this.state.tagPrice,
          ReferenceNumber: this.state.referenceNumber.trim(),
          Brand: this.state.selectedBrand,
          SellingPriceOnline: this.state.onlinePrice,
          ItemColors: this.state.colorsInformation,
        }
        addExternalProduction(temp).then(res=> {
          this.setState({
            notificationMsg: "operation accomplished successfully",
            showValidMessage: false,
          })
        }).catch(err => {
          this.setState({
            notificationMsg: 'Error during the operation',
            showValidMessage: false,
          })
        })
      }
      else{
        this.setState({
          showValidMessage: true,
        })
      }
    
    }

    handleAddColor = () => {
      if( (!this.state.seletedColrs.includes(this.state.COLOR)) && this.state.COLOR !== ''){
        const temp = {
          Name: this.state.COLOR,
          Sizes: [],
        }
        this.setState({
          showAddSizesIcon: true,
          colorsInformation: [
            ...this.state.colorsInformation,
            temp
          ],
          seletedColrs:[
            ...this.state.seletedColrs,
            this.state.COLOR
          ],
          sizesInfromation: [],
           colorView: {label:this.state.COLOR ,value: this.state.COLOR}
        }, () => {
          const index = this.state.seletedColrs.findIndex(res => res === this.state.COLOR);
          
          this.setState({
            selectedIndex: index
          })
        })
       
      }

    }
    handleAddSizes = () => {
      if(this.state.COLOR !=='' && this.state.seletedColrs.length > 0){
        const temp ={
          Name: this.state.sizeName,
          Quantity: this.state.sizeQuantity,
          Barcode: this.state.sizeBarocde,
          Barcode2: this.state.sizeBarocde2,
          Barcode3: this.state.sizeBarocde3
        }
        this.setState({
          sizesInfromation: [
            ...this.state.sizesInfromation,
            temp
          ]
        },()=>{
          const tempData = this.state.colorsInformation;
        let index ;
        for( let i=0; i< tempData.length; i++){
          if(tempData[i].Name === this.state.COLOR){
            index = i;
          }
        }
        tempData[index].Sizes = this.state.sizesInfromation;
        this.setState({
          colorsInformation: tempData,
        })
        })
      }
    this.setState({
          sizeName: '',
          sizeQuantity: '',
          sizeBarocde: '',
          sizeBarocde2: '',
          sizeBarocde3: '',
    })
      
    }
    
    deleteSize = sizeObj => {
      const index = this.state.colorsInformation.findIndex(res =>  res.Name === this.state.COLOR);
      const temp =  this.state.colorsInformation;
      const filterdSizes = temp[index].Sizes.filter(res => res !== sizeObj );
      temp[index].Sizes = filterdSizes;
      this.setState({
        colorsInformation: temp,
        sizesInfromation: filterdSizes
      })
  
    }

    render() {
        return (
          <Grid> 
             {this.state.notificationMsg !== "" && (
          <MessagesNotifications message={this.state.notificationMsg} />
        )}
            <Paper style={{width: '80%', margin: '0 auto'}}>
              <span style={{margin:'10px'}}> Product Information</span>
                <Grid container justify="center" spacing={3}>
            <Grid item xs={12} sm={3}>
              <span>English name</span>
              <input
              required
                type="text"
                className="rawabi-input"
                value={this.state.englishName}
                onChange={e =>
                  this.handleChange("englishName", e.target.value )
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <span>Tag cost</span>
              <input
                type="number"
                className="rawabi-input"
                value={this.state.tagCost}
                onChange={e =>
                  this.handleChange("tagCost", e.target.value )
                }
              />
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} sm={3}>
              <span>Tag price</span>
              <input
                type="number"
                className="rawabi-input"
                value={this.state.tagPrice}
                onChange={e =>
                  this.handleChange("tagPrice", e.target.value )
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <span> Reference number</span>
              <Grid style={{position: 'relative'}}>
              <input
              
              type="text"
              className="rawabi-input"
              value={this.state.referenceNumber}
              onChange={e =>
                this.handleChange("referenceNumber", e.target.value )
              }
            />
            <Tooltip title='Generate' >
            <Button onClick={() => this.handleGenerateRefrenceNumber('referenceNumber')} style={{position:'absolute',right:'0px'}}>
              <CreateIcon/>
              </Button>
            </Tooltip>
            {
              this.state.isGenerateReferenceNumber && (<div style={{position:'absolute',left:0,top:4}}>
              <BlackLoader />
              </div>)
            }
            
            
             
              </Grid>
             
            
            
            
            </Grid>
          </Grid>
          <Grid container justify='center' spacing={4}>
          <Grid item xs={12} sm={3}>
              <span> online price</span>
              <input
                type="text"
                className="rawabi-input"
                value={this.state.onlinePrice}
                onChange={e =>
                  this.handleChange("onlinePrice", e.target.value )
                }
              />
            </Grid>
          <Grid item xs={12} sm={3}>
          <span> Brand</span>
            <FilterSelect
              options={this.state.brandsOptions}
              selected={this.state.selectedBrand}
              // onChange={this.onChange("selectedBrand")}
              onChange={selected =>
                this.handleChange("selectedBrand", selected )
              }
            />
          </Grid>
       
          </Grid>
            </Paper>
            <br/>
            <Paper style={{width: '80%', margin: '0 auto',minHeight:'200px'}}>
              <span style={{margin:'10px'}}>  Color</span>
              <br/>
              <Grid container justify="flex-start" spacing={3}>
                <Grid item sm={1}/>
              <Grid item xs={12} sm={3}>
              <FilterSelect
                    options={this.state.colorOptions}
                    onChange={selected =>
                      this.handleChange("COLOR", selected.label)
                    }
                  />
                
              </Grid>
              <Grid>
                {
                  this.state.showAddOperation && (<Button onClick={this.handleAddColor}><AddCircleOutlineIcon fontSize='large'/></Button>)
                }
              
              </Grid>
              <Grid item xs={12} sm={4}>
              <List component="nav" aria-label="main mailbox folders">
      
          {
            this.state.seletedColrs.map((color, index) => (
              <React.Fragment>
              <ListItem 
              button
              selected={this.state.selectedIndex === index}
              onClick={(event) => this.handleListItemClick(event, index)}
              >
              <ListItemIcon>
              <DeleteOutlineIcon color='secondary' onClick={ () => this.deleteColor(color)}/> |
             </ListItemIcon>
             <ListItemText primary={color} />
           </ListItem>
            <Divider />
            </React.Fragment>
            ))
          }
       
      </List>
     
      
              </Grid>
            
            </Grid>
            </Paper>
            <br/>
            <Paper style={{width: '80%', margin: '0 auto'}}>
            <span style={{margin:'10px'}}>Size Information</span>
            
            {/* <Grid> */}
           
              {/* <FilterSelect
                    options={this.state.colorOptions}
                    selected= {this.state.colorView}
                    onChange={selected =>
                      this.handleChange("colorView", selected.label)
                    }
                  /> */}
                
{/*              
            </Grid> */}
            <Grid container>
              <Grid item xs={1}/>
              <Grid item  xs={12} sm={2}>
              <Grid container justify="flex-start" spacing={1} direction='column' >
                  <br/>
            <Grid item xs={12}>
              <span>Name</span>
              <input
                type="text"
                className="rawabi-input"
                value={this.state.sizeName}
                onChange={e =>
                  this.handleChange("sizeName", e.target.value )
                }
              />
            </Grid>
            <Grid item xs={12} >
              <span>Quantity</span>
              <input
                type="text"
                className="rawabi-input"
                value={this.state.sizeQuantity}
                onChange={e =>
                  this.handleChange("sizeQuantity", e.target.value )
                }
              />
            </Grid>
            <Grid item xs={12}>
            <Grid style={{position: 'relative'}}>
              <span>  Barcode</span>
              <input
                type="text"
                className="rawabi-input"
                value={this.state.sizeBarocde}
                onChange={e =>
                  this.handleChange("sizeBarocde", e.target.value )
                }
              />
              <Tooltip title='Generate' >
            <Button onClick={ ()=> this.handleGenerateBarcode('sizeBarocde')} style={{position:'absolute',right:'0px'}}>
              <CreateIcon/>
              </Button>
            </Tooltip>
            {
              this.state.isGenerateBarcode && (
              <div style={{position:'absolute',left:0,top:23}}>
              <BlackLoader />
              </div>
              )
            }
            
            </Grid>
            </Grid>
            <Grid item xs={12}>
            <Grid style={{position: 'relative'}}>
              <span>Barcode2</span>
              <input
                type="text"
                className="rawabi-input"
                value={this.state.sizeBarocde2}
                onChange={e =>
                  this.handleChange("sizeBarocde2", e.target.value )
                }
              />
              <Tooltip title='Generate' >
            <Button onClick={ ()=> this.handleGenerateBarcode('sizeBarocde2')} style={{position:'absolute',right:'0px'}}>
              <CreateIcon/>
              </Button>
            </Tooltip>
            {
              this.state.isGenerateBarcode2 && (  <div style={{position:'absolute',left:0,top:23}}>
              <BlackLoader />
              </div>)
            }
          
            </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Barcode3</span>
              <Grid style={{position: 'relative'}}>
              <input
                type="text"
                className="rawabi-input"
                value={this.state.sizeBarocde3}
                onChange={e =>
                  this.handleChange("sizeBarocde3", e.target.value )
                }
              />
              <Tooltip title='Generate' >
            <Button onClick={ ()=> this.handleGenerateBarcode('sizeBarocde3')} style={{position:'absolute',right:'0px'}}>
              <CreateIcon/>
              </Button>
            </Tooltip>
            {
              this.state.isGenerateBarcode3 && (
                <div style={{position:'absolute',left:0,top:4}}>
                <BlackLoader />
                </div>
              )
            }
           
            </Grid>
            </Grid>
            
              <br/>
              
          </Grid>
              </Grid>
              {/* <Grid item xs={1}/> */}
              <Grid item xs={1} j>
                <Grid container  direction='column' justify='center'>
                  {
                    this.state.showAddSizesIcon &&(<Button style={{marginTop:75}} onClick={this.handleAddSizes}><AddCircleOutlineIcon fontSize='large'/></Button>)
                  }
                
                </Grid>
             
             
              </Grid>
              <Grid item xs={12} sm={6}>
         {
           this.state.sizesInfromation.length > 0 && (
            <Table   aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
               <TableCell align="right">Barcode</TableCell>
                <TableCell align="right">Barcode2</TableCell>
                <TableCell align="right">Barcode3</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.sizesInfromation.map((row) => (
                <TableRow  >
                  <TableCell component="th" scope="row">
                    {row.Name}
                  </TableCell>
                  <TableCell align='right'>{row.Barcode}</TableCell>
                  <TableCell align="right">{row.Barcode2}</TableCell>
                  <TableCell align="right">{row.Barcode3}</TableCell>
                  <TableCell align="right">{row.Quantity}</TableCell>
                  <TableCell align="right">
                    <Button onClick={ () => this.deleteSize(row)}>
                    <DeleteOutlineIcon color='secondary'/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> 
           )
         }
          
         
       </Grid>
            </Grid>
             
         <br/>
            </Paper>
            <br/>
            <Grid container justify='center'>
            <Grid item>
              <br/>
              {
                this.state.showValidMessage &&(
                  <span style={{color:'red'}}> All required filed should be filled</span> 
                )
              }
           
            </Grid>
            </Grid>
            <br/>
            <Paper style={{width: '20%', margin: '0 auto',backgroundColor:'#6c757d'}}>
              <Grid container justify='center' alignContent='center' spacing={4}>
                <Grid item>
                <Button color='primary' variant='contained'onClick={this.handleAddProduct}>
                  Add
                </Button>
                </Grid>
              <Grid item>
              <Button color='primary' variant='contained' onClick={this.clearData}>
                 clear
                </Button>
              </Grid>
               
              </Grid>
            </Paper>
            <br/> <br/> <br/>
            </Grid>
        )
    }
}
