import { GET, POST, reform } from './actions';

export const getStatuses = () => GET('api/TInventory/status')
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getStores = () => GET('api/TInventory/storelist')
  .then(res => reform(res, 'Id', 'value'))
  .then(res => reform(res, 'Name', 'label'));

export const getLog = () => GET('api/TInventory/log');

export const getInventoryCount = () => GET('api/TInventory/InventoryCount');


export const checkItem = (StoreId, Quantity, Barcode, InMinusInventory) => {
  const query = {
    StoreId,
    Quantity,
    Barcode,
    InMinusInventory,
  };

  return POST('api/TInventory/inventory', query);
};
