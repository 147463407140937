import React, { Component } from "react";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import ImageCropper from "../../ImageCropper/ImageCropper";
import NoImage from "../../../assests/images/NoImage.png";
import { toast } from "react-toastify";
import "./RestaurantData.scss";
import {
  updateRestaurant,
  createRestaurant,
  uploadImage,
  DeleteMenuImage,
  DeleteResturant,
  getRestaurantImage,
  getImagesBase64,
  UploadImage,
  getRestaurant
} from "../../../actions/80/StoreAPIs";
import BlackLoader from "../../BlackLoader/BlackLoader";
import MessagesNotifications from "../../MessagesNotifications/MessagesNotifications";
import ImageUploader from "../ImageUploaderForRest/ImageUploader";

class RestaurantData extends Component {
  constructor() {
    super();
    this.state = {
      logoImage: "",
      coverImage: "",
      menuImage: "",
      toCropImage: "",
      restaurantName: "",
      phoneNumber: null,
      email: null,
      ExtNumber: null,
      restaurantArabicName: "",
      restaurantDescription: "",
      arabicDescription: "",
      notificationMsg: "",
      logoErr: false,
      coverErr: false,
      openCrop: false,
      isUpdating: false,
      imageType: null,
      toUploadPictures: [],
      isUploading: false,
      ImageTypeForRes: "",
      CoverImageChange: false,
      logoImageChange: false,
      MenuImages: []
    };
  }

  componentDidMount() {
    const { info } = this.props;
    const { MenuImages } = this.state;
    if (info) {
      getRestaurant(info.StoreId).then(res => {
        const tmp = [];

        res.StoreImages.map((image, index) => {
          if (image.StoreImageType === 0) {
            this.setState({
              originalLogo: `${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
                `/${image.StoreImageUrl}`
              )
            });
          }

          if (image.StoreImageType === 1) {
            this.setState({
              originalCover: `${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
                `/${image.StoreImageUrl}`
              )
            });
          }
          if (image.StoreImageType === 2) {
            tmp.push(image);
          }
        });
        this.setState({
          email: info.Email,
          ExtNumber: info.ExtNumber,
          phoneNumber: info.PhoneNumber,
          restaurantName: info.StoreName,
          restaurantDescription: info.StoreDescription || "",
          restaurantArabicName: info.ArabicName,
          arabicDescription: info.ArabicDescription || "",
          MenuImages: tmp
        });
      });
    }
  }

  handleChange = (value, id) => {
    this.setState({
      [id]: value
    });
  };

  handleClose = type => {
    this.setState({
      openCrop: false
    });
  };

  setImage = (image, id) => {
    this.setState({
      [id]: image,
      openCrop: false
    });
  };

  handleCreate = () => {
    const {
      restaurantName,
      restaurantArabicName,
      restaurantDescription,
      arabicDescription,
      coverImage,
      logoImage,
      phoneNumber,
      email,
      ExtNumber
    } = this.state;

    this.setState({
      notificationMsg: ""
    });
    const { onSelectRestaurant, refreshRestaurants, printMessage } = this.props;

    const toCreate = {
      restaurantName,
      restaurantArabicName,
      restaurantDescription,
      arabicDescription,
      phoneNumber,
      email,
      ExtNumber,
      phoneNumber
    };

    createRestaurant(toCreate)
      .then(res => {
        if (coverImage !== "") {
          const toUploadCover = {
            RestaurantImage: coverImage,
            RestaurantImageType: 0,
            Restaurant_Id: res.Id
          };
          UploadImage(toUploadCover);
        }
        if (logoImage !== "") {
          const toUploadLogo = {
            RestaurantImage: logoImage,
            Restaurant_Id: res.Id,
            RestaurantImageType: 1
          };
          UploadImage(toUploadLogo);
        }
        this.setState({
          notificationMsg: "restaurantCreated"
        });
        refreshRestaurants();
        onSelectRestaurant(res.Id);
      })
      .catch(() => {
        this.setState({
          notificationMsg: "createRestaurant"
        });
      });
    printMessage();
  };

  handleUpdate = () => {
    const {
      email,
      phoneNumber,
      ExtNumber,
      restaurantName,
      restaurantArabicName,
      restaurantDescription,
      arabicDescription
    } = this.state;

    const { info, refreshRestaurants } = this.props;

    this.setState({
      isUpdating: true,
      notificationMsg: ""
    });
    const toUpdate = {
      Id: info.StoreId,
      restaurantName,
      restaurantDescription,
      arabicDescription,
      restaurantArabicName,
      email,
      phoneNumber,
      ExtNumber
    };

    updateRestaurant(toUpdate)
      .then(() => {
        refreshRestaurants();
        this.setState({
          isUpdating: false,
          notificationMsg: "Store Updated"
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "updateRestaurant"
        });
      });
  };

  setPicturesData = product => toUploadPictures => {
    this.setState({
      toUploadPictures
    });
  };

  setImageType = product => ImageTypeForRes => {
    this.setState({
      ImageTypeForRes
    });
  };

  handleUpload = () => {
    const { toUploadPictures, ImageTypeForRes } = this.state;
    const { info } = this.props;
    this.setState({
      isUploading: true,
      notificationMsg: ""
    });
    uploadImage(info.StoreId, toUploadPictures[0], 2)
      .then(res => {
        this.setState({
          notificationMsg: "ImageUploaded"
        });
        this.handleUploaded(toUploadPictures[0]);
        if (toUploadPictures.length > 0) {
          this.handleUpload();
        }
        this.handleRefreshMenuImage(info.Id);
      })
      .catch(() => {
        this.setState({
          notificationMsg: "uploadImage"
        });
      });
  };

  handleUploaded = picture => {
    const { toUploadPictures } = this.state;
    const array = toUploadPictures;
    const index = array.indexOf(picture);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState(
        {
          toUploadPictures: array
        },
        () => {
          this.setState({ isUploading: toUploadPictures.length > 0 });
        }
      );
    }
  };

  handleDeleteMenu = (Imageid, index) => {
    const { info } = this.props;
    this.setState({
      notificationMsg: ""
    });
    DeleteMenuImage(Imageid).then(() => {
      this.setState({
        notificationMsg: "MenuImageDeleted"
      });
      this.handleRefreshMenuImage(info.StoreId);
    });
  };

  handleRefreshMenuImage = () => {
    const { info } = this.props;
    getRestaurantImage(info.StoreId).then(res => {
      const tmp = [];
      res.StoreImages.map((image, index) => {
        if (image.RestaurantImageType === 0) {
          this.setState({
            originalLogo: `${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
              `/${image.RestaurantImageUrl}`
            )
          });
        }

        if (image.RestaurantImageType === 1) {
          this.setState({
            originalCover: `${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
              `/${image.RestaurantImageUrl}`
            )
          });
        }
        if (image.RestaurantImageType === 2) {
          tmp.push(image);
        }
      });
      this.setState({
        restaurantName: info.StoreName,
        restaurantDescription: info.StoreDescription || "",
        MenuImages: tmp
      });
    });
  };

  handleDeleteResturant = () => {
    const { info, afterDeleteRes, printMessage } = this.props;
    this.setState({
      notificationMsg: ""
    });
    DeleteResturant(info.StoreId).then(() => {
      afterDeleteRes();
      printMessage();
    });
  };

  handleCoverImageChange = (e, id) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        if (id === "coverImage") {
          this.setState({
            imageType: "coverImage",
            [id]: reader.result,
            CoverImageChange: true
          });
        }
        if (id === "logoImage") {
          this.setState({
            imageType: "logoImage",
            [id]: reader.result,
            logoImageChange: true
          });
        }
      };
      image.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleUploadLogoImage = (e, id) => {
    const { logoImage } = this.state;
    const { info } = this.props;
    const toUploadImages = {
      RestaurantImage: logoImage,
      Restaurant_Id: info.StoreId,
      RestaurantImageType: 0
    };
    UploadImage(toUploadImages)
      .then(() => {
        this.setState({
          notificationMsg: "Store Updated",
          logoImageChange: false
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "updateRestaurant"
        });
      });
  };

  handleUploadCoverImage = (e, id) => {
    const { coverImage } = this.state;
    const { info } = this.props;
    const toUploadImages = {
      RestaurantImage: coverImage,
      Restaurant_Id: info.StoreId,
      RestaurantImageType: 1
    };
    UploadImage(toUploadImages)
      .then(() => {
        this.setState({
          notificationMsg: "Store Updated",
          CoverImageChange: false
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "updateRestaurant"
        });
      });
  };

  render() {
    const {
      logoImage,
      coverImage,
      originalLogo,
      originalCover,
      restaurantName,
      phoneNumber,
      email,
      ExtNumber,
      restaurantArabicName,
      restaurantDescription,
      arabicDescription,
      openCrop,
      toCropImage,
      imageType,
      aspect,
      cropShape,
      isUpdating,
      notificationMsg,
      toUploadPictures,
      isUploading,
      CoverImageChange,
      logoImageChange,
      MenuImages
    } = this.state;
    const { info, showCreate } = this.props;

    return (
      <Paper>
        <Grid container justify="center">
          {notificationMsg !== "" && (
            <MessagesNotifications message={notificationMsg} />
          )}
          <Grid item xs={12}>
            <div className="cover-container">
              <img
                className="cover-img"
                src={coverImage || originalCover || NoImage}
                alt="cover"
              />
              <Grid item className="cover-button-wrapper" xs={1}>
                <span className="label">
                  <AddPhotoAlternate />
                </span>
                <input
                  type="file"
                  id="upload"
                  accept="image/*"
                  className="upload-box"
                  onChange={e => this.handleCoverImageChange(e, "coverImage")}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="profile-container">
              <img
                className="profile-photo"
                src={logoImage || originalLogo || NoImage}
                alt="profile"
              />
              <Grid item className="logo-button-wrapper" xs={1}>
                <span className="label">
                  <AddPhotoAlternate />
                </span>
                <input
                  type="file"
                  id="upload"
                  accept="image/*"
                  className="upload-box"
                  onChange={e => this.handleCoverImageChange(e, "logoImage")}
                />
              </Grid>
            </div>
          </Grid>

          <Grid container justify="center" spacing={2}>
            {!showCreate && logoImageChange && (
              <Grid item xs={6} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUploadLogoImage}
                  fullWidth
                >
                  <span className="button-text">Upload Logo Image</span>
                </Button>
              </Grid>
            )}
            {!showCreate && CoverImageChange && (
              <Grid item xs={6} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUploadCoverImage}
                  fullWidth
                >
                  <span className="button-text">Upload Cover Image</span>
                </Button>
              </Grid>
            )}
          </Grid>

          {!showCreate && (
            <Grid container justify="center">
              <Grid item xs={12} sm={9}>
                {/* <ImageUploader
                  setPicturesData={this.setPicturesData({})}
                  GetImageTypeForRes={this.setImageType()}
                  maxFileSize={5242880}
                  imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
                /> */}
                {isUploading && (
                  <Grid container justify="center">
                    <div className="uploading">
                      <BlackLoader />
                      <p className="uploading-text ">Downloading</p>
                    </div>
                  </Grid>
                )}
                <Grid container justify="center">
                  <Grid item xs={12} sm={3}>
                    {toUploadPictures.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleUpload}
                        fullWidth
                      >
                        <span className="button-text">Upload Menus Images</span>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>English name</span>
              <input
                type="text"
                className="rawabi-input"
                value={restaurantName}
                onChange={e =>
                  this.handleChange(e.target.value, "restaurantName")
                }
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>Arabic name</span>
              <input
                type="text"
                className="rawabi-input"
                value={restaurantArabicName || ""}
                onChange={e =>
                  this.handleChange(e.target.value, "restaurantArabicName")
                }
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>Email</span>
              <input
                type="email"
                className="rawabi-input"
                value={email}
                onChange={e => this.handleChange(e.target.value, "email")}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>Phone Number</span>
              <input
                required
                type="number"
                className="rawabi-input"
                value={phoneNumber}
                onChange={e => this.handleChange(e.target.value, "phoneNumber")}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>ExtNumber</span>
              <input
                type="number"
                className="rawabi-input"
                value={ExtNumber}
                onChange={e => this.handleChange(e.target.value, "ExtNumber")}
              />
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>English description</span>
              <textarea
                className="restaurant-textarea"
                rows="4"
                value={restaurantDescription}
                onChange={e =>
                  this.handleChange(e.target.value, "restaurantDescription")
                }
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <span>Arabic description</span>
              <textarea
                className="restaurant-textarea"
                rows="4"
                value={arabicDescription}
                onChange={e =>
                  this.handleChange(e.target.value, "arabicDescription")
                }
              />
            </Grid>
          </Grid>

          <Grid container justify="space-evenly" className="margin-bottom">
            {info && (
              <Grid container justify="space-evenly" className="margin-bottom">
                <Grid item md={2} className="margin-bottom">
                  <Button
                    onClick={this.handleUpdate}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isUpdating}
                  >
                    <span className="button-text">Update</span>
                  </Button>
                </Grid>
                <Grid item md={2} className="margin-bottom">
                  <Button
                    onClick={this.handleDeleteResturant}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={isUpdating}
                  >
                    <span className="button-text">Delete Store</span>
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={3} className="margin-bottom">
              {showCreate && (
                <Button
                  onClick={this.handleCreate}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <span className="button-text">CREATE</span>
                </Button>
              )}
              {isUpdating && (
                <Grid container justify="center">
                  <BlackLoader />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            {info &&
              MenuImages.length > 0 &&
              MenuImages.map((image, index) => (
                <Grid container key={index}>
                  <img
                    className="menu-photo"
                    src={`${process.env.REACT_APP_API_ENDPOINT_80}`.concat(
                      `/${image.RestaurantImageUrl}`
                    )}
                    alt="Menu"
                  />
                  <Grid container justify="center" className="margin-bottom">
                    <Grid item xs={12} sm={4}>
                      <Button
                        onClick={() =>
                          this.handleDeleteMenu(image.RestaurantImageId, index)
                        }
                        variant="contained"
                        color="secondary"
                        fullWidth
                      >
                        <span className="button-text">Delete Menu</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Dialog open={openCrop} onClose={() => this.handleClose()} fullWidth>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} style={{ minHeight: "500px" }}>
                <ImageCropper
                  image={toCropImage}
                  setImage={this.setImage}
                  imageType={imageType}
                  aspect={aspect}
                  cropShape={cropShape}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Paper>
    );
  }
}

export default RestaurantData;
