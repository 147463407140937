import React, { Component } from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FilterSelect from '../../FilterSelect/FilterSelect';
import { exportFile } from '../../../actions/SrAccountantAPIs';
import './Stores.scss';
import BlackLoader from '../../BlackLoader/BlackLoader';
import MessagesNotifications from '../../MessagesNotifications/MessagesNotifications';

class Stores extends Component {
  constructor() {
    super();
    this.state = {
      selectedStore: '',
      notificationMsg: '',
      selectedStatus: { label: 'ALL', value: undefined },
      buttonDisabled: true,
      openWarning: false,
      isExporting: false,
    };
  }

  handleSelect = (id, selectedValue) => {
    this.setState({
      [id]: selectedValue,
    }, () => {
      const { selectedStore } = this.state;
      this.setState({
        buttonDisabled: !selectedStore,
      });
    });
  }

  handleClickOpen = () => {
    this.setState({ openWarning: true });
  };

  handleClose = () => {
    this.setState({ openWarning: false });
  };

  handleYesButton = () => {
    const { selectedStore } = this.state;
    const { onClear } = this.props;
    this.setState({ openWarning: false });
    onClear(selectedStore.value);
  }

  handleExport = () => {
    const { selectedStore, selectedStatus } = this.state;
    this.setState({
      isExporting: true,
      buttonDisabled: true,
    });
    exportFile(selectedStore, selectedStatus)
      .then(() => this.setState({
        isExporting: false,
        buttonDisabled: false,
      }))
      .catch(() => {
        this.setState({
          notificationMsg: 'exportFile',
        });
      });
  }

  render() {
    const {
      selectedStore,
      selectedStatus,
      buttonDisabled,
      openWarning,
      isExporting,
      notificationMsg,
    } = this.state;
    const {
      onFind,
      storeOptions,
      statusOptions,
      isClearing,
    } = this.props;
    return (
      <Paper className='stores-paper' elevation={1}>
        {notificationMsg !== '' && (<MessagesNotifications message={notificationMsg} />)}
        <Grid container>
          <p className='title'>STORES</p>
        </Grid>
        <Grid container justify='center'>
          <Grid item xs={12} sm={4}>
            <span>Store</span>
            <FilterSelect
              options={storeOptions}
              onChange={(selectedValue) => { this.handleSelect('selectedStore', selectedValue); }}
              disabled={isClearing || isExporting}
            />
            <br />
            <span>Status</span>
            <FilterSelect
              options={statusOptions}
              onChange={(selectedValue) => { this.handleSelect('selectedStatus', selectedValue); }}
              selected={selectedStatus}
              disabled={isClearing || isExporting}
            />
            <br />
            <Grid container justify='center' >
              {isExporting && (
              <Grid item xs={12}>
                <BlackLoader /> Exporting...
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={() => onFind(selectedStore, selectedStatus)}
                  disabled={buttonDisabled}
                >
                  <span className='button-text'>FIND</span>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={this.handleExport}
                  disabled={buttonDisabled || isExporting}
                >
                  <span className='button-text'>Export</span>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  fullWidth
                  onClick={this.handleClickOpen}
                  disabled={buttonDisabled || isClearing}
                >
                  <span className='button-text'>CLEAR</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openWarning}
          onClose={this.handleClose}
        >
          <DialogTitle>Are you sure you want to clear the store?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You will not be able to undo this action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleYesButton} color='secondary'>
              YES
            </Button>
            <Button onClick={this.handleClose} color='secondary'>
              NO
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default Stores;
