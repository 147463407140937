import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BlackLoader from '../../BlackLoader/BlackLoader';

const ProductsCardToolbar = (props) => {
  const {
    numSelected,
    onSend,
    isSending,
    totalUnit,
    selectAll,
    deselectAll,
    isSelectAll,
  } = props;

  return (
    <Toolbar className='table-toolbar'>
      <div className='table-title'>
        {numSelected > 0 ? (
          <Typography variant='h6'>
            {numSelected} Selected
          </Typography>
        ) : (
          <Typography variant='h6'>
              Products
            {totalUnit > 0 && <span> | Total Unit: {totalUnit}</span>}
          </Typography>
        )}
      </div>
      <div className='table-spacer' />
      {numSelected > 0 ? (
        <div className='toolbar-buttons'>
          <Grid container justify='flex-end'>
            <Grid item xs={12} sm={3}>
              {isSending
               && (
               <Grid container justify='center'>
                 <BlackLoader />
               </Grid>
               )}
              {!isSending && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={onSend}
                  fullWidth
                >
                  <span className='button-text'>Send</span>
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      ) : null
      }
      {isSelectAll ? (
        <Button
          variant='contained'
          color='primary'
          onClick={deselectAll}
          fullWidth
        >
          <span className='button-text'>Deselect all</span>
        </Button>
      ) : (
        <Button
          variant='contained'
          color='primary'
          onClick={selectAll}
          fullWidth
        >
          <span className='button-text'>Select all</span>
        </Button>
      )}
    </Toolbar>
  );
};

ProductsCardToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default ProductsCardToolbar;
