import { GET, PUT, POST, DELETE, getBase64, reform } from "./Methods";

export const getStores = () =>
  GET("/api/store/stores")
    .then(res => reform(res, "Id", "value"))
    .then(res => reform(res, "StoreName", "label"))
    .then((res) => {
      res.unshift({ label: 'ALL', value: 'ALL' , Id: null});
      return res;
    });
  

export const getRestaurant = restaurantId => GET(`/api/store/${restaurantId}`);
export const getStoresData = () => GET(`/api/store/StoresUsers`);

export const createRestaurant = restaurantData => {
  const query = {
    StoreName: restaurantData.restaurantName,
    StoreDescription: restaurantData.restaurantDescription,
    PhoneNumber: restaurantData.phoneNumber,
    ArabicDescription: restaurantData.arabicDescription,
    ArabicName: restaurantData.restaurantArabicName,
    Email: restaurantData.email,
    ExtNumber: restaurantData.ExtNumber
  };
  return POST("/api/store", query);
};

export const toDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const updateRestaurant = async restaurantData => {
  toDataUrl(restaurantData.cover, myBase64 => {
    restaurantData.cover = myBase64;
  });
  toDataUrl(restaurantData.logo, myBase64 => {
    restaurantData.logo = myBase64;
  });
  await sleep(500);
  const query = {
    StoreId: restaurantData.Id,
    StoreName: restaurantData.restaurantName,
    StoreDescription: restaurantData.restaurantDescription,
    PhoneNumber: restaurantData.phoneNumber,
    ArabicDescription: restaurantData.arabicDescription,
    ArabicName: restaurantData.restaurantArabicName,
    Email: restaurantData.email,
    ExtNumber: restaurantData.ExtNumber
  };
  return PUT(`/api/store`, query);
};

export const uploadImage = (restaurantId, picture, type) => {
  const myRegexp = /;base64,(.*)/;
  const match = myRegexp.exec(picture.data);
  const base64 = match[1];
  var base64result = base64.substr(base64.indexOf(",") + 1);
  picture = base64result;
  const query = {
    Store_Id: restaurantId,
    StoreImageType: type,
    StoreImage: picture
  };

  return POST(`/api/store/image`, query);
};

export const getRestaurantImage = restaurantId =>
  GET(`/api/store/image/${restaurantId}`);

export const DeleteMenuImage = id => DELETE(`/api/store/image/${id}`);

export const DeleteResturant = id => DELETE(`/api/store/${id}`);
export const DeleteStoreUser = Email => DELETE(`/api/store/storeUser?email=${Email}`);

export const getImagesBase64 = restImageID =>
  GET(`/api/store/image/${restImageID}`);

// export const UploadImage = restImageID => GET(`/api/restaurant/image/${restImageID}`);

export const UploadImage = async ImageData => {
  toDataUrl(ImageData.RestaurantImage, myBase64 => {
    var base64result = myBase64.substr(myBase64.indexOf(",") + 1);
    ImageData.RestaurantImage = base64result;
  });

  await sleep(500);
  const query = {
    Store_Id: ImageData.Restaurant_Id,
    StoreImageType: ImageData.RestaurantImageType,
    StoreImage: ImageData.RestaurantImage
  };
  return POST("/api/store/image", query);
};


export const addStoreUser = userInfo => POST('/api/store/SetStore', userInfo);
