import React, { Component } from "react";
import {
    Button,
    Grid,
    Paper,
    TextField,
    CircularProgress
} from '@material-ui/core/';

import '../../../components/Store/RestaurantSelect/RestaurantSelect.scss';
import '../../../components/Orders/OrderForm/OrderSearchForm.scss';
import FilterSelect from '../../../components/FilterSelect/FilterSelect';
import {
    getLocations,
    getLocationsTypes,
} from '../../../actions/80/MarketingAdApis';

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationsTypes: [
                { value: "All", label: "All" }
            ],
            locations: [],
            locationTypeSelected: { value: "All", label: "All" },
            locationSelected: { value: "All", label: "All" },
            title: '',
            updatedObj: {
                locationId: "",
                locationTypeId: "",
                locationTypeName: "",
                title: ""
            },
            locationSelectDisabled: true,
        };
    }

    async componentDidMount() {
        this.setLocationstypes();
    }

    setLocationstypes = () => {
        let temp = [
            ...this.state.locationsTypes
        ];
        this.props.locationsTypes.map((location) => {
            temp.push({ value: location.Id, label: location.Name });
        });
        this.setState({ locationsTypes: temp });
    }

    setLocations = (locationsData) => {
        let temp = [
            { value: "All", label: "All" }
        ];
        locationsData.map((location) => {
            temp.push({ value: location.Id, label: location.Name });
        });
        this.setState({
            locations: temp,
            locationSelectDisabled: false
        });
    }

    handleSearch = () => {
        this.props.onShowCreateAd && this.props.onShowCreateAd(false);
        this.props.onSearch && this.props.onSearch(this.state.updatedObj);
    }

    handleChangelocationsTypes = async (value) => {
        this.setState({
            locationTypeSelected: value,
            updatedObj: {
                ...this.state.updatedObj,
                locationTypeId: (value.value === "All") ? "" : value.value,
                locationTypeName: (value.value === "All") ? "" : value.label,
                locationId: ""
            },
            locationSelectDisabled: (value.value === "All") ? true : false,
            locations: [],
            locationSelected: { value: "All", label: "All" },
        });
        if (value.label !== "All") {
            let response = await getLocations(value.label);
            if (response) {
                this.setLocations(response);
            } else {
                this.setNotificationMsg("getLocations");
            }
        }
    }

    handleChangelocation = (value) => {
        this.setState({
            locationSelected: value,
            updatedObj: {
                ...this.state.updatedObj,
                locationId: (value.value === "All") ? "" : value.value
            }
        });
    }

    handleChangeTitle = (event) => {
        this.setState({
            title: event.target.value,
            updatedObj: {
                ...this.state.updatedObj,
                title: event.target.value
            }
        })
    }

    render() {
        return (
            <Paper className='stores-paper' elevation={1}>
                <Grid container justify='center'>
                    <Grid item xs={12} sm={3} style={{ paddingRight: '20px' }}>
                        <h6 className="headerFont">Search By Location Type</h6>
                        <FilterSelect
                            disabled={this.props.isSearching}
                            options={this.state.locationsTypes}
                            onChange={this.handleChangelocationsTypes}
                            selected={this.state.locationTypeSelected}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ paddingRight: '20px' }}>
                        <h6 className="headerFont">Search By Location</h6>
                        <FilterSelect
                            disabled={this.state.locationSelectDisabled || this.props.isSearching}
                            options={this.state.locations}
                            onChange={this.handleChangelocation}
                            selected={this.state.locationSelected}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ paddingRight: '20px' }}>
                        <h6 className="headerFont">Search By Title</h6>
                        <TextField
                            id="outlined-size-normal"
                            variant="outlined"
                            size="small"
                            onChange={(e) => this.handleChangeTitle(e)}
                            disabled={this.props.isSearching}
                        />
                    </Grid>
                </Grid>
                <Grid container justify='center' style={{ paddingTop: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSearch()}
                        disabled={this.props.isSearching}
                        style={{ padding: '6px 40px' }}
                    >
                        {
                            this.props.isSearching ? (
                                <CircularProgress
                                    style={{
                                        color: '#fff',
                                        height: '24px',
                                        width: '24px'
                                    }}
                                />
                            ) : (
                                    <span className="button-text">Search</span>
                                )
                        }
                    </Button>
                </Grid>
            </Paper>
        );
    }
}

export default SearchForm;
