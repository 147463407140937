import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Button } from '@material-ui/core';
import TagsInput from 'react-tagsinput';
import FilterSelect from '../../FilterSelect/FilterSelect';
import './FilterForm.scss';


const FilterForm = (props) => {
  const {
    onChange,
    onFilter,
    filterOptions,
    filterSelected,
  } = props;
  return (
    <Paper className='manager-paper' elevation={1}>
      <Grid container>
        <p className='title'>ICG FILTER</p>
      </Grid>
      <Grid container justify='center'>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Warehouse</span>
          <FilterSelect
            options={filterOptions.wareHouses}
            selected={filterSelected.wareHouse}
            placeholder='ALL'
            onChange={onChange('wareHouse')}
            isMulti
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Brand</span>
          <FilterSelect
            options={filterOptions.brands}
            selected={filterSelected.brand}
            placeholder='ALL'
            onChange={onChange('brand')}
            isMulti
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Season</span>
          <FilterSelect
            options={filterOptions.seasons}
            selected={filterSelected.season}
            placeholder='ALL'
            onChange={onChange('season')}
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Section</span>
          <FilterSelect
            options={filterOptions.sections}
            selected={filterSelected.section}
            placeholder='ALL'
            onChange={onChange('section')}
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Family</span>
          <FilterSelect
            options={filterOptions.families}
            selected={filterSelected.family}
            placeholder='ALL'
            onChange={onChange('family')}
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Sub Family</span>
          <FilterSelect
            options={filterOptions.subFamilies}
            selected={filterSelected.subFamily}
            placeholder='ALL'
            onChange={onChange('subFamily')}
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Barcode</span>
          <div className='tagInputContainer'>
            <TagsInput type='text'
             value={filterSelected.barCode} 
             onChange={onChange('barCode')}
             />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <span>Reference No.</span>
          <input
            type='text'
            className='manager-input'
            onChange={onChange('referenceNo')}
            value={filterSelected.referenceNo}
          />
        </Grid>
        <Grid item xs={12} sm={4} className='padding'>
          <br />
          <Button
            variant='contained'
            color='primary'
            onClick={onFilter}
            fullWidth
          >
            <span className='button-text'>Search</span>
          </Button>
        </Grid>
      </Grid>
    </Paper>

  );
};

export default FilterForm;
