import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import FilterForm from "../../components/Manager/FilterForm/FilterForm";
import ManagerCard from "../../components/Manager/ManagerCard/ManagerCard";
import ManagerCardToolbar from "../../components/Manager/ManagerCard/ManagerCardToolbar";
import GreenLoader from "../../components/GreenLoader/GreenLoader";
import InfoBox from "../../components/InfoBox/InfoBox";
import MessagesNotifications from "../../components/MessagesNotifications/MessagesNotifications";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import uid from "uid";
import {
  getAllBrands,
  getAllWareHouses,
  getAllSeasons,
  getAllSections,
  getAllFamilies,
  getAllSubFamilies,
  getSections,
  getCategories,
  getSubCategories,
  getProductColor,
  productsFilter,
  changePage,
  getTotalRecords,
  getTotalUnit,
  sendProducts
} from "../../actions/ManagerAPIs";
import "./Manager.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Manager extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      open: false,
      selected: [],
      selectedData: [],
      colors: [],
      filterOptions: {
        wareHouses: [],
        brands: [],
        seasons: [],
        sections: [],
        families: [],
        subFamilies: []
      },
      filterSelected: {
        wareHouse: [],
        brand: [],
        season: "",
        section: "",
        family: "",
        subFamily: "",
        referenceNo: "",
        barCode: []
      },
      eccommerceSections: [],
      eccommerceCategories: [],
      eccommerceSubCategories: [],
      selectedCheckboxes: {},
      selectedSection: "",
      selectedCategory: "",
      selectedSubCategory: "",
      currentPage: 0,
      heighestVisitedPage: 0,
      totalRecords: 0,
      totalUnit: 0,
      rowsPerPage: 20,
      isSending: false,
      isLoading: false,
      showTable: false,
      selectAll: false,
      expanded: null,
      notificationMsg: "",
      DuplicationIssues: []
    };
  }

  componentDidMount() {
    /* fetching data from the server to add it on each combo box */
    getAllBrands()
      .then(res => {
        const { filterOptions } = this.state;
        this.setState({
          filterOptions: {
            ...filterOptions,
            brands: res
          }
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "getBrands"
        });
      });

    getAllWareHouses()
      .then(res => {
        const { filterOptions } = this.state;
        this.setState({
          filterOptions: {
            ...filterOptions,
            wareHouses: res
          }
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "getAllWareHouses"
        });
      });

    getAllSeasons()
      .then(res => {
        const { filterOptions } = this.state;
        this.setState({
          filterOptions: {
            ...filterOptions,
            seasons: [{ label: "ALL", value: undefined }, ...res]
          }
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "getAllSeasons"
        });
      });

    getSections()
      .then(res => {
        this.setState({
          eccommerceSections: res
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "getSections"
        });
      });
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  // to change the filter items.
  handleChange = id => selectedValue => {
    const { filterSelected } = this.state;
    // here you will get other data depeded in your choice.
    if (id === "brand" || id === "section" || id === "family") {
      this.setState(
        {
          filterSelected: {
            ...filterSelected,
            [id]: selectedValue
          }
        },
        () => {
          this.handleEmptying(id);
          this.getData(id);
        }
      );
    } else if (id === "selectedSection") {
      this.setState({
        ...filterSelected,
        [id]: selectedValue,
        eccommerceCategories: getCategories(selectedValue),
        selectedCategory: "",
        selectedSubCategory: ""
      });
    } else if (id === "selectedCategory") {
      this.setState({
        ...filterSelected,
        [id]: selectedValue,
        eccommerceSubCategories: getSubCategories(selectedValue),
        selectedSubCategory: ""
      });
    } else if (id === "selectedSubCategory") {
      this.setState({
        ...filterSelected,
        [id]: selectedValue
      });
    } else if (id === "referenceNo") {
      this.setState({
        filterSelected: {
          ...filterSelected,
          [id]: selectedValue.target.value
        }
      });
    } else if (id === "barCode") {
      this.setState({
        filterSelected: {
          ...filterSelected,
          barCode: selectedValue
        }
      });
    } else if (id) {
      this.setState({
        filterSelected: {
          ...filterSelected,
          [id]: selectedValue
        }
      });
    }
  };

  handleChangePrice = (referenceNo, value) => {
    const { selectedData } = this.state;
    let newData = [];

    newData = selectedData.map(product =>
      product.ReferenceNumber === referenceNo
        ? {
            ...product,
            price: value
          }
        : product
    );

    this.setState({
      selectedData: newData
    });
  };

  handleChangeSection = (type, referenceNo) => selectedValue => {
    const { selectedData } = this.state;
    let newData = [];
    switch (type) {
      case "SECTION":
        newData = selectedData.map(product =>
          product.ReferenceNumber === referenceNo
            ? {
                ...product,
                section: selectedValue,
                category: "",
                categories: getCategories(selectedValue)
              }
            : product
        );
        break;
      case "CATEGORY":
        newData = selectedData.map(product =>
          product.ReferenceNumber === referenceNo
            ? {
                ...product,
                category: selectedValue,
                subCategory: "",
                subCategories: getSubCategories(selectedValue)
              }
            : product
        );
        break;
      case "SUBCATEGORY":
        newData = selectedData.map(product =>
          product.ReferenceNumber === referenceNo
            ? {
                ...product,
                subCategory: selectedValue
              }
            : product
        );
        break;
      default:
        break;
    }

    this.setState({
      selectedData: newData
    });
  };

  // for emptying the options if you change some of the upper values.
  handleEmptying = id => {
    const { filterOptions, filterSelected } = this.state;
    if (id === "brand") {
      this.setState({
        filterOptions: {
          ...filterOptions,
          sections: [],
          families: [],
          subFamilies: []
        },
        filterSelected: {
          ...filterSelected,
          section: "",
          family: "",
          subFamily: ""
        }
      });
    }
    if (id === "section") {
      this.setState({
        filterOptions: {
          ...filterOptions,
          families: [],
          subFamilies: []
        },
        filterSelected: {
          ...filterSelected,
          family: "",
          subFamily: ""
        }
      });
    }
    if (id === "family") {
      this.setState({
        filterOptions: {
          ...filterOptions,
          subFamilies: []
        },
        filterSelected: {
          ...filterSelected,
          subFamily: ""
        }
      });
    }
  };

  // to get the options for sections, familie & sub families.
  getData = id => {
    const { filterSelected, filterOptions } = this.state;
    const { brand, section, family } = filterSelected;
    if (id === "brand") {
      getAllSections(brand)
        .then(res => {
          this.setState({
            filterOptions: {
              ...filterOptions,
              sections: [{ label: "ALL", value: undefined }, ...res]
            }
          });
        })
        .catch(() => {
          this.setState({
            notificationMsg: "getSections"
          });
        });
    } else if (id === "section") {
      getAllFamilies(brand, section.value)
        .then(res => {
          this.setState({
            filterOptions: {
              ...filterOptions,
              families: [{ label: "ALL", value: undefined }, ...res]
            }
          });
        })
        .catch(() => {
          this.setState({
            notificationMsg: "getAllFamilies"
          });
        });
    } else if (id === "family") {
      getAllSubFamilies(brand, section.value, family.value)
        .then(res => {
          this.setState({
            filterOptions: {
              ...filterOptions,
              subFamilies: [{ label: "ALL", value: undefined }, ...res]
            }
          });
        })
        .catch(() => {
          this.setState({
            notificationMsg: "getAllSubFamilies"
          });
        });
    }
  };

  // to filter.
  handleFilter = () => {
    this.setState({
      isLoading: true,
      showTable: false,
      currentPage: 0,
      heighestVisitedPage: 0,
      totalRecords: 0,
      totalUnit: 0,
      data: [],
      selected: [],
      selectedData: [],
      eccommerceCategories: [],
      selectedCategory: ""
    });
    const { filterSelected, rowsPerPage } = this.state;
    productsFilter(filterSelected, rowsPerPage, 0)
      .then(res => {
        this.setState(
          {
            data: res,
            isLoading: false,
            showTable: true
          },
          () => {
            this.setState({
              totalRecords: getTotalRecords(),
              totalUnit: getTotalUnit()
            });
          }
        );
        res.map(product => {
          this.setState(prevState => ({
            selectedCheckboxes: {
              ...prevState.selectedCheckboxes,
              [product.id]: false
            }
          }));
        });
      })
      .catch(() => {
        this.setState({
          notificationMsg: "productsFilter"
        });
      });
  };

  handleChangePage = (event, page) => {
    // this.setState({
    //   selectedCheckboxes: {},
    // })
    const { currentPage, heighestVisitedPage, rowsPerPage } = this.state;
    if (page > currentPage && page > heighestVisitedPage) {
      this.setState(
        {
          currentPage: page,
          heighestVisitedPage: page,
          isLoading: true,
          showTable: false
        },
        () => {
          changePage(page, rowsPerPage)
            .then(res => {
              this.setState({
                data: res,
                isLoading: false,
                showTable: true
              });
            })
            .catch(() => {
              this.setState({
                notificationMsg: "changePage"
              });
            });
        }
      );
    } else {
      this.setState({
        currentPage: page
      });
    }
  };

  handleChangeRowsPerPage = event => {
    const { filterSelected } = this.state;
    this.setState(
      {
        rowsPerPage: event.target.value,
        isLoading: true,
        showTable: false,
        currentPage: 0,
        heighestVisitedPage: 0,
        totalRecords: 0,
        totalUnit: 0,
        data: [],
        selected: [],
        selectedData: [],
        eccommerceCategories: [],
        selectedCategory: ""
      },
      () => {
        productsFilter(filterSelected, event.target.value, 0)
          .then(res => {
            this.setState(
              {
                data: res,
                isLoading: false,
                showTable: true
              },
              () => {
                this.setState({
                  totalRecords: getTotalRecords(),
                  totalUnit: getTotalUnit()
                });
              }
            );
          })
          .catch(() => {
            this.setState({
              notificationMsg: "productsFilter"
            });
          });
      }
    );
  };

  handleSelected = (newSelected, newSelectedData) => {
    this.setState({
      selected: newSelected,
      selectedData: newSelectedData
    });
  };

  handleSelectAll = () => {
    const { data, selectedCheckboxes } = this.state;
    const newSelected = [];
    const newSelectedData = [];
    data.forEach(product => {
      getProductColor(product.ReferenceNumber).then(productColors => {
        newSelected.push(product.id);
        newSelectedData.push({
          ReferenceNumber: product.ReferenceNumber,
          section: "",
          category: "",
          subCategory: "",
          categories: [],
          subCategoies: [],
          price: product.Price,
          Colors: productColors.map(item => ({
            Name: item.Name,
            NumberOfItems: item.NumberOfItems,
            NumberOfItemsInInventory: item.NumberOfItemsInInventory,
            totalNumber: item.NumberOfItems,
            Sizes: item.Sizes
          }))
        });
        this.handleSetColors(productColors, product.ReferenceNumber);
      });
    });

    this.handleSelected(newSelected, newSelectedData);

    Object.keys(selectedCheckboxes).forEach(checkbox => {
      this.setState(prevState => ({
        selectedCheckboxes: {
          ...prevState.selectedCheckboxes,
          [checkbox]: true
        }
      }));
    });

    this.setState({
      selectAll: true
    });
  };

  handleDeselectAll = () => {
    const { selectedCheckboxes } = this.state;
    const newSelected = [];
    const newSelectedData = [];
    this.handleSelected(newSelected, newSelectedData);
    Object.keys(selectedCheckboxes).forEach(checkbox => {
      this.setState(prevState => ({
        selectedCheckboxes: {
          ...prevState.selectedCheckboxes,
          [checkbox]: false
        }
      }));
    });
    this.setState({
      selectAll: false
    });
  };

  handleChangeCheckbox = id => {
    const { selected, selectedData, data } = this.state;

    const selectedIndex = selected.indexOf(id);
    const selectedProduct = data.find(product => product.id === id);

    let newSelected = [];
    let newSelectedData = [];
    let currentProductColors = [];
    getProductColor(selectedProduct.ReferenceNumber).then(productColors => {
      currentProductColors = productColors;
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
        newSelectedData = newSelectedData.concat(selectedData, {
          ReferenceNumber: selectedProduct.ReferenceNumber,
          section: "",
          category: "",
          subCategory: "",
          categories: [],
          subCategoies: [],
          price: selectedProduct.Price,
          Colors: currentProductColors.map(item => ({
            Name: item.Name,
            NumberOfItems: item.NumberOfItems,
            NumberOfItemsInInventory: item.NumberOfItemsInInventory,
            totalNumber: item.NumberOfItems,
            Sizes: item.Sizes
          }))
        });
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        newSelectedData = newSelectedData.concat(selectedData.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
        newSelectedData = newSelectedData.concat(
          selectedData.slice(0, selectedIndex),
          selectedData.slice(selectedIndex + 1)
        );
      }
      this.handleSetColors(productColors, selectedProduct.ReferenceNumber);
      this.handleSelected(newSelected, newSelectedData);
    });

    this.setState(prevState => ({
      selectedCheckboxes: {
        ...prevState.selectedCheckboxes,
        [id]: !prevState.selectedCheckboxes[id]
      }
    }));
  };

  handleSetColors = (productColors, referenceNo) => {
    this.setState(prevState => ({
      colors: {
        ...prevState.colors,
        [referenceNo]: productColors
      }
    }));
  };

  // to send the selected items to the photographer.
  handleSendButton = () => {
    const { selectedData, filterSelected, rowsPerPage } = this.state;
    const toSendProducts = selectedData.map(product => ({
      ReferenceNumber: product.ReferenceNumber,
      SellingPrice: product.price,
      CategoryId: product.category.value,
      SubCategoryId: product.subCategory.value,
      Colors: product.Colors
    }));
    this.setState({
      isSending: true,
      notificationMsg: ""
    });
    sendProducts(toSendProducts)
      .then(res => {
        if (res.length) {
          this.setState({
            open: !this.state.open,
            isSending: false,
            DuplicationIssues: res
          });
        } else {
          this.setState({
            notificationMsg: "SendSuccessfully",
            selectedCheckboxes: {}
          });
          this.handleDeselectAll();
          productsFilter(filterSelected, rowsPerPage, 0)
            .then(res => {
              this.setState(
                {
                  data: res,
                  isSending: false,
                  selected: [],
                  selectedData: [],
                  colors: [],
                  currentPage: 0,
                  heighestVisitedPage: 0,
                  totalRecords: 0,
                  totalUnit: 0
                },
                () => {
                  this.setState({
                    totalRecords: getTotalRecords(),
                    totalUnit: getTotalUnit()
                  });
                }
              );
            })
            .catch(() => {
              this.setState({
                notificationMsg: "productsFilter"
              });
            });
        }
      })
      .catch(() => {
        this.setState({
          notificationMsg: "sendProducts"
        });
      });
  };

  render() {
    const {
      data,
      selected,
      selectedData,
      colors,
      filterOptions,
      filterSelected,
      eccommerceSections,
      eccommerceCategories,
      eccommerceSubCategories,
      selectedSection,
      selectedCategory,
      selectedCheckboxes,
      currentPage,
      totalRecords,
      totalUnit,
      isSending,
      selectAll,
      rowsPerPage,
      isLoading,
      showTable,
      notificationMsg,
      DuplicationIssues
    } = this.state;
    return (
      <Grid container justify="center">
        {notificationMsg !== "" && (
          <MessagesNotifications message={notificationMsg} />
        )}
        <Grid item xs={12} sm={10}>
          <FilterForm
            filterOptions={filterOptions}
            filterSelected={filterSelected}
            onChange={this.handleChange}
            onFilter={this.handleFilter}
          />
        </Grid>
        {isLoading && !showTable && (
          <Grid container justify="center">
            <GreenLoader />
          </Grid>
        )}
        {showTable && data.length === 0 && (
          <InfoBox message="No Data Available!" />
        )}
        {!isLoading && showTable && data.length > 0 && (
          <Grid item xs={12} sm={10}>
            <div className="card-root">
              <ManagerCardToolbar
                numSelected={selected.length}
                onSend={this.handleSendButton}
                isSending={isSending}
                isSelectAll={selectAll}
                totalUnit={totalUnit}
                onChange={this.handleChange}
                selectAll={this.handleSelectAll}
                deselectAll={this.handleDeselectAll}
              />
              <ManagerCard
                data={data}
                selected={selected}
                selectedData={selectedData}
                eccommerceSections={eccommerceSections}
                colors={colors}
                isSending={isSending}
                totalRecords={totalRecords}
                totalUnit={totalUnit}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                selectedSection={selectedSection}
                selectedCategory={selectedCategory}
                isSelectAll={selectAll}
                onChange={this.handleChange}
                onChangePrice={this.handleChangePrice}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleSendButton={this.handleSendButton}
                onChangeSection={this.handleChangeSection}
                setColors={this.handleSetColors}
                selectedCheckboxes={selectedCheckboxes}
                handleChange={this.handleChangeCheckbox}
              />
            </div>
          </Grid>
        )}
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            style={{
              backgroundColor: "#313639",
              color: "red",
              textAlign: "center"
            }}
            id="alert-dialog-slide-title"
          >
            {"Duplication Issues"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {DuplicationIssues.map((issue, index) => (
                <TreeItem nodeId="2" label={`Message: ${issue.Message}`}>
                  {issue.ReferenceNumbers.map((ob, index) => (
                    <TreeItem
                      nodeId={uid(5)}
                      label={`ReferenceNumbers: ${ob.ReferenceNumber}`}
                    >
                      <TreeItem nodeId={uid(6)} label="Colors">
                        {ob.Colors.map((color, index) => (
                          <div>
                            <TreeItem
                              nodeId={uid(7)}
                              label={`colorName: ${color.ColorName}`}
                            >
                              {color.Barcodes.map((br, index) => (
                                <div>
                                  <TreeItem
                                    nodeId={uid(8)}
                                    label={`BarCode: ${br.Barcode}`}
                                  />
                                  <TreeItem
                                    nodeId="8"
                                    label={`Size: ${br.SizeName}`}
                                  />
                                </div>
                              ))}
                            </TreeItem>
                          </div>
                        ))}
                      </TreeItem>
                    </TreeItem>
                  ))}
                </TreeItem>
              ))}
            </TreeView>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default Manager;
